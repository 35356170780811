import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { communication } from "../../../communication/communication";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Loader from "../../../Modals/Loader";
import { showLoader, hideLoader } from "../../../redux-store/loaderReducer";
import swal from "sweetalert";
import { useMemo } from "react";
import ReactPaginate from "react-paginate";



const MaterialTransferReport = () => {
    const loginUserDetails = useSelector((state) => state.userDetails);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);
    const [reportData, setReportData] = useState([])
    const [locations, setLocations] = useState();
    const [locationWiseSite, setLocationWiseSites] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(1)
    const [searchFilterValue, setSearchFilterValue] = useState({
        searchByLocation: "",
        searchBySite: "",
    });
    const [date, setDate] = useState({
        startDate: "",
        endDate: "",
    });
    const { searchByLocation, searchBySite } = searchFilterValue;
    const pageLimit = process.env.REACT_APP_PAGE_LIMIT;

    //Onclick set current page
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchByLocation || searchBySite || (date?.startDate && date?.endDate)) {
            searchMaterialTransportReport(searchByLocation, searchBySite, date?.startDate, date?.endDate);
        } else {
            getReportsData(selectedPage + 1);
        }
    };

    //get location sites According to location
    const getLocationWiseSites = async (locationId) => {
        try {
            setLocationWiseSites([]);
            if (locationId) {
                const result = await communication.getLocationWiseSites(locationId);
                if (result.data.status === "SUCCESS") {
                    setLocationWiseSites(result?.data?.sites);
                } else if (result?.data?.status === "JWT_INVALID") {
                    swal({ text: result.data?.message, icon: "warning" });
                    navigate("/login");
                } else {
                    setLocationWiseSites([]);
                }
            } else {
                setLocationWiseSites([]);
                getReportsData();

            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //fetch Material issue report by Location, site, startDate and endDate
    const searchMaterialTransportReport = async (toLocationId, toSiteId, startDate, endDate, page) => {
        try {
            if (toLocationId || toSiteId || (startDate && endDate)) {
                const responseFromServer = await communication.searchMaterialTransferReport(toLocationId, toSiteId, startDate, endDate, page);
                if (responseFromServer?.data?.status === "SUCCESS") {
                    setReportData(responseFromServer?.data?.materialTransportReport);
                    setPageCount(responseFromServer?.data?.totalPages);
                } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                    dispatch(hideLoader())
                    swal({ text: responseFromServer.data?.message, icon: "warning" });
                    navigate("/login");
                } else {
                    dispatch(hideLoader());
                    setReportData([]);
                    setPageCount(0);
                }
            } else {
                setSearchFilterValue({ searchByLocation: "", searchBySite: "", });
                setDate({ startDate: "", endDate: "" });
                getReportsData();
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //get data on initial load
    const getReportsData = async (page) => {
        try {
            dispatch(showLoader());
            const serverResponse = await communication.getMaterialTransferReport(page);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                setReportData(serverResponse?.data?.transferMaterial);
                setPageCount(serverResponse?.data?.totalPages);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                setReportData([]);
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }
    }

    //get all Locations data on initial load
    const fetchLocation = async () => {
        try {
            const responseFromServer = await communication.getAllLocations();
            if (responseFromServer.data.status === "SUCCESS") {
                //if User is director set all Locations otherWise set location according to accessControl
                if (loginUserDetails?.designation?.toLowerCase() === "director") {
                    setLocations(responseFromServer.data.locations);
                } else {
                    let location = [];
                    loginUserDetails?.accessControl?.forEach(access => {
                        responseFromServer?.data?.locations?.forEach((locationDetails) => {
                            if (access.locationName === locationDetails.locationName) {
                                location.push(locationDetails);
                            }
                        })
                    });
                    setLocations(location);

                }
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setLocations([]);
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Export Data to Excel
    const exportData = async () => {
        try {
            dispatch(showLoader());
            const serverResponse = await communication.exportMaterialTransportReportList();
            if (serverResponse.data) {
                //Create a Blob from the PDF Stream
                const file = new Blob([serverResponse.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
                });
                FileSaver.saveAs(file, "Transfered Materials.xlsx");
            } else {
                swal({ text: "Failed to export data in excel", icon: "warning" });
            }
            dispatch(hideLoader());
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    };

    useMemo(() => {
        if (searchByLocation) {
            searchMaterialTransportReport(searchByLocation, searchBySite, date?.startDate, date?.endDate, currentPage + 1);
        }
    }, [searchByLocation, searchBySite]);

    useMemo(() => {
        if (date?.startDate && date?.endDate) {
            searchMaterialTransportReport(searchByLocation, searchBySite, date?.startDate, date?.endDate)
        }
    }, [date])

    useEffect(() => {
        getReportsData();
        fetchLocation();
    }, []);

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <>
                        <div className="row reports_search_box">
                            <div className="col-lg-2 col-md-4 pe-lg-2 pe-md-0" style={{ display: "flex", alignItems: "flex-end" }}>
                                <select className="form-select" onChange={(event) => { { getLocationWiseSites(JSON.parse(event.target.value)?._id); setSearchFilterValue({ ...searchFilterValue, searchByLocation: JSON.parse(event.target.value)?._id }) } }} >
                                    <option value={JSON.stringify({ _id: "" })}>Select Location</option>
                                    {locations?.map((location, index) => (
                                        <option value={JSON.stringify(location)} selected={location?._id === searchFilterValue?.searchByLocation} key={index}>{location?.locationName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-2 col-md-4 pe-lg-2 pe-md-2 pe-lg-0 ps-lg-2 ps-md-0" style={{ display: "flex", alignItems: "flex-end" }}>
                                <select onChange={(event) => setSearchFilterValue({ ...searchFilterValue, searchBySite: JSON.parse(event.target.value)?._id })} className="form-select">
                                    <option value={JSON.stringify({ _id: "" })}>Select Site</option>
                                    {locationWiseSite?.map((sites, index) => {
                                        return (<option key={index} value={JSON.stringify(sites)} selected={sites?._id === searchFilterValue?.searchBySite}>{sites?.siteName}</option>)
                                    }
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-2 col-md-4 mt-lg-0 mt-md-3 ps-lg-2 ps-md-0">
                                <label>From</label>
                                <input type="date" value={date?.startDate} className='form-control' placeholder="From" onChange={(event) => setDate({ ...date, startDate: event.target.value })} />
                            </div>
                            <div className="col-lg-2 col-md-4 mt-lg-0 mt-md-3">
                                <label>To</label>
                                <input type="date" value={date?.endDate} className='form-control' placeholder="To" onChange={(event) => setDate({ ...date, endDate: event.target.value })} />
                            </div>
                            <div className="col-lg-4 col-md-8 pe-0 mt-lg-0 mt-md-3" style={{ display: "flex", alignItems: "flex-end", justifyContent: "end" }}>
                                {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.download_reports === true) ?
                                    <>
                                        <button onClick={() => exportData()}>Export<i className="fa-solid fa-file-excel ms-2"></i></button>
                                        {/* <ReactToPrint trigger={() => <button className="ms-3">Print<i className="fa-solid fa-print ms-2"></i></button>} content={() => componentRef.current} /> */}
                                    </>
                                    :
                                    <></>
                                }

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="reports_table">
                                    <div className="table_heading">
                                        <div className="sr_no">
                                            <h5>Sr. No.</h5>
                                        </div>
                                        <div className="material_name">
                                            <h5>Material</h5>
                                        </div>
                                        <div className="brand">
                                            <h5>Make/Brand</h5>
                                        </div>
                                        <div className="quantity">
                                            <h5>Quantity</h5>
                                        </div>
                                        <div className="from">
                                            <h5>From Location</h5>
                                        </div>
                                        <div className="site_name">
                                            <h5>From Site</h5>
                                        </div>
                                        <div className="to">
                                            <h5>To Location</h5>
                                        </div>
                                        <div className="site_name">
                                            <h5>To Site</h5>
                                        </div>
                                        <div className="date">
                                            <h5>Date</h5>
                                        </div>
                                    </div>
                                    {reportData?.length > 0 ?
                                        <>
                                            {
                                                reportData?.map((report, index) => {
                                                    return (
                                                        <div className="table_content">
                                                            <div className="sr_no">
                                                                <h5>{pageLimit * currentPage + (index + 1)}</h5>
                                                            </div>
                                                            <div className="material_name">
                                                                <h5>{report?.materialName}</h5>
                                                            </div>
                                                            <div className="brand">
                                                                <h5>{report?.brand}</h5>
                                                            </div>
                                                            <div className="quantity">
                                                                <h5>{report?.QuantityToTransfer}</h5>
                                                            </div>
                                                            <div className="from">
                                                                <h5>{report?.fromLocation}</h5>
                                                            </div>
                                                            <div className="site_name">
                                                                <h5>{report?.fromSite}</h5>
                                                            </div>
                                                            <div className="to">
                                                                <h5>{report?.toLocation}</h5>
                                                            </div>
                                                            <div className="site_name">
                                                                <h5>{report?.toSite}</h5>
                                                            </div>
                                                            <div className="time">
                                                                <h5>{new Date(report?.creationDate).toLocaleDateString()}</h5>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Materials Transfer Report Not Available</h5>
                                        </div>
                                    }
                                </div>
                            </div>
                            {pageCount > 1 &&
                                <div className="pagination_wrapper">
                                    <ReactPaginate
                                        previousLabel={pageCount === 1 ? "First" : "Prev"}
                                        nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        forcePage={currentPage}
                                        activeClassName={"active"} />
                                </div>
                            }
                        </div>
                    </>
            }
        </>
    );
}
export default MaterialTransferReport;