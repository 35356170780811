import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const MaterialTransferForm = ({ setShowMaterialTransferForm }) => {
    const [validator, showValidationMessage] = useValidator();
    const [locations, setLocations] = useState([]);
    const [fromSites, setFromSites] = useState([]);
    const [toSites, setToSites] = useState([]);
    const [issueNumbers, setIssueNumbers] = useState([]);
    const [allMaterials, setAllMaterials] = useState([]);
    const [brands, setBrands] = useState([]);
    const [QuantityAvailableInStore, setQuantityAvailableInStore] = useState("");
    const loginUserDetails = useSelector((state) => state.userDetails);
    const navigate = useNavigate();
    const [materialToTransfer, setMaterialToTransfer] = useState(
        {
            fromSiteId: "",
            fromLocationId: "",
            toSiteId: "",
            toLocationId: "",
            issueRefNo: "",
            vehicleNumber: "",
            transportName: "",
            materialId: "",
            brand: "",
            QuantityToTransfer: "",
            QuantityAvailableInStore: "",
            remark: ""
        }
    );

    const [materialUnitDetail, setMaterialUnitDetail] = useState(
        {
            unitId: "",
            unitName: ""
        }
    );

    const { fromSiteId, fromLocationId, toSiteId, toLocationId, issueRefNo, vehicleNumber, transportName, materialId, brand, QuantityToTransfer, remark } = materialToTransfer;

    //Fetch all locations
    const fetchAllLocations = async () => {

        try {
            const responseFromServer = await communication.getAllLocations();

            if (responseFromServer?.data?.status === "SUCCESS") {
                setLocations(responseFromServer?.data?.locations);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setLocations([]);
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //from Location Wise Sites
    const fromLocationWiseSites = async (locationId) => {
        try {
            const responseFromServer = await communication.getLocationWiseSites(locationId);

            if (responseFromServer?.data?.status === "SUCCESS") {
                setFromSites(responseFromServer?.data?.sites);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "error" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //To location wise Sites
    const toLocationWiseSites = async (locationId) => {
        try {

            const responseFromServer = await communication.getLocationWiseSites(locationId);

            if (responseFromServer?.data?.status === "SUCCESS") {
                setToSites(responseFromServer?.data?.sites);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "error" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //get location and site wise issue numbers
    const locationAndSiteWiseIssueNo = async (siteId) => {
        try {
            if (siteId) {
                const getIssueNumber = await communication.getLocationAndSiteWiseIssueNo(toLocationId, siteId);
                if (getIssueNumber?.data?.status === "SUCCESS") {
                    setIssueNumbers(getIssueNumber?.data?.issueMaterial);
                } else if (getIssueNumber?.data?.status === "JWT_INVALID") {
                    swal({ text: getIssueNumber.data?.message, icon: "error" });
                    navigate("/login");
                } else {
                    swal({ text: getIssueNumber.data?.message, icon: "error" });
                    setIssueNumbers([]);
                }
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Fetch All Materials on initial load
    const fetchAllMaterials = async () => {
        try {
            const responseFromServer = await communication.getAllMaterial();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setAllMaterials(responseFromServer?.data?.materials);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "error" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //get brands by material 
    const fetchBrands = async (materialId) => {
        try {
            if (materialId) {
                const getBrands = await communication.getBrandByMaterial(materialId);
                if (getBrands?.data?.status === "SUCCESS") {
                    setBrands(getBrands?.data?.brands);
                    //set unit id and unit name as per material id
                    const [{ unitId, unitName }] = getBrands?.data?.brands;
                    setMaterialUnitDetail({ ...materialUnitDetail, unitId: unitId, unitName: unitName });
                } else if (getBrands?.data?.status === "JWT_INVALID") {
                    swal({ text: getBrands.data?.message, icon: "error" });
                    navigate("/login");
                } else {
                    setBrands([]);
                    setMaterialUnitDetail({ unitId: "", unitName: "" });
                    swal({ text: getBrands.data?.message, icon: "error" });
                }
            } else {
                setBrands([]);
                setMaterialUnitDetail({ unitId: "", unitName: "" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //get Available quantity by brand
    const fetchAvailableQuantity = async (materialBrand) => {
        try {
            const serverResponse = await communication.getAvailableStockByBrand(materialId, materialBrand);
            if (serverResponse?.data?.status === "SUCCESS") {
                setQuantityAvailableInStore(serverResponse.data.availableMaterial);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setQuantityAvailableInStore(0);
                swal({ text: serverResponse.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    useEffect(() => {
        fetchAllLocations();
        fetchAllMaterials();
    }, []);

    const transferMaterial = async () => {
        try {

            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            if (QuantityToTransfer > QuantityAvailableInStore) {
                alert("Quantity is not available in store to transfer");
                return false;
            }

            const dataToSend = {
                ...materialToTransfer, unitId: materialUnitDetail?.unitId, QuantityAvailableInStore, transferedBy: loginUserDetails
            }

            const serverResponse = await communication.transferMaterial(dataToSend);

            if (serverResponse?.data?.status === "SUCCESS") {
                setShowMaterialTransferForm(false);
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "error" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }

    }

    return (
        <div className="issue_transfer">
            <div className="form">
                <div className="form_heading">
                    <h5>Stock Transfer</h5>
                </div>
                {/* <div className="row" style={{ textAlign: "left" }}>
                    <div className="col-12">
                        <h5><b>STN No. 01</b></h5>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <select name="fromLocation" className="form-select" onChange={(event) => { setMaterialToTransfer({ ...materialToTransfer, fromLocationId: JSON.parse(event.target.value)?._id }); fromLocationWiseSites(JSON.parse(event.target.value)?._id) }}>
                            <option value={JSON.stringify({ _id: "" })}>From Location</option>
                            {locations?.map((location, index) => (
                                <option value={JSON.stringify(location)} key={index}>{location?.locationName}</option>
                            ))}
                        </select>
                        {validator.message("fromLocation", fromLocationId, "required", {
                            messages: {
                                required: "Location is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <select name="fromSite" className="form-select" onChange={(event) => setMaterialToTransfer({ ...materialToTransfer, fromSiteId: JSON.parse(event.target.value)?._id })}>
                            <option value={JSON.stringify({ _id: "" })}>From Site</option>
                            {fromSites?.map((site, index) => (
                                <option value={JSON.stringify(site)} key={index}>{site?.siteName}</option>
                            ))}
                        </select>
                        {validator.message("fromSite", fromSiteId, "required", {
                            messages: {
                                required: "Site is required"
                            }
                        })}
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <select name="toLocation" className="form-select" onChange={(event) => { setMaterialToTransfer({ ...materialToTransfer, toLocationId: JSON.parse(event.target.value)?._id }); toLocationWiseSites(JSON.parse(event.target.value)?._id) }}>
                            <option value={JSON.stringify({ _id: "" })}>To Location</option>
                            {locations?.map((location, index) => (
                                <option value={JSON.stringify(location)} key={index}>{location?.locationName}</option>
                            ))}
                        </select>
                        {validator.message("toLocation", toLocationId, "required", {
                            messages: {
                                required: "Location is required"
                            }
                        })}
                    </div>

                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <select name="toSite" className="form-select" onChange={(event) => { setMaterialToTransfer({ ...materialToTransfer, toSiteId: JSON.parse(event.target.value)?._id }); locationAndSiteWiseIssueNo(JSON.parse(event.target.value)?._id) }}>
                            <option value={JSON.stringify({ _id: "" })}>To Site</option>
                            {toSites?.map((site, index) => (
                                <option value={JSON.stringify(site)} key={index}>{site?.siteName}</option>
                            ))}
                        </select>
                        {validator.message("toSite", toSiteId, "required", {
                            messages: {
                                required: "Site is required"
                            }
                        })}
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <select name="issueRefNo" onChange={(event) => setMaterialToTransfer({ ...materialToTransfer, issueRefNo: event.target.value })} className="form-select">
                            <option value="">Select Issue No.</option>
                            {issueNumbers?.map((issueNumber, index) => (
                                <option value={issueNumber?.issueNumber} key={index}>{issueNumber?.issueNumber}</option>
                            ))}
                        </select>
                        {validator.message("issueRefNo", issueRefNo, "required", {
                            messages: {
                                required: "Issue Number is required"
                            }
                        })}
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="vehicleNumber" onChange={(event) => setMaterialToTransfer({ ...materialToTransfer, vehicleNumber: event.target.value })} className="form-control" autoComplete="off" required />
                            <label className="input_label">Vehicle No.</label>
                        </div>
                        {validator.message("vehicleNumber", vehicleNumber, "required", {
                            messages: {
                                required: "Vehicle Number is required"
                            }
                        })}
                    </div>

                </div>
                <div className="row">

                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="transportName" onChange={(event) => setMaterialToTransfer({ ...materialToTransfer, transportName: event.target.value })} className="form-control" autoComplete="off" required />
                            <label className="input_label">Transporter Name</label>
                        </div>
                        {validator.message("transportName", transportName, "required|alpha_space", {
                            messages: {
                                required: "Transporter Name is required"
                            }
                        })}
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <select name="material" className="form-select" onChange={(event) => { setMaterialToTransfer({ ...materialToTransfer, materialId: JSON.parse(event.target.value)?._id }); fetchBrands(JSON.parse(event.target.value)?._id) }}>
                            <option value={JSON.stringify({ _id: "" })}>Select Material</option>
                            {allMaterials?.map((material, index) => (
                                <option value={JSON.stringify(material)} key={index}>{material?.materialName}</option>
                            ))}
                        </select>
                        {validator.message("material", materialId, "required", {
                            messages: {
                                required: "Material is required"
                            }
                        })}
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <select name="brand" className="form-select" onChange={(event) => { setMaterialToTransfer({ ...materialToTransfer, brand: event.target.value }); fetchAvailableQuantity(event.target.value) }}>
                            <option value={JSON.stringify({ brand: "" })}>Select Make/Brand</option>
                            {brands?.map((brandDetail, index) => (
                                <option value={brandDetail?.brand} key={index}>{brandDetail?.brand}</option>
                            ))}
                        </select>
                        {validator.message("brand", brand, "required", {
                            messages: {
                                required: "Brand is required"
                            }
                        })}
                    </div>


                </div>
                <div className="row">

                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="unit" value={materialUnitDetail?.unitName} className="form-control" autoComplete="off" required />
                            <label className="input_label">Unit</label>
                        </div>

                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="availableQty" value={QuantityAvailableInStore} className="form-control" autoComplete="off" required />
                            <label className="input_label">Quantity Available in Store</label>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="QuantityToTransfer" onChange={(event) => setMaterialToTransfer({ ...materialToTransfer, QuantityToTransfer: event.target.value })} className="form-control" autoComplete="off" required />
                            <label className="input_label">Quantity to Transfer</label>
                        </div>
                        {validator.message("QuantityToTransfer", QuantityToTransfer, "required|numeric", {
                            messages: {
                                required: "Quantity is required"
                            }
                        })}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="remark" onChange={(event) => setMaterialToTransfer({ ...materialToTransfer, remark: event.target.value })} className="form-control" autoComplete="off" required />
                            <label className="input_label">Remark</label>
                        </div>
                        {validator.message("remark", remark, "required|alpha_space", {
                            messages: {
                                required: "Remark is required"
                            }
                        })}
                    </div>
                </div>

                <div className="form_button">
                    <button onClick={() => transferMaterial()}>Add</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowMaterialTransferForm(false)}></i>
                </div>
            </div>
        </div >
    );
}
export default MaterialTransferForm;