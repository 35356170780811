import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { communication } from "../../communication/communication";
import swal from "sweetalert";

const QuotationReceipt = () => {
    const componentRef = useRef();
    const [quotation, setQuotation] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const quotationId = queryParams.get('quotationId');

    //get all uploaded Quotations for selected Purchase Order
    const getQuotations = async () => {
        const serverResponse = await communication.getQuotationById(quotationId);
        if (serverResponse.data.status === "SUCCESS") {
            setQuotation(serverResponse?.data?.quotation[serverResponse?.data?.quotation.length - 1])
        } else if (serverResponse?.data?.status === "JWT_INVALID") {
            swal({ text: serverResponse.data?.message, icon: "error" });
            navigate("/login");
        } else {
            setQuotation([]);
        }
    }

    useEffect(() => {
        getQuotations();
    }, [])

    return (

        <div className="receipt_main">
            <div className="print_button">
                <ReactToPrint trigger={() => <i className="fa-solid fa-print" title="Print"></i>} content={() => componentRef.current} />
            </div>

            <div className="receipt_section" ref={componentRef}>
                <h5 className="heading"><b>TERM SHEET FOR PURCHASE ORDER (MATERIAL)</b></h5>
                {/*----General Information------*/}
                <table className="general_information">
                    <thead>
                        <tr>
                            <th colSpan={3}>General Information</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="general_information_data">
                            <td>1</td>
                            <td>PO. Number</td>
                            <td>{quotation?.poNumber}</td>
                        </tr>
                        <tr className="general_information_data">
                            <td>2</td>
                            <td>Name of Package</td>
                            <td>{quotation?.vendor?.packageName}</td>
                        </tr>
                        <tr className="general_information_data">
                            <td>3</td>
                            <td>Name of Vender/Supplier</td>
                            <td>{quotation?.vendor?.venderName}</td>
                        </tr>
                        <tr className="general_information_data">
                            <td>4</td>
                            <td>Contact No. of Vender/Supplier</td>
                            <td>{quotation?.vendor?.contactNumber}</td>
                        </tr>
                        <tr className="general_information_data">
                            <td>5</td>
                            <td>Email of Vender/Supplier</td>
                            <td>{quotation?.vendor?.venderEmail}</td>
                        </tr>
                        <tr className="general_information_data">
                            <td>6</td>
                            <td>Location/Chainage Where Material has to be Supplied</td>
                            <td>{quotation?.vendor?.suppliedLocation}</td>
                        </tr>
                        <tr className="general_information_data">
                            <td>7</td>
                            <td>Description of Material</td>
                            <td>{quotation?.vendor?.materialDescription}</td>
                        </tr>
                        <tr className="general_information_data">
                            <td>8</td>
                            <td>Unit of Measurement</td>
                            <td>{quotation?.vendor?.measurementUnit}</td>
                        </tr>
                        <tr className="general_information_data">
                            <td>9</td>
                            <td>Technical Specification of Material</td>
                            <td>{quotation?.vendor?.technicalSpecification}</td>
                        </tr>
                        <tr className="general_information_data">
                            <td>10</td>
                            <td>Delivery Schedule of Material Supply</td>
                            <td>{quotation?.vendor?.deliverySchedule}</td>
                        </tr>
                        <tr className="general_information_data">
                            <td>11</td>
                            <td>Rejection Tollerence Limit, If any</td>
                            <td>{quotation?.vendor?.rejectionLimit}</td>
                        </tr>
                        <tr className="general_information_data">
                            <td>12</td>
                            <td>Warranty/Guarantee</td>
                            <td>{quotation?.vendor?.waranty}</td>
                        </tr>
                    </tbody>
                </table>
                {/*-----Bill of Quantity-----*/}
                <table className="bill_of_quantity">
                    <thead>
                        <tr>
                            <th colSpan={7}>Bill of Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bill_of_quantity_heading">
                            <th>Sr. No.</th>
                            <th>Material</th>
                            <th>Brand</th>
                            <th>Description of Material</th>
                            <th>Approx Qty.</th>
                            <th>Unit</th>
                            <th>Rate</th>
                        </tr>
                        {quotation?.bill?.map((boq, index) => {
                            return (
                                <tr className="bill_of_quantity_data">
                                    <td>{index + 1}</td>
                                    <td>{boq?.materialName}</td>
                                    <td>{boq?.brand}</td>
                                    <td style={{ width: "20%", overflow: "hidden" }}>{boq?.description}</td>
                                    {boq?.unlimitedQuantity ? <td>Unlimited</td>
                                        :
                                        <td>{boq?.approxQty}</td>
                                    }
                                    <td>{boq?.unitName}</td>
                                    <td>{boq?.rate}</td>
                                </tr>
                            )
                        })}

                        <tr>
                            <td colSpan={7}><b>#Note - Above Rates are Excluding GST. GST shall be paid extra if and as applicable from time to time.</b></td>
                        </tr>
                    </tbody>
                </table>

                {/*-----Financial Term(A)---*/}
                <table className="financial_term">
                    <thead>
                        <tr>
                            <th colSpan={4}>Financial Term (A)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="financial_term_heading">
                            <th>Sr. No.</th>
                            <th>Whether above rates includes following cost</th>
                            <th>Specify</th>
                            <th>Remark</th>
                        </tr>
                        <tr className="financial_term_data">
                            <td>1</td>
                            <td>Loading of Material</td>
                            <td>{quotation?.financialTermA?.materialLoading}</td>
                            <td>{quotation?.financialTermA?.loadingMaterialDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>2</td>
                            <td>Unloading of Material</td>
                            <td>{quotation?.financialTermA?.materialUnloading}</td>
                            <td>{quotation?.financialTermA?.materialUnloadingDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>3</td>
                            <td>Transportation Charges</td>
                            <td>{quotation?.financialTermA?.transportationCharges}</td>
                            <td>{quotation?.financialTermA?.transportationChargesDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>4</td>
                            <td>Royalty(If any)</td>
                            <td>{quotation?.financialTermA?.royalty}</td>
                            <td>{quotation?.financialTermA?.royaltyDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>5</td>
                            <td>Other Taxes and Cess (If any)</td>
                            <td>{quotation?.financialTermA?.taxes}</td>
                            <td>{quotation?.financialTermA?.taxesDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>6</td>
                            <td>Other (If any)</td>
                            <td>{quotation?.financialTermA?.others}</td>
                            <td>{quotation?.financialTermA?.otherDetail}</td>
                        </tr>
                    </tbody>
                </table>

                {/*---Financial Term(B)----*/}
                <table className="financial_term">
                    <thead>
                        <tr>
                            <th colSpan={4}>Financial Term (B)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="financial_term_heading">
                            <th>Sr. No.</th>
                            <th>Description</th>
                            <th>Specify</th>
                            <th>Remark</th>
                        </tr>
                        <tr className="financial_term_data">
                            <td>1</td>
                            <td>Bill Submission Cycle</td>
                            <td>{quotation?.financialTermB?.billSubmission}</td>
                            <td>{quotation?.financialTermB?.billSubmissionDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>2</td>
                            <td>Credit Period in Days</td>
                            <td>{quotation?.financialTermB?.creditPeriod}</td>
                            <td>{quotation?.financialTermB?.creditPeriodDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>3</td>
                            <td>Any other Specific Payment Terms</td>
                            <td>{quotation?.financialTermB?.paymentTerm}</td>
                            <td>{quotation?.financialTermB?.paymentTermDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>4</td>
                            <td>Security Deposit</td>
                            <td>{quotation?.financialTermB?.securityDeposite}</td>
                            <td>{quotation?.financialTermB?.securityDepositeDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>5</td>
                            <td>TDS</td>
                            <td>{quotation?.financialTermB?.tds}</td>
                            <td>{quotation?.financialTermB?.tdsDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>6</td>
                            <td>Royalty (If any)</td>
                            <td>{quotation?.financialTermB?.termBRoyalty}</td>
                            <td>{quotation?.financialTermB?.termBRoyaltyDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>7</td>
                            <td>Other Taxes and Cess (If any)</td>
                            <td>{quotation?.financialTermB?.otherTaxes}</td>
                            <td>{quotation?.financialTermB?.otherTaxesDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>8</td>
                            <td>Liquidity Damages / Penalty for Delay</td>
                            <td>{quotation?.financialTermB?.delayPenalty}</td>
                            <td>{quotation?.financialTermB?.delayPenaltyDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>9</td>
                            <td>Penalty for Substandard Material</td>
                            <td>{quotation?.financialTermB?.substandardMaterial}</td>
                            <td>{quotation?.financialTermB?.substandardMaterialDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>10</td>
                            <td>Advance against Material</td>
                            <td>{quotation?.financialTermB?.advance}</td>
                            <td>{quotation?.financialTermB?.advanceDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>11</td>
                            <td>Terms for Recovery for Advance against Material</td>
                            <td>{quotation?.financialTermB?.recovery}</td>
                            <td>{quotation?.financialTermB?.recoveryDetail}</td>
                        </tr>
                        <tr className="financial_term_data">
                            <td>12</td>
                            <td>Other (If any)</td>
                            <td>{quotation?.financialTermB?.termBOthers}</td>
                            <td>{quotation?.financialTermB?.termBOthersDetail}</td>
                        </tr>
                    </tbody>
                </table>

                {/*----Special Remark----*/}
                <div className="special_remark">
                    <div className="row">
                        <div className="col-12">
                            <h5><b>Special Remark</b> - <span>{quotation.specialRemark}</span></h5>
                        </div>
                    </div>
                </div>
                <div className="approved_section">
                    <div className="row">
                        <div className="col-6 finalized_by">
                            <h5><b>FINALIZED BY</b></h5>
                            <h6>(dated signature with name & designation)</h6>
                        </div>
                        <div className="col-6 approved_by">
                            <h5><b>APPROVED BY</b></h5>
                            <h6>(dated signature with name & designation)</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 accepted_by">
                            <h5><b>ACCEPTED BY</b></h5>
                            <h6>(Vender/Agency Representative dated signature with name & designation)</h6>
                        </div>
                    </div>
                </div>

                {/*----Type of Materials----*/}
                <table className="material_type">
                    <thead>
                        <tr>
                            <th colSpan={3}>Type of Materials</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="material_type_data">
                            <td>Binding Wire</td>
                            <td>Geo Membrane</td>
                            <td>Rubble Stone</td>
                        </tr>
                        <tr className="material_type_data">
                            <td>Bitumen Concrete</td>
                            <td>Gravels</td>
                            <td>Sand</td>
                        </tr>
                        <tr className="material_type_data">
                            <td>Bitumen Emulsion</td>
                            <td>Holticulture Material</td>
                            <td>Selected Soil</td>
                        </tr>
                        <tr className="material_type_data">
                            <td>Bitumen</td>
                            <td>Hume Pipes</td>
                            <td>Shuttering Plates</td>
                        </tr>
                        <tr className="material_type_data">
                            <td>Boulders</td>
                            <td>Lime</td>
                            <td>Shuttering Verticals</td>
                        </tr>
                        <tr className="material_type_data">
                            <td>Boundary Pillar</td>
                            <td>Lubricants</td>
                            <td>Sign Boards</td>
                        </tr>
                        <tr className="material_type_data">
                            <td>Cement</td>
                            <td>Marking Paint</td>
                            <td>Stone Dust</td>
                        </tr>
                        <tr className="material_type_data">
                            <td>Clay Bricks</td>
                            <td>Metal</td>
                            <td>Structural Steel</td>
                        </tr>
                        <tr className="material_type_data">
                            <td>Concrete Chemicals</td>
                            <td>Murrum / Copra</td>
                            <td>Traffic Accessories</td>
                        </tr>
                        <tr className="material_type_data">
                            <td>Concrete Kerb</td>
                            <td>Pitching Stone</td>
                            <td>Traffic Sign Boards</td>
                        </tr>
                        <tr className="material_type_data">
                            <td>Curing Compound</td>
                            <td>RCC KM Stone</td>
                            <td>Water</td>
                        </tr>
                        <tr className="material_type_data">
                            <td>Dressed Stone</td>
                            <td>Reinforcement Bars</td>
                            <td>Wire Fencing</td>
                        </tr>
                        <tr className="material_type_data">
                            <td>Fly Ash Bricks</td>
                            <td>RMC</td>
                            <td>Fuel</td>
                        </tr>
                        <tr className="material_type_data">
                            <td>Road Delineators</td>
                        </tr>
                    </tbody>
                </table>

                {/*----Unit of Measurement----*/}
                <table className="unit_of_measurement">
                    <thead>
                        <tr>
                            <th colSpan={3}>Unit of Measurement (UOM)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="unit_data">
                            <td>Cubic Feet</td>
                            <td>Litre</td>
                            <td>Running Meter</td>
                        </tr>
                        <tr className="unit_data">
                            <td>Cubic Meter</td>
                            <td>Metric Tonne</td>
                            <td>Square Feet</td>
                        </tr>
                        <tr className="unit_data">
                            <td>Number(s)</td>
                            <td>Packet / Box</td>
                            <td>Square Meter</td>
                        </tr>
                        <tr className="unit_data">
                            <td>KG (Kelogram)</td>
                            <td>Running Feet</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    );
}
export default QuotationReceipt;