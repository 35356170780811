import React, { useState } from 'react';
import { communication } from '../../communication/communication';
import useValidator from '../../formValidation/useValidator';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const UpdateLocation = ({ setShowUpdateLocation, showUpdateLocation, fetchLocation }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [newLocationName, setNewLocationName] = useState(showUpdateLocation.locationName);

    //Update location name
    const updateLocation = async () => {
        try {
            //check location is entered or not
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const result = await communication.updateLocation(newLocationName, showUpdateLocation.locationId);
            if (result.data.status === "SUCCESS") {
                setShowUpdateLocation({ isModalShow: false, locationId: "", locationName: "" });
                fetchLocation()
                swal({ text: result.data?.message, icon: "success" });
            } else if (result?.data?.status === "JWT_INVALID") {
                swal({ text: result.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: result.data?.message, icon: "warning" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <div className="add_new_designation">
            <div className="form">
                <div className="form_heading">
                    <h5>Update Location</h5>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="input_group">
                            <input type="text" name="email" onChange={(event) => { setNewLocationName(event.target.value) }} value={newLocationName} className="form-control" autoComplete="off" required />
                        </div>
                        {validator.message("newLocationName", newLocationName, "required|alpha_space", {
                            messages: {
                                required: "Location is required"
                            }
                        })}
                    </div>
                </div>
                <div className="form_button">
                    <button onClick={() => updateLocation()} >Update</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowUpdateLocation({ isModalShow: false, locationId: "", locationName: "" })}  ></i>
                </div>
            </div>
        </div>
    );
}

export default UpdateLocation