import React from "react";

const Loader = () => {
    return (
        <div className="loader_main">
            <div className="loader">
            </div>
        </div>
    );
}
export default Loader;