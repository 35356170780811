import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import Loader from "../../Modals/Loader";
import { confirmAlert } from 'react-confirm-alert';
import { removeCookies } from "../../redux-store/userAuthenticateReducer";
import UpdatePurchaseIndent from "../forms/UpdatePurchaseIndent";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

const PurchaseIndent = () => {
    const loginUserDetails = useSelector((state) => state.userDetails);
    const [purchaseIndents, setPurchaseIndents] = useState([]);
    const [selectedPurchaseIndent, setSelectedPurchaseIndent] = useState([]);
    const [updateIndent, setShowUpdateIndent] = useState({ isShowModal: false, purchaseIndentId: "", createdBy: "", categoryId: "", materialId: "", locationId: "", siteId: "", deliveryTo: "", materialDescription: "", quantityAvailableInStore: "", quantityRequired: "", unlimitedQuantity: "", materialTechSpecification: "", requiredFor: "", remark: "" });
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);

    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchPurchaseIndentBySearch(searchValue, selectedPage + 1);
        } else {
            fetchAllIndents(selectedPage + 1);
        }
    }

    // Search filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchPurchaseIndentBySearch(searchString, currentPage + 1);
        } else {
            const responseFromServer = await communication.getPurchaseIndentList();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setPurchaseIndents(responseFromServer?.data?.purchaseIndents);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                navigate('/login');
                swal({ text: responseFromServer.data?.message, icon: "warning" });

            } else {
                setPurchaseIndents([]);
                setPageCount(0);
            }
        }
    }

    //fetch purchase indents by search
    const fetchPurchaseIndentBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchIndentList(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setPurchaseIndents(responseFromServer?.data?.purchaseIndents);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setPurchaseIndents([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //set Selected Purchase indent
    const selectIndent = (value, indent) => {
        if (value) {
            setSelectedPurchaseIndent([...selectedPurchaseIndent, indent]);
        } else {
            const data = selectedPurchaseIndent.filter(indents => {
                return indents._id !== indent._id;
            })
            setSelectedPurchaseIndent(data);
        }
    }


    //approve Purchase Indent
    const approvePurchaseIndent = async () => {
        try {
            //check indent select or not
            if (selectedPurchaseIndent?.length === 0) {
                swal({ text: "Please select at least one indent", icon: "warning" });
                return false;
            }
            dispatch(showLoader())
            //call api
            const serverResponse = await communication.approvePurchaseIndent(selectedPurchaseIndent);
            if (serverResponse.data.status === "SUCCESS") {
                dispatch(hideLoader())
                fetchAllIndents();
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader())
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }
        } catch (error) {
            dispatch(hideLoader())
            swal({ text: error?.message, icon: "error" });
        }

    }

    //fetch indent on initial load
    const fetchAllIndents = async (page) => {
        try {
            dispatch(showLoader())
            const responseFromServer = await communication.getPurchaseIndentList(page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader())
                setPurchaseIndents(responseFromServer?.data?.purchaseIndents);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(removeCookies());
                navigate('/login');
                swal({ text: responseFromServer.data?.message, icon: "warning" });

            } else {
                dispatch(hideLoader())
                setPurchaseIndents([]);
                setPageCount(0);
            }
        } catch (error) {
            dispatch(hideLoader())
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchAllIndents();
    }, []);

    //Delete Purchase Indent
    const deletePurchaseIndent = async (purchaseIndentId) => {
        try {

            confirmAlert({
                title: 'Are you sure you want to Delete Purchase Indent?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            const serverResponse = await communication.deletePurchaseIndent(purchaseIndentId);
                            if (serverResponse?.data?.status === "SUCCESS") {
                                fetchAllIndents();
                                swal({ text: serverResponse.data?.message, icon: "success" });
                            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                                swal({ text: serverResponse.data?.message, icon: "warning" });
                                navigate("/login");
                            } else {
                                swal({ text: serverResponse.data?.message, icon: "warning" });
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { return false }
                    }
                ],
                overlayClassName: "react-confirm-alert"
            });

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    return (
        <>
            {

                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="dashboard_tab_main">
                        <h4 className="title">Procurement - New Request Indents</h4>
                        <div className='search_div'>
                            <input type="text" onChange={(event) => { searchFilter(event.target.value); setSearchValue(event.target.value) }} className='form-control' placeholder='search by material, category, site' title="search by Material, Category, Site" />
                            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.approval_purchase_indent === true) ?
                                <button onClick={() => { approvePurchaseIndent() }}>Approve</button>
                                :
                                <></>
                            }
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="purchase_indent">
                                    <div className="table_heading">
                                        <div className="checkbox">
                                            <h5>Sr.No.</h5>
                                        </div>
                                        <div className="material_name">
                                            <h5>Material</h5>
                                        </div>
                                        <div className="category">
                                            <h5>Category</h5>
                                        </div>
                                        <div className="deliver_to">
                                            <h5>Deliver To</h5>
                                        </div>
                                        <div className="req_quantity">
                                            <h5>Required Qty.</h5>
                                        </div>
                                        <div className="avail_quantity">
                                            <h5>Available Qty.</h5>
                                        </div>
                                        <div className="location">
                                            <h5>Location</h5>
                                        </div>
                                        <div className="site_name">
                                            <h5>Site</h5>
                                        </div>
                                        <div className="specification">
                                            <h5>Specification</h5>
                                        </div>
                                        <div className="req_for">
                                            <h5>Required for</h5>
                                        </div>
                                        <div className="description_of_material">
                                            <h5>Description</h5>
                                        </div>
                                        <div className="remark">
                                            <h5>Remarks</h5>
                                        </div>
                                        <div className="date">
                                            <h5>Date</h5>
                                        </div>
                                        <div className="user_action">
                                            <h5>Action</h5>
                                        </div>

                                    </div>
                                    {purchaseIndents?.length > 0 ?
                                        <>
                                            {purchaseIndents?.map((indent, index) => (
                                                <div className="table_content">
                                                    <div className="checkbox approve_checkbox">
                                                        <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" onChange={(event) => { selectIndent(event.target.checked, indent) }} />
                                                        <h5>{index + 1}</h5>
                                                    </div>
                                                    <div className="material_name">
                                                        <h5>{indent?.materialName}</h5>
                                                    </div>
                                                    <div className="category">
                                                        <h5>{indent?.categoryName}</h5>
                                                    </div>
                                                    <div className="deliver_to">
                                                        <h5>{indent?.deliveryTo}</h5>
                                                    </div>
                                                    <div className="req_quantity">
                                                        <h5>{indent?.unlimitedQuantity === false ? indent?.quantityRequired : "Unlimited"}</h5>
                                                    </div>
                                                    <div className="avail_quantity">
                                                        <h5>{indent?.quantityAvailableInStore}</h5>
                                                    </div>
                                                    <div className="location">
                                                        <h5>{indent?.locationName}</h5>
                                                    </div>
                                                    <div className="site_name">
                                                        <h5>{indent?.siteName}</h5>
                                                    </div>
                                                    <div className="specification">
                                                        <h5>{indent?.materialTechSpecification}</h5>
                                                    </div>
                                                    <div className="req_for">
                                                        <h5>{indent?.requiredFor}</h5>
                                                    </div>
                                                    <div className="description_of_material">
                                                        <h5>{indent?.materialDescription}</h5>
                                                    </div>
                                                    <div className="remark">
                                                        <h5>{indent?.remark}</h5>
                                                    </div>
                                                    <div className="date">
                                                        <h5>{indent?.createdDate}</h5>
                                                    </div>
                                                    <div className="user_action">
                                                        <div className="action">
                                                            <i className="fa-solid fa-pen-to-square pe-1" onClick={() => setShowUpdateIndent({ isShowModal: true, purchaseIndentId: indent?._id, createdBy: indent?.createdBy?.userName, categoryId: indent?.categoryId, materialId: indent?.materialId, locationId: indent?.locationId, siteId: indent?.siteId, deliveryTo: indent?.deliveryTo, materialDescription: indent?.materialDescription, quantityAvailableInStore: indent?.quantityAvailableInStore, quantityRequired: indent?.quantityRequired, unlimitedQuantity: indent?.unlimitedQuantity, materialTechSpecification: indent?.materialTechSpecification, requiredFor: indent?.requiredFor, remark: indent?.remark })}></i>
                                                            <i className="fa-solid fa-trash ps-1" onClick={() => deletePurchaseIndent(indent?._id)}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Purchase Indents Not Available</h5>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                        {updateIndent?.isShowModal &&
                            < div className="modal_box">
                                <UpdatePurchaseIndent updateIndent={updateIndent} setShowUpdateIndent={setShowUpdateIndent} fetchAllIndents={fetchAllIndents} />
                            </div>
                        }

                    </div>
            }
        </>
    );
}
export default PurchaseIndent;