import React, { useEffect, useState } from "react";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../../Modals/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import { useDispatch, useSelector } from 'react-redux';

const UploadQuotaion = ({ setShowUploadQuotationForm, selectedPurchaseOrder, getUploadedQuotations }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [unlimitedQuantity, setUnlimitedQuantity] = useState(false);
    const [boqList, setBoqList] = useState([]);
    const [specialRemark, setSpecialRemark] = useState();
    const poNumber = selectedPurchaseOrder?.poNumber;
    const poId = selectedPurchaseOrder?._id;
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();



    //Set Default values for vender
    const [vender, setVender] = useState({
        packageName: "",
        venderName: "",
        representativeName: "",
        contactNumber: "",
        suppliedLocation: "",
        venderEmail: "",
        materialDescription: "",
        measurementUnit: "",
        technicalSpecification: "",
        deliverySchedule: "",
        rejectionLimit: "",
        waranty: "",
    });

    //Set default values for bill
    const [bill, setBill] = useState([{
        approxQty: "",
        unit: "",
        rate: "",
        remark: "",
        description: ""
    }])

    //Set default values for Financial term A
    const [financialTermA, setFinancialTermA] = useState({
        materialLoading: "",
        loadingMaterialDetail: "",
        materialUnloading: "",
        materialUnloadingDetail: "",
        transportationCharges: "",
        transportationChargesDetail: "",
        royalty: "",
        royaltyDetail: "",
        taxes: "",
        taxesDetail: "",
        others: "",
        otherDetail: ""
    })

    //Set default values for Financial term B
    const [financialTermB, setFinancialTermB] = useState({
        billSubmission: "",
        billSubmissionDetail: "",
        creditPeriod: "",
        creditPeriodDetail: "",
        paymentTerm: "",
        paymentTermDetail: "",
        securityDeposite: "",
        securityDepositeDetail: "",
        tds: "",
        tdsDetail: "",
        termBRoyalty: "",
        termBRoyaltyDetail: "",
        otherTaxes: "",
        otherTaxesDetail: "",
        delayPenalty: "",
        delayPenaltyDetail: "",
        substandardMaterial: "",
        substandardMaterialDetail: "",
        advance: "",
        advanceDetail: "",
        recovery: "",
        recoveryDetail: "",
        termBOthers: "",
        termBOthersDetail: "",
    });

    //Get nput values for vender 
    const venderDetail = (event) => {
        setVender({ ...vender, [event.target.name]: event.target.value });
    }
    //Get BOQ Input values
    const billDetails = (event) => {
        setBill({ ...bill, [event.target.name]: event.target.value });
    }
    //Get Financial Term A values
    const financialTermADetail = (event) => {
        setFinancialTermA({ ...financialTermA, [event.target.name]: event.target.value });
    }
    //Get Financial Term B values
    const financialTermBDetail = (event) => {
        setFinancialTermB({ ...financialTermB, [event.target.name]: event.target.value });
    }

    //Add item in boq list
    const addBoq = () => {
        //check Validation 
        if (unlimitedQuantity !== true) {
            if ([undefined, null, ""].includes(bill.approxQty)) {
                swal({ text: "Approx Quantity is Require", icon: "error" });
                return false;
            }
        }
        if ([undefined, null, ""].includes(bill.rate)) {
            swal({ text: "Rate is Required", icon: "error" });
            return false;
        }
        if ([undefined, null, ""].includes(bill.description)) {
            swal({ text: "Description is Required", icon: "error" });
            return false;
        }

        setBoqList([...boqList, { materialName: selectedPurchaseOrder?.materialName, materialId: selectedPurchaseOrder?.materialId, categoryName: selectedPurchaseOrder?.categoryName, categoryId: selectedPurchaseOrder?.categoryId, unitName: selectedPurchaseOrder?.unitName, unitId: selectedPurchaseOrder?.unitId, brand: bill.brand, approxQty: bill.approxQty, unlimitedQuantity: unlimitedQuantity, rate: bill.rate, description: bill.description }]);

    }

    //Delete Boq list items
    const deleteItem = (index) => {
        const updatedList = boqList.filter((elem, id) => {
            return index !== id;
        });
        setBoqList(updatedList);
    }

    //Upload Quotation
    const uploadeQuotation = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            const dataToSend = {
                poNumber,
                poId,
                vender,
                bill: boqList,
                financialTermA,
                financialTermB,
                specialRemark
            }
            const serverResponse = await communication.uploadedQuotations(dataToSend);
            if (serverResponse.data.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "success" });
                setShowUploadQuotationForm(false);
                getUploadedQuotations();
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "error" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="upload_quotaion_form">
                        <div className="form">
                            <div className="form_heading">
                                <h5>Upload Quotation</h5>
                            </div>
                            <div className="row">
                                <div className="col-12" style={{ textAlign: "left" }}>
                                    <h5><b>PO Number: {selectedPurchaseOrder?.poNumber}</b></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <div className="input_group">
                                        <input type="text" name="packageName" className="form-control" onChange={(event) => venderDetail(event)} autoComplete="off" required />
                                        <label className="input_label">Name Of Package</label>
                                    </div>
                                    {validator.message("packageName", vender.packageName, "required|alpha_space", {
                                        messages: {
                                            required: "Package Name is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="input_group">
                                        <input type="text" name="venderName" className="form-control" onChange={(event) => venderDetail(event)} autoComplete="off" required />
                                        <label className="input_label">Name Of Vendor / Supplier</label>
                                    </div>
                                    {validator.message("venderName", vender.venderName, "required|alpha_space", {
                                        messages: {
                                            required: "Vender / Supplier Name is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="input_group">
                                        <input type="text" name="representativeName" className="form-control" onChange={(event) => venderDetail(event)} autoComplete="off" required />
                                        <label className="input_label">Vendor / Supplier Representative Name</label>
                                    </div>
                                    {validator.message("representativeName", vender.representativeName, "required|alpha_space", {
                                        messages: {
                                            required: "Representative Name is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <div className="input_group">
                                        <input type="text" name="contactNumber" className="form-control" onChange={(event) => venderDetail(event)} autoComplete="off" required />
                                        <label className="input_label">Contact No. Of Vendor / Supplier</label>
                                    </div>
                                    {validator.message("contactNumber", vender.contactNumber, "required|numeric|min:10|max:10", {
                                        messages: {
                                            required: "Contact Number is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="input_group">
                                        <input type="text" name="suppliedLocation" className="form-control" onChange={(event) => venderDetail(event)} autoComplete="off" required />
                                        <label className="input_label">Location/Chainage Material to supplied</label>
                                    </div>
                                    {validator.message("suppliedLocation", vender.suppliedLocation, "required|alpha_space", {
                                        messages: {
                                            required: "Location is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="input_group">
                                        <input type="text" name="venderEmail" className="form-control" onChange={(event) => venderDetail(event)} autoComplete="off" required />
                                        <label className="input_label">Email Of Vendor / Supplier</label>
                                    </div>
                                    {validator.message("venderEmail", vender.venderEmail, "required|email", {
                                        messages: {
                                            required: "Email is required"
                                        }
                                    })}
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="input_group">
                                        <input type="text" name="materialDescription" className="form-control" onChange={(event) => venderDetail(event)} autoComplete="off" required />
                                        <label className="input_label">Description of Material</label>
                                    </div>
                                    {validator.message("materialDescription", vender.materialDescription, "required", {
                                        messages: {
                                            required: "Material Description is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="input_group">
                                        <input type="text" name="measurementUnit" className="form-control" onChange={(event) => venderDetail(event)} autoComplete="off" required />
                                        <label className="input_label">Unit Of Measurement</label>
                                    </div>
                                    {validator.message("measurementUnit", vender.measurementUnit, "required|alpha_space", {
                                        messages: {
                                            required: "Unit is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="input_group">
                                        <input type="text" name="technicalSpecification" className="form-control" onChange={(event) => venderDetail(event)} autoComplete="off" required />
                                        <label className="input_label">Technical Specification of Material</label>
                                    </div>
                                    {validator.message("technicalSpecification", vender.technicalSpecification, "required|alpha_space", {
                                        messages: {
                                            required: "Technical Specification is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <div className="input_group">
                                        <input type="text" name="deliverySchedule" className="form-control" onChange={(event) => venderDetail(event)} autoComplete="off" required />
                                        <label className="input_label">Delivery Schedule of Material Supply</label>
                                    </div>
                                    {validator.message("deliverySchedule", vender.deliverySchedule, "required|alpha_space", {
                                        messages: {
                                            required: "Delivery Schedule is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="input_group">
                                        <input type="text" name="rejectionLimit" className="form-control" onChange={(event) => venderDetail(event)} autoComplete="off" required />
                                        <label className="input_label">Rejection Tollerence Limit,if any</label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="input_group">
                                        <input type="text" name="waranty" className="form-control" onChange={(event) => venderDetail(event)} autoComplete="off" required />
                                        <label className="input_label">Warranty / Guarantee</label>
                                    </div>
                                    {validator.message("waranty", vender.waranty, "required|alpha_space", {
                                        messages: {
                                            required: "Warranty/Guaramtee is required"
                                        }
                                    })}
                                </div>
                            </div>


                            {/*----Bill of Quantities(BOQ)-----*/}
                            <div className="row mt-4">
                                <div className="col-12" style={{ textAlign: "left" }}>
                                    <h5><b>Bill of Quantities (BOQ)</b></h5>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="input_group">
                                        <input type="text" name="material" value={selectedPurchaseOrder?.materialName} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Material Name</label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="input_group">
                                        <input type="text" name="unit" value={selectedPurchaseOrder?.unitName} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Unit of Measurement</label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="input_group">
                                        <input type="text" name="brand" onChange={(event) => billDetails(event)} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Brand of Material</label>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                {unlimitedQuantity === false &&
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input_group">
                                            <input type="text" name="approxQty" onChange={(event) => billDetails(event)} className="form-control" autoComplete="off" required />
                                            <label className="input_label">Approx.Qty</label>
                                        </div>
                                    </div>
                                }

                                {[undefined, null, ""].includes(bill.approxQty) &&
                                    <div className="col-lg-4 col-md-12 unlimited_quantity_checkbox">
                                        <h5>Check this for Unlimited QTY</h5>
                                        <div class="form-check form-switch">
                                            <input type="checkbox" name="unlimitedQty" onChange={() => setUnlimitedQuantity(!unlimitedQuantity)} class="form-check-input" />
                                        </div>
                                    </div>
                                }

                                <div className="col-lg-4 col-md-6">
                                    <div className="input_group">
                                        <input type="number" name="rate" onChange={(event) => billDetails(event)} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Rate</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="input_group">
                                        <input type="text" name="description" onChange={(event) => billDetails(event)} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Description</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form_button">
                                <button onClick={() => addBoq()}>Add</button>
                            </div>

                            {/*----Added Quotation List---------*/}
                            <div className="added_quotations">
                                <div className="added_quotations_details">
                                    <div className="detail">
                                        <div className="table_header">
                                            <div className="row m-0">
                                                <div className="sr_no">
                                                    <h5>Sr. No.</h5>
                                                </div>
                                                <div className="material_name">
                                                    <h5>Material</h5>
                                                </div>
                                                <div className="brand">
                                                    <h5>Brand</h5>
                                                </div>
                                                <div className="quantity">
                                                    <h5>Approx Qty.</h5>
                                                </div>
                                                <div className="unit">
                                                    <h5>Unit</h5>
                                                </div>
                                                <div className="rate">
                                                    <h5>Rate</h5>
                                                </div>
                                                <div className="description">
                                                    <h5>Description</h5>
                                                </div>
                                                <div className="user_action">
                                                    <h5>Action</h5>
                                                </div>
                                            </div>
                                        </div>
                                        {boqList.map((boq, index) => (
                                            <div className="table_data" key={index}>
                                                <div className="row m-0">
                                                    <div className="sr_no">
                                                        <h5>{index + 1}</h5>
                                                    </div>
                                                    <div className="material_name">
                                                        <h5>{boq.materialName}</h5>
                                                    </div>
                                                    <div className="brand">
                                                        <h5>{boq.brand}</h5>
                                                    </div>
                                                    {boq?.unlimitedQuantity === true ?
                                                        <div className="quantity">
                                                            <h5>Unlimited</h5>
                                                        </div>
                                                        :
                                                        <div className="quantity">
                                                            <h5>{boq.approxQty}</h5>
                                                        </div>
                                                    }
                                                    <div className="unit">
                                                        <h5>{boq.unitName}</h5>
                                                    </div>
                                                    <div className="rate">
                                                        <h5>{boq.rate}</h5>
                                                    </div>
                                                    <div className="description">
                                                        <h5>{boq.description}</h5>
                                                    </div>
                                                    <div className="user_action">
                                                        <div className="action">
                                                            <i className="fa-solid fa-trash" onClick={() => deleteItem(index)}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/*----Financial Term A-----*/}
                            <div className="financial_term mt-5">
                                <>
                                    <div className="row">
                                        <div className="col-12" style={{ textAlign: "left" }}>
                                            <h5><b>Financial Term (A)</b></h5>
                                        </div>
                                    </div>

                                    <div className="row mt-3 mb-3">
                                        <div className="col-8" style={{ textAlign: "left" }}>
                                            <h5><b>Whether above Rates includes following Cost</b></h5>
                                        </div>
                                        <div className="col-4" style={{ textAlign: "left" }}>
                                            <h5><b>If NOT, Specify details</b></h5>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Loading of Material</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="materialLoading" value="Yes" onChange={(event) => financialTermADetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="materialLoading" value="No" onChange={(event) => financialTermADetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("materialLoading", financialTermA.materialLoading, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="loadingMaterialDetail" onChange={(event) => financialTermADetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermA.materialLoading === "No" ?
                                                <>
                                                    {validator.message("loadingMaterialDetail", financialTermA.loadingMaterialDetail, "required", {
                                                        messages: {
                                                            required: "Loading material details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Unloading of Material</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="materialUnloading" value="Yes" onChange={(event) => financialTermADetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="materialUnloading" value="No" onChange={(event) => financialTermADetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("materialUnloading", financialTermA.materialUnloading, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="materialUnloadingDetail" onChange={(event) => financialTermADetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermA.materialUnloading === "No" ?
                                                <>
                                                    {validator.message("materialUnloadingDetail", financialTermA.materialUnloadingDetail, "required", {
                                                        messages: {
                                                            required: "Unloading material details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Transportation Charges</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="transportationCharges" value="Yes" onChange={(event) => financialTermADetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="transportationCharges" value="No" onChange={(event) => financialTermADetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("transportationCharges", financialTermA.transportationCharges, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="transportationChargesDetail" onChange={(event) => financialTermADetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermA.transportationCharges === "No" ?
                                                <>
                                                    {validator.message("transportationChargesDetail", financialTermA.transportationChargesDetail, "required", {
                                                        messages: {
                                                            required: "Transportation charges details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Royalty (If any)</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="royalty" value="Yes" onChange={(event) => financialTermADetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="royalty" value="No" onChange={(event) => financialTermADetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("royalty", financialTermA.royalty, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="royaltyDetail" onChange={(event) => financialTermADetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermA.royalty === "No" ?
                                                <>
                                                    {validator.message("royaltyDetail", financialTermA.royaltyDetail, "required", {
                                                        messages: {
                                                            required: "Royalty details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Other Taxes & Cess (If any)</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="taxes" value="Yes" onChange={(event) => financialTermADetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="taxes" value="No" onChange={(event) => financialTermADetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("taxes", financialTermA.taxes, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="taxesDetail" onChange={(event) => financialTermADetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermA.taxes === "No" ?
                                                <>
                                                    {validator.message("taxesDetail", financialTermA.taxesDetail, "required", {
                                                        messages: {
                                                            required: "Taxes details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Others (if any)</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="others" value="Yes" onChange={(event) => financialTermADetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="others" value="No" onChange={(event) => financialTermADetail(event)} class="form-check-input ms-2" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="otherDetail" onChange={(event) => financialTermADetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                            <hr />
                            {/*----Financial Term B-----*/}
                            <div className="financial_term mt-4">
                                <>
                                    <div className="row">
                                        <div className="col-12" style={{ textAlign: "left" }}>
                                            <h5><b>Financial Term (B)</b></h5>
                                        </div>
                                    </div>

                                    <div className="row mt-3 mb-3">
                                        <div className="col-8" style={{ textAlign: "left" }}>
                                            <h5><b>Description</b></h5>
                                        </div>
                                        <div className="col-4" style={{ textAlign: "left" }}>
                                            <h5><b>If NOT, Specify details</b></h5>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Bill Submission Cycle</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="billSubmission" value="Yes" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="billSubmission" value="No" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("billSubmission", financialTermB.billSubmission, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="billSubmissionDetail" onChange={(event) => financialTermBDetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermB.billSubmission === "No" ?
                                                <>
                                                    {validator.message("billSubmissionDetail", financialTermB.billSubmissionDetail, "required", {
                                                        messages: {
                                                            required: "Bill Submission details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Credit Period in Days</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="creditPeriod" value="Yes" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="creditPeriod" value="No" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("creditPeriod", financialTermB.creditPeriod, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="creditPeriodDetail" onChange={(event) => financialTermBDetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermB.creditPeriod === "No" ?
                                                <>
                                                    {validator.message("creditPeriodDetail", financialTermB.creditPeriodDetail, "required", {
                                                        messages: {
                                                            required: "Credit Period details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Specific Payment Terms</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="paymentTerm" value="Yes" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="paymentTerm" value="No" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("paymentTerm", financialTermB.paymentTerm, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="paymentTermDetail" onChange={(event) => financialTermBDetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermB.paymentTerm === "No" ?
                                                <>
                                                    {validator.message("paymentTermDetail", financialTermB.paymentTermDetail, "required", {
                                                        messages: {
                                                            required: "Payment Term details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Security Deposit</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="securityDeposite" value="Yes" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="securityDeposite" value="No" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("securityDeposite", financialTermB.securityDeposite, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="securityDepositeDetail" onChange={(event) => financialTermBDetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermB.securityDeposite === "No" ?
                                                <>
                                                    {validator.message("securityDepositeDetail", financialTermB.securityDepositeDetail, "required", {
                                                        messages: {
                                                            required: "Security Deposite details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>TDS</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="tds" value="Yes" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="tds" value="No" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("tds", financialTermB.tds, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="tdsDetail" onChange={(event) => financialTermBDetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermB.tds === "No" ?
                                                <>
                                                    {validator.message("tdsDetail", financialTermB.tdsDetail, "required", {
                                                        messages: {
                                                            required: "TDS details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Royalty (If any)</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="termBRoyalty" value="Yes" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="termBRoyalty" value="No" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("termBRoyalty", financialTermB.termBRoyalty, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="termBRoyaltyDetail" onChange={(event) => financialTermBDetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermB.termBRoyalty === "No" ?
                                                <>
                                                    {validator.message("termBRoyaltyDetail", financialTermB.termBRoyaltyDetail, "required", {
                                                        messages: {
                                                            required: "Royalty details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Other Taxes & Cess (If any)</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="otherTaxes" value="Yes" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="otherTaxes" value="No" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("otherTaxes", financialTermB.otherTaxes, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="otherTaxesDetail" onChange={(event) => financialTermBDetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermB.otherTaxes === "No" ?
                                                <>
                                                    {validator.message("otherTaxesDetail", financialTermB.otherTaxesDetail, "required", {
                                                        messages: {
                                                            required: "Taxes details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Liquidty Damages / Penalty For Delay</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="delayPenalty" value="Yes" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="delayPenalty" value="No" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("delayPenalty", financialTermB.delayPenalty, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="delayPenaltyDetail" onChange={(event) => financialTermBDetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermB.delayPenalty === "No" ?
                                                <>
                                                    {validator.message("delayPenaltyDetail", financialTermB.delayPenaltyDetail, "required", {
                                                        messages: {
                                                            required: "Delay Penalty details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Penalty For Substandard Material</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="substandardMaterial" value="Yes" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="substandardMaterial" value="No" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("substandardMaterial", financialTermB.substandardMaterial, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="substandardMaterialDetail" onChange={(event) => financialTermBDetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermB.substandardMaterial === "No" ?
                                                <>
                                                    {validator.message("substandardMaterialDetail", financialTermB.substandardMaterialDetail, "required", {
                                                        messages: {
                                                            required: "Substandard material details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Advance against Material</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="advance" value="Yes" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="advance" value="No" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("advance", financialTermB.advance, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="advanceDetail" onChange={(event) => financialTermBDetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermB.advance === "No" ?
                                                <>
                                                    {validator.message("advanceDetail", financialTermB.advanceDetail, "required", {
                                                        messages: {
                                                            required: "Advance details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Terms for Recovery of Advance against Material</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="recovery" value="Yes" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="recovery" value="No" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <span className="checkbox_validation_msg">
                                                    {validator.message("recovery", financialTermB.recovery, "required", {
                                                        messages: {
                                                            required: "Check at least one"
                                                        }
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="recoveryDetail" onChange={(event) => financialTermBDetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                            {financialTermB.recovery === "No" ?
                                                <>
                                                    {validator.message("recoveryDetail", financialTermB.recoveryDetail, "required", {
                                                        messages: {
                                                            required: "Recovery details is Required"
                                                        }
                                                    })}
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                            <div className="row">
                                                <div className="col-6" style={{ textAlign: "left" }}>
                                                    <h5>Others (If Any)</h5>
                                                </div>
                                                <div className="col-3">
                                                    <label>Yes</label>
                                                    <input type="radio" name="termBOthers" value="Yes" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                                <div className="col-3">
                                                    <label>No</label>
                                                    <input type="radio" name="termBOthers" value="No" onChange={(event) => financialTermBDetail(event)} class="form-check-input ms-2" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="input_group">
                                                <input type="text" name="termBOthersDetail" onChange={(event) => financialTermBDetail(event)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Detail</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-4 col-md-8">
                                            <div className="input_group">
                                                <input type="text" name="specialRemark" onChange={(event) => setSpecialRemark(event.target.value)} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Special Remark</label>
                                            </div>
                                            {validator.message("specialRemark", specialRemark, "required", {
                                                messages: {
                                                    required: "Remark is Required"
                                                }
                                            })}
                                        </div>
                                    </div>
                                </>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12">
                                    <button className="me-3" onClick={() => uploadeQuotation()}>Submit</button>
                                    <button onClick={() => setShowUploadQuotationForm(false)}>Cancel</button>
                                </div>
                            </div>

                        </div>

                        <div className="close_form">
                            <i className="fa-solid fa-xmark" onClick={() => setShowUploadQuotationForm(false)}></i>
                        </div>

                    </div>
            }
        </>
    );
}
export default UploadQuotaion;