import React, { useEffect, useState } from "react";
import { communication } from "../../communication/communication";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const UpdateUser = ({ setShowUpdateUserForm, updateUser }) => {
    const navigate = useNavigate();
    const [designation, setDesignation] = useState([]);
    const [updateExistingUser, setUpdateExistingUser] = useState(updateUser);

    //Fetch all designations
    const fetchDesignation = async () => {
        try {
            const responseFromServer = await communication.getDesignation();
            if (responseFromServer.data.status === "SUCCESS") {
                setDesignation(responseFromServer.data.designations);
                responseFromServer.data.designations?.filter((ele) => {
                    if (updateExistingUser?.designationName === ele?.designationName) {
                        setUpdateExistingUser({ ...updateExistingUser, designationId: ele?._id })
                    }
                })
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setDesignation([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchDesignation();
    }, []);

    const updateTheUser = async (userId) => {
        const { name, email, designationName, designationId, licenseNo } = updateExistingUser;
        if (!name || !email || !designationName || !userId) {
            swal({ text: "Please fill all the Fields", icon: "warning" });
            return false;
        }
        const serverResponse = await communication.updateUser(userId, name, email, designationName, designationId, licenseNo);
        try {
            if (serverResponse.data.status === "SUCCESS") {
                setShowUpdateUserForm(false);
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (

        <div className="add_new_user">
            <div className="form">
                <div className="form_heading">
                    <h5>Update User</h5>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="input_group">
                            <input type="text" name="name" value={updateExistingUser.name} onChange={(event) => setUpdateExistingUser({ ...updateExistingUser, name: event.target.value })} className="form-control" autoComplete="off" required />
                            <label className="input_label">Name of User</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <select name="designationName" className="form-select" onChange={(event) => setUpdateExistingUser({ ...updateExistingUser, designationName: JSON.parse(event.target.value)?.designationName, designationId: JSON.parse(event.target.value)?._id })}>
                            {designation?.map((designation, index) => {
                                return (
                                    <option value={JSON.stringify(designation)} selected={designation?.designationName === updateExistingUser?.designationName} key={index} >{designation.designationName}</option>
                                );
                            })}
                        </select>

                    </div>
                </div>
                {["driver"].includes(updateExistingUser?.designationName?.toLowerCase()) &&
                    <div className="row">
                        <div className="col-6">
                            <div className="input_group">
                                <input type="text" name="licenseNo" value={updateExistingUser?.licenseNo} onChange={(event) => setUpdateExistingUser({ ...updateExistingUser, licenseNo: event.target.value })} className="form-control" autoComplete="off" />
                                <label className="input_label">Driver License no.</label>
                            </div>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-6">
                        <div className="input_group">
                            <input type="text" name="email" value={updateExistingUser.email} onChange={(event) => setUpdateExistingUser({ ...updateExistingUser, email: event.target.value })} className="form-control" autoComplete="off" required />
                            <label className="input_label">Email</label>
                        </div>
                    </div>
                </div>
                <div className="form_button">
                    <button onClick={() => updateTheUser(updateUser.userId)}>Update</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowUpdateUserForm(false)}></i>
                </div>
            </div>
        </div>
    );
}
export default UpdateUser;