import React, { useState, useEffect } from "react";
import { communication } from "../communication/communication";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import AddNewSite from "./forms/AddNewSite";
import AddLocation from "./forms/AddLocation";
import UpdateSite from "./forms/UpdateSite";
import UpdateLocation from "./forms/UpdateLocation";
import { showLoader } from "../redux-store/loaderReducer";
import { hideLoader } from "../redux-store/loaderReducer";
import Loader from "../Modals/Loader";
import { confirmAlert } from 'react-confirm-alert';
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

const SiteManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showAddSiteForm, setShowAddSiteForm] = useState(false);
    const [showAddLocationForm, setShowAddLocationForm] = useState(false);
    const [showUpdateSite, setShowUpdateSite] = useState({ isModalShow: false, siteId: "", siteName: "" });
    const [locations, setLocations] = useState([]);
    const [selectedLocationSite, setSelectedLocationSite] = useState({ locationName: null, locationId: null, siteDetails: "" });
    const [showUpdateLocation, setShowUpdateLocation] = useState({ isModalShow: false, locationId: "", locationName: "" });
    const [pageCount, setPageCount] = useState(2);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [activeSelectedItem, setActiveSelectedItem] = useState(0);

    const loader = useSelector((state) => state.loader);
    const loginUserDetails = useSelector((state) => state.userDetails);
    const pageLimit = process.env.REACT_APP_PAGE_LIMIT;

    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchLocationsBySearch(searchValue, selectedPage + 1);
        } else {
            fetchLocation(selectedPage + 1);
        }
    }

    //search site
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchLocationsBySearch(searchString, currentPage);
        } else {
            const responseFromServer = await communication.getAllLocationsList();
            if (responseFromServer.data.status === "SUCCESS") {
                setLocations(responseFromServer?.data?.locations);
                setPageCount(responseFromServer?.data?.totalPages);
                showLocationDetails(responseFromServer.data.locations[0].locationName, responseFromServer.data.locations[0]._id);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setLocations([]);
                setPageCount(0);
            }
        }
    }

    //fetch locations by search
    const fetchLocationsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchLocations(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setLocations(responseFromServer?.data?.locations);
                setPageCount(responseFromServer?.data?.totalPages);
                showLocationDetails(responseFromServer.data.locations[0].locationName, responseFromServer.data.locations[0]._id);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setLocations([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //get all Locations data on initial load
    const fetchLocation = async (page) => {
        try {
            dispatch(
                showLoader()
            )
            const responseFromServer = await communication.getAllLocationsList(page);
            if (responseFromServer.data.status === "SUCCESS") {
                dispatch(hideLoader())
                setLocations(responseFromServer?.data?.locations);
                setPageCount(responseFromServer?.data?.totalPages);
                showLocationDetails(responseFromServer.data.locations[0].locationName, responseFromServer.data.locations[0]._id);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                setLocations([]);
                setPageCount(0);
                swal({ text: responseFromServer.data?.message, icon: "warning" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }
    }
    //show location details
    const showLocationDetails = async (locationName, locationId) => {
        try {
            const result = await communication.getLocationWiseSites(locationId);
            if (result.data.status === "SUCCESS") {
                setSelectedLocationSite({ locationName: locationName ?? selectedLocationSite.locationName, locationId: locationId ?? selectedLocationSite.locationId, siteDetails: result.data.sites });
            } else if (result?.data?.status === "JWT_INVALID") {
                swal({ text: result.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setSelectedLocationSite({ locationName, locationId, siteDetails: [] });
                dispatch(hideLoader())
            }
        } catch (error) {
            dispatch(hideLoader())
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        if (!showAddSiteForm) {
            fetchLocation();
        }
    }, [showAddSiteForm])

    //Delete location
    const deleteLocation = async (locationId) => {
        confirmAlert({
            title: 'Are you sure you want to Delete Location?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const serverResponse = await communication.deleteLocation(locationId);
                        if (serverResponse.data.status === "SUCCESS") {
                            fetchLocation();
                            return swal({ text: serverResponse.data?.message, icon: "success" });
                        } else if (serverResponse?.data?.status === "JWT_INVALID") {
                            swal({ text: serverResponse.data?.message, icon: "error" });
                            navigate("/login");
                        } else {
                            return swal({ text: serverResponse.data?.message, icon: "error" });
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return false }
                }
            ],
            overlayClassName: "react-confirm-alert"
        });
    }

    //Delete site
    const deleteSite = async (siteId) => {
        confirmAlert({
            title: 'Are you sure you want to Delete Site?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const serverResponse = await communication.deleteSite(siteId);
                        if (serverResponse.data.status === "SUCCESS") {
                            showLocationDetails();
                            return swal({ text: serverResponse.data?.message, icon: "success" });
                        } else if (serverResponse?.data?.status === "JWT_INVALID") {
                            swal({ text: serverResponse.data?.message, icon: "error" });
                            navigate("/login");
                        } else {
                            return swal({ text: serverResponse.data?.message, icon: "error" });
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return false }
                }
            ],
            overlayClassName: "react-confirm-alert"
        });
    }


    return (
        <>
            {

                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="dashboard_tab_main">
                        <h4 className="title">Site Management</h4>
                        <div className="search_div">
                            <input type="text" className='form=control' onChange={(event) => { { searchFilter(event.target.value); setSearchValue(event.target.value) } }} placeholder='search by Location' />
                            {pageCount > 1 &&
                                <div className="pagination_wrapper">
                                    <ReactPaginate
                                        previousLabel={pageCount === 1 ? "First" : "Prev"}
                                        nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        forcePage={currentPage}
                                        activeClassName={"active"} />
                                </div>
                            }
                        </div>
                        <div className="row site_management_table_wrapper">
                            {/*------Add Location section start---*/}
                            <div className="col-lg-6 col-md-12">
                                <div className="add_location">
                                    <h5>All Locations</h5>
                                    {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.add_location === true) ?
                                        <button onClick={() => setShowAddLocationForm(true)}>Add Location</button>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className="table_section add_location_section">
                                    <div className="table_header">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Sr. No</h5>
                                            </div>
                                            <div className="col-lg-7 col-md-7">
                                                <h5>Location Name</h5>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <h5>Action</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {locations?.length > 0 ?
                                        <>
                                            {locations?.map((location, index) => {
                                                return (
                                                    <div className={`table_data ${activeSelectedItem !== index ? "" : "active_selected_row"}`} key={index}>
                                                        <div className="row">
                                                            <div className="col-lg-2 col-md-2">
                                                                <h5>{pageLimit * currentPage + (index + 1)}</h5>
                                                            </div>
                                                            <div className="col-lg-7 col-md-7 click_event" onClick={() => { setSelectedLocationSite({ locationName: location.locationName, locationId: location?._id, siteDetails: [] }); setActiveSelectedItem(index); showLocationDetails(location.locationName, location._id); }}>
                                                                <h5><a>{location.locationName}</a></h5>
                                                            </div>
                                                            <div className="col-lg-3 col-md-3">
                                                                <div className="action">
                                                                    <i className="fa-solid fa-pen-to-square" onClick={() => setShowUpdateLocation({ isModalShow: true, locationId: location._id, locationName: location.locationName })}></i>
                                                                    <i className="fa-solid fa-trash" onClick={() => deleteLocation(location._id)}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Location Not Available</h5>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/*------Add Site section start---*/}
                            <div className="col-lg-6 col-md-12 mt-lg-0 mt-md-3">
                                <div className="add_site">
                                    <h5>{selectedLocationSite.locationName}</h5>
                                    {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.add_site === true) ?
                                        <button onClick={() => setShowAddSiteForm(true)}>Add Sites</button>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className="table_section add_site_section">

                                    <div className="table_header">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Sr. No</h5>
                                            </div>
                                            <div className="col-lg-7 col-md-7">
                                                <h5>Site Name</h5>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <h5>Action</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {selectedLocationSite.siteDetails.length > 0 ?
                                        <>
                                            {selectedLocationSite.siteDetails.map((sites, index) => {
                                                return (
                                                    <div className="table_data" key={index}>
                                                        <div className="row">
                                                            <div className="col-lg-2 col-md-2">
                                                                <h5>{index + 1}</h5>
                                                            </div>
                                                            <div className="col-lg-7 col-md-7">
                                                                <h5>{sites.siteName}</h5>
                                                            </div>
                                                            <div className="col-lg-3 col-md-3">
                                                                <div className="action">
                                                                    <i className="fa-solid fa-pen-to-square" onClick={() => setShowUpdateSite({ isModalShow: true, siteId: sites._id, siteName: sites.siteName })} ></i>
                                                                    <i className="fa-solid fa-trash" onClick={() => deleteSite(sites._id)}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Site Not Available for this Location</h5>
                                        </div>
                                    }



                                </div>
                            </div>
                        </div>
                        {
                            showAddLocationForm &&
                            < div className="modal_box">
                                <AddLocation setShowAddLocationForm={setShowAddLocationForm} locationId={selectedLocationSite?.locationId} fetchLocation={fetchLocation} />
                            </div>
                        }
                        {
                            showAddSiteForm &&
                            < div className="modal_box">
                                <AddNewSite setShowAddSiteForm={setShowAddSiteForm} selectedLocationSite={selectedLocationSite} showLocationDetails={showLocationDetails} />
                            </div>
                        }
                        {
                            showUpdateLocation.isModalShow &&
                            <div className="modal_box">
                                <UpdateLocation setShowUpdateLocation={setShowUpdateLocation} showUpdateLocation={showUpdateLocation} fetchLocation={fetchLocation} />
                            </div>
                        }
                        {
                            showUpdateSite.isModalShow &&
                            <div className="modal_box">
                                <UpdateSite setShowUpdateSite={setShowUpdateSite} showUpdateSite={showUpdateSite} showLocationDetails={showLocationDetails} />
                            </div>
                        }


                    </div >
            }
        </>
    );
}
export default SiteManagement;