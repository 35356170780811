import React from "react";
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";

const InventoryDropdown = () => {
    const loginUserDetails = useSelector((state) => state.userDetails);
    return (
        <ul className="dropdown">
            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.all_material === true) ?
                <NavLink to="/main-dashboard/inventory/all-material">
                    <li>All Material</li>
                </NavLink>
                :
                <></>
            }
            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.material_issuing === true) ?
                <NavLink to="/main-dashboard/inventory/material-issuing">
                    <li>Material Issuing</li>
                </NavLink>
                :
                <></>
            }
            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.material_transfer === true) ?
                <NavLink to="/main-dashboard/inventory/material-transfer">
                    <li>Material Transfer</li>
                </NavLink>
                :
                <></>
            }
            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.material_received === true) ?
                <NavLink to="/main-dashboard/inventory/material-received">
                    <li>Material Received</li>
                </NavLink>
                :
                <></>
            }
            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.approved_po === true) ?
                <NavLink to="/main-dashboard/inventory/approved-requirements">
                    <li>Approved Requirements</li>
                </NavLink>
                :
                <></>
            }
            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.reports === true) ?
                <NavLink to="/main-dashboard/inventory/reports">
                    <li>Reports</li>
                </NavLink>
                :
                <></>
            }
        </ul>
    );
}
export default InventoryDropdown;