import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import Loader from "../../Modals/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import swal from "sweetalert";

const ChangePassword = ({ setShowPasswordForm, updateUser }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);
    const [validator, showValidationMessage] = useValidator();
    const [userPassword, setUserPassword] = useState([
        {
            password: "",
            confirmPassword: ""
        }
    ]);
    const { password, confirmPassword } = userPassword;

    const changePassword = (event) => {
        setUserPassword({ ...userPassword, [event.target.name]: event.target.value })
    }
    //Update User Password
    const updatePassword = async (userId) => {
        try {
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            const serverResponse = await communication.changeUserPassword(userId, password);
            if (serverResponse.data.status === "SUCCESS") {
                setShowPasswordForm(false);
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }
    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="add_new_user">
                        <div className="form">
                            <div className="form_heading">
                                <h5>Change Password</h5>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input_group">
                                        <input type="password" name="password" onChange={(event) => changePassword(event)} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Enter New Password</label>
                                    </div>
                                    {validator.message("password", password, "required|min:8", {
                                        messages: {
                                            required: "Password is required"
                                        }
                                    })}
                                </div>
                                <div className="col-6">
                                    <div className="input_group">
                                        <input type="password" name="confirmPassword" onChange={(event) => changePassword(event)} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Confirm Password</label>
                                    </div>
                                    {validator.message("confirmPassword", confirmPassword, `required|min:8|in:${password}`, {
                                        messages: {
                                            required: "Confirm Password is required",
                                            in: 'Passwords need to match!'
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="form_button">
                                <button onClick={() => updatePassword(updateUser.userId)}>Update</button>
                            </div>
                            <div className="close_form">
                                <i className="fa-solid fa-xmark" onClick={() => setShowPasswordForm(false)}></i>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}
export default ChangePassword;