import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const MaterialIssuingForm = ({ setShowMaterialIssuingForm }) => {
    const [validator, showValidationMessage] = useValidator();
    const [locationWiseSites, setLocationWiseSites] = useState([]);
    const [location, setLocation] = useState([]);
    const [supervisors, setSupervisors] = useState([]);
    const [subContractors, setSubContractors] = useState([]);
    const [allMaterial, setAllMaterial] = useState([]);
    const [brands, setBrands] = useState([]);
    const [availableQuantity, setAvailableQuantity] = useState("");
    const loginUserDetails = useSelector((state) => state.userDetails);
    const navigate = useNavigate();
    const [materials, setMaterials] = useState(
        {
            locationId: "",
            siteId: "",
            source: "",
            subContractorId: "",
            supervisorId: "",
            materialId: "",
            brand: "",
            requiredQuantity: ""
        }
    );

    const [materialUnitDetail, setMaterialUnitDetail] = useState(
        {
            unitId: "",
            unitName: ""
        }
    );

    const { locationId, siteId, source, subContractorId, supervisorId, materialId, brand, requiredQuantity } = materials;
    //Fetch Supervisors 
    const fetchSupervisors = async (siteId) => {
        try {
            const getSupervisors = await communication.fetchSiteSupervisor(siteId);
            if (getSupervisors.data.status === "SUCCESS") {
                setSupervisors(getSupervisors.data.supervisors);
            } else if (getSupervisors?.data?.status === "JWT_INVALID") {
                swal({ text: getSupervisors.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: getSupervisors.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    //Fetch SubContractors 
    const fetchSubContractor = async (siteId) => {
        try {
            const getSubContractors = await communication.fetchSiteWiseSubContractors(siteId);
            if (getSubContractors.data.status === "SUCCESS") {
                setSubContractors(getSubContractors.data.subContractors);
            } else if (getSubContractors?.data?.status === "JWT_INVALID") {
                swal({ text: getSubContractors.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: getSubContractors.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    const fetchAvailableQuantity = async (brand) => {
        try {
            if (brand) {
                const serverResponse = await communication.getAvailableStockByBrand(materialId, brand);
                if (serverResponse.data.status === "SUCCESS") {
                    setAvailableQuantity(serverResponse.data.availableMaterial);
                } else if (serverResponse?.data?.status === "JWT_INVALID") {
                    swal({ text: serverResponse.data?.message, icon: "error" });
                    navigate("/login");
                } else {
                    setAvailableQuantity(0);
                }
            } else {
                setAvailableQuantity(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    //Fetch brand by material 
    const fetchBrands = async (materialId) => {
        try {
            if (materialId) {
                const getBrands = await communication.getBrandByMaterial(materialId);
                if (getBrands.data.status === "SUCCESS") {
                    setBrands(getBrands?.data?.brands);
                    //set unit id and unit name as per material id
                    const [{ unitId, unitName }] = getBrands?.data?.brands;
                    setMaterialUnitDetail({ ...materialUnitDetail, unitId: unitId, unitName: unitName });
                } else if (getBrands?.data?.status === "JWT_INVALID") {
                    swal({ text: getBrands.data?.message, icon: "error" });
                    navigate("/login");
                } else {
                    setBrands([]);
                    setMaterialUnitDetail({ unitId: "", unitName: "" });
                    swal({ text: getBrands.data?.message, icon: "error" });
                }
            } else {
                setBrands([]);
                setMaterialUnitDetail({ unitId: "", unitName: "" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Fetch All Materials on initial load
    const getAllMaterial = async () => {
        try {
            const responseFromServer = await communication.getAllMaterial();
            if (responseFromServer.data.status === "SUCCESS") {
                setAllMaterial(responseFromServer.data.materials);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setAllMaterial([]);
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    //get all Locations data on initial load
    const fetchLocations = async () => {
        try {
            const responseFromServer = await communication.getAllLocations();
            if (responseFromServer.data.status === "SUCCESS") {
                setLocation(responseFromServer.data.locations);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setLocation([]);
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //! get sites According to location
    const getLocationWiseSites = async (locationId) => {
        try {
            const result = await communication.getLocationWiseSites(locationId);
            if (result.data.status === "SUCCESS") {
                setLocationWiseSites(result.data.sites);
            } else if (result?.data?.status === "JWT_INVALID") {
                swal({ text: result.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: result.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchLocations();
        getAllMaterial();
    }, [])

    //issue material
    const issueMaterial = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const dataToSend = {
                ...materials, unitId: materialUnitDetail?.unitId, availableQuantity, issuedBy: loginUserDetails
            }
            const serverResponse = await communication.issueMaterial(dataToSend);
            if (serverResponse.data.status === "SUCCESS") {
                setShowMaterialIssuingForm(false);
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    return (
        <div className="issue_material">
            <div className="form">
                <div className="form_heading">
                    <h5>Issue Material</h5>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <select name="locationId" className="form-select" onChange={(event) => { getLocationWiseSites(JSON.parse(event.target.value)?._id); setMaterials({ ...materials, locationId: JSON.parse(event.target.value)?._id }) }}>
                            <option value={JSON.stringify({ _id: "" })}>Select location</option>
                            {location.map((locationDetails, index) => {
                                return (
                                    <option value={JSON.stringify(locationDetails)} selected={locationDetails?._id === locationId} key={index}>{locationDetails.locationName}</option>
                                )
                            })}

                        </select>
                        {validator.message("locationId", locationId, "required", {
                            messages: {
                                required: "locationName is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <select name="siteId" className="form-select" onChange={(event) => { setMaterials({ ...materials, siteId: JSON.parse(event.target.value)?._id }); fetchSupervisors(JSON.parse(event.target.value)?._id); fetchSubContractor(JSON.parse(event.target.value)?._id); }}>
                            <option value="" selected>Select Site</option>
                            {locationWiseSites.map((site, index) => {
                                return (
                                    <option value={JSON.stringify(site)} selected={site?._id === siteId} key={index}>{site.siteName}</option>
                                )
                            })}
                        </select>
                        {validator.message("siteId", siteId, "required", {
                            messages: {
                                required: "Site is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <select name="source" className="form-select" onChange={(event) => setMaterials({ ...materials, source: event.target.value })}>
                            <option value="">Select Source</option>
                            <option value="Inventory" selected={source === "Inventory"}>Inventory</option>
                            <option value="Site" selected={source === "Site"}>Site</option>
                        </select>
                        {validator.message("source", source, "required", {
                            messages: {
                                required: "Source is required"
                            }
                        })}
                    </div>

                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <select name="subContractorId" className="form-select" onChange={(event) => setMaterials({ ...materials, subContractorId: JSON.parse(event.target.value)?._id })}>
                            <option value={JSON.stringify({ _id: "" })}>Select Sub-Contractor</option>
                            {subContractors?.map((contractor, index) => (
                                <option value={JSON.stringify(contractor)} selected={contractor?._id === subContractorId} key={index}>{contractor?.subContractorName}</option>
                            ))}
                        </select>
                        {validator.message("subContractorId", subContractorId, "required", {
                            messages: {
                                required: "Sub-Contractor is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <select name="supervisorId" className="form-select" onChange={(event) => setMaterials({ ...materials, supervisorId: JSON.parse(event.target.value)?._id })}>
                            <option value={JSON.stringify({ _id: "" })}>Select Supervisor</option>
                            {supervisors?.map((supervisor, index) => (
                                <option value={JSON.stringify(supervisor)} selected={supervisor?._id === supervisorId} key={index}>{supervisor?.name}</option>
                            ))}
                        </select>
                        {validator.message("supervisorId", supervisorId, "required", {
                            messages: {
                                required: "Supervisor is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <select name="materialId" className="form-select" onChange={(event) => { setMaterials({ ...materials, materialId: JSON.parse(event.target.value)?._id }); fetchBrands(JSON.parse(event.target.value)?._id); }}>
                            <option value={JSON.stringify({ _id: "" })}>Select Material</option>
                            {allMaterial?.map((material, index) => (
                                <option value={JSON.stringify(material)} selected={material?._id === materialId} key={index}>{material?.materialName}</option>
                            ))}
                        </select>
                        {validator.message("materialId", materialId, "required", {
                            messages: {
                                required: "Material is required"
                            }
                        })}
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="material_code" className="form-control" autoComplete="off" required />
                            <label className="input_label">Material Code</label>
                        </div>
                    </div> */}
                    <div className="col-lg-4 col-md-6">
                        <select name="brand" className="form-select" onChange={(event) => { setMaterials({ ...materials, brand: event.target.value }); fetchAvailableQuantity(event.target.value); }}>
                            <option value="" selected>Select Make/Brand</option>
                            {brands?.map((brandDetails, index) => (
                                <option value={brandDetails?.brand} selected={brandDetails?.brand === brand} key={index}>{brandDetails?.brand}</option>
                            ))}
                        </select>
                        {validator.message("brand", brand, "required", {
                            messages: {
                                required: "Brand is required"
                            }
                        })}
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="units" value={materialUnitDetail?.unitName} className="form-control" autoComplete="off" required />
                            <label className="input_label">Unit</label>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="availableQuantity" value={availableQuantity} className="form-control" autoComplete="off" required />
                            <label className="input_label">Available Quantity in Store</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="requiredQuantity" value={requiredQuantity} onChange={(event) => setMaterials({ ...materials, requiredQuantity: event.target.value })} className="form-control" autoComplete="off" required />
                            <label className="input_label">required Quantity</label>
                        </div>
                        {validator.message("requiredQuantity", requiredQuantity, "required", {
                            messages: {
                                required: "Quantity is required"
                            }
                        })}
                    </div>
                </div>
                {/* <div className="row">
                   
                    <div className="col-lg-2 col-md-3" style={{ display: "flex", justifyContent: "start", alignItems: "center", paddingTop: "1.6875rem" }}>
                        <h5>Date : {`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`}</h5>
                    </div>
                    <div className="col-lg-2 col-md-3" style={{ display: "flex", justifyContent: "start", alignItems: "center", paddingTop: "1.6875rem" }}>
                        <h5>Time : {`${date.getHours()}:${date.getMinutes()}`}</h5>
                    </div>
                </div> */}
                <div className="form_button">
                    <button onClick={() => issueMaterial()}>Add</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowMaterialIssuingForm(false)}></i>
                </div>
            </div>
        </div>
    );
}
export default MaterialIssuingForm;