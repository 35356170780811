import React from "react";

const AllMaterialDetail = ({ selectedBrand }) => {
    return (
        <div className="all_material_detail_section">
            {/* <div>
                <h5><b>Material Code - 100</b></h5>
            </div> */}
            <div className="row mt-3 mb-3">
                <div className="col-lg-8 col-md-8">
                    <h5>Material Name - {selectedBrand?.poDetails?.materialName}</h5>
                    <h5 className="mt-3 mb-3">Category - {selectedBrand?.poDetails?.categoryName}</h5>
                    <h5>Unit - {selectedBrand?.poDetails?.unitName}</h5>
                </div>
                <div className="col-lg-4 col-md-4">
                    <h5><b>Available Quantity</b></h5>
                    <div className="total_quantity">
                        <h5>{selectedBrand?.materialQuantity}</h5>
                    </div>
                </div>
            </div>
            <div className="purchase_history_section">
                <h5><b>Purchase History</b></h5>
                <div className="purchase_history_detail">
                    {selectedBrand?.grnDetails?.map((grn) => {
                        return (
                            <div className="row m-0 mb-3">
                                <div className="col-lg-1 col-md-1 ps-0">
                                    <div className="history_icon">
                                        <i className="fa-solid fa-check"></i>
                                    </div>
                                </div>
                                <div className="col-lg-11 col-md-11 history_content">
                                    <h5>GRN No. {grn?.grnNumber}</h5>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        </div>

    );
}
export default AllMaterialDetail;