export const accessControl = {
    "siteId": "",
    "locationId": "",
    "select_all": false,
    "dashboard": false,
    //Procurement
    "procurement": false,
    "approval_purchase_indent": false,
    "new_purchase_indent": false,
    "all_approvals": false,
    "purchase_order_approval": false,
    "total_requirement": false,
    "quotation_approval": false,
    "add_new_purchase_order": false,
    "inventory": false,
    "all_material": false,
    "material_issuing": false,
    "material_transfer": false,
    "material_received": false,
    "approved_po": false,
    "reports": false,
    "create_purchase_indent": false,
    "material_issuing_edit": false,
    "material_transfer_edit": false,
    "material_received_grn": false,
    "grn_of_approved_po": false,
    "download_reports": false,
    "machines": false,
    "machine_logs": false,
    "mobile_section": false,
    "machinery_access": false,
    "machine_management": false,
    "add_machine": false,
    "site_management": false,
    "add_location": false,
    "add_site": false,
    "designation_management": false,
    "add_designation": false,
    "material_management": false,
    "add_material": false,
    "sub_contractor": false,
    "add_contractor": false,
    "user_management": false,
    "add_users": false,
}