import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import UpdateMaterialissuingForm from "../forms/UpdateMaterialissuingForm";
import Loader from "../../Modals/Loader";
import { confirmAlert } from 'react-confirm-alert';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { useMemo } from "react";

const MaterailIssuing = () => {
    const [issuingMaterial, setIssuingMaterial] = useState([]);
    const [locations, setLocations] = useState([]);
    const [locationWiseSite, setLocationWiseSites] = useState([]);
    const [showUpdateMaterialIssuingForm, setShowUpdateMaterialIssuingForm] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchFilterValue, setSearchFilterValue] = useState({
        searchValue: "",
        searchByLocation: "",
        searchBySite: ""
    });
    const { searchValue, searchByLocation, searchBySite } = searchFilterValue;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);
    const loginUserDetails = useSelector((state) => state.userDetails);
    const pageLimit = process.env.REACT_APP_PAGE_LIMIT;


    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchIssuedMaterialsBySearch(searchValue, selectedPage + 1);
        } else if (searchByLocation) {
            getLocationAndSiteWiseIssuedMaterialList(searchByLocation, searchBySite, selectedPage + 1);
        } else {
            fetchIssueMaterial(selectedPage + 1);
        }
    }

    // Search filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchIssuedMaterialsBySearch(searchString, currentPage + 1);
        } else {
            const responseFromServer = await communication.getIssueMaterialList();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setIssuingMaterial(responseFromServer?.data?.issueMaterial);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "waring" });
                navigate("/login");
            } else {
                setIssuingMaterial([]);
                setPageCount(0);
            }
        }
    }

    //fetch issued materials by search
    const fetchIssuedMaterialsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchInventoryIssuedMaterial(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setIssuingMaterial(responseFromServer?.data?.issueMaterial);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "waring" });
                navigate("/login");
            } else {
                setIssuingMaterial([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" })
        }
    }

    //Filter Location and Site wise Issued Materials
    const getLocationAndSiteWiseIssuedMaterialList = async (locationId, siteId, page) => {
        try {
            if (locationId) {
                const responseFromServer = await communication.locationAndSiteWiseIssuedMaterialList(locationId, siteId, page);
                if (responseFromServer?.data?.status === "SUCCESS") {
                    getLocationWiseSites(locationId);
                    setIssuingMaterial(responseFromServer?.data?.issueMaterial);
                    setPageCount(responseFromServer?.data?.totalPages);
                } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                    swal({ text: responseFromServer?.data?.message, icon: "warning" });
                    navigate("/login");
                } else {
                    setIssuingMaterial([]);
                    setLocationWiseSites([]);
                    setPageCount(0);
                }
            } else {
                setSearchFilterValue({ searchByLocation: "", searchBySite: "" });
                fetchIssueMaterial();

            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Fetch Issued material on initial load
    const fetchIssueMaterial = async (page) => {
        try {
            dispatch(showLoader())
            const responseFromServer = await communication.getIssueMaterialList(page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                setIssuingMaterial(responseFromServer?.data?.issueMaterial);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "waring" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                setIssuingMaterial([]);
                setPageCount(0);
            }
        } catch (error) {
            dispatch(hideLoader())
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Fetch all Locations
    const fetchAllLocations = async () => {
        try {
            const responseFromServer = await communication.getAllLocations();
            if (responseFromServer.data.status === "SUCCESS") {

                //if User is director set all Locations otherWise set location according to accessControl
                if (loginUserDetails?.designation?.toLowerCase() === "director") {
                    setLocations(responseFromServer.data.locations);
                } else {
                    let location = [];
                    loginUserDetails?.accessControl?.forEach(access => {
                        responseFromServer?.data?.locations?.forEach((locationDetails) => {
                            if (access.locationName === locationDetails.locationName) {
                                location.push(locationDetails);
                            }
                        })
                    });
                    setLocations(location);
                }
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setLocations([]);
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    // get sites According to location
    const getLocationWiseSites = async (locationId) => {
        try {
            if (locationId) {
                const result = await communication.getLocationWiseSites(locationId);
                if (result.data.status === "SUCCESS") {
                    setLocationWiseSites(result.data.sites);
                } else if (result?.data?.status === "JWT_INVALID") {
                    swal({ text: result.data?.message, icon: "warning" });
                    navigate("/login");
                } else {
                    setLocationWiseSites([]);
                }
            } else {
                setLocationWiseSites([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Export Data to Excel
    const exportData = async () => {
        try {
            dispatch(showLoader());
            const serverResponse = await communication.exportMaterialIssueList();
            if (serverResponse.data) {
                //Create a Blob from the PDF Stream
                const file = new Blob([serverResponse.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
                });
                FileSaver.saveAs(file, "Essued Materials.xlsx");
            } else {
                swal({ text: "Failed to export data in excel", icon: "warning" });
            }
            dispatch(hideLoader());
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    };

    useMemo(() => {
        getLocationAndSiteWiseIssuedMaterialList(searchByLocation, searchBySite);
    }, [searchByLocation, searchBySite]);

    useEffect(() => {
        fetchIssueMaterial();
        fetchAllLocations();
    }, []);

    //Delete Issued Material
    const deleteIssueMaterial = async (issueId, issueNumber) => {
        try {
            confirmAlert({
                title: 'Are you sure you want to Delete Issued Material?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            const serverResponse = await communication.deleteIssueMaterial(issueId, issueNumber);
                            if (serverResponse?.data?.status === "SUCCESS") {
                                fetchIssueMaterial();
                                swal({ text: serverResponse.data?.message, icon: "success" });
                            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                                swal({ text: serverResponse.data?.message, icon: "warning" });
                                navigate("/login");
                            } else {
                                swal({ text: serverResponse.data?.message, icon: "warning" });
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { return false }
                    }
                ],
                overlayClassName: "react-confirm-alert"
            });

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <>
            {

                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="dashboard_tab_main">
                        <h4 className="title">Inventory - Material Issuing</h4>
                        <div className="row material_search_box mt-4">
                            <div className="col-lg-3 col-md-6 ps-0" style={{ display: "flex", alignItems: "flex-end" }}>
                                <input type="text" onChange={(event) => { searchFilter(event.target.value); setSearchFilterValue({ ...searchFilterValue, searchValue: event.target.value }) }} className="form-control search_input" placeholder="serach by material, brand, subcontractor" title="serach by material, brand, sub-contractor" />
                            </div>
                            <div className="col-lg-3 col-md-6 pe-lg-2 pe-md-0" style={{ display: "flex", alignItems: "flex-end" }}>
                                <select onChange={(event) => { setSearchFilterValue({ ...searchFilterValue, searchByLocation: JSON.parse(event.target.value)?._id }) }} className="form-select">
                                    <option value={JSON.stringify({ _id: "" })}>Select Location</option>
                                    {locations?.map((location, index) => (
                                        <option value={JSON.stringify(location)} key={index}>{location?.locationName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-3 col-md-6 pe-lg-2 pe-md-2 pe-lg-0 ps-lg-2 ps-md-0 mt-lg-0 mt-md-3">
                                <select onChange={(event) => { setSearchFilterValue({ ...searchFilterValue, searchBySite: JSON.parse(event.target.value)?._id }) }} className="form-select">
                                    <option value={JSON.stringify({ _id: "" })}>Select Site</option>
                                    {locationWiseSite?.map((site, index) => {
                                        return (
                                            <option value={JSON.stringify(site)} key={index}>{site?.siteName}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            {issuingMaterial?.length !== 0 &&
                                <div className="col-lg-3 col-md-6 pe-0 mt-lg-0 mt-md-3" style={{ display: "flex", justifyContent: "end", alignItems: "flex-end" }}>
                                    <button onClick={() => exportData()}>Export<i className="fa-solid fa-file-excel ms-2"></i></button>
                                </div>
                            }
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="table_main material_issuing">
                                    <div className="table_heading">
                                        <div className="sr_no">
                                            <h5>Sr. No.</h5>
                                        </div>
                                        <div className="material_name">
                                            <h5>Material</h5>
                                        </div>
                                        <div className="brand">
                                            <h5>Make/Brand</h5>
                                        </div>
                                        <div className="quantity">
                                            <h5>Quantity</h5>
                                        </div>
                                        <div className="from">
                                            <h5>Issued By</h5>
                                        </div>
                                        <div className="sub_contractor">
                                            <h5>Sub-Contractor</h5>
                                        </div>
                                        <div className="location">
                                            <h5>Location</h5>
                                        </div>
                                        <div className="site_name">
                                            <h5>Name of Site</h5>
                                        </div>
                                        <div className="date">
                                            <h5>Date</h5>
                                        </div>
                                        <div className="user_action">
                                            <h5>Action</h5>
                                        </div>

                                    </div>
                                    {issuingMaterial?.length !== 0 ?
                                        <>
                                            {issuingMaterial?.map((materials, index) => (
                                                <div className="table_content" key={index}>
                                                    <div className="sr_no">
                                                        <h5>{pageLimit * currentPage + (index + 1)}</h5>
                                                    </div>
                                                    <div className="material_name">
                                                        <h5>{materials?.materialName}</h5>
                                                    </div>
                                                    <div className="brand">
                                                        <h5>{materials?.brand}</h5>
                                                    </div>
                                                    <div className="quantity">
                                                        <h5>{materials?.requiredQuantity}</h5>
                                                    </div>
                                                    <div className="from">
                                                        <h5>{materials?.issuedBy?.userName}</h5>
                                                    </div>
                                                    <div className="sub_contractor">
                                                        <h5>{materials?.subContractorName}</h5>
                                                    </div>
                                                    <div className="location">
                                                        <h5>{materials?.locationName}</h5>
                                                    </div>
                                                    <div className="site_name">
                                                        <h5>{materials?.siteName}</h5>
                                                    </div>
                                                    <div className="date">
                                                        <h5>{new Date(materials.creationDate).toLocaleDateString()}</h5>
                                                    </div>
                                                    <div className="user_action">
                                                        <div className="action">
                                                            <NavLink to={`/material-issuing-receipt?issueId=${materials?._id}`} target="_blank">
                                                                <i className="fa-solid fa-file-lines" title="Issue Receipt"></i>
                                                            </NavLink>
                                                            <i className="fa-solid fa-pen-to-square ms-2 me-2" onClick={() => { setSelectedMaterial(materials); setShowUpdateMaterialIssuingForm(true) }}></i>

                                                            <i className="fa-solid fa-trash" onClick={() => deleteIssueMaterial(materials?._id, materials?.issueNumber)}></i>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Issued Materials Not Available</h5>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                        {showUpdateMaterialIssuingForm &&
                            <div className="modal_box">
                                <UpdateMaterialissuingForm setShowUpdateMaterialIssuingForm={setShowUpdateMaterialIssuingForm} issue={selectedMaterial} fetchIssueMaterial={fetchIssueMaterial} />
                            </div>
                        }
                    </div>
            }
        </>
    );
}
export default MaterailIssuing;