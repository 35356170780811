import React, { useEffect, useState } from "react";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
// import { MultiSelect } from "react-multi-select-component";
import Multiselect from "multiselect-react-dropdown";
import Loader from "../../Modals/Loader";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";

const AddNewMachineForm = ({ setShowAddNewMachineForm, fetchAllMachines }) => {
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const [validator, showValidationMessage] = useValidator();
    const [drivers, setDrivers] = useState([]);
    const [location, setLocation] = useState([]);
    const [locationWiseSite, setlocationWiseSite] = useState([]);
    const [machine, setMachine] = useState([
        {
            machineName: "",
            registrationNo: "",
            siteId: "",
            locationId: "",
            driverId: "",
            cur_hired_by: ""
        }
    ])

    const { machineName, registrationNo, siteId, locationId, driverId, cur_hired_by } = machine;

    //Fetch All Drivers
    const fetchAllDrivers = async () => {
        try {
            const responseFromServer = await communication.fetchAllDrivers();
            const getAllLocations = await communication.getAllLocations();
            if ((responseFromServer.data.status === "SUCCESS") && (getAllLocations.data.status === "SUCCESS")) {
                setLocation(getAllLocations.data.locations);
                setDrivers(responseFromServer.data.drivers);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Location Wise Sites
    const locationWiseSites = async (locationId) => {
        try {
            const responseFromServer = await communication.getLocationWiseSites(locationId);
            if (responseFromServer.data.status === "SUCCESS") {
                setlocationWiseSite(responseFromServer.data.sites);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchAllDrivers();
    }, []);

    //Add new Machine
    const AddNewMachineForm = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            const serverResponse = await communication.addMachine(machineName, registrationNo, driverId, siteId, locationId, cur_hired_by);
            if (serverResponse.data.status === "SUCCESS") {
                dispatch(hideLoader());
                setShowAddNewMachineForm(false);
                fetchAllMachines();
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="add_new_material">
                        <div className="form">
                            <div className="form_heading">
                                <h5>Add Machine</h5>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input_group">
                                        <input type="text" name="machineName" onChange={(event) => setMachine({ ...machine, machineName: event.target.value })} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Machine Name</label>
                                    </div>
                                    {validator.message("machineName", machineName, "required", {
                                        messages: {
                                            required: "Machine is required"
                                        }
                                    })}
                                </div>
                                <div className="col-6">
                                    <div className="input_group">
                                        <input type="text" name="registrationNo" onChange={(event) => setMachine({ ...machine, registrationNo: event.target.value })} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Registration No.</label>
                                    </div>
                                    {validator.message("registrationNo", registrationNo, "required", {
                                        messages: {
                                            required: "Registration Number is required"
                                        }
                                    })}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <select className="form-select" name="driverId" onChange={(event) => setMachine({ ...machine, driverId: JSON.parse(event.target.value)?._id })}>
                                        <option value={JSON.stringify({ name: "", _id: "" })} selected>Select Driver</option>
                                        {drivers?.map((driver, index) => (
                                            <option value={JSON.stringify(driver)} key={index}>{driver.name}</option>
                                        ))}
                                    </select>
                                    {validator.message("driverId", driverId, "required", {
                                        messages: {
                                            required: "Driver is required"
                                        }
                                    })}

                                    {/* <MultiSelect
                            options={drivers.map(driver => { return { label: driver.name, value: driver } })}
                            value={selectedDriver}
                            onChange={setSelectedDriver}
                            labelledBy="Select Driver"
                            hasSelectAll={false}
                        /> */}

                                    {/* <Multiselect
                            options={drivers}
                            displayValue="name"
                            showCheckbox={true}
                            onSelect={(event)=>setSelectedDriver(event)}
                            onRemove={(event)=>setSelectedDriver(event)}
                            placeholder="Select Driver"
                            showArrow
                            closeIcon="cancel"
                           customArrow={<><i className="fa-solid fa-angle-down"></i></>}
                            
                        /> */}

                                </div>
                                <div className="col-6">
                                    <select className="form-select" name="locationId" onChange={(event) => { setMachine({ ...machine, locationId: JSON.parse(event.target.value)?._id }); locationWiseSites(JSON.parse(event.target.value)?._id); }}>
                                        <option value={JSON.stringify({ locationName: "", _id: "" })}>Select Location</option>
                                        {location?.map((location, index) => (
                                            <option value={JSON.stringify(location)} key={index}>{location.locationName}</option>
                                        ))}
                                    </select>
                                    {validator.message("locationId", locationId, "required", {
                                        messages: {
                                            required: "Location is required"
                                        }
                                    })}
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <select className="form-select" name="siteId" onChange={(event) => { setMachine({ ...machine, siteId: JSON.parse(event.target.value)?._id }) }}>
                                        <option value={JSON.stringify({ siteName: "", _id: "" })}>Select Site</option>
                                        {locationWiseSite?.map((sites, index) => (
                                            <option value={JSON.stringify(sites)} key={index}>{sites.siteName}</option>
                                        ))}
                                    </select>
                                    {validator.message("siteId", siteId, "required", {
                                        messages: {
                                            required: "Site is required"
                                        }
                                    })}
                                </div>
                                <div className="col-6">
                                    <div className="input_group">
                                        <input type="text" name="cur_hired_by" onChange={(event) => setMachine({ ...machine, cur_hired_by: event.target.value })} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Hired By</label>
                                    </div>
                                    {validator.message("cur_hired_by", cur_hired_by, "required", {
                                        messages: {
                                            required: "Hiring person name is required"
                                        }
                                    })}
                                </div>
                                <div className="col-6"></div>
                            </div>
                            <div className="form_button">
                                <button onClick={() => AddNewMachineForm()}>Add</button>
                            </div>
                            <div className="close_form">
                                <i className="fa-solid fa-xmark" onClick={() => setShowAddNewMachineForm(false)}></i>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}
export default AddNewMachineForm;