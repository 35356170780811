import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import MaterialManagementTabRoutes from "../routes/MaterialManagementTabRoutes";

const MaterialManagement = () => {
    return (
        <div className="dashboard_tab_main">
            <h5 className="title">Material Management</h5>
            <div className="material_management_tabs">
                <div className="row">
                    <div className="col-4">
                        <NavLink to="/main-dashboard/material-management/type-category">
                            <li className="tabs">
                                <h5>Type/Category</h5>
                            </li>
                        </NavLink>
                    </div>
                    <div className="col-4">
                        <NavLink to="/main-dashboard/material-management/unit-management">
                            <li className="tabs">
                                <h5>Unit Management</h5>
                            </li>
                        </NavLink>
                    </div>
                    <div className="col-4">
                        <NavLink to="/main-dashboard/material-management/add-material">
                            <li className="tabs">
                                <h5>Material Management</h5>
                            </li>
                        </NavLink>
                    </div>
                </div>
            </div>
            <Outlet></Outlet>
            {/* <MaterialManagementTabRoutes /> */}
        </div>
    );
}
export default MaterialManagement;