import React, { useRef, useState } from "react";
import { communication } from "../../communication/communication";
import { useDispatch, useSelector } from 'react-redux';
import useValidator from "../../formValidation/useValidator";
import Modal from "../../Modals/Modal";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const AddNewDesignation = ({ setShowDesignationForm }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [designation, setDesignation] = useState();
    const alertModal = useSelector((state) => state.alertModal);
    //add new designation
    const addNewDesignation = async () => {
        try {

            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            //send request to backend
            const serverResponse = await communication.addDesignation(designation);
            if (serverResponse.data.status === "SUCCESS") {
                swal({ text: serverResponse.data?.message, icon: "success" });
                setShowDesignationForm(false);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <>
            <div className="add_new_designation">
                <div className="form">
                    <div className="form_heading">
                        <h5>Add New designation</h5>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="input_group">
                                <input type="text" name="designation" className="form-control" onChange={(event) => setDesignation(event.target.value)} autoComplete="off" required />
                                <label className="input_label">Enter designation</label>
                            </div>
                            {validator.message("designation", designation, "required|alpha_space", {
                                messages: {
                                    required: "Designation is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="form_button">
                        <button onClick={() => addNewDesignation()}>Add</button>
                    </div>
                    <div className="close_form">
                        <i className="fa-solid fa-xmark" onClick={() => setShowDesignationForm(false)}></i>
                    </div>
                </div>
            </div>

            {
                alertModal.alertModal === true &&
                <Modal />
            }
        </>
    );
}
export default AddNewDesignation;