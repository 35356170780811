import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { communication } from "../communication/communication";
import AddNewUser from "./forms/AddNewUser";
import UpdateUser from "./forms/UpdateUser";
import { accessControl } from "../access-control-object";
import Loader from "../Modals/Loader";
import { showLoader, hideLoader } from "../redux-store/loaderReducer";
import ChangePassword from "./forms/ChangePassword";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import ReactPaginate from "react-paginate";
import swal from "sweetalert";


const UserManagement = () => {
    const [addNewUserForm, setAddNewUserForm] = useState(false);
    const [showUpdateUserForm, setShowUpdateUserForm] = useState(false)
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [updateUser, setUpdateUser] = useState({ userId: "", name: "", email: "", designationName: "", designationId: "", licenseNo: "" });
    const [filteredUser, setFilteredUser] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [location, setLocation] = useState([]);
    const [locationWiseSites, setLocationWiseSites] = useState([]);
    const [accessControls, setAccessControls] = useState(accessControl);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [activeSelectedItem, setActiveSelectedItem] = useState(0);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader);
    const loginUserDetails = useSelector((state) => state.userDetails);
    const pageLimit = process.env.REACT_APP_PAGE_LIMIT;

    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchUsersBySearch(searchValue, selectedPage + 1);
        } else {
            getAllUsers(selectedPage + 1);
        }
    }

    //search filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchUsersBySearch(searchString, currentPage);
        } else {
            const responseFromServer = await communication.getAllUserList();
            if (responseFromServer.data.status === "SUCCESS") {
                setFilteredUser(responseFromServer.data.users);
                setSelectedUser(responseFromServer.data.users[0]);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setFilteredUser([]);
                setSelectedUser({});
                setPageCount(0);
            }

        }
    }

    //fetch users by search
    const fetchUsersBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchUsers(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setFilteredUser(responseFromServer.data.users);
                setSelectedUser(responseFromServer.data.users[0]);
                setPageCount(responseFromServer?.data?.totalPages);
                //setting access given to 0th index user
                if (responseFromServer.data.users[0]?.accessControl?.length === 0) {
                    setAccessControls(accessControl);
                } else {
                    responseFromServer.data.users[0]?.accessControl?.forEach((ele) => {
                        setAccessControls(ele);
                        getLocationWiseSites(ele?.locationId);
                    });
                }
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setFilteredUser([]);
                setSelectedUser({});
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }
    // Get all users on initial load
    const getAllUsers = async (page) => {
        try {
            dispatch(showLoader())
            const responseFromServer = await communication.getAllUserList(page);
            if (responseFromServer.data.status === "SUCCESS") {
                dispatch(hideLoader());
                setFilteredUser(responseFromServer.data.users);
                setSelectedUser(responseFromServer.data.users[0]);
                setPageCount(responseFromServer?.data?.totalPages);
                //setting access given to 0th index user
                if (responseFromServer.data.users[0]?.accessControl?.length === 0) {
                    setAccessControls(accessControl);
                } else {
                    responseFromServer.data.users[0]?.accessControl?.forEach((ele) => {
                        setAccessControls(ele);
                        getLocationWiseSites(ele?.locationId);
                    });
                }

            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                dispatch(hideLoader());
                setFilteredUser([]);
                setSelectedUser({});
                setPageCount(0);
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
            dispatch(hideLoader())
        }
    }

    //get all Site data on initial load
    const fetchLocation = async () => {
        try {
            dispatch(showLoader());
            const responseFromServer = await communication.getAllLocations();
            if (responseFromServer.data.status === "SUCCESS") {
                dispatch(hideLoader())
                setLocation(responseFromServer.data.locations);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data.message, icon: "warning" });
                dispatch(hideLoader())
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
            dispatch(hideLoader())
        }
    }
    //! get location sites According to location
    const getLocationWiseSites = async (locationId) => {
        try {
            const result = await communication.getLocationWiseSites(locationId);
            if (result.data.status === "SUCCESS") {
                setLocationWiseSites(result.data.sites);
            } else if (result?.data?.status === "JWT_INVALID") {
                swal({ text: result.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: result.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //add User access Controls
    const addAccessControls = async () => {
        try {
            dispatch(showLoader());
            if ([undefined, null, ""].includes(accessControls?.siteId)) {
                dispatch(hideLoader());
                swal({ text: "Please select Site", icon: "warning" });
                return false;
            }


            //check user select all access control if yes then set all values true
            if (accessControls.select_all === true) {
                for (let access in accessControls) {
                    if (!(access === "siteId" || access === "locationId")) {
                        accessControls[access] = true;
                    }
                };
            }

            const responseFromServer = await communication.addAccessControl(selectedUser._id, accessControls);
            if (responseFromServer.data.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: responseFromServer.data?.message, icon: "success" });
            } else if (responseFromServer.data.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate('/login');
            } else {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                dispatch(hideLoader());
            }


        } catch (error) {
            swal({ text: error?.message, icon: "error" });
            dispatch(hideLoader());
        }
    }



    const deleteUser = async (userId) => {
        confirmAlert({
            title: 'Are you sure you want to Delete User?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const serverResponse = await communication.deleteUser(userId);
                        if (serverResponse.data.status === "SUCCESS") {
                            getAllUsers();
                            return swal({ text: serverResponse.data?.message, icon: "success" });
                        } else if (serverResponse?.data?.status === "JWT_INVALID") {
                            dispatch(hideLoader())
                            swal({ text: serverResponse.data?.message, icon: "warning" });
                            navigate("/login");
                        } else {
                            return swal({ text: serverResponse.data?.message, icon: "warning" });
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return false }
                }
            ],
            overlayClassName: "react-confirm-alert"
        });
    }

    //Set access values on user click
    const accessValue = (user) => {
        if (user?.accessControl?.length === 0) {
            setAccessControls(accessControl);
        } else {
            user?.accessControl?.forEach((ele) => {
                setAccessControls(ele);
                getLocationWiseSites(ele?.locationId);
            });
        }
    }

    useEffect(() => {
        getAllUsers();
    }, [addNewUserForm, showUpdateUserForm, showPasswordForm]);

    useEffect(() => {
        fetchLocation();
    }, []);

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="dashboard_tab_main">
                        <h4 className="title">User Management</h4>
                        <div className="search_div">
                            <input type="text" className='form=control' onChange={(event) => { searchFilter(event.target.value); setSearchValue(event.target.value) }} placeholder='search by name, designation' title="search by name and designation" />
                            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.add_users === true) ?
                                <button onClick={() => setAddNewUserForm(true)}>Add New User</button> :
                                <></>
                            }

                        </div>
                        <div className="row user_management_wrapper">
                            {/*------Add Site section start---*/}
                            <div className="col-lg-6 col-md-12">
                                <div className="table_section user_management_section">
                                    <div className="table_header">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Sr. No</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <h5>Name of User</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <h5>Designation</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Action</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredUser?.length > 0 ?
                                        <>
                                            {filteredUser?.map((user, index) => (
                                                <div className={`table_data ${activeSelectedItem !== index ? "" : "active_selected_row"}`}>
                                                    <div className="row">
                                                        <div className="col-lg-2 col-md-2">
                                                            <h5>{pageLimit * currentPage + (index + 1)}</h5>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 click_event" onClick={() => { setSelectedUser(user); accessValue(user); setActiveSelectedItem(index) }}>
                                                            <h5><a>{user.name}</a></h5>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4">
                                                            <h5>{user.designationName}</h5>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div className="action">
                                                                <i className="fa-solid fa-pen-to-square" onClick={() => { setUpdateUser({ userId: user._id, name: user.name, email: user.email, designationName: user.designationName, designationId: "", licenseNo: user?.licenseNo ? user?.licenseNo : "" }); setShowUpdateUserForm(true) }}></i>
                                                                <i className="fa-solid fa-trash" onClick={() => deleteUser(user._id)}></i>
                                                                <i className="fa-solid fa-key" onClick={() => { setUpdateUser({ userId: user._id }); setShowPasswordForm(true) }}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Users are not Available</h5>
                                        </div>
                                    }

                                </div>
                            </div>

                            {/*------Add Location section start---*/}
                            <div className="col-lg-6 col-md-12 mt-md-3 mt-lg-0">
                                <div className="table_section user_management_section">
                                    <div className="user_access_control_main">
                                        <div className="row">
                                            <div className="col-12 give_access">
                                                <h5 className="title mb-2">{`${selectedUser.name} - ${selectedUser.designationName}`}</h5>
                                                <button onClick={() => addAccessControls()}>Give Access</button>
                                            </div>
                                        </div>
                                        {/*---select Site and Location-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Location</label>
                                                <select name="location" className="form-select" onChange={(event) => { setAccessControls({ ...accessControls, locationId: JSON.parse(event.target.value)?._id }); getLocationWiseSites(JSON.parse(event.target.value)?._id) }}>
                                                    <option value={JSON.stringify({ locationName: "", _id: "" })} >Select Location</option>
                                                    {location.map((location, index) => (
                                                        <option key={index} value={JSON.stringify(location)} selected={location?._id === accessControls?.locationId}>{location.locationName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-6">
                                                <label>Site</label>
                                                <select className="form-select" onChange={(e) => { setAccessControls({ ...accessControls, siteId: JSON.parse(e.target.value)?._id }) }}>
                                                    <option value={JSON.stringify({ siteName: "", _id: "" })} >Select Site</option>
                                                    {locationWiseSites?.map((sites, index) => {
                                                        return (<option key={index} value={JSON.stringify(sites)} selected={sites?._id === accessControls?.siteId}>{sites.siteName}</option>)
                                                    }
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        {/*---Access control-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="title">Access Control</h5>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="title">Select All</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox" value="" onChange={(e) => { setAccessControls({ ...accessControls, select_all: !(accessControls.select_all) }) }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---View edit labels-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5><b>Areas</b></h5>
                                                    <h5><b>View</b></h5>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5><b>Edit</b></h5>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---Dashboard -----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Dashboard</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.dashboard || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, dashboard: !(accessControls.dashboard) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">

                                                </div>
                                            </div>
                                        </div>
                                        {/*---User Management-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">User Management</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.user_management || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, user_management: !(accessControls.user_management) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Add Users</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.add_users || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, add_users: !(accessControls.add_users) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---Site Management-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Site Management</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.site_management || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, site_management: !(accessControls.site_management) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Add Location</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.add_location || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, add_location: !(accessControls.add_location) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Add Site</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.add_site || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, add_site: !(accessControls.add_site) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---Designation Management-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Designation Management</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.designation_management || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, designation_management: !(accessControls.designation_management) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Add Designation</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.add_designation || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, add_designation: !(accessControls.add_designation) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---Material Management-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Material Management</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.material_management || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, material_management: !(accessControls.material_management) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Add Material</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.add_material || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, add_material: !(accessControls.add_material) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---Machine Management-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Machine Management</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.machine_management || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, machine_management: !(accessControls.machine_management) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Add Machine</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.add_machine || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, add_machine: !(accessControls.add_machine) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---Sub-Contractor Management-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Sub-contractor Management</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.sub_contractor || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, sub_contractor: !(accessControls.sub_contractor) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Add Sub-contractor</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.add_contractor || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, add_contractor: !(accessControls.add_contractor) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*---Procurement Tab-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="title">Procurement</h5>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Procurement</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.procurement || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, procurement: !(accessControls.procurement) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                </div>
                                            </div>
                                        </div>

                                        {/*---New Requirement-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Total Requirements</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.total_requirement || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, total_requirement: !(accessControls.total_requirement) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Add New Purchase Order</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.add_new_purchase_order || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, add_new_purchase_order: !(accessControls.add_new_purchase_order) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">

                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Quotation Approval</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.quotation_approval || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, quotation_approval: !(accessControls.quotation_approval) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">New Purchase Indent</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.new_purchase_indent || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, new_purchase_indent: !(accessControls.new_purchase_indent) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Purchase Indent Approval</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.approval_purchase_indent || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, approval_purchase_indent: !(accessControls.approval_purchase_indent) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---All Approvals-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">All Approvals</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.all_approvals || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, all_approvals: !(accessControls.all_approvals) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Purchase Order Approval</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.purchase_order_approval || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, purchase_order_approval: !(accessControls.purchase_order_approval) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---Inventory starts-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="title">Inventory</h5>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Inventory</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.inventory || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, inventory: !(accessControls.inventory) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                </div>
                                            </div>
                                        </div>
                                        {/*---All Materials and Add New Purchase Indent-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">All Material</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.all_material || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, all_material: !(accessControls.all_material) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Create Purchase Indent</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.create_purchase_indent || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, create_purchase_indent: !(accessControls.create_purchase_indent) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---Material Issueing-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Material Issuing</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.material_issuing || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, material_issuing: !(accessControls.material_issuing) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Material Issuing</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.material_issuing_edit || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, material_issuing_edit: !(accessControls.material_issuing_edit) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---Material Transfer-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Material Transfer</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.material_transfer || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, material_transfer: !(accessControls.material_transfer) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Material Transfer</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.material_transfer_edit || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, material_transfer_edit: !(accessControls.material_transfer_edit) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---Material Received-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Material Received</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.material_received || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, material_received: !(accessControls.material_received) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Material Received GRN</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.material_received_grn || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, material_received_grn: !(accessControls.material_received_grn) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---Approved PO-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Approved Requirement</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.approved_po || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, approved_po: !(accessControls.approved_po) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">GRN of Approved PO</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.grn_of_approved_po || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, grn_of_approved_po: !(accessControls.grn_of_approved_po) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---Reports-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Reports</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.reports || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, reports: !(accessControls.reports) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Downloads Reports</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.download_reports || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, download_reports: !(accessControls.download_reports) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*---Machine Management starts-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="title">Machine Management</h5>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control"></div>
                                            </div>
                                        </div>
                                        {/*---Machines and Input Machine Logs-----*/}
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Machines</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.machines || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, machines: !(accessControls.machines) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="access_control">
                                                    <h5 className="switch_title">Input Machine Logs</h5>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" checked={accessControls.machine_logs || accessControls.select_all} onChange={(e) => { setAccessControls({ ...accessControls, machine_logs: !(accessControls.machine_logs) }) }} type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                        {/*----Add New User Modal----*/}
                        {addNewUserForm &&
                            < div className="modal_box">
                                <AddNewUser setAddNewUserForm={setAddNewUserForm} addNewUserForm={addNewUserForm} />
                            </div>
                        }
                        {showUpdateUserForm &&
                            < div className="modal_box">
                                <UpdateUser setShowUpdateUserForm={setShowUpdateUserForm} updateUser={updateUser} />
                            </div>
                        }
                        {showPasswordForm &&
                            < div className="modal_box">
                                <ChangePassword setShowPasswordForm={setShowPasswordForm} updateUser={updateUser} />
                            </div>
                        }

                    </div >
            }
        </>
    );
}
export default UserManagement;