import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import AddMaterialForm from "../forms/AddMaterialForm";
import { showLoader } from "../../redux-store/loaderReducer";
import { hideLoader } from "../../redux-store/loaderReducer";
import Loader from "../../Modals/Loader";
import UpdateMaterial from "../forms/UpdateMaterial";
import { confirmAlert } from 'react-confirm-alert';
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

const MaterialManagementTab = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginUserDetails = useSelector((state) => state.userDetails);
    const loader = useSelector((state) => state.loader);
    const [showAddMaterialForm, setShowAddMaterialForm] = useState(false);
    const [updateMaterial, setUpdateMaterial] = useState({ isShowModal: false, materialId: "", materialName: "", categoryName: "", categoryId: "", unitName: "", unitId: "" });
    const [allMaterial, setAllMaterial] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");

    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchMaterialsBySearch(searchValue, selectedPage + 1);
        } else {
            getAllMaterial(selectedPage + 1);
        }
    }

    //filter User according to designation & name
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchMaterialsBySearch(searchString, currentPage + 1);
        } else {
            const responseFromServer = await communication.getAllMaterialList();
            if (responseFromServer.data.status === "SUCCESS") {
                setAllMaterial(responseFromServer?.data?.materials);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setAllMaterial([]);
                setPageCount(0);
            }
        }
    }

    //fetch material list by search
    const fetchMaterialsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchMaterials(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setAllMaterial(responseFromServer?.data?.materials);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setAllMaterial([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Fetch All Materials on initial load
    const getAllMaterial = async (page) => {
        try {
            dispatch(showLoader())
            const responseFromServer = await communication.getAllMaterialList(page);
            if (responseFromServer.data.status === "SUCCESS") {
                dispatch(hideLoader())
                setAllMaterial(responseFromServer?.data?.materials);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setAllMaterial([]);
                setPageCount(0);
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "warning" });
            }

        } catch (error) {
            dispatch(hideLoader())
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        getAllMaterial();
    }, [updateMaterial]);

    //Delete Material
    const deleteMaterial = async (materialId) => {
        confirmAlert({
            title: 'Are you sure you want to Delete Material?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const serverResponse = await communication.deleteMaterial(materialId);
                        if (serverResponse.data.status === "SUCCESS") {
                            getAllMaterial();
                            return swal({ text: serverResponse.data?.message, icon: "success" });
                        } else if (serverResponse?.data?.status === "JWT_INVALID") {
                            swal({ text: serverResponse.data?.message, icon: "warning" });
                            navigate("/login");
                        } else {
                            return swal({ text: serverResponse.data?.message, icon: "warning" });
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return false }
                }
            ],
            overlayClassName: "react-confirm-alert"
        });
    }

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <>
                        <div className='search_div' >
                            <input type="text" className='form=control' onChange={(event) => { searchFilter(event.target.value); setSearchValue(event.target.value) }} placeholder='search by Material, Category & Unit' title="search by Material, Category & Unit" />

                            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.add_material === true) ?
                                <button onClick={() => setShowAddMaterialForm(true)}>Add New Material</button>
                                :
                                <></>
                            }
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="table_section material_management_section">
                                    <div className="table_header">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Sr. No</h5>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <h5>Material Name</h5>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <h5>Type/Category</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Unit</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Action</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {allMaterial?.length > 0 ?
                                        <>
                                            {allMaterial.map((material, index) => (
                                                <div className="table_data">
                                                    <div className="row">
                                                        <div className="col-lg-2 col-md-2">
                                                            <h5>{index + 1}</h5>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3">
                                                            <h5>{material.materialName}</h5>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3">
                                                            <h5>{material.categoryName}</h5>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <h5>{material.unitName}</h5>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div className="action">
                                                                <i className="fa-solid fa-pen-to-square" onClick={() => setUpdateMaterial({ isShowModal: true, materialId: material?._id, materialName: material?.materialName, categoryId: material?.categoryId, categoryName: material?.categoryName, unitName: material?.unitName, unitId: material?.unitId })}></i>
                                                                <i className="fa-solid fa-trash" onClick={() => deleteMaterial(material?._id)}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Material not Available</h5>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                        {showAddMaterialForm &&
                            < div className="modal_box">
                                <AddMaterialForm setShowAddMaterialForm={setShowAddMaterialForm} getAllMaterial={getAllMaterial} />
                            </div>
                        }
                        {updateMaterial.isShowModal &&
                            < div className="modal_box">
                                <UpdateMaterial setUpdateMaterial={setUpdateMaterial} updateMaterial={updateMaterial} />
                            </div>
                        }

                    </>
            }
        </>
    );
}
export default MaterialManagementTab;