import { createSlice } from '@reduxjs/toolkit';

const loader = createSlice({
    name: "loaderSlice",
    initialState: {
        showHideLoader: false
    },

    reducers: {
        showLoader: (state, action) => {
            state.showHideLoader = true;
        },
        hideLoader: (state, action) => {
            state.showHideLoader = false;
        },
    }
})
export const { showLoader, hideLoader } = loader.actions;
export default loader.reducer;