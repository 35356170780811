import React, { useEffect, useState } from "react";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const AddMaterialForm = ({ setUpdateMaterial, updateMaterial }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [allUnits, setAllUnits] = useState();
    const [allCategory, setAllCategory] = useState();
    const [material, setMaterial] = useState(updateMaterial);
    const { materialName, categoryId, unitId } = material;
    const onMaterialChange = (event) => {
        setMaterial({ ...material, [event.target.name]: event.target.value });
    }

    //Fetch all units 
    const getAllUnitsCategory = async () => {
        try {
            const getAllUnit = await communication.getAllUnits();
            const getAllCategory = await communication.getAllCategory();
            if (getAllUnit?.data?.status === "SUCCESS" && getAllCategory?.data?.status === "SUCCESS") {
                setAllUnits(getAllUnit?.data?.units);
                setAllCategory(getAllCategory?.data?.category);
                //set unit name wise unitId
                getAllUnit?.data?.units?.filter((ele) => {
                    if (ele?.unitName === material?.unitName) {
                        setMaterial({ ...material, unitId: ele?._id });
                    }
                });
                //set category name wise categoryId
                getAllCategory?.data?.category?.filter((ele) => {
                    if (ele?.categoryName === material?.categoryName) {
                        setMaterial({ ...material, categoryId: ele?._id });
                    }
                });
            } else {
                setAllUnits([]);
                setAllCategory([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        getAllUnitsCategory();
    }, []);

    //Update Material 
    const updateExistingMaterial = async (materialId) => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            const serverResponse = await communication.updateMaterial(materialId, materialName, categoryId, unitId);
            if (serverResponse.data.status === "SUCCESS") {
                setUpdateMaterial({ isShowModal: false });
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <div className="add_new_material">
            <div className="form">
                <div className="form_heading">
                    <h5>Update Material</h5>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="input_group">
                            <input type="text" name="materialName" value={material.materialName} onChange={(event) => onMaterialChange(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Material Name</label>
                        </div>
                        {validator.message("materialName", materialName, "required|alpha_space", {
                            messages: {
                                required: "Material is required"
                            }
                        })}
                    </div>
                    <div className="col-6">
                        <select name="unitId" onChange={(event) => onMaterialChange(event)} className="form-select">
                            {allUnits?.map((unit, index) => (
                                <option value={unit._id} selected={unit?._id === material?.unitId} key={index}>{unit.unitName}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <select name="categoryId" onChange={(event) => onMaterialChange(event)} className="form-select">
                            <option value="">select category</option>
                            {allCategory?.map((category, index) => (
                                <option value={category?._id} selected={category?._id === material?.categoryId} key={index}>{category.categoryName}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-6"></div>
                </div>
                <div className="form_button">
                    <button onClick={() => updateExistingMaterial(material.materialId)}>Update</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setUpdateMaterial({ isShowModal: false })}></i>
                </div>
            </div>
        </div>
    );
}
export default AddMaterialForm;