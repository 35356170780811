import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode'

let decodeValue = null;
//----------------------------------Helper Function------------------------------------------------------
(function getCookie() {
    // Split cookie string and get all individual valueName=value pairs in an array
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        if ("hpsUserToken" === cookiePair[0].trim()) {
            // Decode the cookie value and return
            decodeValue = jwtDecode(cookiePair[1]);
            return decodeValue;
        }
    }
    // Return null if not found
    return null;
})();
//----------------------------------Helper Function------------------------------------------------------


const userDetails = createSlice({
    name: "userDetails",
    initialState: {
        userName: decodeValue?.name ?? "",
        designation: decodeValue?.designation ?? "",
        accessControl: decodeValue?.accessControl ?? []
    },
    reducers: {
        addCookies: (state, action) => {
            state.userName = action.payload.name;
            state.designation = action.payload.designation;
            state.accessControl = action?.payload?.accessControl;
            document.cookie = `hpsUserToken=${action.payload.token};path=/`;
        },
        removeCookies: (state, action) => {
            state.userName = "";
            state.designation = "";
            state.accessControl = {};
            document.cookie = "hpsUserToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
    },
});


export const { addCookies, removeCookies } = userDetails.actions;
export default userDetails.reducer;