import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import AddNewMachineForm from "./forms/AddNewMachineForm";
import { communication } from "../communication/communication";
import UpdateMachine from "./forms/UpdateMachine";
import { showLoader, hideLoader } from "../redux-store/loaderReducer";
import Loader from "../Modals/Loader";
import ReactPaginate from "react-paginate";
import { confirmAlert } from 'react-confirm-alert';
import swal from "sweetalert";

const MachineManagement = () => {
    const loginUserDetails = useSelector((state) => state.userDetails);
    const [showAddNewMachineForm, setShowAddNewMachineForm] = useState(false);
    const [showUpdateMachineForm, setShowUpdateMachineForm] = useState({ isShowModal: false, machineId: "", machineName: "", registrationNo: "", driver: "", driverId: "", locationName: "", locationId: "", siteName: "", siteId: "", cur_hired_by: "" })
    const [allMachines, setAllMachines] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);

    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchMachinesBySearch(searchValue, selectedPage + 1);
        } else {
            fetchAllMachines(selectedPage + 1);
        }
    }

    //Search filter 
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchMachinesBySearch(searchString, currentPage + 1);
        } else {
            const responseFromServer = await communication.fetchAllMachinesList();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setAllMachines(responseFromServer?.data?.machines);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setAllMachines([]);
                setPageCount(0);
            }
        }
    }

    //fetch machines by search 
    const fetchMachinesBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchMachines(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setAllMachines(responseFromServer?.data?.machines);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setAllMachines([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }
    //Fetch all Machines on Initial load
    const fetchAllMachines = async (page) => {
        try {
            dispatch(showLoader())
            const responseFromServer = await communication.fetchAllMachinesList(page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader())
                setAllMachines(responseFromServer?.data?.machines);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                setAllMachines([]);
                setPageCount(0);
            }
        } catch (error) {
            dispatch(hideLoader())
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchAllMachines();
    }, []);

    //Delete Machine
    const deleteMachine = async (machineId) => {
        confirmAlert({
            title: 'Are you sure you want to Delete Machine?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const serverResponse = await communication.deleteMachine(machineId);
                        if (serverResponse.data.status === "SUCCESS") {
                            fetchAllMachines();
                            return swal({ text: serverResponse.data?.message, icon: "success" });
                        } else if (serverResponse?.data?.status === "JWT_INVALID") {
                            swal({ text: serverResponse.data?.message, icon: "warning" });
                            navigate("/login");
                        } else {
                            return swal({ text: serverResponse.data?.message, icon: "warning" });
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return false }
                }
            ],
            overlayClassName: "react-confirm-alert"
        });
    }


    return (
        <>
            {

                loader?.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="dashboard_tab_main">
                        <h4 className="title">Machine Management</h4>
                        <div className='search_div'>
                            <input type="text" className='form=control' onChange={(event) => { searchFilter(event.target.value.trim()); setSearchValue(event.target.value.trim()) }} placeholder='search by machine name, driver, location, site' title="Search by machine name, driver name, location, site, hired by" />
                            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.add_machine === true) ?
                                <button onClick={() => setShowAddNewMachineForm(true)}>Add New Machine</button>
                                :
                                <></>
                            }
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="machine_management_table">
                                    <div className="table_heading">
                                        <div className="sr_no">
                                            <h5>Sr. No.</h5>
                                        </div>
                                        <div className="machine_name">
                                            <h5>Machine Name</h5>
                                        </div>
                                        <div className="registration_no">
                                            <h5>Registration No.</h5>
                                        </div>
                                        <div className="driver_name">
                                            <h5>Driver Name</h5>
                                        </div>
                                        <div className="driver_license">
                                            <h5>Driver License</h5>
                                        </div>
                                        <div className="hired_by">
                                            <h5>Hired By</h5>
                                        </div>
                                        <div className="location">
                                            <h5>Location</h5>
                                        </div>
                                        <div className="site_name">
                                            <h5>Name of Site</h5>
                                        </div>
                                        <div className="user_action">
                                            <h5>Action</h5>
                                        </div>

                                    </div>
                                    {allMachines?.length > 0 ?
                                        <>
                                            {allMachines?.map((machine, index) => {
                                                return (
                                                    <div className="table_content" key={index}>
                                                        <div className="sr_no">
                                                            <h5>{index + 1}</h5>
                                                        </div>
                                                        <div className="machine_name">
                                                            <h5 className="click_event" title="Click to see details" onClick={() => navigate(`/main-dashboard/machine-management/machine-details?machineId=${machine?._id}`)}><a>{machine?.machineName}</a></h5>
                                                        </div>
                                                        <div className="registration_no">
                                                            <h5>{machine?.registrationNo}</h5>
                                                        </div>
                                                        <div className="driver_name">
                                                            <h5>{machine?.driver}</h5>
                                                        </div>
                                                        <div className="driver_license">
                                                            <h5>{machine?.licenseNo}</h5>
                                                        </div>
                                                        <div className="hired_by">
                                                            <h5>{machine?.cur_hired_by}</h5>
                                                        </div>
                                                        <div className="location">
                                                            <h5>{machine?.locationName}</h5>
                                                        </div>
                                                        <div className="site_name">
                                                            <h5>{machine?.siteName}</h5>
                                                        </div>
                                                        <div className="user_action">
                                                            <div className="action">
                                                                <i className="fa-solid fa-pen-to-square me-2" onClick={() => setShowUpdateMachineForm({ isShowModal: true, machineId: machine?._id, machineName: machine?.machineName, registrationNo: machine?.registrationNo, driver: machine.driver, driverId: machine?.driverId, locationName: machine?.locationName, locationId: machine?.locationId, siteName: machine?.siteName, siteId: machine?.siteId, cur_hired_by: machine?.cur_hired_by })}></i>
                                                                <i className="fa-solid fa-trash ms-2" onClick={() => deleteMachine(machine?._id)}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            )}
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Machines are not Available</h5>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                        {showAddNewMachineForm &&
                            < div className="modal_box">
                                <AddNewMachineForm setShowAddNewMachineForm={setShowAddNewMachineForm} fetchAllMachines={fetchAllMachines} />
                            </div>
                        }
                        {showUpdateMachineForm.isShowModal &&
                            < div className="modal_box">
                                <UpdateMachine showUpdateMachineForm={showUpdateMachineForm} setShowUpdateMachineForm={setShowUpdateMachineForm} fetchAllMachines={fetchAllMachines} />
                            </div>
                        }

                    </div>
            }
        </>
    );
}
export default MachineManagement;