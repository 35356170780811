import { configureStore } from '@reduxjs/toolkit';
import userDetails from "./userAuthenticateReducer";
import loader from "./loaderReducer";
import alertModal from "./alertMessageReducer";

const store = configureStore({
    reducer: {
        userDetails: userDetails,
        loader: loader,
        alertModal: alertModal
    }
});

export default store;
