import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../../Modals/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";

const UpdateMaterialissuingForm = ({ setShowUpdateMaterialIssuingForm, issue, fetchIssueMaterial }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [locationWiseSites, setLocationWiseSites] = useState([]);
    const [location, setLocation] = useState([]);
    const [supervisors, setSupervisors] = useState([]);
    const [subContractors, setSubContractors] = useState([]);
    const [allMaterial, setAllMaterial] = useState([]);
    const [brands, setBrands] = useState([]);
    const [availableQuantity, setAvailableQuantity] = useState(issue.availableQuantityInStore);
    const [materials, setMaterials] = useState(issue);


    const { siteId, source, locationId, subContractor, subContractorId, supervisor, supervisorId, materialName, materialId, brand, requiredQuantity } = materials;
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader);

    //Get All input field values
    const issueDetails = (event) => {
        if (event.subContractorName) {
            setMaterials({ ...materials, "subContractor": event })
        } else if (event.name) {
            setMaterials({ ...materials, "supervisor": event })
        } else if (event.materialName) {
            setMaterials({ ...materials, "material": event })
        } else {
            setMaterials({ ...materials, [event.target.name]: event.target.value })
        }
    }

    //issue material
    const updateIssueMaterial = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            const serverResponse = await communication.updateIssueMaterial(materials);
            if (serverResponse.data.status === "SUCCESS") {
                setShowUpdateMaterialIssuingForm(false);
                fetchIssueMaterial();
                swal({ text: serverResponse.data?.message, icon: "success" });
                dispatch(hideLoader());
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    //Fetch Supervisors 
    const fetchSupervisors = async (siteId) => {
        try {
            const getSupervisors = await communication.fetchSiteSupervisor(siteId);
            if (getSupervisors.data.status === "SUCCESS") {
                setSupervisors(getSupervisors.data.supervisors);
            } else if (getSupervisors?.data?.status === "JWT_INVALID") {
                swal({ text: getSupervisors.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: getSupervisors.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    //Fetch SubContractors 
    const fetchSubContractor = async (siteId) => {
        try {
            const getSubContractors = await communication.fetchSiteWiseSubContractors(siteId);
            if (getSubContractors.data.status === "SUCCESS") {
                setSubContractors(getSubContractors.data.subContractors);
            } else if (getSubContractors?.data?.status === "JWT_INVALID") {
                swal({ text: getSubContractors.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: getSubContractors.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Fetch Available stock by brand
    const fetchAvailableQuantity = async (materialBrand) => {
        try {
            const serverResponse = await communication.getAvailableStockByBrand(materialName, materialBrand);
            if (serverResponse.data.status === "SUCCESS") {
                setAvailableQuantity(serverResponse.data.availableMaterial);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setAvailableQuantity(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    //Fetch brand of material 
    const fetchBrands = async (materialId) => {
        try {
            const getBrands = await communication.getBrandByMaterial(materialId);
            if (getBrands.data.status === "SUCCESS") {
                setBrands(getBrands.data.brands);
            } else if (getBrands?.data?.status === "JWT_INVALID") {
                swal({ text: getBrands.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setBrands([]);
                swal({ text: getBrands.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    //Fetch All Materials on initial load
    const getAllMaterial = async () => {
        try {
            const responseFromServer = await communication.getAllMaterial();
            if (responseFromServer.data.status === "SUCCESS") {
                setAllMaterial(responseFromServer.data.materials);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setAllMaterial([]);
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    //get all Locations data on initial load
    const fetchLocations = async () => {
        try {
            const responseFromServer = await communication.getAllLocations();
            if (responseFromServer.data.status === "SUCCESS") {
                setLocation(responseFromServer.data.locations);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setLocation([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //! get location sites According to location
    const getLocationWiseSites = async (locationId) => {
        try {
            const result = await communication.getLocationWiseSites(locationId);
            if (result.data.status === "SUCCESS") {
                setLocationWiseSites(result.data.sites);
            } else if (result?.data?.status === "JWT_INVALID") {
                swal({ text: result.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: result.data?.message, icon: "error" });
                setLocationWiseSites([])
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchLocations();
        getLocationWiseSites(locationId);
        getAllMaterial();
        fetchSupervisors(siteId);
        fetchSubContractor(siteId);
        fetchBrands(materialId)
    }, [])
    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="issue_material">
                        <div className="form">
                            <div className="form_heading">
                                <h5>Update Issue Material</h5>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <select name="locationId" className="form-select" onChange={(event) => { setMaterials({ ...materials, locationName: JSON.parse(event?.target.value)?.name, locationId: JSON.parse(event.target.value)?._id }); getLocationWiseSites(JSON.parse(event.target.value)?._id) }} >
                                        <option value={JSON.stringify({ _id: "" })} >Select location</option>
                                        {location.map((locationDetails) => {
                                            return (
                                                <option value={JSON.stringify(locationDetails)} selected={locationDetails?._id === locationId ? true : false} >{locationDetails.locationName}</option>
                                            )
                                        })}

                                    </select>
                                    {validator.message("locationId", locationId, "required", {
                                        messages: {
                                            required: "locationName is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <select name="siteId" className="form-select" onChange={(event) => { setMaterials({ ...materials, siteName: JSON.parse(event.target.value)?.name, siteId: JSON.parse(event.target.value)?._id }); fetchSubContractor(JSON.parse(event.target.value)?._id); fetchSupervisors(JSON.parse(event.target.value)?._id) }}>
                                        <option value={JSON.stringify({ name: "", _id: "" })} >Select Site</option>
                                        {locationWiseSites.map((site) => {
                                            return (
                                                <option value={JSON.stringify(site)} selected={site?._id === siteId} >{site.siteName}</option>
                                            )
                                        })}
                                    </select>
                                    {validator.message("siteId", siteId, "required", {
                                        messages: {
                                            required: "Site is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <select name="source" className="form-select" onChange={(event) => issueDetails(event)}>
                                        <option value="" >Select Source</option>
                                        <option value="Inventory" selected={source === "Inventory"} >Inventory</option>
                                        <option value="Site" selected={source === "Site"}>Site</option>
                                    </select>
                                    {validator.message("source", source, "required", {
                                        messages: {
                                            required: "Source is required"
                                        }
                                    })}
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <select name="subContractorId" className="form-select" onChange={(event) => setMaterials({ ...materials, subContractorName: JSON.parse(event.target.value)?.name, subContractorId: JSON.parse(event.target.value)?._id })}>
                                        <option value={JSON.stringify({ name: "", _id: "" })} selected>Select Sub-Contractor</option>
                                        {subContractors?.map((contractor, index) => (
                                            <option value={JSON.stringify(contractor)} selected={contractor?._id === subContractorId} key={index}>{contractor?.subContractorName}</option>
                                        ))}
                                    </select>
                                    {validator.message("subContractorId", subContractorId, "required", {
                                        messages: {
                                            required: "Sub-Contractor is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <select name="supervisorId" className="form-select" onChange={(event) => setMaterials({ ...materials, subContractorName: JSON.parse(event.target.value)?.name, supervisorId: JSON.parse(event.target.value)?._id })}>
                                        <option value={JSON.stringify({ name: "", _id: "" })} >Select Supervisor</option>
                                        {supervisors?.map((superVisor, index) => (
                                            <option value={JSON.stringify(superVisor)} selected={supervisor?._id === supervisorId} key={index}>{superVisor?.name}</option>
                                        ))}
                                    </select>
                                    {validator.message("supervisorId", supervisorId, "required", {
                                        messages: {
                                            required: "Supervisor is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <select name="materialId" className="form-select" onChange={(event) => { setMaterials({ ...materials, materialName: JSON.parse(event.target.value)?.name, materialId: JSON.parse(event.target.value)?._id }); fetchBrands(JSON.parse(event.target.value)?._id) }}>
                                        <option value={JSON.stringify({ name: "", _id: "" })} selected>Select Material</option>
                                        {allMaterial?.map((materialDetails, index) => (
                                            <option value={JSON.stringify(materialDetails)} selected={materialDetails?._id === materialId} key={index}>{materialDetails?.materialName}</option>
                                        ))}
                                    </select>
                                    {validator.message("materialId", materialId, "required", {
                                        messages: {
                                            required: "Material is required"
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                {/* <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="material_code" className="form-control" autoComplete="off" required />
                            <label className="input_label">Material Code</label>
                        </div>
                    </div> */}
                                <div className="col-lg-4 col-md-6">
                                    <select name="brand" className="form-select" onChange={(event) => { fetchAvailableQuantity(event.target.value); issueDetails(event) }}>
                                        <option value="" >Select Make/Brand</option>
                                        {brands?.map((brandDetails, index) => (
                                            <option value={brandDetails?.brand} selected={brandDetails?.brand === brand} key={index}>{brandDetails?.brand}</option>
                                        ))}
                                    </select>
                                    {validator.message("brand", brand, "required", {
                                        messages: {
                                            required: "Brand is required"
                                        }
                                    })}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="input_group">
                                        <input type="text" name="availableQuantity" value={availableQuantity} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Available Quantity in Store</label>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="input_group">
                                        <input type="text" name="requiredQuantity" value={requiredQuantity} onChange={(event) => issueDetails(event)} className="form-control" autoComplete="off" required />
                                        <label className="input_label">required Quantity</label>
                                    </div>
                                    {validator.message("requiredQuantity", requiredQuantity, "required", {
                                        messages: {
                                            required: "Quantity is required"
                                        }
                                    })}
                                </div>
                            </div>
                            {/* <div className="row">
                   
                    <div className="col-lg-2 col-md-3" style={{ display: "flex", justifyContent: "start", alignItems: "center", paddingTop: "1.6875rem" }}>
                        <h5>Date : {`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`}</h5>
                    </div>
                    <div className="col-lg-2 col-md-3" style={{ display: "flex", justifyContent: "start", alignItems: "center", paddingTop: "1.6875rem" }}>
                        <h5>Time : {`${date.getHours()}:${date.getMinutes()}`}</h5>
                    </div>
                </div> */}
                            <div className="form_button">
                                <button onClick={() => updateIssueMaterial()}>Update</button>
                            </div>
                            <div className="close_form">
                                <i className="fa-solid fa-xmark" onClick={() => setShowUpdateMaterialIssuingForm(false)}></i>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default UpdateMaterialissuingForm