import React, { useEffect, useState } from "react";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const CreateGrnForm = ({ setShowCreateGrnForm, poDetails, fetchGrn }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [balanceQuantity, setBalanceQuantity] = useState();
    const [rejectedQuantity, setRejectedQuantity] = useState();
    const [selectedVendor, setSelectedVendor] = useState();
    const [brand, setBrand] = useState([])
    const [vendor, setVendor] = useState([]);
    const [grnData, setGrnData] = useState(
        {
            siteAddress: "",
            grnDate: "",
            supplierName: "",
            receivedBy: "",
            transporterName: "",
            gateNumber: "",
            challanNumber: "",
            invoiceNumber: "",
            vehicleNumber: "",
            totalQuantity: poDetails?.quantityRequired,
            receivedQuantity: "",
            acceptedQuantity: "",
            poNumber: poDetails?.poNumber,
            poId: poDetails?._id,
            type: poDetails?.type,
            siteId: poDetails?.siteId,
            locationId: poDetails?.locationId,
            brand: {}
        }
    )

    const { siteAddress, supplierName, receivedBy, transporterName, gateNumber, grnDate, challanNumber, invoiceNumber, vehicleNumber, totalQuantity, receivedQuantity, acceptedQuantity, } = grnData;


    //Getting all inputs value
    const grnDetail = (event) => {
        setGrnData({ ...grnData, [event.target.name]: event.target.value });
    }

    //get selected Vendor Detail
    const getVendor = async () => {
        try {
            const serverResponse = await communication.getSelectedVendor(poDetails?._id);
            if (serverResponse?.data?.status === "SUCCESS") {
                setVendor(serverResponse?.data?.vendorDetails);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setVendor([]);
                swal({ text: serverResponse.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    //upload grn for PO
    const createGrn = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            //check receivedQuantity is less than acceptQuantity
            if (acceptedQuantity > receivedQuantity) {
                return swal({ text: "Accepted Quantity not be more than Received Quantity", icon: "error" });
            }

            let dataToSend = {
                ...grnData,
                rejectedQuantity,
                vendor: selectedVendor
            }

            if (poDetails?.type === "GENERAL") {
                if (receivedQuantity > totalQuantity) {
                    return swal({ text: "Received Quantity not be more than total Quantity", icon: "error" });
                }
                dataToSend["balanceQuantity"] = balanceQuantity
            }

            //upload grn 
            const serverResponse = await communication.uploadGrn(dataToSend);
            if (serverResponse?.data?.status === "SUCCESS") {
                setShowCreateGrnForm(false);
                swal({ text: serverResponse.data?.message, icon: "success" });
                fetchGrn();
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "error" });
                return false;
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
            return false;
        }
    }

    const changeHandler = (vendorDetail) => {
        setSelectedVendor(vendorDetail)

        //set Brand according to Vendor
        const brandAccordingToVendor = [];
        vendor.forEach((element) => {
            if (vendorDetail.venderName === element?.vendor?.venderName) {
                element.bill.forEach(brand => {
                    if (brand?.isSelected) {
                        brandAccordingToVendor.push(brand);
                    }
                })
            }
        });
        setBrand(brandAccordingToVendor);
    }

    useEffect(() => {
        getVendor();
    }, [])

    return (
        <div className="issue_transfer">
            <div className="form">
                <div className="form_heading">
                    <h5>Create GRN</h5>
                </div>
                <div className="row mt-4" style={{ textAlign: "left" }}>
                    <div className="col-lg-3 col-md-3 mt-lg-0 mt-md-2">
                        <h5><b>PO No. : {poDetails?.poNumber}</b></h5>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <h5><b>Name of Location : {poDetails?.locationName}</b></h5>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5><b>Name of Site : {poDetails?.siteName}</b></h5>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <input type="date" name="grnDate" onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                        {/* <div className="input_group">
                            <label className="input_label">Date</label>
                        </div> */}
                        {validator.message("Date", grnDate, "required", {
                            messages: {
                                required: "Date is required"
                            }
                        })}
                    </div>

                </div>
                <div className="row" >
                    <div className="col-lg-6 col-md-6">
                        <select className="form-select" onChange={(event) => { changeHandler(JSON.parse(event.target.value)) }} >
                            <option value={JSON.stringify({ venderName: "" })}>Select Vendor</option>
                            {vendor.map((vendorDetails, index) => {
                                return (
                                    <option value={JSON.stringify(vendorDetails?.vendor)} key={index}>{vendorDetails?.vendor?.venderName}</option>
                                )
                            })}
                        </select>
                        {validator.message("Vendor", selectedVendor, "required", {
                            messages: {
                                required: "Vendor is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <select className="form-select" onChange={(event) => { setGrnData({ ...grnData, brand: JSON.parse(event.target.value) }) }} >
                            <option value={JSON.stringify({})}>Select Brand</option>
                            {brand?.map((brandDetails, index) => {
                                return (
                                    <option value={JSON.stringify(brandDetails)} key={index}>{brandDetails?.brand}</option>
                                )
                            })}
                        </select>
                        {validator.message("Brand", selectedVendor, "required", {
                            messages: {
                                required: "Brand is required"
                            }
                        })}
                    </div>

                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="siteAddress" onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Address of Site</label>
                        </div>
                        {validator.message("siteAddress", siteAddress, "required", {
                            messages: {
                                required: "Site Address is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="supplierName" onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Name of Supplier</label>
                        </div>
                        {validator.message("supplierName", supplierName, "required|alpha_space", {
                            messages: {
                                required: "Supplier Name is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="receivedBy" onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Received By</label>
                        </div>
                        {validator.message("receivedBy", receivedBy, "required|alpha_space", {
                            messages: {
                                required: "Receiver Name is required"
                            }
                        })}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="transporterName" onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Transporter Name</label>
                        </div>
                        {validator.message("transporterName", transporterName, "required|alpha_space", {
                            messages: {
                                required: "Transporter Name is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="gateNumber" onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Gate Entry Number</label>
                        </div>
                        {validator.message("gateNumber", gateNumber, "required", {
                            messages: {
                                required: "Gate Number is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="challanNumber" onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Challan Number</label>
                        </div>
                        {validator.message("challanNumber", challanNumber, "required", {
                            messages: {
                                required: "Challan Number is required"
                            }
                        })}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="invoiceNumber" onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Invoice Number</label>
                        </div>
                        {validator.message("invoiceNumber", invoiceNumber, "required", {
                            messages: {
                                required: "Invoice Number is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="vehicleNumber" onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Vehicle Number</label>
                        </div>
                        {validator.message("vehicleNumber", vehicleNumber, "required", {
                            messages: {
                                required: "Vehicle Number is required"
                            }
                        })}
                    </div>
                    {poDetails.type === "GENERAL" &&
                        <div className="col-lg-4 col-md-6">
                            <div className="input_group">
                                <input type="text" name="totalQuantity" value={totalQuantity} className="form-control" autoComplete="off" />
                                <label className="input_label">Total Quantity</label>
                            </div>
                        </div>
                    }
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="receivedQuantity" onChange={(event) => { grnDetail(event); setRejectedQuantity(event.target.value - acceptedQuantity) }} className="form-control" autoComplete="off" required />
                            <label className="input_label">Received Quantity</label>
                        </div>
                        {validator.message("receivedQuantity", receivedQuantity, "required|numeric", {
                            messages: {
                                required: "Received Quantity is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="acceptedQuantity" onChange={(event) => { grnDetail(event); setBalanceQuantity(totalQuantity - event.target.value); setRejectedQuantity(receivedQuantity - event.target.value) }} className="form-control" autoComplete="off" required />
                            <label className="input_label">Accepted Quantity</label>
                        </div>
                        {validator.message("acceptedQuantity", acceptedQuantity, "required|numeric", {
                            messages: {
                                required: "Accepted Quantity is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="rejectedQuantity" value={rejectedQuantity} className="form-control" autoComplete="off" required />
                            <label className="input_label">Rejected Quantity</label>
                        </div>
                    </div>
                </div>
                {poDetails.type === "GENERAL" &&
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="input_group">
                                <input type="text" name="balanceQuantity" value={balanceQuantity} className="form-control" autoComplete="off" required />
                                <label className="input_label">Balance Quantity</label>
                            </div>
                        </div>
                    </div>
                }
                <div className="form_button">
                    <button onClick={() => createGrn()}>Add</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowCreateGrnForm(false)}></i>
                </div>
            </div>
        </div>
    );
}
export default CreateGrnForm;