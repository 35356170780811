import React, { useState } from "react";

const DeleteModal = ({ setShowDeleteModal, showDeleteModal, deleteOperation, deletes }) => {
    const [id, setId] = useState(showDeleteModal.id)
    return (
        <div className="delete_modal">
            <div className="text">
                <h5>Are You Sure You Want to Delete?</h5>
            </div>
            <div className="delete_modal_button">
                <button onClick={() => {
                    {showDeleteModal.type === "deleteOperation" ? deleteOperation(id) : deletes(id)}}}>Confirm</button>
                <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
            </div>
        </div>
    );
}
export default DeleteModal;