import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { communication } from "../communication/communication";
import AddSubContractorForm from "./forms/AddSubContractorForm";
import UpdateSubContractor from "./forms/UpdateSubContractor";
import { showLoader, hideLoader } from "../redux-store/loaderReducer";
import Loader from "../Modals/Loader";
import ReactPaginate from "react-paginate";
import { confirmAlert } from 'react-confirm-alert';
import swal from "sweetalert";

const SubContractorManagement = () => {
    const loginUserDetails = useSelector((state) => state.userDetails);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);
    const [showSubContractorForm, setShowSubContractorForm] = useState(false);
    const [subContractor, setSubContractor] = useState([]);
    const [updateContractor, setUpdateContractor] = useState({ isShowModal: false, subContractorId: "", subContractorName: "", siteName: "", siteId: "", locationName: "", locationId: "" })
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");

    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchSubContractorsBySearch(searchValue, selectedPage + 1);
        } else {
            fetchAllSubContractors(selectedPage + 1);
        }
    }

    // Search filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchSubContractorsBySearch(searchString, currentPage + 1);
        } else {
            const responseFromServer = await communication.fetchSubContractorsList();
            if (responseFromServer.data.status === "SUCCESS") {
                setSubContractor(responseFromServer?.data?.subContractors);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setSubContractor([]);
                setPageCount(0);
            }
        }
    }

    //fetch sub contractors by search
    const fetchSubContractorsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchSubContractors(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setSubContractor(responseFromServer?.data?.subContractors);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setSubContractor([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Fetch all SubContractors on initial load
    const fetchAllSubContractors = async (page) => {
        try {
            dispatch(showLoader())
            const responseFromServer = await communication.fetchSubContractorsList(page);
            if (responseFromServer.data.status === "SUCCESS") {
                dispatch(hideLoader())
                setSubContractor(responseFromServer?.data?.subContractors);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                setSubContractor([]);
                setPageCount(0);
            }
        } catch (error) {
            dispatch(hideLoader())
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchAllSubContractors();
    }, []);

    //Delete SubContractor
    const deleteSubContractor = async (subContractorId) => {
        confirmAlert({
            title: 'Are you sure you want to Delete Sub-Contractor?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const serverResponse = await communication.deleteSubContractor(subContractorId);
                        if (serverResponse.data.status === "SUCCESS") {
                            fetchAllSubContractors();
                            return swal({ text: serverResponse.data?.message, icon: "success" });
                        } else if (serverResponse?.data?.status === "JWT_INVALID") {
                            swal({ text: serverResponse.data?.message, icon: "error" });
                            navigate("/login");
                        } else {
                            return swal({ text: serverResponse.data?.message, icon: "error" });
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return false }
                }
            ],
            overlayClassName: "react-confirm-alert"
        });
    }


    return (
        <>
            {

                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="dashboard_tab_main">
                        <h4 className="title">Sub-Contractor Management</h4>
                        <div className='search_div'>
                            <input type="text" className='form=control' onChange={(event) => { searchFilter(event.target.value); setSearchValue(event.target.value) }} placeholder='search by sub-contractor, location, site' title="Search by sub-contractor, location, site" />
                            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.add_contractor === true) ?
                                <button onClick={() => setShowSubContractorForm(true)}>Add Sub-Contractor</button>
                                :
                                <></>
                            }
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="table_section">
                                    <div className="table_header">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-1">
                                                <h5>Sr. No</h5>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <h5>Sub Contractor Name</h5>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <h5>Site</h5>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <h5>Location</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Action</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {subContractor?.length > 0 ?
                                        <>
                                            {subContractor?.map((subContractor, index) => (
                                                <div className="table_data">
                                                    <div className="row">
                                                        <div className="col-lg-1 col-md-1">
                                                            <h5>{index + 1}</h5>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3">
                                                            <h5>{subContractor.subContractorName}</h5>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3">
                                                            <h5>{subContractor.siteName}</h5>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3">
                                                            <h5>{subContractor.locationName}</h5>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div className="action">
                                                                <i className="fa-solid fa-pen-to-square" onClick={() => setUpdateContractor({ isShowModal: true, subContractorId: subContractor?._id, subContractorName: subContractor?.subContractorName, siteName: subContractor?.siteName, siteId: subContractor?.siteId, locationName: subContractor?.locationName, locationId: subContractor?.locationId })}></i>
                                                                <i className="fa-solid fa-trash" onClick={() => deleteSubContractor(subContractor?._id)}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Sub Contractor Not Available</h5>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                        {showSubContractorForm &&
                            < div className="modal_box">
                                <AddSubContractorForm setShowSubContractorForm={setShowSubContractorForm} fetchAllSubContractors={fetchAllSubContractors} />
                            </div>
                        }
                        {updateContractor.isShowModal &&
                            < div className="modal_box">
                                <UpdateSubContractor updateContractor={updateContractor} setUpdateContractor={setUpdateContractor} fetchAllSubContractors={fetchAllSubContractors} />
                            </div>
                        }

                    </div>
            }
        </>
    );
}

export default SubContractorManagement