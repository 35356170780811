import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import ReactToPrint from "react-to-print";
import swal from "sweetalert";
import { useSelector } from "react-redux";

const GrnReceipt = () => {
    const componentRef = useRef();
    const [grn, setGrn] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const grnId = queryParams.get('grnId');

    const loginUserDetails = useSelector((state) => state.userDetails);

    //get Grn details on initial load
    const fetchGrn = async () => {
        try {
            const serverResponse = await communication.getGrnByGrnId(grnId);
            if (serverResponse.data.status === "SUCCESS") {
                setGrn(serverResponse?.data?.grn);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //file Preview
    let fileExtension = "";
    const filePreview = async (fileUrl) => {
        try {
            if (fileUrl) {
                const responseFromServer = await communication.previewGrnFile(fileUrl);
                const splitFile = fileUrl.split(".");
                fileExtension = splitFile[splitFile.length - 1];
                const file = new Blob([responseFromServer?.data], {
                    type: fileExtension === "pdf" ? "application/pdf" : "image/jpg"
                });
                const blobFile = URL.createObjectURL(file);
                window.open(blobFile);
            } else {
                swal({ text: "File Not Found", icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchGrn();
    }, [])


    return (

        <div className="receipt_main">
            <div className="print_button">
                <ReactToPrint trigger={() => <i className="fa-solid fa-print" title="Print"></i>} content={() => componentRef.current} />
            </div>
            <div className="receipt_section" ref={componentRef}>
                <h5 className="heading"><b>M/S. HPS CONSTRUCTION</b></h5>
                <h5 className="secondary_heading">GOODS RECEIPT NOTE</h5>
                <div className="row mt-5">
                    <div className="col-6 receipt_info_left">
                        <h5><b>grn.no:</b> <span>{grn?.grnNumber}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Name of Location:</b> <span>{grn?.location}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Name of Site:</b> <span>{grn?.site}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Address:</b> <span>{grn?.siteAddress}</span></h5>
                        <h5><b>Name of Supplier:</b> <span>{grn?.vendor?.venderName}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Invoice No:</b> <span>{grn?.invoiceNumber}</span></h5>

                    </div>
                    <div className="col-6 receipt_info_right">
                        <h5><b>Date:</b> <span>{grn?.grnDate}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Challan No:</b> <span>{grn?.challanNumber}</span></h5>
                        <h5><b>PO No:</b> <span>{grn?.poNumber}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Gate Entry No:</b> <span>{grn?.gateNumber}</span></h5>
                        <h5><b>Transporter Name:</b> <span>{grn?.transporterName}</span></h5>
                        <h5 className="mt-2"><b>Vehicle No:</b> <span>{grn?.vehicleNumber}</span></h5>
                    </div>
                </div>
                <table className="goods_receipt_note">
                    <thead>
                        <tr className="goods_receipt">
                            <th>Sr. No.</th>
                            <th>Vender</th>
                            <th>Material Name</th>
                            <th>Unit</th>
                            <th>Rate</th>
                            {grn.type === "GENERAL" &&
                                <th>Total Qty.</th>
                            }
                            <th>Received Qty.</th>
                            <th>Accepted Qty.</th>
                            <th>Rejected Qty.</th>
                            {grn.type === "GENERAL" &&
                                <th>Balance Qty.</th>
                            }
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="goods_receipt">
                            <td>1</td>
                            <td>{grn?.vendor?.venderName}</td>
                            <td>{grn?.brand?.materialName}</td>
                            <td>{grn?.brand?.unitName}</td>
                            <td>{grn?.brand?.rate}</td>
                            {grn.type === "GENERAL" &&
                                <td>{grn?.totalQuantity}</td>}
                            <td>{grn?.receivedQuantity}</td>
                            <td>{grn?.acceptedQuantity}</td>
                            <td>{grn?.rejectedQuantity}</td>
                            {grn.type === "GENERAL" &&
                                <td>{grn?.balanceQuantity}</td>}
                            <td>{grn?.brand?.description}</td>
                        </tr>
                    </tbody>
                </table>
                {(loginUserDetails?.designation.toLowerCase() === "director" && ![undefined, null].includes(grn?.liveLocation)) &&
                    <div className="row">
                        <div className="col-12 live_location">
                            <h4>Grn Uploaded Location:</h4>
                            <h5>{grn?.liveLocation?.streetNumber}, {grn?.liveLocation?.street}, {grn?.liveLocation?.city}</h5>
                        </div>
                    </div>
                }
                {(loginUserDetails?.designation.toLowerCase() === "director" && ![undefined, null].includes(grn?.grnFile)) &&
                    <div className="attachment_wrapper">
                        <div className="attachment" title="Preview Grn File" onClick={() => filePreview(grn?.grnFile)}>
                            {fileExtension === "pdf" ?
                                <i className="fa-solid fa-file-pdf"></i>
                                :
                                <i className="fa-solid fa-image"></i>
                            }
                            <h5>Grn File</h5>
                        </div>
                    </div>
                }
                <div className="row authorities">
                    <div className="col-3">
                        <h5><b>Prepared By</b></h5>
                    </div>
                    <div className="col-3">
                        <h5><b>Checked By</b></h5>
                    </div>
                    <div className="col-3">
                        <h5><b>Stores In-charge</b></h5>
                    </div>
                    <div className="col-3">
                        <h5><b>Authorised By</b></h5>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default GrnReceipt;