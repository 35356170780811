import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useLocation, useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import swal from "sweetalert";
import { useSelector } from "react-redux";

const ReceivedMaterialReceipt = () => {
    const [receiptData, setReceiptData] = useState({});
    const componentRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const receivedMaterialId = queryParams.get('receivedMaterialId');

    const loginUserDetails = useSelector((state) => state.userDetails);

    //Fetch Received Material Details by id
    const fetchReceipt = async () => {
        try {
            const responseFromServer = await communication.getReceivedMaterialReceipt(receivedMaterialId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setReceiptData(responseFromServer?.data?.issueMaterial);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "error" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //file Preview
    let fileExtension = "";
    const filePreview = async (fileUrl) => {
        try {
            if (fileUrl) {
                const responseFromServer = await communication.previewGrnFile(fileUrl);
                const splitFile = fileUrl.split(".");
                fileExtension = splitFile[splitFile.length - 1];
                const file = new Blob([responseFromServer?.data], {
                    type: fileExtension === "pdf" ? "application/pdf" : "image/jpg"
                });
                const blobFile = URL.createObjectURL(file);
                window.open(blobFile);
            } else {
                swal({ text: "File Not Found", icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchReceipt();
    }, []);


    return (
        <div className="receipt_main">
            <div className="print_button">
                <ReactToPrint trigger={() => <i className="fa-solid fa-print" title="Print"></i>} content={() => componentRef.current} />
            </div>

            <div className="receipt_section" ref={componentRef}>
                <h5 className="heading"><b>M/S. HPS CONSTRUCTION</b></h5>
                <h5 className="secondary_heading">Flat NO. S5, 206/5, Palm Road, Civil Lines, Nagpur-440001</h5>
                <h5 className="secondary_heading">GOODS RECEIPT NOTE</h5>
                <div className="row mt-5">
                    <div className="col-6 receipt_info_left">
                        <h5><b>PO No:</b> <span>{receiptData?.poNumber}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Grn No:</b> <span>{receiptData?.grnNumber}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Name of Location:</b> <span>{receiptData?.location}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Name of Site:</b> <span>{receiptData?.site}</span></h5>
                    </div>
                    <div className="col-6 receipt_info_right">
                        <h5><b>Date:</b> <span>{receiptData?.grnDate}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Name of Supplier:</b> <span>{receiptData?.supplierName}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Name of Transporter:</b> <span>{receiptData?.transporterName}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Vehicle No:</b> <span>{receiptData?.vehicleNumber}</span></h5>
                    </div>
                </div>
                <table className="goods_receipt_note">
                    <thead>
                        <tr className="issue_receipt">
                            <th>Sr. No.</th>
                            <th>Material Name</th>
                            <th>Brand</th>
                            <th>Unit</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="issue_receipt">
                            <td>1</td>
                            <td>{receiptData?.brand?.materialName}</td>
                            <td>{receiptData?.brand?.brand}</td>
                            <td>{receiptData?.brand?.unitName}</td>
                            <td>{receiptData?.acceptedQuantity}</td>
                        </tr>
                    </tbody>
                </table>
                {(loginUserDetails?.designation.toLowerCase() === "director" && ![undefined, null].includes(receiptData?.liveLocation)) &&
                    <div className="row">
                        <div className="col-12 live_location">
                            <h4>Grn Uploaded Location:</h4>
                            <h5>{receiptData?.liveLocation?.streetNumber}, {receiptData?.liveLocation?.street}, {receiptData?.liveLocation?.city}</h5>
                        </div>
                    </div>
                }
                {(loginUserDetails?.designation.toLowerCase() === "director" && ![undefined, null].includes(receiptData?.grnFile)) &&
                    <div className="attachment_wrapper">
                        <div className="attachment" title="Preview Grn File" onClick={() => filePreview(receiptData?.grnFile)}>
                            {fileExtension === "pdf" ?
                                <i className="fa-solid fa-file-pdf"></i>
                                :
                                <i className="fa-solid fa-image"></i>
                            }
                            <h5>Grn File</h5>
                        </div>
                    </div>
                }
                <div className="row authorities">
                    <div className="col-4">
                        <h5><b>Received By</b></h5>
                    </div>
                    <div className="col-4">
                        <h5><b>Authorised By</b></h5>
                    </div>
                    <div className="col-4">
                        <h5><b>Sign of Supplier</b></h5>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ReceivedMaterialReceipt;