import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { addCookies } from "./redux-store/userAuthenticateReducer"
import useValidator from "./formValidation/useValidator";
import { communication } from "./communication/communication";
import swal from "sweetalert";
import { hideLoader, showLoader } from "./redux-store/loaderReducer";
import Loader from "./Modals/Loader";

const SignIn = () => {
    const dispatch = useDispatch();
    const [validator, showValidationMessage] = useValidator();
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);

    const submitHandler = async () => {
        try {
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            const serverResponse = await communication.login(userId, password);
            if (serverResponse.data.status === "SUCCESS") {
                dispatch(hideLoader());
                const dataFromServer = serverResponse.data.userData;
                dispatch(
                    addCookies({
                        name: dataFromServer?.name,
                        designation: dataFromServer?.designation,
                        accessControl: dataFromServer?.accessControl,
                        token: serverResponse?.data?.jwtToken
                    })
                );
                navigate("/main-dashboard")
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse?.data?.message, icon: "error" });
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" });
        }
    }
    return (
        <>
            {loader?.showHideLoader === true ?
                <Loader />
                :
                <div className="sign_in_main">
                    <div className="row m-0">
                        <div className="col-lg-6">
                            <div className="sign_in_anim">
                                <img className="building" src="Images/building.png" alt="building.png" />
                                <img className="engineer" src="Images/engineer.png" alt="engineer.png" />
                                <img className="friends" src="Images/friends.png" alt="friends.png" />
                                <h2>We Construct Dreams</h2>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="sign_in_form_main">
                                <div className="company_logo">
                                    <img src="Images/logo.png" alt="logo" />
                                </div>
                                <div className="sign_in_form">
                                    <div className="row">
                                        <div className="col-8 offset-2">
                                            <div className="input_group">
                                                <input type="text" name="email" className="form-control" autoComplete="off" onChange={(event) => { setUserId(event.target.value) }} required />
                                                <label className="input_label">Email</label>
                                            </div>
                                            {validator.message("email", userId, "required|email", {
                                                messages: {
                                                    required: "Email is required"
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-8 offset-2">
                                            <div className="input_group">
                                                <input type="password" name="password" className="form-control" onChange={(event) => { setPassword(event.target.value) }} required />
                                                <label className="input_label">Password</label>
                                            </div>
                                            {validator.message("password", password, "required|min:8", {
                                                messages: {
                                                    required: "Password is required"
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 sign_in_btn">
                                            <button onClick={submitHandler} >Sign In</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
export default SignIn;