import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import DeleteModal from "../../Modals/DeleteModal";
import UpdatePurchaseOrder from "../forms/UpdatePurchaseOrder";
import TotalRequirementsDetail from "./TotalRequirementsDetail";
import Loader from "../../Modals/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import ReactPaginate from "react-paginate";
import { confirmAlert } from 'react-confirm-alert';
import swal from "sweetalert";

const TotalRequirements = () => {
    const loginUserDetails = useSelector((state) => state.userDetails);
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [purchaseOrder, setPurchaseOrder] = useState([]);
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState([]);
    const [updatePo, setUpdatePo] = useState({ isShowModal: false, poId: "", categoryId: "", materialId: "", locationId: "", siteId: "", unitId: "", rejectionLimit: "", deliverySchedule: "", deliveryTo: "", materialDescription: "", materialTechSpecification: "", quantityRequired: "", supervisor: "", supervisorId: "", unlimitedQuantity: "" });
    const [showDeleteModal, setShowDeleteModal] = useState({ isShowModal: false, id: "", type: "" });
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [activeSelectedItem, setActiveSelectedItem] = useState(0);

    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchPurchaseOrdersBySearch(searchValue, selectedPage + 1);
        } else {
            fetchPurchaseOrder(selectedPage + 1);
        }
    }

    // Search filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchPurchaseOrdersBySearch(searchString, currentPage + 1);
        } else {
            const responseFromServer = await communication.fetchPurchaseOrderList();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setPurchaseOrder(responseFromServer?.data.purchaseOrders);
                setSelectedPurchaseOrder(responseFromServer?.data.purchaseOrders[0]);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setPurchaseOrder([]);
                setSelectedPurchaseOrder([]);
                setPageCount(0);
            }
        }
    }

    //fetch purchase orders by search
    const fetchPurchaseOrdersBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchPurchaseOrders(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setPurchaseOrder(responseFromServer?.data.purchaseOrders);
                setSelectedPurchaseOrder(responseFromServer?.data.purchaseOrders[0]);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
            } else {
                setPurchaseOrder([]);
                setSelectedPurchaseOrder([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //fetch purchase orders on initial load
    const fetchPurchaseOrder = async (page) => {
        try {
            dispatch(showLoader());
            const responseFromServer = await communication.fetchPurchaseOrderList(page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                setPurchaseOrder(responseFromServer?.data.purchaseOrders);
                setSelectedPurchaseOrder(responseFromServer?.data.purchaseOrders[0]);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: responseFromServer.data?.message, icon: "warning" });
            } else {
                dispatch(hideLoader());
                setPurchaseOrder([]);
                setSelectedPurchaseOrder([]);
                setPageCount(0);
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }
    }
    
    useEffect(() => {
        fetchPurchaseOrder();
    }, []);

    //Delete Purchase Order
    const deleteOperation = async (poId) => {
        try {

            confirmAlert({
                title: 'Are you sure you want to Delete Purchase Order?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            const serverResponse = await communication.deletePurchaseOrder(poId);
                            if (serverResponse.data.status === "SUCCESS") {
                                setShowDeleteModal({ isShowModal: false });
                                fetchPurchaseOrder();
                                swal({ text: serverResponse.data?.message, icon: "success" });
                            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                                swal({ text: serverResponse.data?.message, icon: "error" });
                                navigate("/login");
                            } else {
                                swal({ text: serverResponse.data?.message, icon: "error" });
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { return false }
                    }
                ],
                overlayClassName: "react-confirm-alert"
            });

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="dashboard_tab_main">
                        <h4 className="title">Procurement - Total Requirement</h4>
                        <div className='search_div'>
                            <input type="text" onChange={(event) => { searchFilter(event.target.value); setSearchValue(event.target.value) }} className='form=control' placeholder='search by category, material, location' title="search by Category, Material, Location" />
                            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.add_new_purchase_order === true) ?
                                <NavLink to="/main-dashboard/procurement/add-new-requirement">
                                    <button>Add New Requirement</button>
                                </NavLink>
                                :
                                <></>
                            }

                        </div>
                        <div className="row m-0">
                            <div className="col-lg-6 col-md-12 ps-0 pe-md-0 pe-lg-2">
                                <div className="table_section total_requirements_table_main">
                                    <div className="total_requirements_table">
                                        <div className="table_header">
                                            <div className="row m-0">
                                                <div className="sr_no">
                                                    <h5>Sr.No</h5>
                                                </div>
                                                <div className="indent_number">
                                                    <h5>PO Number</h5>
                                                </div>
                                                <div className="category">
                                                    <h5>Category</h5>
                                                </div>
                                                <div className="material_name">
                                                    <h5>Material</h5>
                                                </div>
                                                <div className="location">
                                                    <h5>Location</h5>
                                                </div>
                                                <div className="state">
                                                    <h5>Status</h5>
                                                </div>
                                                <div className="type">
                                                    <h5>Type</h5>
                                                </div>
                                                <div className="date">
                                                    <h5>Date</h5>
                                                </div>
                                                <div className="user_action">
                                                    <h5>Action</h5>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            purchaseOrder.length > 0 ?
                                                <>
                                                    {purchaseOrder?.map((poOrder, index) => {
                                                        return (

                                                            <div className={`table_data ${activeSelectedItem !== index ? "" : "active_selected_row"}`} key={index}>
                                                                <div className="row m-0">
                                                                    <div className="sr_no">
                                                                        <h5>{index + 1}</h5>
                                                                    </div>
                                                                    <div className="indent_number click_event">
                                                                        <h5 onClick={() => { setSelectedPurchaseOrder(poOrder); setActiveSelectedItem(index) }}><a>{poOrder.poNumber}</a></h5>
                                                                    </div>
                                                                    <div className="category">
                                                                        <h5>{poOrder?.categoryName}</h5>
                                                                    </div>
                                                                    <div className="material_name">
                                                                        <h5>{poOrder?.materialName}</h5>
                                                                    </div>
                                                                    <div className="location">
                                                                        <h5>{poOrder?.locationName}</h5>
                                                                    </div>
                                                                    <div className="state">
                                                                        <h5>{poOrder?.status}</h5>
                                                                    </div>
                                                                    <div className="type">
                                                                        <h5>{poOrder?.type}</h5>
                                                                    </div>
                                                                    <div className="date">
                                                                        <h5>{poOrder?.orderDate}</h5>
                                                                    </div>
                                                                    <div className="user_action">
                                                                        <div className="action">
                                                                            <i className="fa-solid fa-pen-to-square me-2" onClick={() => setUpdatePo({ isShowModal: true, poId: poOrder?._id, categoryId: poOrder?.categoryId, materialId: poOrder?.materialId, locationId: poOrder?.locationId, siteId: poOrder?.siteId, unitId: poOrder?.unitId, rejectionLimit: poOrder?.rejectionLimit, deliverySchedule: poOrder?.deliverySchedule, deliveryTo: poOrder?.deliveryTo, materialDescription: poOrder?.materialDescription, materialTechSpecification: poOrder?.materialTechSpecification, quantityRequired: poOrder?.quantityRequired, supervisor: poOrder?.supervisor, supervisorId: poOrder?.supervisorId, unlimitedQuantity: poOrder?.unlimitedQuantity })}></i>
                                                                            <i className="fa-solid fa-trash ms-2" onClick={() => setShowDeleteModal({ isShowModal: true, id: poOrder?._id, type: "deleteOperation" })}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                </>
                                                :
                                                <div className="data_not_available_message" style={{ textAlign: "left", marginLeft: "20%" }}>
                                                    <h5>Requirement Not Available</h5>
                                                </div>
                                        }

                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 ps-0 pe-0 mt-lg-0 mt-md-3">
                                <TotalRequirementsDetail selectedPurchaseOrder={selectedPurchaseOrder} />
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                        {updatePo.isShowModal &&
                            <div className="modal_box">
                                <UpdatePurchaseOrder updatePo={updatePo} setUpdatePo={setUpdatePo} fetchPurchaseOrder={fetchPurchaseOrder} />
                            </div>
                        }
                        {showDeleteModal.isShowModal &&
                            <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} deleteOperation={deleteOperation} />
                        }
                    </div>
            }
        </>
    );
}
export default TotalRequirements;