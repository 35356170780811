import React, { useState, useEffect } from "react";
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { communication } from "../../../communication/communication";
import CreateGrnForm from "../../forms/CreateGrnForm";
import UpdateGrnForm from "../../forms/UpdateGrnForm";
import swal from "sweetalert";

const LivePoDetails = ({ selectedPo, getLivePO }) => {
    const loginUserDetails = useSelector((state) => state.userDetails);
    const navigate = useNavigate();
    const [showRequirementDetail, setShowRequirementDetail] = useState(false);
    const [poDetails, setPoDetails] = useState({});
    const [selectedGrn, setSelectedGrn] = useState({});
    const [showCreateGrnForm, setShowCreateGrnForm] = useState(false);
    const [showUpdateGrnForm, setShowUpdateGrnForm] = useState(false);
    const [grn, setGrn] = useState([]);

    //get PO details on initial load
    const getPoDetails = async () => {
        try {
            const serverResponse = await communication.getPurchaseOrderByIdAndPoNumber(selectedPo.poId, selectedPo.poNumber);
            if (serverResponse?.data?.status === "SUCCESS") {
                setPoDetails(serverResponse?.data?.purchaseOrder);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setPoDetails({})
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //get Uploaded grn for PO
    const fetchGrn = async () => {
        try {
            const result = await communication.getGrn(selectedPo?.poId, selectedPo?.poNumber);
            if (result?.data?.status === "SUCCESS") {
                setGrn(result?.data?.grn);
            } else if (result?.data?.status === "JWT_INVALID") {
                swal({ text: result.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setGrn([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //mark Purchase Order as complete
    const markPOAsComplete = () => {
        try {
            confirmAlert({
                title: `Do you Want to Complete PO?`,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            const serverResponse = await communication.markLivePOAsComplete(selectedPo.poId);
                            if (serverResponse.data.status === "SUCCESS") {
                                getLivePO();
                                return swal({ text: serverResponse.data?.message, icon: "success" });
                            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                                swal({ text: serverResponse.data?.message, icon: "error" });
                                navigate("/login");
                            } else {
                                return swal({ text: serverResponse.data?.message, icon: "error" });
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { return false }
                    }
                ],
                overlayClassName: "react-confirm-alert"
            });

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        getPoDetails();
        fetchGrn();
    }, [selectedPo])


    return (
        <div className="general_po_detail_section">
            <div className="row mb-3 m-0">
                <div className="col-lg-4 col-md-4">
                    <h5><b>PO Number - {selectedPo.poNumber}</b></h5>
                </div>
                <div className="col-lg-8 col-md-8 live_po_detail_button">
                    {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.grn_of_approved_po === true) ?
                        <>
                            <button className="me-3" onClick={() => { setShowCreateGrnForm(true) }}>Create GRN</button>
                            {/* <button className="me-3" onClick={() => setShowUpdateGrnForm(true)}>Update GRN</button> */}
                            <button onClick={() => markPOAsComplete()}>Completed</button>
                        </>
                        :
                        <></>
                    }

                </div>
            </div>
            <div className="requirement_details_section">
                <h5>Requirement Details</h5>
                <i className="fa-solid fa-caret-down" onClick={() => setShowRequirementDetail(!showRequirementDetail)}></i>
                {showRequirementDetail &&
                    <div className="requirement_detail">
                        <div className="quotation_details">
                            <div className="detail">
                                <div className="table_header">
                                    <div className="row m-0">
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Material</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Category</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Quantity</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Unit</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Location</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Site</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table_data">
                                    <div className="row m-0">
                                        <div className="col-lg-2 col-md-2">
                                            <h5>{poDetails?.material?.materialName}</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>{poDetails?.material?.categoryName}</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            {poDetails?.unlimitedQuantity ?
                                                <h5>Unlimited</h5>
                                                :
                                                <h5>{poDetails?.quantityRequired}</h5>
                                            }
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>{poDetails?.material?.unitName}</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>{poDetails?.locationName}</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>{poDetails?.siteName}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-12">
                                <h5 className="title">Description of Material - <span>{poDetails?.materialDescription}</span></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h5 className="title">Technical Specification - <span>{poDetails?.materialTechSpecification}</span></h5>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-12">
                                <h5 className="title">Delivery Schedule - <span>{poDetails?.deliverySchedule}</span></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h5 className="title">Rejection Limit - <span>{poDetails?.rejectionLimit}</span></h5>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {/*-----Inventory GRN Table-----*/}
            {poDetails?.deliveryTo === "Inventory" &&
                <div className="inventory_grn_section">
                    <div className="grn_title">
                        <h5>Inventory GRN :</h5>
                    </div>

                    <div className="inventory_grn_details">
                        <div className="detail">
                            <div className="table_header">
                                <div className="row m-0">
                                    <div className="col-lg-2 col-md-2">
                                        <h5>Sr. No.</h5>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <h5>GRN Number</h5>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <h5>Date</h5>
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <h5>Action</h5>
                                    </div>
                                </div>
                            </div>
                            {grn?.length > 0 ?
                                <>
                                    {grn?.map((grnDetails, index) => {
                                        return (
                                            <div className="table_data" key={index}>
                                                <div className="row m-0">
                                                    <div className="col-lg-2 col-md-2">
                                                        <h5>{index + 1}</h5>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <h5>{grnDetails?.grnNumber}</h5>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <h5>{grnDetails?.grnDate}</h5>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2">
                                                        {index === 0 &&
                                                            <i className="fa-solid fa-pen-to-square" onClick={() => { setShowUpdateGrnForm(true); setSelectedGrn(grnDetails) }} ></i>
                                                        }
                                                        <NavLink to={`/grn-receipt?grnId=${grnDetails?._id}`} target="_blank">
                                                            <i className="fa-solid fa-file-lines ms-2" title="GRN Receipt"></i>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <div className="data_not_available_message">
                                    <h5>GRN Not Created</h5>
                                </div>
                            }

                        </div>
                    </div>

                </div>
            }
            {/*-----Site GRN Table-----*/}
            {poDetails?.deliveryTo === "Site" &&
                <div className="site_grn_section">
                    <div className="grn_title">
                        <h5>Site GRN :</h5>
                    </div>

                    <div className="site_grn_details">
                        <div className="detail">
                            <div className="table_header">
                                <div className="row m-0">
                                    <div className="col-lg-2 col-md-2">
                                        <h5>Sr. No.</h5>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <h5>GRN Number</h5>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <h5>Date</h5>
                                    </div>
                                    <div className="col-lg-2 col-md-2">
                                        <h5>Action</h5>
                                    </div>
                                </div>
                            </div>
                            {grn?.length > 0 ?
                                <>
                                    {grn?.map((grnDetails, index) => {
                                        return (
                                            <div className="table_data">
                                                <div className="row m-0">
                                                    <div className="col-lg-2 col-md-2">
                                                        <h5>{index + 1}</h5>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <h5>{grnDetails?.grnNumber}</h5>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <h5>{new Date(grnDetails?.grnDate).toLocaleDateString()}</h5>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2">
                                                        {index === 0 &&
                                                            <i className="fa-solid fa-pen-to-square" onClick={() => { setShowUpdateGrnForm(true); setSelectedGrn(grnDetails) }} ></i>
                                                        }
                                                        <NavLink to={`/grn-receipt?grnId=${grnDetails?._id}`} target="_blank">
                                                            <i className="fa-solid fa-file-lines ms-2" title="GRN Receipt"></i>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <div className="data_not_available_message">
                                    <h5>GRN Not Created</h5>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            }
            {
                showCreateGrnForm &&
                <div className="modal_box">
                    <CreateGrnForm setShowCreateGrnForm={setShowCreateGrnForm} poDetails={poDetails} fetchGrn={fetchGrn} />

                </div>
            }

            {
                showUpdateGrnForm &&
                <div className="modal_box">
                    <UpdateGrnForm setShowUpdateGrnForm={setShowUpdateGrnForm} poDetails={poDetails} selectedGrn={selectedGrn} fetchGrn={fetchGrn} />
                </div>
            }
        </div >

    );
}
export default LivePoDetails;