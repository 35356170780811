import React, { useState } from 'react';
import { communication } from '../../communication/communication';
import useValidator from '../../formValidation/useValidator';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const UpdateSite = ({ setShowUpdateSite, showUpdateSite, showLocationDetails }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [newSiteName, setNewSiteName] = useState(showUpdateSite.siteName);

    //Update site name
    const updateSite = async () => {
        try {
            //check SiteName is added or not
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            const serverResponse = await communication.updateSite(newSiteName, showUpdateSite.siteId);
            if (serverResponse.data.status === "SUCCESS") {
                setShowUpdateSite({ isModalShow: false, siteId: "", siteName: "" });
                showLocationDetails();
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <div className="add_new_designation">
            <div className="form">
                <div className="form_heading">
                    <h5>Update Site</h5>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="input_group">
                            <input type="text" name="newSiteName" onChange={(event) => { setNewSiteName(event.target.value) }} value={newSiteName} className="form-control" autoComplete="off" required />
                        </div>
                        {validator.message("newSiteName", newSiteName, "required|alpha Space", {
                            messages: {
                                required: "Site Name is required"
                            }
                        })}
                    </div>
                </div>
                <div className="form_button">
                    <button onClick={() => updateSite()} >Update</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => { setShowUpdateSite({ isModalShow: false, siteId: "", siteName: "" }) }} ></i>
                </div>
            </div>
        </div>
    );
}

export default UpdateSite