import React, { useState, useEffect } from "react";
import useValidator from "../../formValidation/useValidator";
import { communication } from "../../communication/communication";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const UpdateGrnForm = ({ setShowUpdateGrnForm, poDetails, selectedGrn, fetchGrn }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [balanceQuantity, setBalanceQuantity] = useState(selectedGrn?.balanceQuantity);
    const [selectedVendor, setSelectedVendor] = useState();
    const [brand, setBrand] = useState([])
    const [vendor, setVendor] = useState([]);
    const [rejectedQuantity, setRejectedQuantity] = useState(selectedGrn?.rejectedQuantity);
    const [updateGrnData, setUpdateGrnData] = useState(
        {
            grnId: selectedGrn?._id,
            grnNumber: selectedGrn?.grnNumber,
            siteAddress: selectedGrn.siteAddress,
            grnDate: selectedGrn?.grnDate,
            supplierName: selectedGrn?.supplierName,
            receivedBy: selectedGrn?.receivedBy,
            transporterName: selectedGrn?.transporterName,
            gateNumber: selectedGrn?.gateNumber,
            challanNumber: selectedGrn?.challanNumber,
            invoiceNumber: selectedGrn?.invoiceNumber,
            vehicleNumber: selectedGrn?.vehicleNumber,
            totalQuantity: selectedGrn?.totalQuantity ?? "",
            receivedQuantity: selectedGrn?.receivedQuantity,
            acceptedQuantity: selectedGrn?.acceptedQuantity,
            poNumber: poDetails?.poNumber,
            poId: poDetails?._id,
            type: poDetails?.type,
            site: poDetails?.siteName,
            location: poDetails?.locationName,
            brand: selectedGrn?.brand
        }
    );

    const { site, siteAddress, supplierName, receivedBy, grnNumber, type, transporterName, gateNumber, grnDate, challanNumber, invoiceNumber, vehicleNumber, totalQuantity, receivedQuantity, acceptedQuantity } = updateGrnData;


    //Getting all inputs value
    const grnDetail = (event) => {
        setUpdateGrnData({ ...updateGrnData, [event.target.name]: event.target.value });
    }

    //get selected Vendor Detail
    const getVendor = async () => {
        try {
            const serverResponse = await communication.getSelectedVendor(poDetails?._id);
            if (serverResponse?.data?.status === "SUCCESS") {
                setVendor(serverResponse?.data?.vendorDetails);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setVendor([]);
                swal({ text: serverResponse.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    const changeHandler = (vendorDetail) => {
        setSelectedVendor(vendorDetail)

        //set Brand according to Vendor
        const brandAccordingToVendor = [];
        vendor?.forEach((element) => {
            if (vendorDetail?.venderName === element?.vendor?.venderName) {
                element?.bill.forEach(brand => {
                    if (brand?.isSelected) {
                        brandAccordingToVendor?.push(brand);
                    }
                })
            }
        });
        setBrand(brandAccordingToVendor);
    }

    const updateGrn = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            //check receivedQuantity is less than acceptQuantity
            if (acceptedQuantity > receivedQuantity) {
                return swal({ text: "Accepted Quantity not be more than received Quantity", icon: "error" });
            }

            let dataToSend = {
                ...updateGrnData,
                rejectedQuantity,
                vendor: selectedVendor
            }

            if (poDetails?.type === "GENERAL") {
                if (receivedQuantity > totalQuantity) {
                    return swal({ text: "Received Quantity not be more than total Quantity", icon: "error" });
                }
                dataToSend["balanceQuantity"] = balanceQuantity
            }

            const serverResponse = await communication.updateGrnById(dataToSend);
            if (serverResponse?.data?.status === "SUCCESS") {
                setShowUpdateGrnForm(false);
                fetchGrn();
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "error" });
                return false;
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
            return false;
        }
    }

    useEffect(() => {
        getVendor();
    }, [])


    useEffect(() => {
        changeHandler(selectedGrn?.vendor);
    }, [vendor])


    return (
        <div className="issue_transfer">
            <div className="form">
                <div className="form_heading">
                    <h5>Update GRN</h5>
                </div>
                <div className="row mt-4" style={{ textAlign: "left" }}>
                    <div className="col-lg-3 col-md-3">
                        <h5><b>GRN No. {grnNumber}</b></h5>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <h5><b>Name of Site : {site}</b></h5>
                    </div>
                    <div className="col-lg-2 col-md-3">
                        <h5><b>Date : {grnDate}</b></h5>
                    </div>
                    <div className="col-lg-3 col-md-3 mt-lg-0 mt-md-2">
                        <h5><b>PO No. : {poDetails?.poNumber}</b></h5>
                    </div>
                </div>
                <div className="row" >
                    <div className="col-lg-6 col-md-6">
                        <select className="form-select" onChange={(event) => { changeHandler(JSON.parse(event.target.value)) }} >
                            <option value={JSON.stringify({ venderName: "" })}>Select Vendor</option>
                            {vendor?.map((vendorDetails, index) => {
                                return (
                                    <option value={JSON.stringify(vendorDetails?.vendor)} selected={selectedVendor.venderName} key={index}>{vendorDetails?.vendor?.venderName}</option>
                                )
                            })}
                        </select>
                        {validator.message("Vendor", selectedVendor, "required", {
                            messages: {
                                required: "Vendor is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <select className="form-select" onChange={(event) => { setUpdateGrnData({ ...updateGrnData, brand: JSON.parse(event.target.value) }) }} >
                            <option value={JSON.stringify({})}>Select Brand</option>
                            {brand?.map((brandDetails, index) => {
                                return (
                                    <option value={JSON.stringify(brandDetails)} selected={updateGrnData?.brand?.brand} key={index}>{brandDetails?.brand}</option>
                                )
                            })}
                        </select>
                        {validator.message("Brand", selectedVendor, "required", {
                            messages: {
                                required: "Brand is required"
                            }
                        })}
                    </div>

                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="siteAddress" value={siteAddress} onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Address of Site</label>
                        </div>
                        {validator.message("siteAddress", siteAddress, "required", {
                            messages: {
                                required: "Site Address is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="supplierName" value={supplierName} onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Name of Supplier</label>
                        </div>
                        {validator.message("supplierName", supplierName, "required|alpha_space", {
                            messages: {
                                required: "Supplier Name is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="receivedBy" value={receivedBy} onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Received By</label>
                        </div>
                        {validator.message("receivedBy", receivedBy, "required|alpha_space", {
                            messages: {
                                required: "Receiver Name is required"
                            }
                        })}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="transporterName" value={transporterName} onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Transporter Name</label>
                        </div>
                        {validator.message("transporterName", transporterName, "required|alpha_space", {
                            messages: {
                                required: "Transporter Name is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="gateNumber" value={gateNumber} onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Gate Entry Number</label>
                        </div>
                        {validator.message("gateNumber", gateNumber, "required", {
                            messages: {
                                required: "Gate Number is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="challanNumber" value={challanNumber} onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Challan Number</label>
                        </div>
                        {validator.message("challanNumber", challanNumber, "required", {
                            messages: {
                                required: "Challan Number is required"
                            }
                        })}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="invoiceNumber" value={invoiceNumber} onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Invoice Number</label>
                        </div>
                        {validator.message("invoiceNumber", invoiceNumber, "required", {
                            messages: {
                                required: "Invoice Number is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="vehicleNumber" value={vehicleNumber} onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Vehicle Number</label>
                        </div>
                        {validator.message("vehicleNumber", vehicleNumber, "required", {
                            messages: {
                                required: "Vehicle Number is required"
                            }
                        })}
                    </div>
                    {type === "GENERAL" &&
                        <div className="col-lg-4 col-md-6">
                            <div className="input_group">
                                <input type="text" name="totalQuantity" onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                                <label className="input_label">Total Quantity</label>
                            </div>
                        </div>
                    }
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="receivedQuantity" value={receivedQuantity} onChange={(event) => grnDetail(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Received Quantity</label>
                        </div>
                        {validator.message("receivedQuantity", receivedQuantity, "required|numeric", {
                            messages: {
                                required: "Received Quantity is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="acceptedQuantity" value={acceptedQuantity} onChange={(event) => { grnDetail(event); setBalanceQuantity(totalQuantity - event.target.value); setRejectedQuantity(receivedQuantity - event.target.value); }} className="form-control" autoComplete="off" required />
                            <label className="input_label">Accepted Quantity</label>
                        </div>
                        {validator.message("acceptedQuantity", acceptedQuantity, "required|numeric", {
                            messages: {
                                required: "Accepted Quantity is required"
                            }
                        })}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="input_group">
                            <input type="text" name="rejected_quantity" value={rejectedQuantity} className="form-control" autoComplete="off" required />
                            <label className="input_label">Rejected Quantity</label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {type === "GENERAL" &&
                        <div className="col-lg-4 col-md-6">
                            <div className="input_group">
                                <input type="text" name="invoiceNumber" value={balanceQuantity} className="form-control" autoComplete="off" required />
                                <label className="input_label">Balance Quantity</label>
                            </div>
                        </div>
                    }
                </div>

                <div className="form_button">
                    <button onClick={() => updateGrn()}>Update</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowUpdateGrnForm(false)}></i>
                </div>
            </div>
        </div>
    );
}
export default UpdateGrnForm;