import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import swal from "sweetalert";

const AddNewCategory = ({ setShowCategoryForm, fetchAllCategory }) => {
    const [validator, showValidationMessage] = useValidator();
    const [categoryName, setCategoryName] = useState();
    const navigate = useNavigate();

    //function to add category into database
    const addCategory = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const serverResponse = await communication.addCategory(categoryName);
            if (serverResponse.data.status === "SUCCESS") {
                swal({ text: serverResponse.data?.message, icon: "success" });
                fetchAllCategory();
                setShowCategoryForm(false);
            } else if (serverResponse.data.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate('/login');
            } else {
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <div className="add_new_category">
            <div className="form">
                <div className="form_heading">
                    <h5>Add New Category</h5>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="input_group">
                            <input type="text" name="categoryName" onChange={(event) => setCategoryName(event.target.value)} className="form-control" autoComplete="off" required />
                            <label className="input_label" >Category Name</label>
                        </div>
                        {validator.message("categoryName", categoryName, "required|alpha_space", {
                            messages: {
                                required: "Category is required"
                            }
                        })}
                    </div>
                </div>
                <div className="form_button">
                    <button onClick={() => addCategory()}>Add</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowCategoryForm(false)}></i>
                </div>
            </div>
        </div>
    );
}
export default AddNewCategory;