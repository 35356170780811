import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import GeneralPo from '../Components/inventory/approvedRequirementTabComponents/GeneralPo';
import LivePo from '../Components/inventory/approvedRequirementTabComponents/LivePo';



const ApprovedRequirementTabRoutes = () => {
    return (
        <Routes>
            <Route exact path="general-po" element={<GeneralPo />}></Route>
            <Route exact path="live-po" element={<LivePo />}></Route>
            <Route path="*" element={<Navigate to="/inventory/approved-requirements/general-po" replace />}></Route>
        </Routes>
    )
}
export default ApprovedRequirementTabRoutes;