import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import SideNav from "./SideNav";
import { useDispatch } from 'react-redux';
import { removeCookies } from "../redux-store/userAuthenticateReducer"
import { Outlet, useNavigate } from "react-router-dom";
import DashboardViewRoutes from "../routes/DashboardViewRoutes";
import Modal from "../Modals/Modal";
import Loader from "../Modals/Loader";
import DeleteModal from "../Modals/DeleteModal";
import VenderLinkForm from "../Components/forms/VenderLinkForm";


const Dashboard = () => {
    const dispatch = useDispatch();
    const [showLogoutBox, setShowLogoutBox] = useState(false);
    const loginUserDetails = useSelector((state) => state.userDetails);
    const navigate = useNavigate();

    useEffect(() => {
        if ((loginUserDetails.userName === undefined || loginUserDetails.userName === "") || (loginUserDetails.designation === undefined || loginUserDetails.designation === "")) {
            dispatch(removeCookies());
            navigate('/login');
        }
    }, []);

    const userLogout = () => {
        dispatch(removeCookies());
        navigate('/login');
        return true;
    }

    return (
        <div className="main_section">
            <div className="panel">
                <SideNav />
                <div className="dashboard_view">
                    {/* <DashboardViewRoutes /> */}
                    <Outlet></Outlet>
                </div>
            </div>
            <div className="user_icon">
                <i class="fa-solid fa-user" onClick={() => setShowLogoutBox(true)}></i>
            </div>
            {showLogoutBox === true ?
                <div className="logout_box">
                    <i class="fa-solid fa-xmark close_btn" onClick={() => setShowLogoutBox(false)}></i>
                    <div className="user_info">
                        <h5 className="user_name">{`Name: ${loginUserDetails.userName}`}</h5>
                        <h5 className="designation mt-2">{`Designation: ${loginUserDetails.designation}`}</h5>
                    </div>
                    <button onClick={userLogout}>Logout</button>
                </div>
                : <span></span>
            }

            {/* <VenderLinkForm /> */}

        </div>


    );
}
export default Dashboard;