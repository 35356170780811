import React, { useEffect, useMemo, useState } from "react";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const UpdateMachine = ({ showUpdateMachineForm, setShowUpdateMachineForm, fetchAllMachines }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [drivers, setDrivers] = useState([]);
    const [location, setLocation] = useState([]);
    const [locationWiseSite, setlocationWiseSite] = useState([]);
    const [updateExistingMachine, setUpdateExistingMachine] = useState(showUpdateMachineForm);

    const { machineName, registrationNo, driver, driverId, siteName, siteId, locationName, locationId, cur_hired_by } = updateExistingMachine;
    //Fetch All Drivers
    const fetchAllDrivers = async () => {
        try {
            const responseFromServer = await communication.fetchAllDrivers();
            const getAllLocations = await communication.getAllLocations();
            if ((responseFromServer.data.status === "SUCCESS") && (getAllLocations.data.status === "SUCCESS")) {
                setLocation(getAllLocations.data.locations);
                setDrivers(responseFromServer.data.drivers);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Location Wise Sites
    const locationWiseSites = async (locationId) => {
        try {
            const responseFromServer = await communication.getLocationWiseSites(locationId);
            if (responseFromServer.data.status === "SUCCESS") {
                setlocationWiseSite(responseFromServer.data.sites);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useMemo(() => {
        locationWiseSites(locationId);
    }, [locationId]);

    useEffect(() => {
        fetchAllDrivers();
    }, []);

    //Add new Machine
    const updateMachine = async (machineId) => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            const serverResponse = await communication.updateMachine(machineName, machineId, registrationNo, locationName, locationId, siteName, siteId, driver, driverId, cur_hired_by);
            if (serverResponse.data.status === "SUCCESS") {
                setShowUpdateMachineForm({ isShowModal: false });
                fetchAllMachines();
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <div className="add_new_material">
            <div className="form">
                <div className="form_heading">
                    <h5>Update Machine</h5>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="input_group">
                            <input type="text" name="machineName" value={machineName} onChange={(event) => setUpdateExistingMachine({ ...updateExistingMachine, machineName: event.target.value })} className="form-control" autoComplete="off" required />
                            <label className="input_label">Machine Name</label>
                        </div>
                        {validator.message("machineName", machineName, "required", {
                            messages: {
                                required: "Machine is required"
                            }
                        })}
                    </div>
                    <div className="col-6">
                        <div className="input_group">
                            <input type="text" name="registrationNo" value={registrationNo} onChange={(event) => setUpdateExistingMachine({ ...updateExistingMachine, registrationNo: event.target.value })} className="form-control" autoComplete="off" required />
                            <label className="input_label">Registration No.</label>
                        </div>
                        {validator.message("registrationNo", registrationNo, "required", {
                            messages: {
                                required: "Registration Number is required"
                            }
                        })}
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <select className="form-select" name="driver" onChange={(event) => setUpdateExistingMachine({ ...updateExistingMachine, driver: JSON.parse(event.target.value)?.name, driverId: JSON.parse(event.target.value)?._id })}>
                            {drivers?.map((driver, index) => (
                                <option value={JSON.stringify(driver)} selected={driver?._id === driverId} key={index}>{driver.name}</option>
                            ))}
                        </select>
                        {validator.message("driver", drivers, "required", {
                            messages: {
                                required: "Driver is required"
                            }
                        })}

                        {/* <Multiselect
                            options={drivers}
                            displayValue="name"
                            showCheckbox={true}
                            onSelect={(event)=>setSelectedDriver(event)}
                            onRemove={(event)=>setSelectedDriver(event)}
                            placeholder="Select Driver"
                            showArrow
                            closeIcon="cancel"
                           customArrow={<><i className="fa-solid fa-angle-down"></i></>}
                            
                        /> */}

                    </div>
                    <div className="col-6">
                        <select className="form-select" name="locationName" onChange={(event) => { setUpdateExistingMachine({ ...updateExistingMachine, locationName: JSON.parse(event.target.value)?.locationName, locationId: JSON.parse(event.target.value)?._id, siteName: "", siteId: "" }); }}>
                            {location?.map((location, index) => (
                                <option value={JSON.stringify(location)} selected={location?._id === locationId} key={index}>{location.locationName}</option>
                            ))}
                        </select>
                    </div>

                </div>
                <div className="row">
                    <div className="col-6">
                        <select className="form-select" name="siteName" onChange={(event) => { setUpdateExistingMachine({ ...updateExistingMachine, siteId: JSON.parse(event.target.value)?._id, siteName: JSON.parse(event.target.value)?.siteName }) }}>
                            <option value={JSON.stringify({ siteName: "", _id: "" })}>Select Site</option>
                            {locationWiseSite?.map((sites, index) => (
                                <option value={JSON.stringify(sites)} selected={sites?._id === siteId} key={index}>{sites.siteName}</option>
                            ))}
                        </select>
                        {validator.message("siteName", siteName, "required", {
                            messages: {
                                required: "Site is required"
                            }
                        })}
                    </div>
                    <div className="col-6">
                        <div className="input_group">
                            <input type="text" name="cur_hired_by" value={cur_hired_by} onChange={(event) => setUpdateExistingMachine({ ...updateExistingMachine, cur_hired_by: event.target.value })} className="form-control" autoComplete="off" required />
                            <label className="input_label">Hired By</label>
                        </div>
                        {validator.message("cur_hired_by", cur_hired_by, "required|alpha_space", {
                            messages: {
                                required: "Hiring person name is required"
                            }
                        })}
                    </div>
                    <div className="col-6"></div>
                </div>
                <div className="form_button">
                    <button onClick={() => updateMachine(showUpdateMachineForm?.machineId)}>Update</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowUpdateMachineForm({ isShowModal: false })}></i>
                </div>
            </div>
        </div>
    );
}
export default UpdateMachine;