import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import Loader from "../../Modals/Loader";
import { communication } from "../../communication/communication";
import * as FileSaver from "file-saver";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { useMemo } from "react";

const MaterialReceived = () => {
    const [receivedMaterials, setReceivedMaterials] = useState([]);
    const [locations, setLocations] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchFilterValue, setSearchFilterValue] = useState({
        searchValue: "",
        searchByLocation: "",
    });
    const [date, setDate] = useState({
        startDate: "",
        endDate: "",
    });
    const { searchValue, searchByLocation } = searchFilterValue;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);
    const loginUserDetails = useSelector((state) => state.userDetails);
    const pageLimit = process.env.REACT_APP_PAGE_LIMIT;

    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchReceivedMaterialsBySearch(searchValue, selectedPage + 1);
        } else if (searchByLocation || (date?.startDate && date?.endDate)) {
            fetchReceivedMaterialsByLocationAndDateFilter(searchByLocation, date?.startDate, date?.endDate);
        } else {
            fetchReceivedMaterials(selectedPage + 1);
        }
    }

    //Filter data
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchReceivedMaterialsBySearch(searchString, currentPage + 1);
        } else {
            const responseFromServer = await communication.getAllReceivedMaterialList();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setReceivedMaterials(responseFromServer?.data?.receivedMaterial);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setReceivedMaterials([]);
                setPageCount(0);
            }
        }
    }

    //fetch received materials by search
    const fetchReceivedMaterialsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchInventoryReceivedMaterial(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setReceivedMaterials(responseFromServer?.data?.receivedMaterial);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "waring" });
                navigate("/login");
            } else {
                setReceivedMaterials([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" })
        }
    }

    //fetch Received materials by Location, startDate and endDate
    const fetchReceivedMaterialsByLocationAndDateFilter = async (locationId, startDate, endDate, page) => {
        try {
            if (locationId || (startDate && endDate)) {
                const responseFromServer = await communication.getAllReceivedMaterialListByLocationAndDateFilter(locationId, startDate, endDate, page);
                if (responseFromServer?.data?.status === "SUCCESS") {
                    setReceivedMaterials(responseFromServer?.data?.receivedMaterial);
                    setPageCount(responseFromServer?.data?.totalPages);
                } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                    dispatch(hideLoader())
                    swal({ text: responseFromServer.data?.message, icon: "warning" });
                    navigate("/login");
                } else {
                    dispatch(hideLoader());
                    setReceivedMaterials([]);
                    setPageCount(0);
                }
            } else {
                setSearchFilterValue({ searchByLocation: "", });
                setDate({ startDate: "", endDate: "" });
                fetchReceivedMaterials();
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }
    //Fetch All Received Materials on initial load
    const fetchReceivedMaterials = async (page) => {
        try {
            dispatch(showLoader());
            const responseFromServer = await communication.getAllReceivedMaterialList(page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                setReceivedMaterials(responseFromServer?.data?.receivedMaterial);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                setReceivedMaterials([]);
                setPageCount(0);
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Fetch all Locations
    const fetchAllLocations = async () => {
        try {
            const responseFromServer = await communication.getAllLocations();
            if (responseFromServer.data.status === "SUCCESS") {

                //if User is director set all Locations otherWise set location according to accessControl
                if (loginUserDetails?.designation?.toLowerCase() === "director") {
                    setLocations(responseFromServer.data.locations);
                } else {
                    let location = [];
                    loginUserDetails?.accessControl?.forEach(access => {
                        responseFromServer?.data?.locations?.forEach((locationDetails) => {
                            if (access.locationName === locationDetails.locationName) {
                                location.push(locationDetails);
                            }
                        })
                    });
                    setLocations(location);
                }
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setLocations([]);
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    };

    //Export Data to Excel
    const exportData = async () => {
        try {
            dispatch(showLoader());
            const serverResponse = await communication.exportReceivedMaterialList();
            if (serverResponse.data) {
                //Create a Blob from the PDF Stream
                const file = new Blob([serverResponse.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
                });
                FileSaver.saveAs(file, "Received Materials.xlsx");
            } else {
                swal({ text: "Failed to export data in excel", icon: "warning" });
            }
            dispatch(hideLoader());
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "warning" });
        }
    };

    useMemo(() => {
        fetchReceivedMaterialsByLocationAndDateFilter(searchByLocation, date?.startDate, date?.endDate);
    }, [searchByLocation,]);

    useEffect(() => {
        if (date?.startDate && date?.endDate) {
            fetchReceivedMaterialsByLocationAndDateFilter(searchByLocation, date?.startDate, date?.endDate)
        }
    }, [date])

    useEffect(() => {
        fetchReceivedMaterials();
        fetchAllLocations();
    }, []);


    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="dashboard_tab_main">
                        <h4 className="title">Inventory - Material Received</h4>
                        <div className="row material_search_box">
                            <div className="col-lg-3 col-md-6 ps-0" style={{ display: "flex", alignItems: "flex-end" }}>
                                <input type="text" onChange={(event) => { searchFilter(event.target.value); setSearchFilterValue({ ...searchFilterValue, searchValue: event.target.value }) }} className="form-control search_input" placeholder="search by material, site" />
                            </div>
                            <div className="col-lg-3 col-md-6 pe-lg-2 pe-md-0" style={{ display: "flex", alignItems: "flex-end" }}>
                                <select onChange={(event) => setSearchFilterValue({ ...searchFilterValue, searchByLocation: JSON.parse(event.target.value)?._id })} className="form-select">
                                    <option value={JSON.stringify({ _id: "" })}>Select Location</option>
                                    {locations?.map((location, index) => (
                                        <option value={JSON.stringify(location)} key={index}>{location?.locationName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-2 col-md-4 mt-lg-0 mt-md-3 ps-lg-2 ps-md-0">
                                <label>From</label>
                                <input type="date" value={date?.startDate} name="startDate" onChange={(event) => setDate({ ...date, startDate: event.target.value })} className='form-control' placeholder="From" />
                            </div>
                            <div className="col-lg-2 col-md-4 mt-lg-0 mt-md-3">
                                <label>To</label>
                                <input type="date" name="endDate" value={date?.endDate} onChange={(event) => setDate({ ...date, endDate: event.target.value })} className='form-control' placeholder="To" />
                            </div>
                            {receivedMaterials?.length !== 0 &&
                                <div className="col-lg-2 col-md-4 pe-0 mt-lg-0 mt-md-3" style={{ display: "flex", justifyContent: "end", alignItems: "flex-end" }}>
                                    <button onClick={() => exportData()}>Export<i className="fa-solid fa-file-excel ms-2"></i></button>
                                </div>
                            }
                        </div>

                        <div className="row" style={{ position: "relative" }}>
                            <div className="col-lg-12 col-md-12">
                                <div className="table_main">
                                    <>
                                        <div className="table_heading">
                                            <div className="sr_no">
                                                <h5>Sr. No.</h5>
                                            </div>
                                            <div className="material_name">
                                                <h5>Material</h5>
                                            </div>
                                            <div className="location_name">
                                                <h5>Location</h5>
                                            </div>
                                            <div className="site_name">
                                                <h5>Site</h5>
                                            </div>
                                            <div className="received_by">
                                                <h5>Received By</h5>
                                            </div>
                                            <div className="quantity">
                                                <h5>Quantity</h5>
                                            </div>
                                            <div className="quantity">
                                                <h5>Reveived Qty</h5>
                                            </div>
                                            <div className="quantity">
                                                <h5>Balance Qty</h5>
                                            </div>
                                            <div className="po_no">
                                                <h5>PO No.</h5>
                                            </div>
                                            <div className="grn_no">
                                                <h5>GRN No.</h5>
                                            </div>
                                            <div className="date">
                                                <h5>Date</h5>
                                            </div>

                                            <div className="user_action">
                                                <h5>Action</h5>
                                            </div>
                                        </div>
                                        {receivedMaterials?.length !== 0 ?
                                            <>
                                                {
                                                    receivedMaterials?.map((material, index) => {
                                                        return (
                                                            <div className="table_content">
                                                                <div className="sr_no">
                                                                    <h5>{pageLimit * currentPage + (index + 1)}</h5>
                                                                </div>
                                                                <div className="material_name">
                                                                    <h5>{material?.brand?.materialName}</h5>
                                                                </div>
                                                                <div className="location_name">
                                                                    <h5>{material?.locationName}</h5>
                                                                </div>
                                                                <div className="site_name">
                                                                    <h5>{material?.siteName}</h5>
                                                                </div>
                                                                <div className="received_by">
                                                                    <h5>{material?.receivedBy}</h5>
                                                                </div>
                                                                <div className="quantity">
                                                                    <h5>{material?.totalQuantity === undefined ? "Unlimited" : material?.totalQuantity}</h5>
                                                                </div>
                                                                <div className="quantity">
                                                                    <h5>{material?.receivedQuantity}</h5>
                                                                </div>
                                                                <div className="quantity">
                                                                    <h5>{material?.balanceQuantity === undefined ? "-" : material?.balanceQuantity}</h5>
                                                                </div>
                                                                <div className="po_no">
                                                                    <h5>{material?.poNumber}</h5>
                                                                </div>
                                                                <div className="grn_no">
                                                                    <h5>{material?.grnNumber}</h5>
                                                                </div>
                                                                <div className="date">
                                                                    <h5>{new Date(material?.grnDate).toLocaleDateString().split("/").join("-")}</h5>
                                                                </div>
                                                                <div className="user_action">
                                                                    <div className="action">
                                                                        <NavLink to={`/received-material-receipt?receivedMaterialId=${material?._id}`} target="_blank">
                                                                            <i className="fa-solid fa-file-lines" title="Material Received Receipt"></i>
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </>
                                            :
                                            <div className="data_not_available_message">
                                                <h5>Material Not Available</h5>
                                            </div>
                                        }
                                    </>
                                </div>
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                    </div>
            }
        </>
    );
}
export default MaterialReceived;