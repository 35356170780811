import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useLocation, useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import swal from "sweetalert";

const MaterialIssuingReceipt = () => {
    const [receiptData, setReceiptData] = useState({});
    const componentRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const issueId = queryParams.get('issueId');

    //Fetch Material Issue Details on by id
    const fetchReceipt = async () => {
        try {
            const responseFromServer = await communication.getIssueMaterialReceipt(issueId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setReceiptData(responseFromServer?.data?.issueMaterial);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "error" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchReceipt();
    }, []);

    return (
        <div className="receipt_main">
            <div className="print_button">
                <ReactToPrint trigger={() => <i className="fa-solid fa-print" title="Print"></i>} content={() => componentRef.current} />
            </div>

            <div className="receipt_section" ref={componentRef}>
                <h5 className="heading"><b>M/S. HPS CONSTRUCTION</b></h5>
                <h5 className="secondary_heading">Flat NO. S5, 206/5, Palm Road, Civil Lines, Nagpur-440001</h5>
                <h5 className="secondary_heading">ISSUE NOTE</h5>
                <div className="row mt-5">
                    <div className="col-6 receipt_info_left">
                        <h5><b>Issue no:</b> <span>{receiptData?.issueNumber}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Name of Location:</b> <span>{receiptData?.locationName}</span></h5>
                    </div>
                    <div className="col-6 receipt_info_right">
                        <h5><b>Date:</b> <span>{receiptData?.issueDate}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Name of Site:</b> <span>{receiptData?.siteName}</span></h5>
                    </div>
                </div>
                <table className="goods_receipt_note">
                    <thead>
                        <tr className="issue_receipt">
                            <th>Sr. No.</th>
                            <th>Material Name</th>
                            <th>Brand</th>
                            <th>Unit</th>
                            <th>Isuued Qty.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="issue_receipt">
                            <td>1</td>
                            <td>{receiptData?.materialName}</td>
                            <td>{receiptData?.brand}</td>
                            <td>{receiptData?.unitName}</td>
                            <td>{receiptData?.requiredQuantity}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="row authorities">
                    <div className="col-6">
                        <h5><b>Received By</b></h5>
                    </div>
                    <div className="col-6">
                        <h5><b>Authorized By</b></h5>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MaterialIssuingReceipt;