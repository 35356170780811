import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import AllApprovalDetails from "./AllApprovalDetails";
import Loader from "../../Modals/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

const AllApprovals = () => {
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState([]);
    const [activeSelectedItem, setActiveSelectedItem] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);
    const pageLimit = process.env.REACT_APP_PAGE_LIMIT

    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");

    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchApprovedPoListBySearch(searchValue, selectedPage + 1);
        } else {
            getApprovalPO(selectedPage + 1);
        }
    }

    // Search filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchApprovedPoListBySearch(searchString, currentPage + 1);
        } else {
            const serverResponse = await communication.getAllApprovalPOList();
            if (serverResponse.data.status === "SUCCESS") {
                setPurchaseOrders(serverResponse?.data?.purchaseOrders);
                setSelectedPurchaseOrder(serverResponse?.data?.purchaseOrders[0]);
                setPageCount(serverResponse?.data?.totalPages);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setPurchaseOrders([]);
                setPageCount(0);
            }
        }
    }

    //fetch Approved po list By Search
    const fetchApprovedPoListBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchAllApprovedPoList(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setPurchaseOrders(responseFromServer?.data?.purchaseOrders);
                setSelectedPurchaseOrder(responseFromServer?.data?.purchaseOrders[0]);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setPurchaseOrders([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //get Approved pi on initial load
    const getApprovalPO = async (page) => {
        try {
            dispatch(showLoader());
            const serverResponse = await communication.getAllApprovalPOList(page);
            if (serverResponse.data.status === "SUCCESS") {
                dispatch(hideLoader());
                setPurchaseOrders(serverResponse?.data?.purchaseOrders);
                setSelectedPurchaseOrder(serverResponse?.data?.purchaseOrders[0]);
                setPageCount(serverResponse?.data?.totalPages);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                setPurchaseOrders([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        getApprovalPO();
    }, [])


    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="dashboard_tab_main">
                        <h4 className="title">Procurement - All Approvals</h4>
                        <div className='search_div'>
                            <input type="text" onChange={(event) => { searchFilter(event.target.value); setSearchValue(event.target.value) }} className='form-control' placeholder='search by material, category, site' title="search by Material, Category, Site, po and Status" />
                        </div>
                        <div className="row all_approval m-0">
                            <div className="col-lg-6 col-md-12 ps-lg-0">
                                <div className="table_section all_approvals_section">
                                    <div className="all_approvals_table">
                                        <div className="table_header">
                                            <div className="row m-0">
                                                <div className="col-lg-1 col-md-1">
                                                    <h5>Sr.No</h5>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>PO Number</h5>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>Category</h5>
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <h5>Material</h5>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>Site Name</h5>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>Status</h5>
                                                </div>
                                            </div>
                                        </div>
                                        {purchaseOrders?.length > 0 ?
                                            <>
                                                {purchaseOrders?.map((purchaseOrder, index) => {
                                                    return (
                                                        <div className={`table_data ${activeSelectedItem !== index ? "" : "active_selected_row"}`} key={index}>
                                                            <div className="row m-0">
                                                                <div className="col-lg-1 col-md-1 data">
                                                                    <h5>{pageLimit * currentPage + (index + 1)}</h5>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2 data click_event" onClick={() => { setSelectedPurchaseOrder(purchaseOrder); setActiveSelectedItem(index) }}>
                                                                    <h5><a>{purchaseOrder?.poNumber}</a></h5>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2 data">
                                                                    <h5>{purchaseOrder?.categoryName}</h5>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 data">
                                                                    <h5>{purchaseOrder?.materialName}</h5>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2 data">
                                                                    <h5>{purchaseOrder?.siteName}</h5>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2 data">
                                                                    <h5>{purchaseOrder?.status}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </>
                                            :
                                            <div className="data_not_available_message" style={{ textAlign: "left", marginLeft: "20%" }}>
                                                <h5>Material Not Available</h5>
                                            </div>
                                        }

                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 ps-lg-0 pe-lg-0">
                                <AllApprovalDetails selectedPurchaseOrder={selectedPurchaseOrder} />
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                    </div>
            }
        </>
    );
}
export default AllApprovals;