import React, { useEffect, useState } from "react";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const AddSubContractorForm = ({ setShowSubContractorForm, fetchAllSubContractors }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [location, setLocation] = useState([]);
    const [locationWiseSites, setlocationWiseSites] = useState([])
    const [subContractor, setSubContractor] = useState(
        {
            subContractorName: "",
            locationName: "",
            locationId: "",
            siteName: "",
            siteId: ""
        }
    );

    const { subContractorName, locationName, locationId, siteName, siteId } = subContractor;

    //Get all inputs values on onChange event
    const subContractorDetails = (event) => {
        setSubContractor({ ...subContractor, [event.target.name]: event.target.value });
    }

    //Get Location wise Sites 
    const locationWiseSite = async (locationName) => {
        try {
            const responseFromServer = await communication.getLocationWiseSites(locationName);
            if (responseFromServer.data.status === "SUCCESS") {
                setlocationWiseSites(responseFromServer.data.sites);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Get all Locations
    const fetchLocations = async () => {
        try {
            const responseFromServer = await communication.getAllLocations();
            if (responseFromServer.data.status === "SUCCESS") {
                setLocation(responseFromServer.data.locations);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setLocation([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchLocations();
    }, []);

    //Adding SubContractor
    const addSubContractor = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const serverResponse = await communication.addSubContractor(subContractorName, locationName, locationId, siteName, siteId);
            if (serverResponse.data.status === "SUCCESS") {
                setShowSubContractorForm(false);
                fetchAllSubContractors();
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <div className="add_new_material">
            <div className="form">
                <div className="form_heading">
                    <h5>Add Sub-Contractor</h5>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="input_group">
                            <input type="text" name="subContractorName" onChange={(event) => setSubContractor({ ...subContractor, subContractorName: event.target.value })} className="form-control" autoComplete="off" required />
                            <label className="input_label">Sub-Contractor Name</label>
                        </div>
                        {validator.message("subContractorName", subContractorName, "required", {
                            messages: {
                                required: "Sub Contractor Name is required"
                            }
                        })}
                    </div>

                    <div className="col-6">
                        <select name="locationName" onChange={(event) => { setSubContractor({ ...subContractor, locationName: JSON.parse(event.target.value)?.locationName, locationId: JSON.parse(event.target.value)?._id }); locationWiseSite(JSON.parse(event.target.value)?._id); }} className="form-select">
                            <option value={JSON.stringify({ locationName: "", _id: "" })}>Select Location</option>
                            {location?.map((location, index) => (
                                <option value={JSON.stringify(location)} key={index}>{location.locationName}</option>
                            ))}
                        </select>
                        {validator.message("locationName", locationName, "required", {
                            messages: {
                                required: "Location is required"
                            }
                        })}
                    </div>

                </div>

                <div className="row">
                    <div className="col-6">
                        <select name="siteName" onChange={(event) => setSubContractor({ ...subContractor, siteName: JSON.parse(event.target.value)?.siteName, siteId: JSON.parse(event.target.value)?._id })} className="form-select">
                            <option value={JSON.stringify({ siteName: "", _id: "" })}>Select Site</option>
                            {locationWiseSites?.map((site, index) => (
                                <option value={JSON.stringify(site)} key={index}>{site.siteName}</option>
                            ))}
                        </select>
                        {validator.message("siteName", siteName, "required", {
                            messages: {
                                required: "Site is required"
                            }
                        })}
                    </div>
                </div>

                <div className="form_button">
                    <button onClick={() => addSubContractor()}>Add</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowSubContractorForm(false)}></i>
                </div>
            </div>
        </div>
    );
}
export default AddSubContractorForm;