import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import Loader from "../../Modals/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import swal from "sweetalert";

const AddNewRequirement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);
    const [validator, showValidationMessage] = useValidator();
    const [locations, setLocations] = useState([]);
    const [locationWiseSites, setLocationWiseSites] = useState([]);
    const [allCategory, setAllCategory] = useState([]);
    const [allMaterial, setAllMaterial] = useState([]);
    const [supervisors, setSupervisors] = useState([]);
    // const [deliverToSupervisor, setDeliverToSupervisor] = useState(false);
    const [unlimitedQuantity, setUnlimitedQuantity] = useState(false)
    const [newRequirement, setNewRequirement] = useState([
        {
            locationId: "",
            siteId: "",
            deliveryTo: "",
            categoryId: "",
            materialId: "",
            supervisor: "",
            supervisorId: "",
            quantityRequired: "",
            materialDescription: "",
            materialTechSpecification: "",
            deliverySchedule: "",
            rejectionLimit: ""
        }
    ]);
    const { locationId, siteId, deliveryTo, categoryId, materialId, supervisor, supervisorId, quantityRequired, materialDescription, materialTechSpecification, deliverySchedule, rejectionLimit } = newRequirement;

    // Fetch All Locations
    const fetchLocations = async () => {
        try {
            dispatch(showLoader());
            const responseFromServer = await communication.getAllLocations();
            if (responseFromServer.data.status === "SUCCESS") {
                dispatch(hideLoader());
                setLocations(responseFromServer.data.locations);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                swal({ text: responseFromServer.data?.message, icon: "error" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Fetch all Categories
    const fetchCategory = async () => {
        try {
            const getCategory = await communication.getAllCategory();

            if (getCategory.data.status === "SUCCESS") {
                setAllCategory(getCategory.data.category);
            } else if (getCategory?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: getCategory.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: getCategory.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Get  location wise site
    const locationWiseSite = async (locationName) => {
        try {
            const responseFromServer = await communication.getLocationWiseSites(locationName);
            if (responseFromServer.data.status === "SUCCESS") {
                setLocationWiseSites(responseFromServer.data.sites);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "error" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Get Category wise Material
    const categoryWiseMaterial = async (categoryId) => {
        try {
            const responseFromServer = await communication.getMaterialByCategory(categoryId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setAllMaterial(responseFromServer?.data?.materials);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }
    //Onchange event for select delivery to
    const selectDeliveryTo = (deliveryTo) => {
        // setDeliverToSupervisor(event.target.value);
        //Fetch supevisors if the current value is Site
        if (deliveryTo === "Site") {
            fetchSupervisors(siteId);
        }
    }
    //Fetch Supervisors 
    const fetchSupervisors = async (siteId) => {
        try {
            const getSupervisors = await communication.fetchSiteSupervisor(siteId);
            if (getSupervisors.data.status === "SUCCESS") {
                setSupervisors(getSupervisors.data.supervisors);
            } else if (getSupervisors?.data?.status === "JWT_INVALID") {
                swal({ text: getSupervisors.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: getSupervisors.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchLocations();
        fetchCategory();
    }, []);

    const addNewRequirement = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            const newRequirement = {
                locationId, siteId, deliveryTo, categoryId, materialId, supervisor, supervisorId, quantityRequired: Number(quantityRequired), unlimitedQuantity, materialDescription, materialTechSpecification, deliverySchedule, rejectionLimit
            }
            const serverResponse = await communication.addNewRequirement(newRequirement);
            if (serverResponse.data.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "success" });
                // window.location.reload(false);
                setNewRequirement({});
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "error" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="dashboard_tab_main">
                        <h4 className="title">Procurement - Add New Requirement</h4>
                        <div className="add_new_requirement_section">
                            <div className="new_requirement_select_box">
                                <div className="select">
                                    <select className="form-select" name="locationId" onChange={(event) => { setNewRequirement({ ...newRequirement, locationId: JSON.parse(event.target.value)?._id }); locationWiseSite(JSON.parse(event.target.value)?._id) }}>
                                        <option value={JSON.stringify({ _id: "" })}>Select Location</option>
                                        {locations?.map((location, index) => (
                                            <option value={JSON.stringify(location)} key={index}>{location.locationName}</option>
                                        ))}
                                    </select>
                                    {validator.message("locationId", locationId, "required", {
                                        messages: {
                                            required: "Location is required"
                                        }
                                    })}
                                </div>
                                <div className="select">
                                    <select className="form-select" name="siteId" onChange={(event) => setNewRequirement({ ...newRequirement, siteId: JSON.parse(event.target.value)?._id })}>
                                        <option value={JSON.stringify({ _id: "" })}>Select site</option>
                                        {locationWiseSites?.map((site, index) => (
                                            <option value={JSON.stringify(site)} key={index}>{site.siteName}</option>
                                        ))}
                                    </select>
                                    {validator.message("siteId", siteId, "required", {
                                        messages: {
                                            required: "Site is required"
                                        }
                                    })}
                                </div>
                                <div className="select">
                                    <select className="form-select" name="deliveryTo" onChange={(event) => { setNewRequirement({ ...newRequirement, deliveryTo: event.target.value }); selectDeliveryTo(event.target.value) }}>
                                        <option value="">Deliver To</option>
                                        <option value="Site">Site</option>
                                        <option value="Inventory">Inventory</option>
                                    </select>
                                    {validator.message("deliveryTo", deliveryTo, "required", {
                                        messages: {
                                            required: "Field is required"
                                        }
                                    })}
                                </div>
                                <div className="select">
                                    <select className="form-select" name="categoryId" onChange={(event) => { setNewRequirement({ ...newRequirement, categoryId: JSON.parse(event.target.value)?._id }); categoryWiseMaterial(JSON.parse(event.target.value)?._id); }}>
                                        <option value={JSON.stringify({ _id: "" })}>Select Category</option>
                                        {allCategory?.map((category, index) => (
                                            <option value={JSON.stringify(category)} key={index}>{category.categoryName}</option>
                                        ))}
                                    </select>
                                    {validator.message("categoryId", categoryId, "required", {
                                        messages: {
                                            required: "Category is required"
                                        }
                                    })}
                                </div>
                                <div className="select">
                                    <select className="form-select" name="materialId" onChange={(event) => setNewRequirement({ ...newRequirement, materialId: JSON.parse(event.target.value)?._id, })}>
                                        <option value={JSON.stringify({ _id: "" })}>Select Material</option>
                                        {allMaterial?.map((material, index) => (
                                            <option value={JSON.stringify(material)} key={index}>{material.materialName}</option>
                                        ))}
                                    </select>
                                    {validator.message("materialId", materialId, "required", {
                                        messages: {
                                            required: "Material is required"
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="required_quantity">
                                {deliveryTo === "Site" &&
                                    < select className="form-select" name="supervisor" onChange={(event) => setNewRequirement({ ...newRequirement, supervisor: JSON.parse(event.target.value)?.name, supervisorId: JSON.parse(event.target.value)?._id, })}>
                                        <option value={JSON.stringify({ name: "", _id: "" })}>Select Supervisor</option>
                                        {supervisors?.map((supervisor, index) => (
                                            <option value={JSON.stringify(supervisor)} key={index}>{supervisor.name}</option>
                                        ))}
                                    </select>
                                }
                                {deliveryTo === "Site" ?
                                    <span>
                                        {validator.message("supervisor", supervisor, "required", {
                                            messages: {
                                                required: "Supervisor is required"
                                            }
                                        })}
                                    </span>
                                    : null
                                }
                                {unlimitedQuantity === false &&
                                    <div className="input_group">
                                        <input type="text" name="quantityRequired" onChange={(event) => setNewRequirement({ ...newRequirement, quantityRequired: event.target.value })} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Quantity Required</label>
                                    </div>
                                }
                                {unlimitedQuantity === false ?
                                    <span>
                                        {validator.message("quantityRequired", quantityRequired, "required|numeric", {
                                            messages: {
                                                required: "Quantity is required"
                                            }
                                        })}
                                    </span>
                                    : null
                                }
                                {[undefined, null, ""].includes(quantityRequired) &&
                                    <div className="access_control">
                                        <h5 className="title me-2">Check this for Unlimited Quantity</h5>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" name="unlimited_quantity" onChange={() => setUnlimitedQuantity(!unlimitedQuantity)} type="checkbox" />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="row textarea">
                                <div className="col-6">
                                    <label>Description of Material</label>
                                    <textarea name="materialDescription" onChange={(event) => setNewRequirement({ ...newRequirement, materialDescription: event.target.value })} className="form-control"></textarea>
                                    {validator.message("materialDescription", materialDescription, "required", {
                                        messages: {
                                            required: "Material Description is required"
                                        }
                                    })}
                                </div>
                                <div className="col-6">
                                    <label>Technical Specification of Material</label>
                                    <textarea name="materialTechSpecification" onChange={(event) => setNewRequirement({ ...newRequirement, materialTechSpecification: event.target.value })} className="form-control"></textarea>
                                    {validator.message("materialTechSpecification", materialTechSpecification, "required", {
                                        messages: {
                                            required: "Technical Specification is required"
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="row textarea">
                                <div className="col-6">
                                    <label >Delivery Schedule of Material Supply</label>
                                    <textarea name="deliverySchedule" onChange={(event) => setNewRequirement({ ...newRequirement, deliverySchedule: event.target.value })} className="form-control"></textarea>
                                    {validator.message("deliverySchedule", deliverySchedule, "required", {
                                        messages: {
                                            required: "Delivery Schedule is required"
                                        }
                                    })}
                                </div>
                                <div className="col-6 mt-lg-0 mt-md-3">
                                    <label>Rejection Tollerence Limit, If Any</label>
                                    <textarea name="rejectionLimit" onChange={(event) => setNewRequirement({ ...newRequirement, rejectionLimit: event.target.value })} className="form-control"></textarea>
                                </div>
                            </div>
                            <div className="add_new_requirement_button">
                                <button onClick={() => addNewRequirement()}>Add</button>
                            </div>
                        </div>
                    </div >
            }
        </>
    );
}
export default AddNewRequirement;