import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../../Modals/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";



const UpdatePurchaseIndent = ({ updateIndent, setShowUpdateIndent, fetchAllIndents }) => {
    const date = new Date();
    const navigate = useNavigate();
    const loginUserDetails = useSelector((state) => state.userDetails);
    const [validator, showValidationMessage] = useValidator();
    const [unlimitedQuantity, setUnlimitedQuantity] = useState(updateIndent?.unlimitedQuantity)
    const [quantityAvailableInStore, setQuantityAvailableInStore] = useState(updateIndent?.quantityAvailableInStore);
    const [category, setCategory] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [locations, setLocations] = useState([]);
    const [sites, setSites] = useState([]);
    const [updatePurchaseIndent, setUpdatePurchaseIndent] = useState(updateIndent)

    const { categoryId, materialId, locationId, siteId, deliveryTo, materialDescription, quantityRequired, materialTechSpecification, requiredFor, remark } = updatePurchaseIndent;
    let createdBy = loginUserDetails;
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader);

    //Fetch all categories on initial load
    const fetchCategory = async () => {
        try {
            const responseFromServer = await communication.getAllCategory();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setCategory(responseFromServer?.data?.category);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setCategory([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }

    }

    //Get CategoryWise Material
    const categoryWiseMaterial = async (categoryId) => {
        try {
            const responseFromServer = await communication.getMaterialByCategory(categoryId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setMaterials(responseFromServer?.data?.materials);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                setMaterials([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Get All Locations
    const fetchLocations = async () => {
        try {
            const responseFromServer = await communication.getAllLocations();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setLocations(responseFromServer?.data?.locations);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setLocations([]);
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Get locationWise Sites
    const locationWiseSites = async (locationId) => {
        try {
            const responseFromServer = await communication.getLocationWiseSites(locationId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setSites(responseFromServer?.data?.sites);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                setSites([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Get Material wise Quantity
    const materialWiseQuantity = async (materialId) => {
        try {
            const serverResponse = await communication.getStockByMaterial(materialId);
            if (serverResponse?.data?.status === "SUCCESS") {
                setQuantityAvailableInStore(serverResponse?.data?.availableQuantity);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setQuantityAvailableInStore();
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchCategory();
        fetchLocations();
        categoryWiseMaterial(categoryId);
        locationWiseSites(locationId);
    }, []);

    const updateNewPurchaseIndent = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader())

            const dataToSend = {
                ...updatePurchaseIndent, createdBy, unlimitedQuantity, quantityAvailableInStore, purchaseIndentId: updateIndent?.purchaseIndentId
            }

            const serverResponse = await communication.updatePurchaseIndent(dataToSend);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader())
                setShowUpdateIndent({ isShowModal: false });
                fetchAllIndents();
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                dispatch(hideLoader())
                swal({ text: serverResponse.data?.message, icon: "error" });
            }

        } catch (error) {
            dispatch(hideLoader())
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <>
            {loader.showHideLoader === true ?
                <Loader />
                :
                <div className="issue_transfer">
                    <div className="form">
                        <div className="form_heading">
                            <h5>Update Purchase Indent</h5>
                        </div>
                        <div className="row mt-4" style={{ textAlign: "left" }}>
                            <div className="col-lg-4 col-md-3">
                                <h5><b>Date : {`${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`}</b></h5>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <h5><b>From : {updateIndent?.createdBy}</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <select name="categoryId" className="form-select" onChange={(event) => { setUpdatePurchaseIndent({ ...updatePurchaseIndent, categoryId: JSON.parse(event.target.value)?._id }); categoryWiseMaterial(JSON.parse(event.target.value)?._id) }}>
                                    {category?.map((categories, index) => (
                                        <option value={JSON.stringify(categories)} selected={categoryId === categories?._id} key={index}>{categories?.categoryName}</option>
                                    ))}
                                </select>
                                {validator.message("categoryId", categoryId, "required", {
                                    messages: {
                                        required: "Category is required"
                                    }
                                })}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <select name="materialId" className="form-select" onChange={(event) => { setUpdatePurchaseIndent({ ...updatePurchaseIndent, materialId: JSON.parse(event.target.value)?._id }); materialWiseQuantity(JSON.parse(event.target.value)?._id) }}>
                                    <option value={JSON.stringify({ _id: "" })} >Select Material</option>
                                    {materials?.map((material, index) => (
                                        <option value={JSON.stringify(material)} selected={material?._id === materialId} key={index}>{material?.materialName}</option>
                                    ))}
                                </select>
                                {validator.message("materialId", materialId, "required", {
                                    messages: {
                                        required: "Material is required"
                                    }
                                })}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <select name="locationId" className="form-select" onChange={(event) => { setUpdatePurchaseIndent({ ...updatePurchaseIndent, locationId: JSON.parse(event.target.value)?._id }); locationWiseSites(JSON.parse(event.target.value)?._id) }}>
                                    <option value={JSON.stringify({ _id: "" })}>Select Location</option>
                                    {locations?.map((location, index) => (
                                        <option value={JSON.stringify(location)} selected={location?._id === locationId} key={index}>{location?.locationName}</option>
                                    ))}
                                </select>
                                {validator.message("locationId", locationId, "required", {
                                    messages: {
                                        required: "Location is required"
                                    }
                                })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <select name="siteId" className="form-select" onChange={(event) => setUpdatePurchaseIndent({ ...updatePurchaseIndent, siteId: JSON.parse(event.target.value)?._id })}>
                                    <option value={JSON.stringify({ _id: "" })}>Select Site</option>
                                    {sites?.map((site, index) => (
                                        <option value={JSON.stringify(site)} selected={site?._id === siteId} key={index}>{site?.siteName}</option>
                                    ))}
                                </select>
                                {validator.message("siteId", siteId, "required", {
                                    messages: {
                                        required: "Site is required"
                                    }
                                })}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <select name="deliveryTo" value={deliveryTo} className="form-select" onChange={(event) => setUpdatePurchaseIndent({ ...updatePurchaseIndent, deliveryTo: event.target.value })}>
                                    <option value="" >Deliver To</option>
                                    <option value="Site" selected={deliveryTo === "Site"}>Site</option>
                                    <option value="Inventory" selected={deliveryTo === "Inventory"}>Inventory</option>
                                </select>
                                {validator.message("deliveryTo", deliveryTo, "required", {
                                    messages: {
                                        required: "Requirement Location is required"
                                    }
                                })}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="input_group">
                                    <input type="text" name="materialDescription" value={materialDescription} onChange={(event) => setUpdatePurchaseIndent({ ...updatePurchaseIndent, materialDescription: event.target.value })} className="form-control" autoComplete="off" required />
                                    <label className="input_label">Material Description</label>
                                </div>
                                {validator.message("materialDescription", materialDescription, "required", {
                                    messages: {
                                        required: "Material description is required"
                                    }
                                })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="input_group">
                                    <input type="text" name="quantityAvailableInStore" value={quantityAvailableInStore} className="form-control" autoComplete="off" required />
                                    <label className="input_label">Quantity Available in Store</label>
                                </div>
                            </div>
                            {unlimitedQuantity === false &&
                                <div className="col-lg-4 col-md-6">
                                    <div className="input_group">
                                        <input type="text" name="quantityRequired" value={quantityRequired} onChange={(event) => setUpdatePurchaseIndent({ ...updatePurchaseIndent, quantityRequired: event.target.value })} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Required Quantity</label>
                                    </div>
                                    {validator.message("quantityRequired", quantityRequired, "required|numeric", {
                                        messages: {
                                            required: "Required Quantity is required"
                                        }
                                    })}
                                </div>
                            }
                            {[undefined, null, "", 0].includes(quantityRequired) &&
                                <div className="col-lg-4 col-md-6 unlimited_quantity_checkbox">
                                    <h5>Check this for Unlimited QTY</h5>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" checked={unlimitedQuantity} onChange={() => setUnlimitedQuantity(!unlimitedQuantity)} />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="input_group">
                                    <input type="text" name="materialTechSpecification" value={materialTechSpecification} onChange={(event) => setUpdatePurchaseIndent({ ...updatePurchaseIndent, materialTechSpecification: event.target.value })} className="form-control" autoComplete="off" required />
                                    <label className="input_label">Specification</label>
                                </div>
                                {validator.message("materialTechSpecification", materialTechSpecification, "required", {
                                    messages: {
                                        required: "Specification is required"
                                    }
                                })}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="input_group">
                                    <input type="text" name="requiredFor" value={requiredFor} onChange={(event) => setUpdatePurchaseIndent({ ...updatePurchaseIndent, requiredFor: event.target.value })} className="form-control" autoComplete="off" required />
                                    <label className="input_label">Required For</label>
                                </div>
                                {validator.message("requiredFor", requiredFor, "required|alpha_space", {
                                    messages: {
                                        required: "Field is required"
                                    }
                                })}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="input_group">
                                    <input type="text" name="remark" value={remark} onChange={(event) => setUpdatePurchaseIndent({ ...updatePurchaseIndent, remark: event.target.value })} className="form-control" autoComplete="off" required />
                                    <label className="input_label">Remark</label>
                                </div>
                            </div>
                        </div>
                        <div className="form_button">
                            <button onClick={() => updateNewPurchaseIndent()}>Update</button>
                        </div>

                        <div className="close_form">
                            <i className="fa-solid fa-xmark" onClick={() => setShowUpdateIndent({ isShowModal: false })}></i>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
export default UpdatePurchaseIndent;