import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import DashboardTab from '../Components/DashboardTab';
import DesignationManagement from "../Components/DesignationManagement";
import SiteManagement from '../Components/SiteManagement';
import UserManagement from '../Components/UserManagement'
import MaterialManagement from '../Components/MaterialManagement';
import MachineManagement from '../Components/MachineManagement';
import SubContractorManagement from '../Components/SubContractorManagement';
import TotalRequirements from '../Components/procurement/TotalRequirements';
import AddNewRequirement from '../Components/procurement/AddNewRequirement';
import PurchaseIndent from '../Components/procurement/PurchaseIndent';
import AllApprovals from '../Components/procurement/AllApprovals';
import AllMaterial from '../Components/inventory/AllMaterial';
import MaterailIssuing from '../Components/inventory/MaterialIssuing';
import MaterialTransfer from '../Components/inventory/MaterialTransfer';
import MaterialReceived from '../Components/inventory/MaterialReceived';
import ApprovedRequirements from '../Components/inventory/ApprovedRequirements';
import Reports from '../Components/inventory/Reports';
import MachineDetails from '../Components/MachineDetails';

const DashboardViewRoutes = () => {
    return (
        <Routes>
            <Route path="/main-dashboard/dashboard" element={<DashboardTab />}></Route>
            <Route path="/designation-management" element={<DesignationManagement />}></Route>
            <Route path="/site-management" element={<SiteManagement />}></Route>
            <Route path="/user-management" element={<UserManagement />}></Route>
            <Route path="/machine-management" element={<MachineManagement />}></Route>
            <Route path="/machine-management/machine-details" element={<MachineDetails />}></Route>
            <Route path="/sub-contractor-management" element={<SubContractorManagement />}></Route>
            <Route path="/procurement/total-requirement" element={<TotalRequirements />}></Route>
            <Route path="/procurement/add-new-requirement" element={<AddNewRequirement />}></Route>
            <Route path="/procurement/purchase-indent" element={<PurchaseIndent />}></Route>
            <Route path="/procurement/all-approvals" element={<AllApprovals />}></Route>
            <Route path="/inventory/all-material" element={<AllMaterial />}></Route>
            <Route path="/inventory/material-issuing" element={<MaterailIssuing />}></Route>
            <Route path="/inventory/material-transfer" element={<MaterialTransfer />}></Route>
            <Route path="/inventory/material-received" element={<MaterialReceived />}></Route>
            <Route path="/inventory/approved-requirements/*" element={<ApprovedRequirements />}></Route>
            <Route path="/material-management/*" element={<MaterialManagement />}></Route>
            <Route path="/inventory/reports/*" element={<Reports />}></Route>
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
    )
}
export default DashboardViewRoutes