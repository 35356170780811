import React, { useState } from "react";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const AddUnitForm = ({ setShowUnitForm, getAllUnits }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [unitName, setUnitName] = useState();

    const addUnit = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const serverResponse = await communication.addUnit(unitName);
            if (serverResponse.data.status === "SUCCESS") {
                setShowUnitForm(false);
                getAllUnits();
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    return (
        <div className="add_new_unit">
            <div className="form">
                <div className="form_heading">
                    <h5>Add New Unit</h5>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="input_group">
                            <input type="text" name="unitName" onChange={(event) => setUnitName(event.target.value)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Unit Name</label>
                        </div>
                        {validator.message("unitName", unitName, "required|alpha_space", {
                            messages: {
                                required: "Unit is required"
                            }
                        })}
                    </div>
                </div>
                <div className="form_button">
                    <button onClick={() => addUnit()}>Add</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowUnitForm(false)}></i>
                </div>
            </div>
        </div>
    );
}
export default AddUnitForm;