import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { communication } from '../../communication/communication';
import useValidator from '../../formValidation/useValidator';
import swal from 'sweetalert';

const UpdateCategoryForm = ({ setShowUpdateCategoryForm, showUpdateCategoryForm, fetchAllCategory }) => {
    const [validator, showValidationMessage] = useValidator();
    const [newCategoryName, setNewCategoryName] = useState(showUpdateCategoryForm.categoryName);
    const navigate = useNavigate();

    const updateCategory = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const serverResponse = await communication.updateCategory(showUpdateCategoryForm.categoryId, newCategoryName);
            if (serverResponse.data.status === "SUCCESS") {
                setShowUpdateCategoryForm({ isModalShow: false, categoryName: "", categoryId: "" });
                fetchAllCategory();
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse.data.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate('/login');
            } else {
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <div className="add_new_designation">
            <div className="form">
                <div className="form_heading">
                    <h5>Update Category</h5>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="input_group">
                            <input type="text" name="newCategoryName" onChange={(event) => setNewCategoryName(event.target.value)} value={newCategoryName} className="form-control" autoComplete="off" required />
                        </div>
                        {validator.message("newCategoryName", newCategoryName, "required|alpha_space", {
                            messages: {
                                required: "Category is required"
                            }
                        })}
                    </div>
                </div>
                <div className="form_button">
                    <button onClick={() => updateCategory()} >Update</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowUpdateCategoryForm({ isModalShow: false, categoryName: "", categoryId: "" })}  ></i>
                </div>
            </div>
        </div>
    )
}

export default UpdateCategoryForm