import React from "react";
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";

const ProcurementDropdown = () => {
    const loginUserDetails = useSelector((state) => state.userDetails);
    return (
        <ul className="dropdown">

            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.total_requirement === true) ?
                <NavLink to="/main-dashboard/procurement/total-requirement">
                    <li>Total Requirements</li>
                </NavLink>
                :
                <></>
            }
            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.add_new_purchase_order === true) ?
                <NavLink to="/main-dashboard/procurement/add-new-requirement">
                    <li>Add New Requirement</li>
                </NavLink>
                :
                <></>
            }
            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.new_purchase_indent === true) ?
                <NavLink to="/main-dashboard/procurement/purchase-indent">
                    <li>New Purchase Indent</li>
                </NavLink>
                :
                <></>
            }
            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.all_approvals === true) ?
                <NavLink to="/main-dashboard/procurement/all-approvals">
                    <li>All Approvals</li>
                </NavLink>
                :
                <></>
            }



        </ul>
    );
}
export default ProcurementDropdown;