import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import UpdateCategoryForm from "../forms/UpdateCategoryForm";
import AddNewCategory from "../forms/AddNewCategory";
import { communication } from "../../communication/communication";
import Loader from "../../Modals/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import { confirmAlert } from 'react-confirm-alert';
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

const TypeCategory = () => {
    const [showCategoryForm, setShowCategoryForm] = useState(false);
    const [showUpdateCategoryForm, setShowUpdateCategoryForm] = useState({ isModalShow: false, categoryName: "", categoryId: "" });
    const [categoryData, setCategoryData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");

    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader);
    const loginUserDetails = useSelector((state) => state.userDetails);
    const navigate = useNavigate();

    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchCategoryBySearch(searchValue, selectedPage + 1);
        } else {
            fetchAllCategory(selectedPage + 1);
        }
    }

    //search filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchCategoryBySearch(searchString, currentPage + 1);
        } else {
            const serverResponse = await communication.getAllCategoryList();
            if (serverResponse.data.status === "SUCCESS") {
                setCategoryData(serverResponse?.data?.category);
                setPageCount(serverResponse?.data?.totalPages);
            } else if (serverResponse.data.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate('/login');
            } else {
                setCategoryData([]);
                setPageCount(0);
            }
        }
    }

    //fetch category by search
    const fetchCategoryBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchCategory(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setCategoryData(responseFromServer?.data?.category);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer.data.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate('/login');
            } else {
                setCategoryData([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //get all category list on initial load
    const fetchAllCategory = async (page) => {
        try {
            dispatch(showLoader());
            const serverResponse = await communication.getAllCategoryList(page);
            if (serverResponse.data.status === "SUCCESS") {
                dispatch(hideLoader());
                setCategoryData(serverResponse?.data?.category);
                setPageCount(serverResponse?.data?.totalPages);
            } else if (serverResponse.data.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate('/login');
            } else {
                setCategoryData([]);
                setPageCount(0);
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }
    }


    useEffect(() => {
        fetchAllCategory();
    }, [])

    //delete category
    const deleteCategory = async (categoryId) => {
        confirmAlert({
            title: 'Are you sure you want to Delete Category?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        if (categoryId === "" || categoryId === undefined) {
                            dispatch(hideLoader());
                            swal({ text: "Category Id not exist", icon: "warning" });
                            return false;
                        }

                        const serverResponse = await communication.deleteCategory(categoryId)
                        if (serverResponse.data.status === "SUCCESS") {
                            fetchAllCategory();
                            return swal({ text: serverResponse.data?.message, icon: "success" });
                        } else if (serverResponse.data.status === "JWT_INVALID") {
                            dispatch(hideLoader());
                            swal({ text: serverResponse.data?.message, icon: "warning" });
                            navigate('/login');
                        } else {
                            dispatch(hideLoader());
                            swal({ text: serverResponse.data?.message, icon: "warning" });
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return false }
                }
            ],
            overlayClassName: "react-confirm-alert"
        });
    }



    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <>
                        <div className='search_div' >
                            <input type="text" className='form=control' onChange={(event) => { searchFilter(event.target.value); setSearchValue(event.target.value) }} placeholder='search by category' title="search by category" />

                            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.add_material === true) ?
                                <button onClick={() => setShowCategoryForm(true)}>Add New Category</button>
                                :
                                <></>
                            }
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="table_section material_management_section">
                                    <div className="table_header">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Sr. No</h5>
                                            </div>
                                            <div className="col-lg-8 col-md-8">
                                                <h5>Type/Category</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Action</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {categoryData.map((category, index) => {
                                        const { categoryName, _id } = category;
                                        return (
                                            <div className="table_data" key={index} >
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2">
                                                        <h5>{index + 1}</h5>
                                                    </div>
                                                    <div className="col-lg-8 col-md-8">
                                                        <h5>{categoryName}</h5>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2">
                                                        <div className="action">
                                                            <i className="fa-solid fa-pen-to-square" onClick={() => setShowUpdateCategoryForm({ isModalShow: true, categoryName: categoryName, categoryId: _id })}></i>
                                                            <i className="fa-solid fa-trash" onClick={() => deleteCategory(_id)}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        );
                                    })}

                                </div>
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                        {showCategoryForm &&
                            < div className="modal_box">
                                <AddNewCategory setShowCategoryForm={setShowCategoryForm} fetchAllCategory={fetchAllCategory} />
                            </div>
                        }

                        {
                            showUpdateCategoryForm.isModalShow &&
                            <div className="modal_box">
                                <UpdateCategoryForm setShowUpdateCategoryForm={setShowUpdateCategoryForm} showUpdateCategoryForm={showUpdateCategoryForm} fetchAllCategory={fetchAllCategory} />
                            </div>
                        }

                    </>
            }

        </>
    );
}
export default TypeCategory