import React, { useEffect, useState } from "react";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const AddMaterialForm = ({ setShowAddMaterialForm, getAllMaterial }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [allUnits, setAllUnits] = useState();
    const [allCategory, setAllCategory] = useState();
    const [material, setMaterial] = useState([{
        materialName: "",
        categoryId: "",
        unitId: ""
    }])
    const { materialName, categoryId, unitId } = material;
    const onMaterialChange = (event) => {
        setMaterial({ ...material, [event.target.name]: event.target.value });
    }

    //Get all units for select box
    const getAllUnitsCategory = async () => {
        try {
            const getAllUnit = await communication.getAllUnits();
            const getAllCategory = await communication.getAllCategory();
            if (getAllUnit.data.status === "SUCCESS" && getAllCategory.data.status === "SUCCESS") {
                setAllUnits(getAllUnit.data.units);
                setAllCategory(getAllCategory.data.category);
            } else if (getAllUnit?.data?.status === "JWT_INVALID") {
                swal({ text: getAllUnit.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setAllUnits([]);
                setAllCategory([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        getAllUnitsCategory();
    }, []);

    //Add Material 
    const addMaterial = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const serverResponse = await communication.addMaterial(materialName, unitId, categoryId);
            if (serverResponse.data.status === "SUCCESS") {
                setShowAddMaterialForm(false);
                getAllMaterial();
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: serverResponse?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <div className="add_new_material">
            <div className="form">
                <div className="form_heading">
                    <h5>Add Material</h5>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="input_group">
                            <input type="text" name="materialName" value={materialName} onChange={(event) => onMaterialChange(event)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Material Name</label>
                        </div>
                        {validator.message("materialName", materialName, "required", {
                            messages: {
                                required: "Material is required"
                            }
                        })}
                    </div>

                    <div className="col-6">
                        <select name="categoryId" onChange={(event) => onMaterialChange(event)} className="form-select">
                            <option value="">Select Category</option>
                            {allCategory?.map((category, index) => (
                                <option value={category._id} key={index}>{category.categoryName}</option>
                            ))}
                        </select>
                        {validator.message("categoryId", categoryId, "required", {
                            messages: {
                                required: "Category is required"
                            }
                        })}
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <select name="unitId" onChange={(event) => onMaterialChange(event)} className="form-select">
                            <option value="">Select Unit</option>
                            {allUnits?.map((unit, index) => (
                                <option value={unit._id} key={index}>{unit.unitName}</option>
                            ))}
                        </select>
                        {validator.message("unitId", unitId, "required", {
                            messages: {
                                required: "Site is required"
                            }
                        })}
                    </div>
                </div>
                <div className="form_button">
                    <button onClick={() => addMaterial()}>Add</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowAddMaterialForm(false)}></i>
                </div>
            </div>
        </div>
    );
}
export default AddMaterialForm;