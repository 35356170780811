import React, { useEffect, useMemo, useState } from "react";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const UpdatePurchaseOrder = ({ updatePo, setUpdatePo, fetchPurchaseOrder }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [locations, setLocations] = useState([]);
    const [locationWiseSites, setLocationWiseSites] = useState([]);
    const [allCategory, setAllCategory] = useState([]);
    const [allMaterial, setAllMaterial] = useState([updatePo?.material]);
    const [supervisors, setSupervisors] = useState([]);
    const [deliverToSupervisor, setDeliverToSupervisor] = useState(false);
    const [unlimitedQuantity, setUnlimitedQuantity] = useState(updatePo?.unlimitedQuantity)
    const [updateRequirement, setUpdateRequirement] = useState(updatePo);

    const { poId, locationId, siteName, siteId, unitId, deliveryTo, categoryId, materialId, supervisor, supervisorId, quantityRequired, materialDescription, materialTechSpecification, deliverySchedule, rejectionLimit } = updateRequirement;
    // Fetch All Locations
    const fetchLocations = async () => {
        try {
            const responseFromServer = await communication.getAllLocations();

            if (responseFromServer.data.status === "SUCCESS") {
                setLocations(responseFromServer.data.locations);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setLocations([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Fetch all Categories
    const fetchCategory = async () => {
        try {
            const getCategory = await communication.getAllCategory();

            if (getCategory.data.status === "SUCCESS") {
                setAllCategory(getCategory.data.category);
            } else if (getCategory?.data?.status === "JWT_INVALID") {
                swal({ text: getCategory.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setAllCategory([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Get  location wise site
    const locationWiseSite = async (locationId) => {
        try {
            const responseFromServer = await communication.getLocationWiseSites(locationId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setLocationWiseSites(responseFromServer?.data?.sites);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer?.data?.message, icon: "error" });
                setLocationWiseSites([]);
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Get Category wise Material
    const categoryWiseMaterial = async (categoryId) => {
        try {
            const responseFromServer = await communication.getMaterialByCategory(categoryId);
            if (responseFromServer.data.status === "SUCCESS") {
                setAllMaterial(responseFromServer?.data?.materials);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                setAllMaterial([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Onchage event for select delivery to
    const selectDeliveryTo = (event) => {
        setDeliverToSupervisor(event.target.value);
        //Fetch supevisors if the current value is Site
        if (event.target.value === "Site") {
            fetchSupervisors();
        } else {
            setUpdateRequirement({ ...updateRequirement, supervisor: "", supervisorId: "" });
        }
    }
    //Fetch Supervisors 
    const fetchSupervisors = async () => {
        try {
            const getSupervisors = await communication.fetchSiteSupervisor(siteName);
            if (getSupervisors.data.status === "SUCCESS") {
                setSupervisors(getSupervisors.data.supervisors);
            } else if (getSupervisors?.data?.status === "JWT_INVALID") {
                swal({ text: getSupervisors.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setSupervisors([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useMemo(() => {
        categoryWiseMaterial(categoryId);
    }, [categoryId]);

    useEffect(() => {
        fetchLocations();
        fetchCategory();
        locationWiseSite(updatePo.locationId);
        // categoryWiseMaterial(updatePo.categoryName);
    }, []);

    //Update purchase order
    const updatePurchaseOrder = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            const updatedPo = {
                poId, locationId, siteId, unitId, deliveryTo, categoryId, materialId, supervisor, supervisorId, quantityRequired: Number(quantityRequired), unlimitedQuantity, materialDescription, materialTechSpecification, deliverySchedule, rejectionLimit
            }
            const serverResponse = await communication.updatePurchaseOrder(updatedPo);
            if (serverResponse.data.status === "SUCCESS") {
                setUpdatePo({ isShowModal: false });
                fetchPurchaseOrder();
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <div className="dashboard_tab_main update_purchase_order">
            <div className="add_new_requirement_section">
                <h4 className="title">Update purchase Order</h4>
                <div className="new_requirement_select_box">
                    <div className="select">
                        <select className="form-select" name="locationId" onChange={(event) => { setUpdateRequirement({ ...updateRequirement, locationId: JSON.parse(event.target.value)?._id }); locationWiseSite(JSON.parse(event.target.value)?._id) }}>
                            {locations?.map((location, index) => (
                                <option value={JSON.stringify(location)} selected={location?._id === locationId} key={index}>{location.locationName}</option>
                            ))}
                        </select>
                        {/* {validator.message("locationId", locationId, "required", {
                            messages: {
                                required: "Location is required"
                            }
                        })} */}
                    </div>
                    <div className="select">
                        <select className="form-select" name="siteId" onChange={(event) => setUpdateRequirement({ ...updateRequirement, siteId: JSON.parse(event.target.value)?._id })}>
                            <option value={JSON.stringify({ _id: "" })}>Select Site</option>
                            {locationWiseSites?.map((site, index) => (
                                <option value={JSON.stringify(site)} selected={site?._id === siteId} key={index}>{site.siteName}</option>
                            ))}
                        </select>
                        {validator.message("siteId", siteId, "required", {
                            messages: {
                                required: "Site is required"
                            }
                        })}
                    </div>
                    <div className="select">
                        <select className="form-select" name="deliveryTo" onChange={(event) => { setUpdateRequirement({ ...updateRequirement, deliveryTo: event.target.value }); selectDeliveryTo(event) }}>
                            <option value="">Select Delivery Type</option>
                            <option value="Site" selected={deliveryTo === "Site"}>Site</option>
                            <option value="Inventory" selected={deliveryTo === "Inventory"}>Inventory</option>
                        </select>
                        {validator.message("deliveryTo", deliveryTo, "required", {
                            messages: {
                                required: "Delivery Type is required"
                            }
                        })}
                    </div>
                    <div className="select">
                        <select className="form-select" name="categoryId" onChange={(event) => { setUpdateRequirement({ ...updateRequirement, categoryId: JSON.parse(event.target.value)?._id }); }}>
                            {allCategory?.map((category, index) => (
                                <option value={JSON.stringify(category)} selected={category?._id === categoryId} key={index} >{category.categoryName}</option>
                            ))}
                        </select>
                        {/* {validator.message("categoryId", categoryId, "required", {
                            messages: {
                                required: "Field is required"
                            }
                        })} */}
                    </div>
                    <div className="select">
                        <select className="form-select" name="materialId" onChange={(event) => setUpdateRequirement({ ...updateRequirement, materialId: JSON.parse(event.target.value)?._id, unitId: JSON.parse(event.target.value)?.unitId })}>
                            <option value={JSON.stringify({ _id: "", unitId: "" })}>Select Material</option>
                            {allMaterial?.map((material, index) => (
                                <option value={JSON.stringify(material)} selected={material?._id === materialId} key={index}>{material?.materialName}</option>
                            ))}
                        </select>
                        {validator.message("materialId", materialId, "required", {
                            messages: {
                                required: "Material is required"
                            }
                        })}
                    </div>
                </div>
                <div className="required_quantity">
                    {deliverToSupervisor === "Site" &&
                        < select className="form-select" name="supervisor" onChange={(event) => setUpdateRequirement({ ...updateRequirement, supervisor: JSON.parse(event.target.value)?.name, supervisorId: JSON.parse(event.target.value)?._id })}>
                            <option value={JSON.stringify({ name: "", _id: "" })}>Select Supervisor</option>
                            {supervisors?.map((supervisor, index) => (
                                <option value={JSON.stringify(supervisor)} selected={supervisor?._id === supervisorId} key={index}>{supervisor.name}</option>
                            ))}
                        </select>
                    }
                    {deliverToSupervisor === "Site" ?
                        <span>
                            {validator.message("supervisor", supervisor, "required", {
                                messages: {
                                    required: "Supervisor is required"
                                }
                            })}
                        </span>
                        : null
                    }
                    {unlimitedQuantity === false &&
                        <div className="input_group">
                            <input type="text" name="quantityRequired" value={quantityRequired} onChange={(event) => setUpdateRequirement({ ...updateRequirement, quantityRequired: event.target.value })} className="form-control" autoComplete="off" required />
                            <label className="input_label">Quantity Required</label>
                        </div>
                    }
                    {unlimitedQuantity === false ?
                        <span>
                            {validator.message("quantityRequired", quantityRequired, "required|numeric", {
                                messages: {
                                    required: "Quantity is required"
                                }
                            })}
                        </span>
                        : null
                    }
                    {[undefined, null, "", 0].includes(quantityRequired) &&
                        <div className="access_control">
                            <h5 className="title me-2">Check this for Unlimited Quantity</h5>
                            <div class="form-check form-switch">
                                <input class="form-check-input" value={unlimitedQuantity} name="unlimited_quantity" checked={unlimitedQuantity === true ? true : false} onChange={() => setUnlimitedQuantity(!unlimitedQuantity)} type="checkbox" />
                            </div>
                        </div>
                    }
                </div>
                <div className="row textarea">
                    <div className="col-6">
                        <label>Description of Material</label>
                        <textarea name="materialDescription" value={materialDescription} onChange={(event) => setUpdateRequirement({ ...updateRequirement, materialDescription: event.target.value })} className="form-control"></textarea>
                        {validator.message("materialDescription", materialDescription, "required", {
                            messages: {
                                required: "Material Description is required"
                            }
                        })}
                    </div>
                    <div className="col-6">
                        <label>Technical Specification of Material</label>
                        <textarea name="materialTechSpecification" value={materialTechSpecification} onChange={(event) => setUpdateRequirement({ ...updateRequirement, materialTechSpecification: event.target.value })} className="form-control"></textarea>
                        {validator.message("materialTechSpecification", materialTechSpecification, "required", {
                            messages: {
                                required: "Technical Specification is required"
                            }
                        })}
                    </div>
                </div>
                <div className="row textarea">
                    <div className="col-6">
                        <label >Delivery Schedule of Material Supply</label>
                        <textarea name="deliverySchedule" value={deliverySchedule} onChange={(event) => setUpdateRequirement({ ...updateRequirement, deliverySchedule: event.target.value })} className="form-control"></textarea>
                        {validator.message("deliverySchedule", deliverySchedule, "required", {
                            messages: {
                                required: "Delivery Schedule is required"
                            }
                        })}
                    </div>
                    <div className="col-6">
                        <label>Rejection Tollerence Limit, If Any</label>
                        <textarea name="rejectionLimit" value={rejectionLimit} onChange={(event) => setUpdateRequirement({ ...updateRequirement, rejectionLimit: event.target.value })} className="form-control"></textarea>
                    </div>
                </div>
                <div className="add_new_requirement_button">
                    <button onClick={() => updatePurchaseOrder()}>Update</button>
                    <button className="ms-3" onClick={() => setUpdatePo({ isShowModal: false })}>Cancel</button>
                </div>
            </div>
        </div >
    );
}
export default UpdatePurchaseOrder;