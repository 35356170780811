import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useLocation, useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import swal from "sweetalert";

const TransferedMaterialReceipt = () => {
    const [receiptData, setReceiptData] = useState({});
    const componentRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const transferId = queryParams.get('transferId');

    //Fetch Material Transfer Details by id
    const fetchReceipt = async () => {
        try {
            const responseFromServer = await communication.getTransferMaterialReceipt(transferId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setReceiptData(responseFromServer?.data?.issueMaterial);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "error" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchReceipt();
    }, []);

    return (
        <div className="receipt_main">
            <div className="print_button">
                <ReactToPrint trigger={() => <i className="fa-solid fa-print" title="Print"></i>} content={() => componentRef.current} />
            </div>

            <div className="receipt_section" ref={componentRef}>
                <h5 className="heading"><b>M/S. HPS CONSTRUCTION</b></h5>
                <h5 className="secondary_heading">Flat NO. S5, 206/5, Palm Road, Civil Lines, Nagpur-440001</h5>
                <h5 className="secondary_heading">Transfered Material Receipt</h5>
                <div className="row mt-5">
                    <div className="col-6 receipt_info_left">
                        <h5><b>Issue Ref No:</b> <span>{receiptData?.issueRefNo}</span></h5>
                        <h5 className="mt-2 mb-2"><b>To Location:</b> <span>{receiptData?.toLocation}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Transporter Name:</b> <span>{receiptData?.transportName}</span></h5>
                    </div>
                    <div className="col-6 receipt_info_right">
                        <h5><b>Date:</b> <span>{receiptData?.TransferDate}</span></h5>
                        <h5 className="mt-2 mb-2"><b>To Site:</b> <span>{receiptData?.fromSite}</span></h5>
                        <h5 className="mt-2 mb-2"><b>Vehicle No:</b> <span>{receiptData?.vehicleNumber}</span></h5>
                    </div>
                </div>
                <table className="goods_receipt_note">
                    <thead>
                        <tr className="issue_receipt">
                            <th>Sr. No.</th>
                            <th>Material Name</th>
                            <th>Brand</th>
                            <th>Unit</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="issue_receipt">
                            <td>1</td>
                            <td>{receiptData?.materialName}</td>
                            <td>{receiptData?.brand}</td>
                            <td>{receiptData?.unitName}</td>
                            <td>{receiptData?.QuantityToTransfer}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="row authorities">
                    <div className="col-6">
                        <h5><b>Transfered By</b></h5>
                    </div>
                    <div className="col-6">
                        <h5><b>Authorized By</b></h5>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TransferedMaterialReceipt;