import axios from 'axios';


const developmentURL = "http://localhost:3005";
const serverURL = "https://hpsbackend.allindianitservices.com";

function getCookie() {
    // Split cookie string and get all individual name=value pairs in an array
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");

        if ("hpsUserToken" === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    // Return null if not found
    return null;
}



function getServerUrl() {

    return process.env.REACT_APP_BACKEND_URL;
}

export const communication = {

    //?---------------------------------Login API-------------------------------------------------------------------------
    login: (email, password) => {
        return axios.post(`${getServerUrl()}/user-management/login`, { email, password }, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    },
    //?---------------------------------Dashboard APIs-------------------------------------------------------------------
    machineData: (locationId, startDate, endDate) => {
        return axios.post(`${getServerUrl()}/dashboard/get-material-received-details`, { locationId, startDate, endDate }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //? --------------------------------designation APIs------------------------------------------------------------------
    addDesignation: (designationName) => {
        return axios.post(`${getServerUrl()}/designation-management/add-designation`, { designationName }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getDesignation: () => {
        return axios.get(`${getServerUrl()}/designation-management/fetch-designation`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //Designation list for designation tab
    getDesignationList: (page) => {
        return axios.get(`${getServerUrl()}/designation-management/get-designation-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateDesignation: (designation) => {
        return axios.post(`${getServerUrl()}/designation-management/update-designation`, designation, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deleteDesignation: (designationId) => {
        return axios.post(`${getServerUrl()}/designation-management/delete-designation`, { designationId: designationId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    //? -------------------------------------Location APIs--------------------------------------------------------------------
    addLocation: (locationName) => {
        return axios.post(`${getServerUrl()}/site-management/add-location`, { locationName: locationName }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAllLocations: () => {
        return axios.get(`${getServerUrl()}/site-management/fetch-location`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //get location list for site management tab
    getAllLocationsList: (page) => {
        return axios.get(`${getServerUrl()}/site-management/get-location-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateLocation: (locationName, locationId) => {
        return axios.post(`${getServerUrl()}/site-management/update-location`, { locationName: locationName, locationId: locationId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getLocationWiseSites: (locationId) => {
        return axios.get(`${getServerUrl()}/site-management/fetch-location-wise-sites?locationId=${locationId}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deleteLocation: (locationId) => {
        return axios.post(`${getServerUrl()}/site-management/delete-location`, { locationId: locationId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    //?------------------------------------------Site APIs--------------------------------------------------------------
    addSite: (locationId, siteName) => {
        return axios.post(`${getServerUrl()}/site-management/add-site`, { locationId, siteName }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateSite: (siteName, siteId) => {
        return axios.post(`${getServerUrl()}/site-management/update-site`, { siteName, siteId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deleteSite: (siteId) => {
        return axios.post(`${getServerUrl()}/site-management/delete-site`, { siteId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    //? -------------------------------User Management APIs----------------------------------------------------------------
    addUser: (name, email, password, designationName, designationId, licenseNo) => {
        return axios.post(`${getServerUrl()}/user-management/add-user`, { name, email, password, designationName, designationId, licenseNo }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAllUser: () => {
        return axios.get(`${getServerUrl()}/user-management/fetch-all-user`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //get user list for User Management tab
    getAllUserList: (page) => {
        return axios.get(`${getServerUrl()}/user-management/get-user-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getSiteWiseUser: (siteId) => {
        return axios.get(`${getServerUrl()}/user_as_per_site?siteId=${siteId}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateUser: (userId, name, email, designationName, designationId, licenseNo) => {
        return axios.post(`${getServerUrl()}/user-management/update-user`, { userId, name, email, designationName, designationId, licenseNo }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    changeUserPassword: (userId, password) => {
        return axios.post(`${getServerUrl()}/user-management/change-password`, { userId, password }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deleteUser: (userID) => {
        return axios.post(`${getServerUrl()}/user-management/delete-user`, { userID }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    addAccessControl: (userId, accessControl) => {
        return axios.post(`${getServerUrl()}/user-management/add-access-control`, { userId, accessControl }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    fetchAllDrivers: () => {
        return axios.get(`${getServerUrl()}/user-management/fetch-all-drivers`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    fetchSiteSupervisor: (siteId) => {
        return axios.get(`${getServerUrl()}/user-management/fetch-sitewise-supervisors?siteId=${siteId}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //?----------------------------------------------Category APIs---------------------------------------------------------------------------
    addCategory: (categoryName) => {
        return axios.post(`${getServerUrl()}/material-management/add-category`, { categoryName }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAllCategory: () => {
        return axios.get(`${getServerUrl()}/material-management/fetch-category`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //get all category list for category management tab
    getAllCategoryList: (page) => {
        return axios.get(`${getServerUrl()}/material-management/get-category-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateCategory: (categoryId, categoryName) => {
        return axios.post(`${getServerUrl()}/material-management/update-category`, { categoryId, categoryName }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deleteCategory: (categoryId) => {
        return axios.post(`${getServerUrl()}/material-management/delete-category`, { categoryId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    //?--------------------------------------------------------------UNIT APIS--------------------------------------------------------------
    addUnit: (unitName) => {
        return axios.post(`${getServerUrl()}/material-management/add-unit`, { unitName }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateUnit: (unitId, unitName) => {
        return axios.post(`${getServerUrl()}/material-management/update-unit`, { unitId, unitName }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deleteUnit: (unitId) => {
        return axios.post(`${getServerUrl()}/material-management/delete-unit`, { unitId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAllUnits: () => {
        return axios.get(`${getServerUrl()}/material-management/fetch-units`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //get all unit list for unit management tab
    getAllUnitsList: (page) => {
        return axios.get(`${getServerUrl()}/material-management/get-unit-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    //?----------------------------------------------Material APIs-------------------------------------------------------------------------
    addMaterial: (materialName, unitId, categoryId) => {
        return axios.post(`${getServerUrl()}/material-management/add-material`, { materialName, unitId, categoryId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateMaterial: (materialId, materialName, categoryId, unitId) => {
        return axios.post(`${getServerUrl()}/material-management/update-material`, { materialId, materialName, categoryId, unitId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deleteMaterial: (materialId) => {
        return axios.post(`${getServerUrl()}/material-management/delete-material`, { materialId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAllMaterial: () => {
        return axios.get(`${getServerUrl()}/material-management/fetch-materials`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //get all material list for material management tab
    getAllMaterialList: (page) => {
        return axios.get(`${getServerUrl()}/material-management/get-material-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getMaterialByCategory: (categoryId) => {
        return axios.post(`${getServerUrl()}/material-management/fetch-category-wise-materials`, { categoryId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAvailableMaterialQuantityAccordingToLocation: (materialId, locationId) => {
        return axios.post(`${getServerUrl()}/material_available_quantity_location`, { materialId, locationId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getMaterialAvailableQuantity: (materialId, locationId) => {
        return axios.post(`${getServerUrl()}/material_available_quantity`, { materialId, locationId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    //?--------------------------------------------------Machine Management APIs----------------------------------------------------------
    addMachine: (machineName, registrationNo, driverId, siteId, locationId, cur_hired_by) => {
        return axios.post(`${getServerUrl()}/machine-management/add-machine`, { machineName, registrationNo, driverId, siteId, locationId, cur_hired_by }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    fetchAllMachines: () => {
        return axios.get(`${getServerUrl()}/machine-management/fetch-machines`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //fetch machine list api for machine management tab
    fetchAllMachinesList: (page) => {
        return axios.get(`${getServerUrl()}/machine-management/fetch-machines-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deleteMachine: (machineId) => {
        return axios.post(`${getServerUrl()}/machine-management/delete-machine`, { machineId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateMachine: (machineName, machineId, registrationNo, locationName, locationId, siteName, siteId, driver, driverId, cur_hired_by) => {
        return axios.post(`${getServerUrl()}/machine-management/update-machine`, { machineName, machineId, registrationNo, locationName, locationId, siteName, siteId, driver, driverId, cur_hired_by }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    machineDetailById: (machineId) => {
        return axios.post(`${getServerUrl()}/machine-management/get-machine-details-by-id`, { machineId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    machineLogsById: (machineId) => {
        return axios.post(`${getServerUrl()}/machine-management/get-machine-logs-by-id`, { machineId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    //?------------------------------------------------Sub-Contractor Management------------------------------------------------------------
    addSubContractor: (subContractorName, locationName, locationId, siteName, siteId) => {
        return axios.post(`${getServerUrl()}/sub-contractor/add-sub-contractor`, { subContractorName, locationName, locationId, siteName, siteId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateSubContractor: (subContractorId, subContractorName, locationName, locationId, siteName, siteId) => {
        return axios.post(`${getServerUrl()}/sub-contractor/update-sub-contractor`, { subContractorId, subContractorName, locationName, locationId, siteName, siteId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deleteSubContractor: (subContractorId) => {
        return axios.post(`${getServerUrl()}/sub-contractor/delete-sub-contractor`, { subContractorId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    fetchSiteWiseSubContractors: (siteId) => {
        return axios.post(`${getServerUrl()}/sub-contractor/fetch-site-wise-sub-contractor`, { siteId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    fetchSubContractors: () => {
        return axios.get(`${getServerUrl()}/sub-contractor/fetch-sub-contractor`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    fetchSubContractorsList: (page) => {
        return axios.get(`${getServerUrl()}/sub-contractor/fetch-sub-contractor-list?page-=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    //?------------------------------------------------Inventory Management APIs-----------------------------------------------
    fetchInventoryAllMaterial: (searchData) => {
        return axios.post(`${getServerUrl()}/inventory_all_materials`, { searchData }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getBrandByMaterial: (materialId) => {
        return axios.post(`${getServerUrl()}/inventory/get-brand-by-material`, { materialId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    getStockByMaterial: (materialId) => {
        return axios.post(`${getServerUrl()}/inventory/available-stock-by-material`, { materialId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAvailableStockByBrand: (materialId, brand) => {
        return axios.post(`${getServerUrl()}/inventory/available-stock-by-brand`, { materialId, brand }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    fetchGeneralPO: () => {
        return axios.get(`${getServerUrl()}/inventory/get-general-purchase-orders`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //fetch general po list for inventory general po tab
    fetchGeneralPOList: (page) => {
        return axios.get(`${getServerUrl()}/inventory/fetch-general-purchase-order-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    fetchLivePO: () => {
        return axios.get(`${getServerUrl()}/inventory/get-live-purchase-orders`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //fetch live po list for Inventory live po tab
    fetchLivePOList: (page) => {
        return axios.get(`${getServerUrl()}/inventory/fetch-live-purchase-order-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    markLivePOAsComplete: (poId) => {
        return axios.post(`${getServerUrl()}/inventory/complete-purchase-order`, { poId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAllAvailableMaterial: () => {
        return axios.get(`${getServerUrl()}/inventory/get-inventory-available-materials`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //get all available material list for All Material Tab
    getAllAvailableMaterialList: (page) => {
        return axios.get(`${getServerUrl()}/inventory/fetch-inventory-material-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAllReceivedMaterial: () => {
        return axios.get(`${getServerUrl()}/inventory/get-received-material`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //get all received material for Inventory received material tab
    getAllReceivedMaterialList: (page) => {
        return axios.get(`${getServerUrl()}/inventory/fetch-received-material-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    exportReceivedMaterialList: async () => {
        return axios.get(`${getServerUrl()}/inventory/export-received-material-list`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            },
            responseType: "blob"
        });
    },
    //get all received material By location, startDate and endDate Inventory received material tab
    getAllReceivedMaterialListByLocationAndDateFilter: (locationId, startDate, endDate, page) => {
        return axios.post(`${getServerUrl()}/inventory/search-received-material-by-date-filter`, { locationId, startDate, endDate, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAllMaterialIssueReportList: (page) => {
        return axios.get(`${getServerUrl()}/inventory/fetch-material-issue-reports-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    exportMaterialIssueReportList: async () => {
        return axios.get(`${getServerUrl()}/inventory/export-material-issue-report-list`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            },
            responseType: "blob"
        });
    },
    searchMaterialIssueReport: (locationId, siteId, startDate, endDate, page) => {
        return axios.post(`${getServerUrl()}/inventory/search-material-issue-report-list`, { locationId, siteId, startDate, endDate, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchMaterialTransferReport: async (toLocationId, toSiteId, startDate, endDate, page) => {
        return axios.post(`${getServerUrl()}/inventory/search-material-transport-report-list`, { toLocationId, toSiteId, startDate, endDate, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    exportMaterialTransportReportList: async () => {
        return axios.get(`${getServerUrl()}/inventory/export-material-transport-report-list`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            },
            responseType: "blob"
        });
    },

    fetchAvailableMaterialByLocation: (locationId) => {
        return axios.post(`${getServerUrl()}/inventory/available-materials-according-location`, { locationId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    uploadGrn: (grnDetails) => {
        return axios.post(`${getServerUrl()}/inventory/upload-grn`, grnDetails, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateGrnById: (grnDetails) => {
        return axios.post(`${getServerUrl()}/inventory/update-grn`, grnDetails, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getGrn: (poId, poNumber) => {
        return axios.post(`${getServerUrl()}/inventory/get-grn`, { poId, poNumber }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getGrnByGrnId: (grnId) => {
        return axios.post(`${getServerUrl()}/inventory/get-grn-by-grnId`, { grnId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getSelectedVendor: (poId) => {
        return axios.post(`${getServerUrl()}/inventory/get-selected-vendor`, { poId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    addPurchaseIndent: (dataToSend) => {
        return axios.post(`${getServerUrl()}/inventory/create-purchase-indent`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getPurchaseIndents: () => {
        return axios.get(`${getServerUrl()}/inventory/get-purchase-indent`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //get all purchase Indent list for new purchase indent tab
    getPurchaseIndentList: (page) => {
        return axios.get(`${getServerUrl()}/inventory/get-purchase-indent-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updatePurchaseIndent: (dataToSend) => {
        return axios.post(`${getServerUrl()}/inventory/update-purchase-indent`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    issueMaterial: (materialDetails) => {
        return axios.post(`${getServerUrl()}/inventory/material-issue`, materialDetails, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    updateIssueMaterial: (materials) => {
        return axios.post(`${getServerUrl()}/inventory/update-material-issue`, materials, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    transferMaterial: (materialDetails) => {
        return axios.post(`${getServerUrl()}/inventory/material-transfer`, materialDetails, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getTransferMaterial: () => {
        return axios.get(`${getServerUrl()}/inventory/get-material-transfer`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //get all transfer material list for inventory transfer material tab
    getTransferMaterialList: (page) => {
        return axios.get(`${getServerUrl()}/inventory/fetch-material-transfer-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    exportMaterialTransferList: async () => {
        return axios.get(`${getServerUrl()}/inventory/export-material-transfer-list`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            },
            responseType: "blob"
        });
    },
    //get all transfer material list by location and site
    locationAndSiteWiseTransferedMaterialList: (toLocationId, toSiteId, page) => {
        return axios.post(`${getServerUrl()}/inventory/get-location-and-site-wise-transfer-material`, { toLocationId, toSiteId, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getIssueMaterial: () => {
        return axios.get(`${getServerUrl()}/inventory/get-material-issue`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //get material issue list for inventory material issuing tab
    getIssueMaterialList: (page) => {
        return axios.get(`${getServerUrl()}/inventory/fetch-material-issue-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    exportMaterialIssueList: async () => {
        return axios.get(`${getServerUrl()}/inventory/export-material-issue-list`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            },
            responseType: "blob"
        });
    },
    //get material issue list by location and site
    locationAndSiteWiseIssuedMaterialList: (locationId, siteId, page) => {
        return axios.post(`${getServerUrl()}/inventory/get-location-wise-site-for-material-issue`, { locationId, siteId, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getMaterialIssueReport: () => {
        return axios.get(`${getServerUrl()}/inventory/get-material-issue-reports`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getMaterialTransferReport: (page) => {
        return axios.get(`${getServerUrl()}/inventory/fetch-material-transfer-report-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    fetchMaterialTransferReportByDate: (dateFilter) => {
        return axios.post(`${getServerUrl()}/inventory/get-transfer-reports-by-date`, dateFilter, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deleteIssueMaterial: (issueId, issueNumber) => {
        return axios.post(`${getServerUrl()}/inventory/delete-material-issue`, { issueId, issueNumber }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deleteTransferMaterial: (transferId) => {
        return axios.post(`${getServerUrl()}/inventory/delete-material-transfer`, { transferId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getIssueMaterialReceipt: (issueId) => {
        return axios.post(`${getServerUrl()}/inventory/get-material-issue-by-id`, { issueId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getTransferMaterialReceipt: (transferId) => {
        return axios.post(`${getServerUrl()}/inventory/get-material-transfer-by-id`, { transferId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getReceivedMaterialReceipt: (receivedMaterialId) => {
        return axios.post(`${getServerUrl()}/inventory/get-material-receive-by-id`, { receivedMaterialId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getLocationAndSiteWiseIssueNo: (locationId, siteId) => {
        return axios.post(`${getServerUrl()}/inventory/get-material-issue-by-site`, { locationId, siteId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //?---------------------------------------------------------------Procurement APIs---------------------------------------------------------
    addNewRequirement: (newRequirement) => {
        return axios.post(`${getServerUrl()}/procurement/add-purchase-order`, newRequirement, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    fetchPurchaseOrder: () => {
        return axios.get(`${getServerUrl()}/procurement/fetch-purchase-orders`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //fetch purchase order list for total requirement tab
    fetchPurchaseOrderList: (page) => {
        return axios.get(`${getServerUrl()}/procurement/fetch-purchase-orders-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    cancelPurchaseOrder: (poId) => {
        return axios.post(`${getServerUrl()}/procurement/cancel-purchase-order`, { poId: poId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getPurchaseOrderByIdAndPoNumber: (poId, poNumber) => {
        return axios.post(`${getServerUrl()}/procurement/fetch-purchase-orders-by-po-number-and-id`, { poId, poNumber }, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    },
    updatePurchaseOrder: (updatedPo) => {
        return axios.post(`${getServerUrl()}/procurement/update-purchase-order`, updatedPo, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deletePurchaseOrder: (poId) => {
        return axios.post(`${getServerUrl()}/procurement/delete-purchase-order`, { poId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deletePurchaseIndent: (purchaseIndentId) => {
        return axios.post(`${getServerUrl()}/procurement/delete-purchase-indent`, { purchaseIndentId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    fetchUploadedQuotations: (poNumber, poId) => {
        return axios.post(`${getServerUrl()}/procurement/fetch-quotation-single-po`, { poNumber, poId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    uploadedQuotations: (dataToSend) => {
        return axios.post(`${getServerUrl()}/procurement/upload-quotation`, dataToSend, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getQuotationById: (quotationId) => {
        return axios.post(`${getServerUrl()}/procurement/fetch-quotation-by-id`, { quotationId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    deleteQuotation: (quotationId) => {
        return axios.post(`${getServerUrl()}/procurement/delete-quotation`, { quotationId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    approvalQuotation: (poNumber, poId, billId, user) => {
        return axios.post(`${getServerUrl()}/procurement/approval-quotation`, { poNumber, poId, billId, user }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    approveQuotation: (poNumber, poId, billId, user) => {
        return axios.post(`${getServerUrl()}/procurement/approved-quotation`, { poNumber, poId, billId, user }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getApprovalQuotation: (poNumber, poId) => {
        return axios.post(`${getServerUrl()}/procurement/get-approval-quotations-by-po`, { poNumber, poId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getSelectedQuotation: (poNumber, poId) => {
        return axios.post(`${getServerUrl()}/procurement/get-selected-quotations-by-po`, { poNumber, poId }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    getAllApprovalPO: () => {
        return axios.get(`${getServerUrl()}/procurement/get-approval-purchase-orders`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    //get All approved po list for All Approvals Tab
    getAllApprovalPOList: (page) => {
        return axios.get(`${getServerUrl()}/procurement/fetch-approval-purchase-order-list?page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    approvePurchaseIndent: (purchaseIndents) => {
        return axios.post(`${getServerUrl()}/procurement/approved-purchase-indent`, { purchaseIndents }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },

    //Search API'S
    searchDesignations: (searchString, page) => {
        return axios.post(`${getServerUrl()}/designation-management/search-designation`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchLocations: (searchString, page) => {
        return axios.post(`${getServerUrl()}/site-management/search-location`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchUsers: (searchString, page) => {
        return axios.post(`${getServerUrl()}/user-management/search-user`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchMaterials: (searchString, page) => {
        return axios.post(`${getServerUrl()}/material-management/search-material`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchUnits: (searchString, page) => {
        return axios.post(`${getServerUrl()}/material-management/search-unit`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchCategory: (searchString, page) => {
        return axios.post(`${getServerUrl()}/material-management/search-category`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchMachines: (searchString, page) => {
        return axios.post(`${getServerUrl()}/machine-management/search-machine`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchSubContractors: (searchString, page) => {
        return axios.post(`${getServerUrl()}/sub-contractor/search-sub-contractor`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchPurchaseOrders: (searchString, page) => {
        return axios.post(`${getServerUrl()}/procurement/search-purchase-order`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchAllApprovedPoList: (searchString, page) => {
        return axios.post(`${getServerUrl()}/procurement/search-approval-purchase-order`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchIndentList: (searchString, page) => {
        return axios.post(`${getServerUrl()}/inventory/search-purchase-indent`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchInventoryAllMaterials: (searchString, page) => {
        return axios.post(`${getServerUrl()}/inventory/search-inventory-matrials`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchInventoryIssuedMaterial: (searchString, page) => {
        return axios.post(`${getServerUrl()}/inventory/search-material-issue-details`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchInventoryTransferedMaterial: (searchString, page) => {
        return axios.post(`${getServerUrl()}/inventory/search-material-transfer-list`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchInventoryReceivedMaterial: (searchString, page) => {
        return axios.post(`${getServerUrl()}/inventory/search-received-material`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchLivePurchaseOrders: (searchString, page) => {
        return axios.post(`${getServerUrl()}/inventory/search-live-purchase-orders`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    searchGeneralPurchaseOrders: (searchString, page) => {
        return axios.post(`${getServerUrl()}/inventory/search-general-purchase-order`, { searchString, page }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getCookie()}`
            }
        });
    },
    previewGrnFile: (fileUrl) => {
        return axios.get(`${getServerUrl()}/inventory/get-file?fileUrl=${fileUrl}`, {
            headers: {
                "Content-Type": "application/pdf",
                "Authorization": `Bearer ${getCookie()}`
            },
            responseType: "blob"
        });
    },

}
