import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import ApproveQuotation from "./ApproveQuotation";
import swal from "sweetalert";
import Loader from "../../Modals/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import { useDispatch, useSelector } from 'react-redux';


const AllApprovalDetails = ({ selectedPurchaseOrder }) => {
    const navigate = useNavigate();
    const [toggleDetailSections, setToggleDetailSections] = useState({ requirementDetail: false, quotationApprove: false });
    const [uploadedQuotation, setUploadedQuotation] = useState([]);
    const [approveQuotation, setApproveQuotation] = useState([]);
    const loader = useSelector((state) => state.loader);



    //get all uploaded Quotations for selected Purchase Order
    const getUploadedQuotations = async () => {
        const serverResponse = await communication.fetchUploadedQuotations(selectedPurchaseOrder?.poNumber, selectedPurchaseOrder?._id);
        if (serverResponse.data.status === "SUCCESS") {
            setUploadedQuotation(serverResponse?.data?.Quotations)
        } else if (serverResponse?.data?.status === "JWT_INVALID") {
            swal({ text: serverResponse.data?.message, icon: "error" });
            navigate("/login");
        } else {
            setUploadedQuotation([]);
        }
    }

    //get all uploaded Quotations for selected Purchase Order
    const fetchSelectedQuotation = async () => {
        const serverResponse = await communication.getSelectedQuotation(selectedPurchaseOrder?.poNumber, selectedPurchaseOrder?._id);
        if (serverResponse.data.status === "SUCCESS") {
            setApproveQuotation(serverResponse?.data?.quotations)
        } else if (serverResponse?.data?.status === "JWT_INVALID") {
            swal({ text: serverResponse.data?.message, icon: "error" });
            navigate("/login");
        } else {
            setApproveQuotation([]);
        }
    }

    useEffect(() => {
        getUploadedQuotations();
    }, [selectedPurchaseOrder])


    useEffect(() => {
        fetchSelectedQuotation();
    }, [selectedPurchaseOrder, toggleDetailSections.quotationApprove]);

    return (
        <>
        {
            loader.showHideLoader === true ?
            <Loader />
            :
            <div className="approval_detail_section">
                <div className="row p-2">
                    <div className="col-12">
                        <h5>Requirement - {selectedPurchaseOrder?.poNumber}</h5>
                    </div>
                </div>
                <div className="requirement_details_section">
                    <h5>Requirement Details</h5>
                    <i className="fa-solid fa-caret-down" onClick={() => setToggleDetailSections({ ...toggleDetailSections, requirementDetail: !toggleDetailSections.requirementDetail })}></i>
                    {toggleDetailSections.requirementDetail &&
                        <div className="requirement_detail">
                            <div className="quotation_details">
                                <div className="detail">
                                    <div className="table_header">
                                        <div className="row m-0">
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Material</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Category</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Quantity</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Unit</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Location</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Site</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table_data">
                                        <div className="row m-0">
                                            <div className="col-lg-2 col-md-2">
                                                <h5>{selectedPurchaseOrder?.materialName}</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>{selectedPurchaseOrder?.categoryName}</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                {selectedPurchaseOrder?.unlimitedQuantity ?
                                                    <h5>Unlimited</h5>
                                                    :
                                                    <h5>{selectedPurchaseOrder?.quantityRequired}</h5>
                                                }
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>{selectedPurchaseOrder?.unitName}</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>{selectedPurchaseOrder?.locationName}</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>{selectedPurchaseOrder?.siteName}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-12">
                                    <h5 className="title">Description of Material - <span>{selectedPurchaseOrder?.materialDescription}</span></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="title">Technical Specification - <span>{selectedPurchaseOrder?.materialTechSpecification}</span></h5>
                                </div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-12">
                                    <h5 className="title">Delivery Schedule - <span>{selectedPurchaseOrder?.deliverySchedule}</span></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="title">Rejection Limit - <span>{selectedPurchaseOrder?.rejectionLimit}</span></h5>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="uploaded_quotations_section">
                    <h5>Uploaded Quotations </h5>
                    <i className="fa-solid fa-caret-down" onClick={() => setToggleDetailSections({ ...toggleDetailSections, uploadedQuotationDetail: !toggleDetailSections.uploadedQuotationDetail })}></i>
                    <div className="quotation_approval_button">
                        {toggleDetailSections.uploadedQuotationDetail &&
                            <div>
                                <button onClick={() => setToggleDetailSections({ ...toggleDetailSections, quotationApprove: !toggleDetailSections.quotationApprove })}>Quotations Approval</button>
                                <div className="quotation_details">
                                    <div className="detail">
                                        <div className="table_header">
                                            <div className="row m-0">
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>Sr. No.</h5>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <h5>Name of Vendor</h5>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <h5>Date of Submission</h5>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>Action</h5>
                                                </div>
                                            </div>
                                        </div>
                                        {uploadedQuotation?.map((quotation, index) => {
                                            return (
                                                <div className="table_data" key={index}>
                                                    <div className="row m-0">
                                                        <div className="col-lg-2 col-md-2 approve_checkbox">
                                                            {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                            <h5>{index + 1}</h5>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4">
                                                            <h5>{quotation?.vendor?.venderName}</h5>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4">
                                                            <h5>{quotation?.submissionDate}</h5>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div className="action">
                                                                <NavLink to={`/purchase-quotation?quotationId=${quotation?._id}`} target="_blank">
                                                                    <i className="fa-solid fa-file-lines" title="Quotation Receipt"></i>
                                                                </NavLink>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="approved_quotations_section">
                    <h5>Approved Quotations </h5>
                    <i className="fa-solid fa-caret-down" onClick={() => setToggleDetailSections({ ...toggleDetailSections, approveQuotationDetail: !toggleDetailSections.approveQuotationDetail })}></i>
                    {toggleDetailSections.approveQuotationDetail &&
                        <div className="quotation_details">
                            <div className="detail">
                                <div className="table_header">
                                    <div className="row m-0">
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Vender</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Approved by</h5>
                                        </div>
                                        <div className="col-lg-8 col-md-8">
                                            <div className="row m-0">
                                                <div className="col-lg-3 col-md-3" style={{ textAlign: "left" }}>
                                                    <h5>Material</h5>
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <h5>Category</h5>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>Brand</h5>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>Rate</h5>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>Quantity</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {approveQuotation?.length > 0 ?
                                    approveQuotation.map((quotation, index) => {
                                        return (
                                            <div className="table_data" key={index}>
                                                <div className="row m-0">
                                                    <div className="col-lg-2 col-md-2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <h5>{quotation?.vendor?.venderName}</h5>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <h5 className="mt-2 mb-2">{quotation?.approveBy.userName}({quotation?.approveBy.designation})</h5>
                                                    </div>
                                                    <div className="col-lg-8 col-md-8">
                                                        {quotation?.bill?.map((material, index) => {
                                                            return (

                                                                <div className="row m-0" key={index}>
                                                                    {
                                                                        material?.isSelected ?
                                                                            <>
                                                                                <div className="col-lg-3 col-md-3">
                                                                                    <div className="approve_checkbox mt-2 mb-2">
                                                                                        {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                                        <h5>{material?.materialName}</h5>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-3">
                                                                                    <h5 className="mt-2 mb-2">{material?.categoryName}</h5>
                                                                                </div>
                                                                                <div className="col-lg-2 col-md-2">
                                                                                    <h5 className="mt-2 mb-2">{material?.brand}</h5>
                                                                                </div>
                                                                                <div className="col-lg-2 col-md-2">
                                                                                    <h5 className="mt-2 mb-2">{material?.rate}</h5>
                                                                                </div>
                                                                                <div className="col-lg-2 col-md-2">
                                                                                    {material?.unlimitedQuantity ? <h5 className="mt-2 mb-2">Unlimited</h5> :
                                                                                        <h5 className="mt-2 mb-2">{material?.approxQty}</h5>
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div>

                                                            )

                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="data_not_available_message">
                                        <h5>Quotation Not Approve for this PO</h5>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
                {/*-----Quotation Approve-----*/}
                {
                    toggleDetailSections.quotationApprove &&
                    <div className="modal_box">
                        <ApproveQuotation setToggleDetailSections={setToggleDetailSections} uploadedQuotation={uploadedQuotation} />
                    </div>
                }
            </div>
        }
        </>
    );
}
export default AllApprovalDetails;