import React from "react";
import { hideAlertModal } from "../redux-store/alertMessageReducer";
import { useDispatch, useSelector } from 'react-redux';

const Modal = () => {
    const dispatch = useDispatch();
    const alertModal = useSelector((state) => state.alertModal);
    return (
        <div className="modal_main">
            <div className="modal_header"></div>
            <div className="modal_body">
                <h5>{alertModal.alertMessage}</h5>
                <button onClick={() => dispatch(hideAlertModal())}>OK</button>
            </div>
        </div>
    );
}
export default Modal;