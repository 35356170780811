import React, { useRef, useState } from "react";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const AddLocation = ({ setShowAddLocationForm, siteId, fetchLocation }) => {
    const [validator, showValidationMessage] = useValidator();
    const [locationName, setLocationName] = useState();
    const navigate = useNavigate();

    const addNewLocation = async () => {
        try {

            //check location is entered or not
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            //send request to backend
            const serverResponse = await communication.addLocation(locationName);
            if (serverResponse.data.status === "SUCCESS") {
                swal({ text: serverResponse.data?.message, icon: "success" });
                setShowAddLocationForm(false);
                fetchLocation();
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <div className="add_new_location">
            <div className="form">
                <div className="form_heading">
                    <h5>Add Location</h5>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="input_group">
                            <input type="text" name="location" onChange={(event) => setLocationName(event.target.value)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Enter Location</label>
                        </div>
                        {validator.message("location", locationName, "required|alpha_space", {
                            messages: {
                                required: "Location is required"
                            }
                        })}
                    </div>
                </div>
                <div className="form_button">
                    <button onClick={() => { addNewLocation() }}>Add</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowAddLocationForm(false)}></i>
                </div>
            </div>
        </div>
    );
}
export default AddLocation;