import React, { useEffect, useMemo, useState } from "react";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const UpdateSubContractor = ({ updateContractor, setUpdateContractor, fetchAllSubContractors }) => {
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [location, setLocation] = useState([]);
    const [locationWiseSites, setlocationWiseSites] = useState([])
    const [contractor, setContractor] = useState(updateContractor);

    const { subContractorName, locationName, locationId, siteName, siteId } = contractor;

    //Get Location wise Sites 
    const locationWiseSite = async (locationId) => {
        try {
            const responseFromServer = await communication.getLocationWiseSites(locationId);
            if (responseFromServer.data.status === "SUCCESS") {
                setlocationWiseSites(responseFromServer.data.sites);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Get all Locations
    const fetchLocations = async () => {
        try {
            const responseFromServer = await communication.getAllLocations();
            if (responseFromServer.data.status === "SUCCESS") {
                setLocation(responseFromServer.data.locations);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                setLocation([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useMemo(() => {
        locationWiseSite(locationId);
    }, [locationId]);

    useEffect(() => {
        fetchLocations();
    }, []);

    //update SubContractor
    const updateSubContractor = async (subContractorId) => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            const serverResponse = await communication.updateSubContractor(subContractorId, subContractorName, locationName, locationId, siteName, siteId);
            if (serverResponse.data.status === "SUCCESS") {
                setUpdateContractor({ isShowModal: false });
                fetchAllSubContractors();
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                swal({ text: serverResponse.data?.message, icon: "error" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <div className="add_new_material">
            <div className="form">
                <div className="form_heading">
                    <h5>Update Sub-Contractor</h5>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="input_group">
                            <input type="text" name="subContractorName" value={subContractorName} onChange={(event) => setContractor({ ...contractor, subContractorName: event.target.value })} className="form-control" autoComplete="off" required />
                            <label className="input_label">Sub-Contractor Name</label>
                        </div>
                        {validator.message("subContractorName", subContractorName, "required|alpha Space", {
                            messages: {
                                required: "Sub Contractor Name is required"
                            }
                        })}
                    </div>
                    <div className="col-6">
                        <select name="locationName" onChange={(event) => { setContractor({ ...contractor, locationName: JSON.parse(event.target.value)?.locationName, locationId: JSON.parse(event.target.value)?._id, siteName: "", siteId: "" }); }} className="form-select">
                            {location?.map((location, index) => (
                                <option value={JSON.stringify(location)} selected={location?._id === locationId} key={index}>{location.locationName}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <select name="siteName" onChange={(event) => setContractor({ ...contractor, siteName: JSON.parse(event.target.value)?.siteName, siteId: JSON.parse(event.target.value)?._id })} className="form-select">
                            <option value={JSON.stringify({ siteName: "", _id: "" })}>Select Site</option>
                            {locationWiseSites?.map((site, index) => (
                                <option value={JSON.stringify(site)} selected={site?._id === siteId} key={index}>{site.siteName}</option>
                            ))}
                        </select>
                        {validator.message("siteName", siteName, "required", {
                            messages: {
                                required: "Site is required"
                            }
                        })}
                    </div>
                </div>

                <div className="form_button">
                    <button onClick={() => updateSubContractor(contractor.subContractorId)}>Update</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setUpdateContractor(false)}></i>
                </div>
            </div>
        </div >
    );
}
export default UpdateSubContractor;