import React from 'react'
import { Route, Routes, Navigate } from "react-router-dom";
import { PrivateRoute } from './PrivateRoute';
import MaterialTransferReport from "../Components/inventory/reportsTab/MaterialTransferReport";
import MaterialIssuing from '../Components/inventory/reportsTab/MaterialIssuing';
import GeneralPo from '../Components/inventory/approvedRequirementTabComponents/GeneralPo';
import LivePo from '../Components/inventory/approvedRequirementTabComponents/LivePo';
import TypeCategory from '../Components/materialManagementTabComponents/TypeCategory';
import UnitManagement from '../Components/materialManagementTabComponents/UnitManagement';
import MaterialManagementTab from '../Components/materialManagementTabComponents/MaterialManagementTab';
import DashboardTab from '../Components/DashboardTab';
import DesignationManagement from "../Components/DesignationManagement";
import SiteManagement from '../Components/SiteManagement';
import UserManagement from '../Components/UserManagement'
import MaterialManagement from '../Components/MaterialManagement';
import MachineManagement from '../Components/MachineManagement';
import SubContractorManagement from '../Components/SubContractorManagement';
import TotalRequirements from '../Components/procurement/TotalRequirements';
import AddNewRequirement from '../Components/procurement/AddNewRequirement';
import PurchaseIndent from '../Components/procurement/PurchaseIndent';
import AllApprovals from '../Components/procurement/AllApprovals';
import AllMaterial from '../Components/inventory/AllMaterial';
import MaterailIssuing from '../Components/inventory/MaterialIssuing';
import MaterialTransfer from '../Components/inventory/MaterialTransfer';
import MaterialReceived from '../Components/inventory/MaterialReceived';
import ApprovedRequirements from '../Components/inventory/ApprovedRequirements';
import Reports from '../Components/inventory/Reports';
import MachineDetails from '../Components/MachineDetails';
import SignIn from '../SignIn';
import Dashboard from "../Components/Dashboard";
import QuotationReceipt from '../Components/receipts/QuotationReceipt';
import GrnReceipt from '../Components/receipts/GrnReceipt';
import MaterialIssuingReceipt from '../Components/receipts/MaterialIssuingReceipt';
import ReceivedMaterialReceipt from '../Components/receipts/ReceivedMaterialReceipt';
import VenderLinkForm from '../Components/forms/VenderLinkForm';
import TransferedMaterialReceipt from '../Components/receipts/TransferedMaterialReceipt';

const AdminRouter = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/login" element={<SignIn />} />
                <Route path="/vender-link" element={<VenderLinkForm />} />
                <Route path="/purchase-quotation" element={<QuotationReceipt />}></Route>
                <Route path="/grn-receipt" element={<GrnReceipt />}></Route>
                <Route path="/material-issuing-receipt" element={<MaterialIssuingReceipt />}></Route>
                <Route path="/received-material-receipt" element={<ReceivedMaterialReceipt />}></Route>
                <Route path="/transfered-material-receipt" element={<TransferedMaterialReceipt />}></Route>
                <Route path="/main-dashboard" element={<PrivateRoute><Dashboard ></Dashboard></PrivateRoute>} >
                    <Route path="/main-dashboard/" element={<DashboardTab />}></Route>
                    <Route path="/main-dashboard/dashboard" element={<DashboardTab />}></Route>
                    <Route path="/main-dashboard/designation-management" element={<DesignationManagement />}></Route>
                    <Route path="/main-dashboard/user-management" element={<UserManagement />}></Route>
                    <Route path="/main-dashboard/site-management" element={<SiteManagement />}></Route>
                    <Route path="/main-dashboard/machine-management" element={<MachineManagement />}></Route>
                    <Route path="/main-dashboard/machine-management/machine-details" element={<MachineDetails />}></Route>
                    <Route path="/main-dashboard/sub-contractor-management" element={<SubContractorManagement />}></Route>
                    <Route path="/main-dashboard/procurement/total-requirement" element={<TotalRequirements />}></Route>
                    <Route path="/main-dashboard/procurement/add-new-requirement" element={<AddNewRequirement />}></Route>
                    <Route path="/main-dashboard/procurement/purchase-indent" element={<PurchaseIndent />}></Route>
                    <Route path="/main-dashboard/procurement/all-approvals" element={<AllApprovals />}></Route>
                    <Route path="/main-dashboard/inventory/all-material" element={<AllMaterial />}></Route>
                    <Route path="/main-dashboard/inventory/material-issuing" element={<MaterailIssuing />}></Route>
                    <Route path="/main-dashboard/inventory/material-transfer" element={<MaterialTransfer />}></Route>
                    <Route path="/main-dashboard/inventory/material-received" element={<MaterialReceived />}></Route>
                    <Route path="/main-dashboard/inventory/approved-requirements" element={<ApprovedRequirements />}>
                        <Route exact path="/main-dashboard/inventory/approved-requirements/" element={<Navigate to="/main-dashboard/inventory/approved-requirements/general-po" replace />}></Route>
                        <Route exact path="/main-dashboard/inventory/approved-requirements/general-po" element={<GeneralPo />}></Route>
                        <Route exact path="/main-dashboard/inventory/approved-requirements/live-po" element={<LivePo />}></Route>
                    </Route>
                    <Route path="/main-dashboard/material-management" element={<MaterialManagement />}>
                        <Route path="/main-dashboard/material-management/" element={<Navigate to="/main-dashboard/material-management/add-material" replace />}></Route>
                        <Route path="/main-dashboard/material-management/type-category" element={<TypeCategory />}></Route>
                        <Route path="/main-dashboard/material-management/unit-management" element={<UnitManagement />}></Route>
                        <Route path="/main-dashboard/material-management/add-material" element={<MaterialManagementTab />}></Route>
                    </Route>
                    <Route path="/main-dashboard/inventory/reports" element={<Reports />}>
                        <Route path="/main-dashboard/inventory/reports/" element={<Navigate to="/main-dashboard/inventory/reports/material-transfer" replace />}></Route>
                        <Route path="/main-dashboard/inventory/reports/material-transfer" element={<MaterialTransferReport />}></Route>
                        <Route path="/main-dashboard/inventory/reports/material-issue" element={<MaterialIssuing />}></Route>
                    </Route>
                </Route>
                <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
        </>
    );
}

export default AdminRouter;