import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import swal from "sweetalert";
import Loader from "../../Modals/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import { useDispatch, useSelector } from 'react-redux';

const VenderLinkForm = () => {
    const navigate = useNavigate();
    const [quotationForm, SetQuotationForm] = useState(false);
    const [purchaseOrder, setPurchaseOrder] = useState();
    const [validator, showValidationMessage] = useValidator();
    const [unlimitedQuantity, setUnlimitedQuantity] = useState(false);
    const [boqList, setBoqList] = useState([]);
    const [specialRemark, setSpecialRemark] = useState();
    const location = useLocation();
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();



    const queryParams = new URLSearchParams(location.search);
    const poId = queryParams.get('poId');
    const poNumber = queryParams.get('poNumber');


    //Set Default values for vender
    const [vender, setVender] = useState({
        packageName: "",
        venderName: "",
        representativeName: "",
        contactNumber: "",
        suppliedLocation: "",
        venderEmail: "",
        materialDescription: "",
        measurementUnit: "",
        technicalSpecification: "",
        deliverySchedule: "",
        rejectionLimit: "",
        waranty: "",
    });

    //Set default values for bill
    const [bill, setBill] = useState({
        approxQty: "",
        unit: "",
        rate: "",
        remark: "",
        description: ""
    });

    //Set default values for Financial term A
    const [financialTermA, setFinancialTermA] = useState({
        materialLoading: "",
        loadingMaterialDetail: "",
        materialUnloading: "",
        materialUnloadingDetail: "",
        transportationCharges: "",
        transportationChargesDetail: "",
        royalty: "",
        royaltyDetail: "",
        taxes: "",
        taxesDetail: "",
        others: "",
        otherDetail: ""
    })

    //Set default values for Financial term B
    const [financialTermB, setFinancialTermB] = useState({
        billSubmission: "",
        billSubmissionDetail: "",
        creditPeriod: "",
        creditPeriodDetail: "",
        paymentTerm: "",
        paymentTermDetail: "",
        securityDeposite: "",
        securityDepositeDetail: "",
        tds: "",
        tdsDetail: "",
        termBRoyalty: "",
        termBRoyaltyDetail: "",
        otherTaxes: "",
        otherTaxesDetail: "",
        delayPenalty: "",
        delayPenaltyDetail: "",
        substandardMaterial: "",
        substandardMaterialDetail: "",
        advance: "",
        advanceDetail: "",
        recovery: "",
        recoveryDetail: "",
        termBOthers: "",
        termBOthersDetail: "",
    });

    //Upload Quotation
    const uploadeQuotation = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            const dataToSend = {
                poNumber,
                poId,
                vender,
                bill: boqList,
                financialTermA,
                financialTermB,
                specialRemark
            }
            const serverResponse = await communication.uploadedQuotations(dataToSend);
            if (serverResponse.data.status === "SUCCESS") {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "success" });
                SetQuotationForm(false);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Add item in boq list
    const addBoq = () => {
        //check Validation 
        if (unlimitedQuantity !== true) {
            if ([undefined, null, ""].includes(bill.approxQty)) {
                swal({ text: "Approx Quantity is Required", icon: "warning" });
                return false;
            }
        }
        if ([undefined, null, ""].includes(bill.rate)) {
            swal({ text: "Rate is Required", icon: "warning" });
            return false;
        }
        if ([undefined, null, ""].includes(bill.description)) {
            swal({ text: "Description is Required", icon: "warning" });
            return false;
        }

        setBoqList([...boqList, { materialName: purchaseOrder?.materialName, categoryName: purchaseOrder?.categoryName, unitName: purchaseOrder?.unitName, brand: bill.brand, approxQty: bill.approxQty, unlimitedQuantity: unlimitedQuantity, rate: bill.rate, description: bill.description }]);

    }

    //Delete Boq list items
    const deleteItem = (index) => {
        const updatedList = boqList.filter((elem, id) => {
            return index !== id;
        });
        setBoqList(updatedList);
    }



    //get Purchase order details
    const fetchPurchaseOrder = async () => {
        try {
            const serverResponse = await communication.getPurchaseOrderByIdAndPoNumber(poId, poNumber);
            if (serverResponse?.data?.status === "SUCCESS") {
                setPurchaseOrder(serverResponse?.data?.purchaseOrder)
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setPurchaseOrder([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchPurchaseOrder();
    }, [])


    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="vender_link_section">
                        <div className="vender_quotation">
                            <div className="form_heading">
                                <h5>Vender Quotation Form</h5>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-12" style={{ textAlign: "left" }}>
                                    <h5><b>PO Number. {purchaseOrder?.poNumber}</b></h5>
                                </div>
                            </div>
                            <div className="detail">
                                <div className="table_header">
                                    <div className="row m-0">
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Material</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Category</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Quantity Required</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Unit</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Location</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>Site</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="table_data">
                                    <div className="row m-0">
                                        <div className="col-lg-2 col-md-2">
                                            <h5>{purchaseOrder?.materialName}</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>{purchaseOrder?.categoryName}</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            {purchaseOrder?.unlimitedQuantity ?
                                                <h5>Unlimited</h5>
                                                :
                                                <h5>{purchaseOrder?.quantityRequired}</h5>}
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>{purchaseOrder?.unitName}</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>{purchaseOrder?.locationName}</h5>
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <h5>{purchaseOrder?.siteName}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3 mb-3">
                                <div className="col-6">
                                    <h5 className="title">Description of Material - <span>{purchaseOrder?.materialDescription}</span></h5>
                                </div>
                                <div className="col-6">
                                    <h5 className="title">Technical Specification - <span>{purchaseOrder?.materialTechSpecification}</span></h5>
                                </div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-6">
                                    <h5 className="title">Delivery Schedule - <span>{purchaseOrder?.deliverySchedule}</span></h5>
                                </div>
                                <div className="col-6">
                                    <h5 className="title">Rejection Limit - <span>{purchaseOrder?.rejectionLimit}</span></h5>
                                </div>
                            </div>
                            <div className="row mt-5 mb-5">
                                <div className="col-12 text-center">
                                    <button className="main_button" onClick={() => SetQuotationForm(!quotationForm)}>Upload Quotation</button>
                                </div>
                            </div>
                        </div>
                        {quotationForm &&

                            <div className="form">
                                <div className="form_heading">
                                    <h5>Upload Quotation</h5>
                                </div>
                                <div className="row">
                                    <div className="col-12" style={{ textAlign: "left" }}>
                                        <h5><b>PO Number: {purchaseOrder?.poNumber}</b></h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-12">
                                        <div className="input_group">
                                            <input type="text" name="packageName" value={vender?.packageName} className="form-control" onChange={(event) => setVender({ vender, packageName: event.target.value })} autoComplete="off" required />
                                            <label className="input_label">Name Of Package</label>
                                        </div>
                                        {validator.message("packageName", vender.packageName, "required", {
                                            messages: {
                                                required: "Package Name is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="input_group">
                                            <input type="text" name="venderName" value={vender?.venderName} className="form-control" onChange={(event) => setVender({ vender, venderName: event.target.value })} autoComplete="off" required />
                                            <label className="input_label">Name Of Vendor / Supplier</label>
                                        </div>
                                        {validator.message("venderName", vender.venderName, "required|alpha_space", {
                                            messages: {
                                                required: "Vender / Supplier Name is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="input_group">
                                            <input type="text" name="representativeName" value={vender?.representativeName} className="form-control" onChange={(event) => setVender({ vender, representativeName: event.target.value })} autoComplete="off" required />
                                            <label className="input_label">Vendor / Supplier Representative Name</label>
                                        </div>
                                        {validator.message("representativeName", vender.representativeName, "required|alpha_space", {
                                            messages: {
                                                required: "Representative Name is required"
                                            }
                                        })}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-12">
                                        <div className="input_group">
                                            <input type="text" name="contactNumber" value={vender?.contactNumber} className="form-control" onChange={(event) => setVender({ vender, contactNumber: event.target.value })} autoComplete="off" required />
                                            <label className="input_label">Contact No. Of Vendor / Supplier</label>
                                        </div>
                                        {validator.message("contactNumber", vender.contactNumber, "required|numeric|min:10|max:10", {
                                            messages: {
                                                required: "Contact Number is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="input_group">
                                            <input type="text" name="suppliedLocation" value={vender?.suppliedLocation} className="form-control" onChange={(event) => setVender({ vender, suppliedLocation: event.target.value })} autoComplete="off" required />
                                            <label className="input_label">Location/Chainage Material to supplied</label>
                                        </div>
                                        {validator.message("suppliedLocation", vender.suppliedLocation, "required|alpha_space", {
                                            messages: {
                                                required: "Location is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="input_group">
                                            <input type="text" name="venderEmail" value={vender?.venderEmail} className="form-control" onChange={(event) => setVender({ vender, venderEmail: event.target.value })} autoComplete="off" required />
                                            <label className="input_label">Email Of Vendor / Supplier</label>
                                        </div>
                                        {validator.message("venderEmail", vender.venderEmail, "required|email", {
                                            messages: {
                                                required: "Email is required"
                                            }
                                        })}
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input_group">
                                            <input type="text" name="materialDescription" value={vender?.materialDescription} className="form-control" onChange={(event) => setVender({ vender, materialDescription: event.target.value })} autoComplete="off" required />
                                            <label className="input_label">Description of Material</label>
                                        </div>
                                        {validator.message("materialDescription", vender.materialDescription, "required", {
                                            messages: {
                                                required: "Material Description is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input_group">
                                            <input type="text" name="measurementUnit" value={vender?.measurementUnit} className="form-control" onChange={(event) => setVender({ vender, measurementUnit: event.target.value })} autoComplete="off" required />
                                            <label className="input_label">Unit Of Measurement</label>
                                        </div>
                                        {validator.message("measurementUnit", vender.measurementUnit, "required|alpha_space", {
                                            messages: {
                                                required: "Unit is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="input_group">
                                            <input type="text" name="technicalSpecification" value={vender?.technicalSpecification} className="form-control" onChange={(event) => setVender({ vender, technicalSpecification: event.target.value })} autoComplete="off" required />
                                            <label className="input_label">Technical Specification of Material</label>
                                        </div>
                                        {validator.message("technicalSpecification", vender.technicalSpecification, "required|alpha_space", {
                                            messages: {
                                                required: "Technical Specification is required"
                                            }
                                        })}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-12">
                                        <div className="input_group">
                                            <input type="text" name="deliverySchedule" value={vender?.deliverySchedule} className="form-control" onChange={(event) => setVender({ vender, deliverySchedule: event.target.value })} autoComplete="off" required />
                                            <label className="input_label">Delivery Schedule of Material Supply</label>
                                        </div>
                                        {validator.message("deliverySchedule", vender.deliverySchedule, "required", {
                                            messages: {
                                                required: "Delivery Schedule is required"
                                            }
                                        })}
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input_group">
                                            <input type="text" name="rejectionLimit" value={vender?.rejectionLimit} className="form-control" onChange={(event) => setVender({ vender, rejectionLimit: event.target.value })} autoComplete="off" required />
                                            <label className="input_label">Rejection Tollerence Limit,if any</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input_group">
                                            <input type="text" name="waranty" value={vender?.waranty} className="form-control" onChange={(event) => setVender({ vender, waranty: event.target.value })} autoComplete="off" required />
                                            <label className="input_label">Warranty / Guarantee</label>
                                        </div>
                                        {validator.message("waranty", vender.waranty, "required", {
                                            messages: {
                                                required: "Warranty/Guarantee is required"
                                            }
                                        })}
                                    </div>
                                </div>


                                {/*----Bill of Quantities(BOQ)-----*/}
                                <div className="row mt-4">
                                    <div className="col-12" style={{ textAlign: "left" }}>
                                        <h5><b>Bill of Quantities (BOQ)</b></h5>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input_group">
                                            <input type="text" name="material" value={purchaseOrder?.materialName} className="form-control" autoComplete="off" required />
                                            <label className="input_label">Material Name</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input_group">
                                            <input type="text" name="unit" value={purchaseOrder?.unitName} className="form-control" autoComplete="off" required />
                                            <label className="input_label">Unit of Measurement</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input_group">
                                            <input type="text" name="brand" value={bill?.brand} onChange={(event) => setBill({ bill, brand: event.target.value })} className="form-control" autoComplete="off" required />
                                            <label className="input_label">Brand of Material</label>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    {unlimitedQuantity === false &&
                                        <div className="col-lg-4 col-md-6">
                                            <div className="input_group">
                                                <input type="text" name="approxQty" value={bill?.approxQty} onChange={(event) => setBill({ bill, approxQty: event.target.value })} className="form-control" autoComplete="off" required />
                                                <label className="input_label">Approx.Qty</label>
                                            </div>
                                        </div>
                                    }

                                    {[undefined, null, ""].includes(bill.approxQty) &&
                                        <div className="col-lg-4 col-md-12 unlimited_quantity_checkbox">
                                            <h5>Check this for Unlimited QTY</h5>
                                            <div class="form-check form-switch">
                                                <input type="checkbox" name="unlimitedQty" value={unlimitedQuantity} onChange={() => setUnlimitedQuantity(!unlimitedQuantity)} class="form-check-input" />
                                            </div>
                                        </div>
                                    }

                                    <div className="col-lg-4 col-md-6">
                                        <div className="input_group">
                                            <input type="number" name="rate" value={bill?.rate} onChange={(event) => setBill({ bill, rate: event.target.value })} className="form-control" autoComplete="off" required />
                                            <label className="input_label">Rate</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="input_group">
                                            <input type="text" name="description" value={bill?.description} onChange={(event) => setBill({ bill, description: event.target.value })} className="form-control" autoComplete="off" required />
                                            <label className="input_label">Description</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form_button">
                                    <button onClick={() => addBoq()}>Add</button>
                                </div>

                                {/*----Added Quotation List---------*/}
                                {boqList?.length > 0 &&
                                    <div className="added_quotations">
                                        <div className="added_quotations_details">
                                            <div className="detail">
                                                <div className="table_header">
                                                    <div className="row m-0">
                                                        <div className="sr_no">
                                                            <h5>Sr. No.</h5>
                                                        </div>
                                                        <div className="material_name">
                                                            <h5>Material</h5>
                                                        </div>
                                                        <div className="brand">
                                                            <h5>Brand</h5>
                                                        </div>
                                                        <div className="quantity">
                                                            <h5>Approx Qty.</h5>
                                                        </div>
                                                        <div className="unit">
                                                            <h5>Unit</h5>
                                                        </div>
                                                        <div className="rate">
                                                            <h5>Rate</h5>
                                                        </div>
                                                        <div className="description">
                                                            <h5>Description</h5>
                                                        </div>
                                                        <div className="user_action">
                                                            <h5>Action</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                {boqList.map((boq, index) => (
                                                    <div className="table_data" key={index}>
                                                        <div className="row m-0">
                                                            <div className="sr_no">
                                                                <h5>{index + 1}</h5>
                                                            </div>
                                                            <div className="material_name">
                                                                <h5>{boq.materialName}</h5>
                                                            </div>
                                                            <div className="brand">
                                                                <h5>{boq.brand}</h5>
                                                            </div>
                                                            {boq?.unlimitedQuantity === true ?
                                                                <div className="quantity">
                                                                    <h5>Unlimited</h5>
                                                                </div>
                                                                :
                                                                <div className="quantity">
                                                                    <h5>{boq.approxQty}</h5>
                                                                </div>
                                                            }
                                                            <div className="unit">
                                                                <h5>{boq.unitName}</h5>
                                                            </div>
                                                            <div className="rate">
                                                                <h5>{boq.rate}</h5>
                                                            </div>
                                                            <div className="description">
                                                                <h5>{boq.description}</h5>
                                                            </div>
                                                            <div className="user_action">
                                                                <div className="action">
                                                                    <i className="fa-solid fa-trash" onClick={() => deleteItem(index)}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/*----Financial Term A-----*/}
                                <div className="financial_term mt-5">
                                    <>
                                        <div className="row">
                                            <div className="col-12" style={{ textAlign: "left" }}>
                                                <h5><b>Financial Term (A)</b></h5>
                                            </div>
                                        </div>

                                        <div className="row mt-3 mb-3">
                                            <div className="col-8" style={{ textAlign: "left" }}>
                                                <h5><b>Whether above Rates includes following Cost</b></h5>
                                            </div>
                                            <div className="col-4" style={{ textAlign: "left" }}>
                                                <h5><b>If NOT, Specify details</b></h5>
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Loading of Material</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="materialLoading" checked={financialTermA?.materialLoading === "Yes"} value="Yes" onChange={(event) => setFinancialTermA({ financialTermA, materialLoading: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="materialLoading" checked={financialTermA?.materialLoading === "No"} value="No" onChange={(event) => setFinancialTermA({ financialTermA, materialLoading: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("materialLoading", financialTermA.materialLoading, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="loadingMaterialDetail" value={financialTermA?.loadingMaterialDetail} onChange={(event) => setFinancialTermA({ financialTermA, loadingMaterialDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermA.materialLoading === "No" ?
                                                    <>
                                                        {validator.message("loadingMaterialDetail", financialTermA.loadingMaterialDetail, "required", {
                                                            messages: {
                                                                required: "Loading material details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Unloading of Material</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="materialUnloading" checked={financialTermA?.materialUnloading === "Yes"} value="Yes" onChange={(event) => setFinancialTermA({ financialTermA, materialUnloading: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="materialUnloading" checked={financialTermA?.materialUnloading === "No"} value="No" onChange={(event) => setFinancialTermA({ financialTermA, materialUnloading: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("materialUnloading", financialTermA.materialUnloading, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="materialUnloadingDetail" value={financialTermA?.materialUnloadingDetail} onChange={(event) => setFinancialTermA({ financialTermA, materialUnloadingDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermA.materialUnloading === "No" ?
                                                    <>
                                                        {validator.message("materialUnloadingDetail", financialTermA.materialUnloadingDetail, "required", {
                                                            messages: {
                                                                required: "Unloading material details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Transportation Charges</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="transportationCharges" checked={financialTermA?.transportationCharges === "Yes"} value="Yes" onChange={(event) => setFinancialTermA({ financialTermA, transportationCharges: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="transportationCharges" checked={financialTermA?.transportationCharges === "No"} value="No" onChange={(event) => setFinancialTermA({ financialTermA, transportationCharges: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("transportationCharges", financialTermA.transportationCharges, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="transportationChargesDetail" value={financialTermA?.transportationChargesDetail} onChange={(event) => setFinancialTermA({ financialTermA, transportationChargesDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermA.transportationCharges === "No" ?
                                                    <>
                                                        {validator.message("transportationChargesDetail", financialTermA.transportationChargesDetail, "required", {
                                                            messages: {
                                                                required: "Transportation charges details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Royalty (If any)</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="royalty" checked={financialTermA?.royalty === "Yes"} value="Yes" onChange={(event) => setFinancialTermA({ financialTermA, royalty: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="royalty" checked={financialTermA?.royalty === "No"} value="No" onChange={(event) => setFinancialTermA({ financialTermA, royalty: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("royalty", financialTermA.royalty, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="royaltyDetail" value={financialTermA?.royaltyDetail} onChange={(event) => setFinancialTermA({ financialTermA, royaltyDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermA.royalty === "No" ?
                                                    <>
                                                        {validator.message("royaltyDetail", financialTermA.royaltyDetail, "required", {
                                                            messages: {
                                                                required: "Royalty details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Other Taxes & Cess (If any)</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="taxes" checked={financialTermA?.taxes === "Yes"} value="Yes" onChange={(event) => setFinancialTermA({ financialTermA, taxes: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="taxes" checked={financialTermA?.taxes === "No"} value="No" onChange={(event) => setFinancialTermA({ financialTermA, taxes: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("taxes", financialTermA.taxes, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="taxesDetail" value={financialTermA?.taxesDetail} onChange={(event) => setFinancialTermA({ financialTermA, taxesDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermA.taxes === "No" ?
                                                    <>
                                                        {validator.message("taxesDetail", financialTermA.taxesDetail, "required", {
                                                            messages: {
                                                                required: "Taxes details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Others (if any)</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="others" checked={financialTermA?.others === "Yes"} value="Yes" onChange={(event) => setFinancialTermA({ financialTermA, others: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="others" checked={financialTermA?.others === "No"} value="No" onChange={(event) => setFinancialTermA({ financialTermA, others: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="otherDetail" value={financialTermA?.otherDetail} onChange={(event) => setFinancialTermA({ financialTermA, otherDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                                <hr />
                                {/*----Financial Term B-----*/}
                                <div className="financial_term mt-4">
                                    <>
                                        <div className="row">
                                            <div className="col-12" style={{ textAlign: "left" }}>
                                                <h5><b>Financial Term (B)</b></h5>
                                            </div>
                                        </div>

                                        <div className="row mt-3 mb-3">
                                            <div className="col-8" style={{ textAlign: "left" }}>
                                                <h5><b>Description</b></h5>
                                            </div>
                                            <div className="col-4" style={{ textAlign: "left" }}>
                                                <h5><b>If NOT, Specify details</b></h5>
                                            </div>
                                        </div>

                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Bill Submission Cycle</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="billSubmission" checked={financialTermB?.billSubmission === "Yes"} value="Yes" onChange={(event) => setFinancialTermB({ financialTermB, billSubmission: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="billSubmission" checked={financialTermB?.billSubmission === "No"} value="No" onChange={(event) => setFinancialTermB({ financialTermB, billSubmission: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("billSubmission", financialTermB.billSubmission, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="billSubmissionDetail" value={financialTermB?.billSubmissionDetail} onChange={(event) => setFinancialTermB({ financialTermB, billSubmissionDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermB.billSubmission === "No" ?
                                                    <>
                                                        {validator.message("billSubmissionDetail", financialTermB.billSubmissionDetail, "required", {
                                                            messages: {
                                                                required: "Bill Submission details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Credit Period in Days</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="creditPeriod" checked={financialTermB?.creditPeriod === "Yes"} value="Yes" onChange={(event) => setFinancialTermB({ financialTermB, creditPeriod: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="creditPeriod" checked={financialTermB?.creditPeriod === "No"} value="No" onChange={(event) => setFinancialTermB({ financialTermB, creditPeriod: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("creditPeriod", financialTermB.creditPeriod, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="creditPeriodDetail" value={financialTermB?.creditPeriodDetail} onChange={(event) => setFinancialTermB({ financialTermB, creditPeriodDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermB.creditPeriod === "No" ?
                                                    <>
                                                        {validator.message("creditPeriodDetail", financialTermB.creditPeriodDetail, "required", {
                                                            messages: {
                                                                required: "Credit Period details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Specific Payment Terms</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="paymentTerm" checked={financialTermB?.paymentTerm === "Yes"} value="Yes" onChange={(event) => setFinancialTermB({ financialTermB, paymentTerm: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="paymentTerm" checked={financialTermB?.paymentTerm === "No"} value="No" onChange={(event) => setFinancialTermB({ financialTermB, paymentTerm: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("paymentTerm", financialTermB.paymentTerm, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="paymentTermDetail" value={financialTermB?.paymentTermDetail} onChange={(event) => setFinancialTermB({ financialTermB, paymentTermDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermB.paymentTerm === "No" ?
                                                    <>
                                                        {validator.message("paymentTermDetail", financialTermB.paymentTermDetail, "required", {
                                                            messages: {
                                                                required: "Payment Term details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Security Deposit</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="securityDeposite" checked={financialTermB?.securityDeposite === "Yes"} value="Yes" onChange={(event) => setFinancialTermB({ financialTermB, securityDeposite: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="securityDeposite" checked={financialTermB?.securityDeposite === "No"} value="No" onChange={(event) => setFinancialTermB({ financialTermB, securityDeposite: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("securityDeposite", financialTermB.securityDeposite, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="securityDepositeDetail" value={financialTermB?.securityDepositeDetail} onChange={(event) => setFinancialTermB({ financialTermB, securityDepositeDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermB.securityDeposite === "No" ?
                                                    <>
                                                        {validator.message("securityDepositeDetail", financialTermB.securityDepositeDetail, "required", {
                                                            messages: {
                                                                required: "Security Deposite details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>TDS</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="tds" checked={financialTermB?.tds === "Yes"} value="Yes" onChange={(event) => setFinancialTermB({ financialTermB, tds: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="tds" checked={financialTermB?.tds === "No"} value="No" onChange={(event) => setFinancialTermB({ financialTermB, tds: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("tds", financialTermB.tds, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="tdsDetail" value={financialTermB?.tdsDetail} onChange={(event) => setFinancialTermB({ financialTermB, tdsDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermB.tds === "No" ?
                                                    <>
                                                        {validator.message("tdsDetail", financialTermB.tdsDetail, "required", {
                                                            messages: {
                                                                required: "TDS details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Royalty (If any)</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="termBRoyalty" checked={financialTermB?.termBRoyalty === "Yes"} value="Yes" onChange={(event) => setFinancialTermB({ financialTermB, termBRoyalty: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="termBRoyalty" checked={financialTermB?.termBRoyalty === "No"} value="No" onChange={(event) => setFinancialTermB({ financialTermB, termBRoyalty: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("termBRoyalty", financialTermB.termBRoyalty, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="termBRoyaltyDetail" value={financialTermB?.termBRoyaltyDetail} onChange={(event) => setFinancialTermB({ financialTermB, termBRoyaltyDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermB.termBRoyalty === "No" ?
                                                    <>
                                                        {validator.message("termBRoyaltyDetail", financialTermB.termBRoyaltyDetail, "required", {
                                                            messages: {
                                                                required: "Royalty details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Other Taxes & Cess (If any)</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="otherTaxes" checked={financialTermB?.otherTaxes === "Yes"} value="Yes" onChange={(event) => setFinancialTermB({ financialTermB, otherTaxes: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="otherTaxes" checked={financialTermB?.otherTaxes === "No"} value="No" onChange={(event) => setFinancialTermB({ financialTermB, otherTaxes: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("otherTaxes", financialTermB.otherTaxes, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="otherTaxesDetail" value={financialTermB?.otherTaxesDetail} onChange={(event) => setFinancialTermB({ financialTermB, otherTaxesDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermB.otherTaxes === "No" ?
                                                    <>
                                                        {validator.message("otherTaxesDetail", financialTermB.otherTaxesDetail, "required", {
                                                            messages: {
                                                                required: "Taxes details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Liquidty Damages / Penalty For Delay</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="delayPenalty" checked={financialTermB?.delayPenalty === "Yes"} value="Yes" onChange={(event) => setFinancialTermB({ financialTermB, delayPenalty: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="delayPenalty" checked={financialTermB?.delayPenalty === "No"} value="No" onChange={(event) => setFinancialTermB({ financialTermB, delayPenalty: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("delayPenalty", financialTermB.delayPenalty, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="delayPenaltyDetail" value={financialTermB?.delayPenaltyDetail} onChange={(event) => setFinancialTermB({ financialTermB, delayPenaltyDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermB.delayPenalty === "No" ?
                                                    <>
                                                        {validator.message("delayPenaltyDetail", financialTermB.delayPenaltyDetail, "required", {
                                                            messages: {
                                                                required: "Delay Penalty details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Penalty For Substandard Material</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="substandardMaterial" checked={financialTermB?.substandardMaterial === "Yes"} value="Yes" onChange={(event) => setFinancialTermB({ financialTermB, substandardMaterial: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="substandardMaterial" checked={financialTermB?.substandardMaterial === "No"} value="No" onChange={(event) => setFinancialTermB({ financialTermB, substandardMaterial: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("substandardMaterial", financialTermB.substandardMaterial, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="substandardMaterialDetail" value={financialTermB?.substandardMaterialDetail} onChange={(event) => setFinancialTermB({ financialTermB, substandardMaterialDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermB.substandardMaterial === "No" ?
                                                    <>
                                                        {validator.message("substandardMaterialDetail", financialTermB.substandardMaterialDetail, "required", {
                                                            messages: {
                                                                required: "Substandard material details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Advance against Material</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="advance" checked={financialTermB?.advance === "Yes"} value="Yes" onChange={(event) => setFinancialTermB({ financialTermB, advance: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="advance" checked={financialTermB?.advance === "No"} value="No" onChange={(event) => setFinancialTermB({ financialTermB, advance: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("advance", financialTermB.advance, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="advanceDetail" value={financialTermB?.advanceDetail} onChange={(event) => setFinancialTermB({ financialTermB, advanceDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermB.advance === "No" ?
                                                    <>
                                                        {validator.message("advanceDetail", financialTermB.advanceDetail, "required", {
                                                            messages: {
                                                                required: "Advance details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Terms for Recovery of Advance against Material</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="recovery" checked={financialTermB?.recovery === "Yes"} value="Yes" onChange={(event) => setFinancialTermB({ financialTermB, recovery: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="recovery" checked={financialTermB?.recovery === "No"} value="No" onChange={(event) => setFinancialTermB({ financialTermB, recovery: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <span className="checkbox_validation_msg">
                                                        {validator.message("recovery", financialTermB.recovery, "required", {
                                                            messages: {
                                                                required: "Check at least one"
                                                            }
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="recoveryDetail" value={financialTermB?.recoveryDetail} onChange={(event) => setFinancialTermB({ financialTermB, recoveryDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                                {financialTermB.recovery === "No" ?
                                                    <>
                                                        {validator.message("recoveryDetail", financialTermB.recoveryDetail, "required", {
                                                            messages: {
                                                                required: "Recovery details is Required"
                                                            }
                                                        })}
                                                    </>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-8" style={{ display: "grid", alignItems: "center" }}>
                                                <div className="row">
                                                    <div className="col-6" style={{ textAlign: "left" }}>
                                                        <h5>Others (If Any)</h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Yes</label>
                                                        <input type="radio" name="termBOthers" checked={financialTermB?.termBOthers === "Yes"} value="Yes" onChange={(event) => setFinancialTermB({ financialTermB, termBOthers: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                    <div className="col-3">
                                                        <label>No</label>
                                                        <input type="radio" name="termBOthers" checked={financialTermB?.termBOthers === "No"} value="No" onChange={(event) => setFinancialTermB({ financialTermB, termBOthers: event.target.value })} class="form-check-input ms-2" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="input_group">
                                                    <input type="text" name="termBOthersDetail" value={financialTermB?.termBOthersDetail} onChange={(event) => setFinancialTermB({ financialTermB, termBOthersDetail: event.target.value })} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Detail</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-4 col-md-8">
                                                <div className="input_group">
                                                    <input type="text" name="specialRemark" onChange={(event) => setSpecialRemark(event.target.value)} className="form-control" autoComplete="off" required />
                                                    <label className="input_label">Special Remark</label>
                                                </div>
                                                {validator.message("specialRemark", specialRemark, "required", {
                                                    messages: {
                                                        required: "Remark is Required"
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-12">
                                        <button className="me-3" onClick={() => uploadeQuotation()}>Submit</button>
                                    </div>
                                </div>

                            </div>

                        }
                    </div>
            }
        </>
    );
}
export default VenderLinkForm;