import AdminRouter from './routes/AdminRouter';
import Dashboard from './Components/Dashboard';
function App() {
  return (
    <>
      <AdminRouter />
      {/* <Dashboard /> */}
    </>
  );
}

export default App;
