import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import LivePoDetails from "./LivePoDetails";
import { communication } from "../../../communication/communication";
import Loader from "../../../Modals/Loader";
import { showLoader, hideLoader } from "../../../redux-store/loaderReducer";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

const LivePo = () => {
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [selectedPo, setSelectedPo] = useState({});
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [activeSelectedItem, setActiveSelectedItem] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);
    const pageLimit = process.env.REACT_APP_PAGE_LIMIT;


    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchLivePurchaseOrdersBySearch(searchValue, selectedPage + 1);
        } else {
            getLivePO(selectedPage + 1);
        }
    }

    // Search filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchLivePurchaseOrdersBySearch(searchString, currentPage + 1);
        } else {
            const serverResponse = await communication.fetchLivePOList();
            if (serverResponse?.data?.status === "SUCCESS") {
                setPurchaseOrders(serverResponse?.data?.livePo);
                setSelectedPo({ poId: serverResponse?.data?.livePo[0]?._id, poNumber: serverResponse?.data?.livePo[0]?.poNumber });
                setPageCount(serverResponse?.data?.totalPages);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setPurchaseOrders([]);
                setSelectedPo({ poId: "", poNumber: "" });
                setPageCount(0);
            }
        }
    }

    //fetch live po By Search
    const fetchLivePurchaseOrdersBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchLivePurchaseOrders(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setPurchaseOrders(responseFromServer?.data?.livePo);
                setSelectedPo({ poId: responseFromServer?.data?.livePo[0]?._id, poNumber: responseFromServer?.data?.livePo[0]?.poNumber });
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setPurchaseOrders([]);
                setSelectedPo({ poId: "", poNumber: "" });
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //get all live Po on initial load
    const getLivePO = async (page) => {
        try {
            dispatch(showLoader());
            const serverResponse = await communication.fetchLivePOList(page);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                setPurchaseOrders(serverResponse?.data?.livePo);
                setSelectedPo({ poId: serverResponse?.data?.livePo[0]?._id, poNumber: serverResponse?.data?.livePo[0]?.poNumber });
                setPageCount(serverResponse?.data?.totalPages);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                setPurchaseOrders([]);
                setSelectedPo({ poId: "", poNumber: "" });
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        getLivePO();
    }, [])

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="General_po_section">
                        <div className="search_div">
                            <input type="text" onChange={(event) => { searchFilter(event.target.value); setSearchValue(event.target.value) }} className='form=control' placeholder='search by category, material, location' title="search by category, material, location" />
                        </div>
                        <div className="row m-0">
                            <div className="col-lg-6 col-md-12 ps-lg-0">
                                <div className="table_section general_po">
                                    <div className="all_approvals_table">
                                        <div className="table_header">
                                            <div className="row m-0">
                                                <div className="col-lg-1 col-md-1">
                                                    <h5>Sr.No</h5>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>PO Number</h5>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>Category</h5>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>Material</h5>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>Location</h5>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <h5>Source</h5>
                                                </div>
                                                <div className="col-lg-1 col-md-2">
                                                    <h5>Status</h5>
                                                </div>
                                            </div>
                                        </div>

                                        {purchaseOrders?.length > 0 ?
                                            <>
                                                {purchaseOrders?.map((po, index) => {
                                                    return (
                                                        <div className={`table_data ${activeSelectedItem !== index ? "" : "active_selected_row"}`} key={index}>
                                                            <div className="row m-0">
                                                                <div className="col-lg-1 col-md-1 data">
                                                                    <h5>{pageLimit * currentPage + (index + 1)}</h5>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2 data click_event" onClick={() => { setSelectedPo({ poId: po._id, poNumber: po.poNumber }); setActiveSelectedItem(index) }}>
                                                                    <h5><a>{po.poNumber}</a></h5>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2 data">
                                                                    <h5>{po.categoryName}</h5>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2 data">
                                                                    <h5>{po.materialName}</h5>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2 data">
                                                                    <h5>{po.locationName}</h5>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2 data">
                                                                    <h5>{po.deliveryTo}</h5>
                                                                </div>
                                                                <div className="col-lg-1 col-md-1 data">
                                                                    <h5>{po.status}</h5>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })}

                                            </>
                                            :
                                            <div className="data_not_available_message" style={{ textAlign: "left", marginLeft: "18%" }}>
                                                <h5>Purchase Order Not Available</h5>
                                            </div>
                                        }


                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 ps-lg-0 pe-lg-0">
                                <LivePoDetails selectedPo={selectedPo} getLivePO={getLivePO} />
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                    </div>
            }
        </>
    );
}
export default LivePo;