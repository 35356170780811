import React, { useState, useEffect } from "react";
import { communication } from "../../communication/communication";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../../Modals/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import { useDispatch, useSelector } from 'react-redux';

const ApproveQuotation = ({ setToggleDetailSections, uploadedQuotation }) => {
    const navigate = useNavigate();
    const [approveBill, setApproveBill] = useState([]);
    const loginUserDetails = useSelector((state) => state.userDetails);
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();



    const onChangeHandler = (value, quotationId, id) => {

        if (value) {
            setApproveBill([...approveBill, { quotationId, billId: id }]);
        } else {
            const data = approveBill.filter(quotation => {
                return quotation.billId !== id;
            })
            setApproveBill(data);
        }
    }

    const approveQuotation = async () => {
        try {
            if (approveBill?.length < 1) {
                swal({ text: "Please select at least one quotation", icon: "error" });
                return false;
            }
            dispatch(showLoader());
            const serverResponse = await communication.approveQuotation(uploadedQuotation[uploadedQuotation?.length - 1].poNumber, uploadedQuotation[uploadedQuotation?.length - 1].poId, approveBill, loginUserDetails);
            if (serverResponse.data.status === "SUCCESS") {
                dispatch(hideLoader());
                setToggleDetailSections({ quotationApprove: false })
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "error" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "error" });
                return false;
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        const approveArray = [];
        uploadedQuotation.forEach(element => {
            element?.bill.forEach((quotation) => {
                if (quotation.isSelected === true) {
                    approveArray.push({ quotationId: element._id, billId: quotation.billId });
                }
            })
        })
        setApproveBill(approveArray);
    }, [])

    return (
        <>
        {
             loader.showHideLoader === true ?
             <Loader />
             :
             <div className="quotaion_approval">
                <div className="form">
                    <div className="form_heading">
                        <h5>All Quotations Approval</h5>
                    </div>
                    <div className="quotation_details">
                        <div className="detail">
                            <div className="table_header">
                                <div className="row m-0">
                                    <div className="col-lg-2 col-md-2">
                                        <h5>Vender</h5>
                                    </div>
                                    <div className="col-lg-10 col-md-10">
                                        <div className="row m-0">
                                            <div className="col-lg-2 col-md-2" style={{ textAlign: "left" }}>
                                                <h5>Material</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Category</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Brand</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Unit</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Rate</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Quantity</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {uploadedQuotation?.length > 0 ?
                                uploadedQuotation.map((quotation, index) => {
                                    return (

                                        <div className="table_data" key={index}>
                                            <div className="row m-0">
                                                <div className="col-lg-2 col-md-2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <h5>{quotation?.vendor?.venderName}</h5>
                                                </div>
                                                <div className="col-lg-10 col-md-10">
                                                    {quotation?.bill?.map((material, index) => {
                                                        return (

                                                            <div className="row m-0" key={index}>
                                                                <div className="col-lg-2 col-md-2">
                                                                    <div className="approve_checkbox mt-2 mb-2">
                                                                        <input class="form-check-input" type="checkbox" checked={approveBill.some(bill => bill.billId === material.billId) ? true : false} id="flexCheckDefault" onChange={(event) => onChangeHandler(event.target.checked, quotation._id, material?.billId)} />
                                                                        <h5>{material?.materialName}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2">
                                                                    <h5 className="mt-2 mb-2">{material?.categoryName}</h5>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2">
                                                                    <h5 className="mt-2 mb-2">{material?.brand}</h5>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2">
                                                                    <h5 className="mt-2 mb-2">{material?.unitName}</h5>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2">
                                                                    <h5 className="mt-2 mb-2">{material?.rate}</h5>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2">
                                                                    {material?.unlimitedQuantity ? <h5 className="mt-2 mb-2">Unlimited</h5> :
                                                                        <h5 className="mt-2 mb-2">{material?.approxQty}</h5>
                                                                    }
                                                                </div>
                                                            </div>

                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })
                                :
                                <div className="data_not_available_message">
                                    <h5>Quotation Not uploaded for this PO</h5>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="form_button">
                        <button onClick={() => approveQuotation()}>Submit</button>
                        <button className="ms-3" onClick={() => setToggleDetailSections({ quotationApprove: false })}>Cancel</button>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default ApproveQuotation