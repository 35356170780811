import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import TypeCategory from '../Components/materialManagementTabComponents/TypeCategory';
import UnitManagement from '../Components/materialManagementTabComponents/UnitManagement';
import MaterialManagementTab from '../Components/materialManagementTabComponents/MaterialManagementTab';



const MaterialManagementTabRoutes = () => {
    return (
        <Routes>
            <Route path="/type-category" element={<TypeCategory />}></Route>
            <Route path="/unit-management" element={<UnitManagement />}></Route>
            <Route path="/add-material" element={<MaterialManagementTab />}></Route>
            <Route path="*" element={<Navigate to="/material-management/add-material" replace />}></Route>
        </Routes>
    )
}
export default MaterialManagementTabRoutes;