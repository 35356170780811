import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import AddNewDesignation from "./forms/AddNewDesignation";
import UpdateDesignation from "./forms/UpdateDesignation";
import { communication } from "../communication/communication";
import { showLoader, hideLoader } from "../redux-store/loaderReducer";
import Loader from "../Modals/Loader";
import { showAlertModal } from "../redux-store/alertMessageReducer";
import Modal from "../Modals/Modal";
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

const DesignationManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showDesignationForm, setShowDesignationForm] = useState(false);
    const [showUpdateDesignation, setShowUpdateDesignation] = useState(false);
    const [designationDataToUpdate, setDesignationDataToUpdate] = useState({ designation: "", designationId: "" })
    const [designations, setDesignations] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");

    const loginUserDetails = useSelector((state) => state.userDetails);
    const loader = useSelector((state) => state.loader);
    const alertModal = useSelector((state) => state);
    const pageLimit = process.env.REACT_APP_PAGE_LIMIT;

    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchDesignationsBySearch(searchValue, selectedPage + 1);
        } else {
            fetchDesignation(selectedPage + 1);
        }
    }

    //search designation
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchDesignationsBySearch(searchString, currentPage);
        } else {
            const responseFromServer = await communication.getDesignationList();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setDesignations(responseFromServer?.data?.designations);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setDesignations([]);
                setPageCount(0);
            }
        }
    }

    //fetch designations by search
    const fetchDesignationsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchDesignations(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setDesignations(responseFromServer?.data?.designations);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setDesignations([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //get all designation data on initial load
    const fetchDesignation = async (page) => {
        try {
            dispatch(showLoader())
            const responseFromServer = await communication.getDesignationList(page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader())
                setDesignations(responseFromServer?.data?.designations);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setDesignations([]);
                setPageCount(0);
                dispatch(
                    hideLoader()
                )
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                dispatch(
                    showAlertModal()
                )
            }

        } catch (error) {
            dispatch(
                hideLoader()
            )
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchDesignation();
    }, [showDesignationForm, showUpdateDesignation])

    const deleteDesignation = async (designationId) => {
        confirmAlert({
            title: 'Are you sure you want to Delete Designation?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const serverResponse = await communication.deleteDesignation(designationId);
                        if (serverResponse.data.status === "SUCCESS") {
                            fetchDesignation();
                            swal({ text: serverResponse.data?.message, icon: "success" });
                        } else if (serverResponse?.data?.status === "JWT_INVALID") {
                            swal({ text: serverResponse.data?.message, icon: "warning" });
                            navigate("/login");
                        } else {
                            return swal({ text: serverResponse.data?.message, icon: "warning" });
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return false }
                }
            ],
            overlayClassName: "react-confirm-alert"
        });
    }

    return (
        <>
            {

                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="dashboard_tab_main">
                        <h4 className="title">Designation Management</h4>
                        <div className='search_div'>
                            <input type="text" className='form=control' onChange={(event) => { searchFilter(event.target.value); setSearchValue(event.target.value) }} placeholder='search by designation' />
                            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.add_designation === true) ?
                                <button onClick={() => setShowDesignationForm(true)}>Add New Designation</button>
                                :
                                <></>
                            }
                        </div>
                        <div className="row">

                            <div className="col-lg-12 col-md-12">
                                <div className="table_section">
                                    <div className="table_header">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Sr. No</h5>
                                            </div>
                                            <div className="col-lg-8 col-md-8">
                                                <h5>Designation Name</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Action</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {designations.length !== 0 ?
                                        <>
                                            {designations?.map((designation, index) => {
                                                return (
                                                    <div className="table_data" key={index}>
                                                        <div className="row">
                                                            <div className="col-lg-2 col-md-2">
                                                                <h5>{pageLimit * currentPage + (index + 1)}</h5>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8">
                                                                <h5>{designation.designationName}</h5>
                                                            </div>
                                                            <div className="col-lg-2 col-md-2">
                                                                <div className="action">
                                                                    <i className="fa-solid fa-pen-to-square" onClick={() => { setDesignationDataToUpdate({ designation: designation.designationName, designationId: designation._id }); setShowUpdateDesignation(true) }} ></i>
                                                                    <i className="fa-solid fa-trash" onClick={() => deleteDesignation(designation._id)}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Designation Not Available</h5>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                        {showDesignationForm &&
                            < div className="modal_box">
                                <AddNewDesignation setShowDesignationForm={setShowDesignationForm} />
                            </div>
                        }

                        {showUpdateDesignation && <div className="modal_box">
                            <UpdateDesignation setShowUpdateDesignation={setShowUpdateDesignation} designationDataToUpdate={designationDataToUpdate} />
                        </div>
                        }
                        {
                            alertModal.alertModal === true &&
                            <Modal />
                        }
                    </div>

            }
        </>
    );
}

export default DesignationManagement