import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { communication } from "../communication/communication";
import swal from "sweetalert";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Loader from "../Modals/Loader";
import { showLoader, hideLoader } from "../redux-store/loaderReducer";


const MachineDetails = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const loginUserDetails = useSelector((state) => state.userDetails);
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const machineId = queryParams.get("machineId");

    const [machineDetails, setMachineDetails] = useState({});
    const [average, setAverage] = useState();
    const [machineLogs, setMachineLogs] = useState([]);
    const [excelData, setExcelData] = useState([]);

    //Setting excel sheet name and extension
    const fileName = "Machine Logs";
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    //Exporting table data to excel sheet 
    const exportToCSV = (excelData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    //fetch machine details by id
    const fetchMachineDetailById = async () => {
        try {
            dispatch(showLoader())
            const responseFromServer = await communication.machineDetailById(machineId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader())
                setMachineDetails(responseFromServer?.data?.machine);
                setAverage(responseFromServer?.data?.average);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setMachineDetails({});
                dispatch(hideLoader())
            }
        } catch (error) {
            dispatch(hideLoader())
            swal({ text: error?.message, icon: "error" });
        }
    }

    //fetch  machine logs by id
    const fetchMachineLogs = async () => {
        try {
            const responseFromServer = await communication.machineLogsById(machineId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setMachineLogs(responseFromServer?.data?.machineLogs);
                exportData(responseFromServer?.data?.machineLogs);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "error" });
                navigate("/login");
            } else {
                setMachineLogs([]);
                exportData([]);
            }
        } catch (error) {
            swal({ text: error.message, icon: "error" });
        }
    }

    //set Data to Export
    const exportData = (data) => {
        //Storing data in array that want to export
        let newArray = [];
        data.forEach((ele) => {
            let obj = {
                SiteName: ele?.siteName, Date: new Date(ele?.endDate).toLocaleDateString(), Fuel: ele?.fuel, Oil: ele?.oil,
                StartingMeter: ele?.startingMeter, ClosingMeter: ele?.closingMeter, WorkingHoursStart: ele?.startTime, WorkingHoursend: ele?.endTime,
                FuelAverage: ele?.average, Hours: ele?.totalHours?.hours, Minutes: ele?.totalHours?.minutes, WorkStatus: ele?.workStatus
            }
            newArray.push(obj);
            setExcelData(newArray);
        })
    }

    useEffect(() => {
        fetchMachineDetailById();
        fetchMachineLogs();
    }, []);


    return (
        <>
            {

                loader?.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="dashboard_tab_main">
                        <h4 className="title">Machine Management</h4>
                        <div className="row material_search_box">
                            {/* <div className="col-lg-3 col-md-4 ps-0" style={{ display: "flex", alignItems: "flex-end" }}>
                    <input type="text" className="form-control search_input" placeholder="serach by name of machine" />
                </div> */}
                            <div className="col-lg-3 col-md-4 mt-lg-0 mt-md-3 ps-lg-2 ps-md-0">
                                <label>From</label>
                                <input type="date" className='form-control' placeholder="From" />
                            </div>
                            <div className="col-lg-3 col-md-4 mt-lg-0 mt-md-3">
                                <label>To</label>
                                <input type="date" className='form-control' placeholder="To" />
                            </div>
                            <div className="col-lg-6 col-md-4" style={{ display: "flex", justifyContent: "end", alignItems: "flex-end" }}>
                                <NavLink to="/main-dashboard/machine-management">
                                    <button>Back</button>
                                </NavLink>
                            </div>
                        </div>

                        <div className="machine_detail_main">
                            <div className="row mb-3">
                                <div className="col-lg-6 col-md-6">
                                    <h5>Machine Name - {machineDetails?.machineName}</h5>
                                </div>
                                <div className="col-lg-6 col-md-6 mt-lg-0 mt-md-2" style={{ textAlign: "right" }}>
                                    {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.download_reports === true) ?
                                        <>
                                            <button onClick={() => exportToCSV(excelData, fileName)}>Download Report<i className="fa-solid fa-download ms-2"></i></button>
                                        </>
                                        : null
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8 col-md-8">
                                    <h5>Name of Site - {machineDetails?.siteName}</h5>
                                    <h5 className="mt-2 mb-2">Name of Driver - {machineDetails?.driver}</h5>
                                    <h5>Driver's License No. - {machineDetails?.licenseNo}</h5>
                                </div>
                                <div className="col-lg-4 col-md-4" style={{ textAlign: "right" }}>
                                    <h5>Overall Average</h5>
                                    <h5 className="mt-3 overall_average">{average} ltr/hr</h5>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="machine_detail_table">
                                        <div className="table_heading">
                                            <div className="sr_no">
                                                <h5>Sr. No.</h5>
                                            </div>
                                            <div className="date">
                                                <h5>Date</h5>
                                            </div>
                                            <div className="fuel">
                                                <h5>Fuel</h5>
                                            </div>
                                            <div className="oil">
                                                <h5>Oil</h5>
                                            </div>
                                            <div className="starting_meter">
                                                <h5>Starting Meter</h5>
                                            </div>
                                            <div className="closing_meter">
                                                <h5>Closing Meter</h5>
                                            </div>
                                            <div className="working_hours_start">
                                                <h5>Working Hours Start</h5>
                                            </div>
                                            <div className="working_hours_end">
                                                <h5>Working Hours end</h5>
                                            </div>
                                            <div className="fuel_average">
                                                <h5>Fuel Average</h5>
                                            </div>
                                            <div className="total_work_hours">
                                                <h5>Total Work Hours</h5>
                                            </div>
                                            <div className="work_status">
                                                <h5>Work Status</h5>
                                            </div>
                                        </div>
                                        {machineLogs?.length > 0 ?
                                            <>
                                                {machineLogs?.map((logs, index) => (
                                                    <div className="table_content">
                                                        <div className="sr_no">
                                                            <h5>{index + 1}</h5>
                                                        </div>
                                                        <div className="date">
                                                            <h5>{new Date(logs?.endDate).toLocaleDateString()}</h5>
                                                        </div>
                                                        <div className="fuel">
                                                            <h5>{(logs?.fuel < 10) ? `0${logs?.fuel}` : logs?.fuel} ltr</h5>
                                                        </div>
                                                        <div className="oil">
                                                            <h5>{(logs?.oil < 10) ? `0${logs.oil}` : logs?.oil} ltr</h5>
                                                        </div>
                                                        <div className="starting_meter">
                                                            <h5>{logs?.startingMeter}</h5>
                                                        </div>
                                                        <div className="closing_meter">
                                                            <h5>{logs?.closingMeter}</h5>
                                                        </div>
                                                        <div className="working_hours_start">
                                                            <h5>{logs?.startTime}</h5>
                                                        </div>
                                                        <div className="working_hours_end">
                                                            <h5>{logs?.endTime}</h5>
                                                        </div>
                                                        <div className="fuel_average">
                                                            <h5>{`${logs?.average} ltr/hr`}</h5>
                                                        </div>
                                                        <div className="total_work_hours">
                                                            <h5>{`${(logs?.totalHours?.hours < 10) ? `0${logs?.totalHours?.hours}` : logs?.totalHours?.hours}:${(logs?.totalHours?.minutes < 10) ? `0${logs?.totalHours?.minutes}` : logs?.totalHours?.minutes} hr`}</h5>
                                                        </div>
                                                        <div className="work_status">
                                                            <h5>{logs?.workStatus}</h5>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                            :
                                            <div className="data_not_available_message">
                                                <h5>Machine Logs are not Available</h5>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            }
        </>
    );
}
export default MachineDetails;