import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import Loader from "../../Modals/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const AddNewUser = ({ setAddNewUserForm, addNewUserForm }) => {
    const [validator, showValidationMessage] = useValidator();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);
    const [designation, setDesignation] = useState([]);
    const [newUser, setNewUser] = useState(
        {
            name: "",
            email: "",
            password: "",
            designationName: "",
            designationId: "",
            licenseNo: ""
        }
    );

    const { name, email, password, designationName, designationId, licenseNo } = newUser;

    const fetchDesignation = async () => {
        try {
            const responseFromServer = await communication.getDesignation();
            if (responseFromServer.data.status === "SUCCESS") {
                setDesignation(responseFromServer.data.designations);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setDesignation([]);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchDesignation();
    }, []);

    const addUser = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            const serverResponse = await communication.addUser(name, email, password, designationName, designationId, licenseNo);
            if (serverResponse.data.status === "SUCCESS") {
                setAddNewUserForm(false);
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }
    }


    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :

                    <div className="add_new_user">
                        <div className="form">
                            <div className="form_heading">
                                <h5>Add New User</h5>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="input_group">
                                        <input type="text" name="name" onChange={(event) => setNewUser({ ...newUser, name: event.target.value })} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Name of User</label>
                                    </div>
                                    {validator.message("name", name, "required|alpha_space", {
                                        messages: {
                                            required: "Name is required"
                                        }
                                    })}
                                </div>
                                <div className="col-6">
                                    <select name="designationName" className="form-select" onChange={(event) => { setNewUser({ ...newUser, designationName: JSON.parse(event.target.value)?.designationName, designationId: JSON.parse(event.target.value)?._id }) }}>
                                        <option value="">Select Designation</option>
                                        {designation?.map((designation, index) => {
                                            return (
                                                <option value={JSON.stringify(designation)} key={index} >{designation.designationName}</option>
                                            );
                                        })}
                                    </select>
                                    {validator.message("designationName", designationName, "required", {
                                        messages: {
                                            required: "Designation is required"
                                        }
                                    })}
                                </div>
                            </div>
                            {["Driver", "DRIVER", "driver"].includes(designationName) &&
                                <div className="row">
                                    <div className="col-6">
                                        <div className="input_group">
                                            <input type="text" name="licenseNo" onChange={(event) => setNewUser({ ...newUser, licenseNo: event.target.value })} className="form-control" autoComplete="off" required />
                                            <label className="input_label">Driver License no.</label>
                                        </div>
                                    </div>
                                    {["Driver", "DRIVER", "driver"].includes(designationName) &&
                                        <>
                                            {validator.message("licenseNo", licenseNo, "required", {
                                                messages: {
                                                    required: "License Number is required"
                                                }
                                            })}
                                        </>
                                    }
                                </div>
                            }
                            <div className="row">
                                <div className="col-6">
                                    <div className="input_group">
                                        <input type="text" name="email" onChange={(event) => setNewUser({ ...newUser, email: event.target.value })} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Email</label>
                                    </div>
                                    {validator.message("email", email, "required|email", {
                                        messages: {
                                            required: "Email is required"
                                        }
                                    })}
                                </div>
                                <div className="col-6">
                                    <div className="input_group">
                                        <input type="password" name="password" onChange={(event) => setNewUser({ ...newUser, password: event.target.value })} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Password</label>
                                    </div>
                                    {validator.message("password", password, "required|min:8", {
                                        messages: {
                                            required: "Password is required"
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="form_button">
                                <button onClick={() => addUser()}>Add</button>
                            </div>
                            <div className="close_form">
                                <i className="fa-solid fa-xmark" onClick={() => setAddNewUserForm(false)}></i>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}
export default AddNewUser;