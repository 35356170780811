import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MaterialIssuingForm from "../forms/MaterialIssuingForm";
import MaterialTransferForm from "../forms/MaterialTransferForm";
import NewPurchaseIndentForm from "../forms/NewPurchaseIndentForm";
import AllMaterialDetail from "./AllMaterialDetail";
import { communication } from "../../communication/communication";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";
import Loader from "../../Modals/Loader";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

const AllMaterial = () => {
    const loginUserDetails = useSelector((state) => state.userDetails);
    const [showMaterialIssuingForm, setShowMaterialIssuingForm] = useState(false);
    const [showMaterialTransferForm, setShowMaterialTransferForm] = useState(false);
    const [showNewPurchaseIndentForm, setShowNewPurchaseIndentForm] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState();
    const [availableMaterial, setAvailableMaterial] = useState([]);
    const [locations, setLocations] = useState([]);
    const [activeSelectedItem, setActiveSelectedItem] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchFilterValue, setSearchFilterValue] = useState({
        searchValue: "",
        searchByLocation: ""
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader);
    const pageLimit = process.env.REACT_APP_PAGE_LIMIT;

    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchFilterValue?.searchValue) {
            fetchAllMaterialsBySearch(searchFilterValue?.searchValue, selectedPage + 1);
        } else {
            fetchAllAvailableMaterial(selectedPage + 1);
        }
    }

    // Search filter
    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchAllMaterialsBySearch(searchString, currentPage + 1);
        } else {
            const serverResponse = await communication.getAllAvailableMaterialList();
            if (serverResponse?.data?.status === "SUCCESS") {
                setAvailableMaterial(serverResponse?.data?.availableMaterial);
                setSelectedBrand(serverResponse?.data?.availableMaterial[0]);
                setPageCount(serverResponse?.data?.totalPages);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate('/login');
            } else {
                setAvailableMaterial([]);
                setSelectedBrand({});
                setPageCount(0);
            }
        }
    }

    //fetch All Materials By Search
    const fetchAllMaterialsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchInventoryAllMaterials(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setAvailableMaterial(responseFromServer?.data?.availableMaterial);
                setSelectedBrand(responseFromServer?.data?.availableMaterial[0]);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer?.data?.message, icon: "warning" });
                navigate('/login');
            } else {
                setAvailableMaterial([]);
                setSelectedBrand({});
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    // get Available material on initial load
    const fetchAllAvailableMaterial = async (page) => {
        try {
            dispatch(showLoader());
            const serverResponse = await communication.getAllAvailableMaterialList(page);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                setAvailableMaterial(serverResponse?.data?.availableMaterial);
                setSelectedBrand(serverResponse?.data?.availableMaterial[0]);
                setPageCount(serverResponse?.data?.totalPages);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: serverResponse?.data?.message, icon: "warning" });
                navigate('/login');
            } else {
                dispatch(hideLoader());
                setAvailableMaterial([]);
                setSelectedBrand({});
                setPageCount(0);
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }

    }


    //get all Locations data on initial load
    const fetchLocation = async () => {
        try {
            dispatch(
                showLoader()
            )
            const responseFromServer = await communication.getAllLocations();
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                //if User is director set all Locations otherWise set location according to accessControl
                if (loginUserDetails?.designation?.toLowerCase() === "director") {
                    setLocations(responseFromServer?.data?.locations);
                } else {
                    let location = [];
                    loginUserDetails?.accessControl?.forEach(access => {
                        responseFromServer?.data?.locations?.forEach((locationDetails) => {
                            if (access?.locationName === locationDetails?.locationName) {
                                location.push(locationDetails);
                            }
                        })
                    });
                    setLocations(location);

                }
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setLocations([]);
                dispatch(hideLoader())
            }

        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }
    }

    //get  available material by location
    const getAvailableMaterialByLocation = async (locationId) => {
        try {
            if (locationId) {
                dispatch(showLoader());
                const serverResponse = await communication.fetchAvailableMaterialByLocation(locationId);
                if (serverResponse?.data?.status === "SUCCESS") {
                    setAvailableMaterial(serverResponse?.data?.availableMaterial);
                    setSelectedBrand(serverResponse?.data?.availableMaterial[0]);
                    dispatch(hideLoader());
                } else if (serverResponse?.data?.status === "JWT_INVALID") {
                    dispatch(hideLoader());
                    swal({ text: serverResponse.data?.message, icon: "warning" });
                    navigate('/login');
                } else {
                    setAvailableMaterial([]);
                    setSelectedBrand({});
                    dispatch(hideLoader());
                }
            } else {
                fetchAllAvailableMaterial();
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error?.message, icon: "error" });
        }

    }

    useEffect(() => {
        fetchAllAvailableMaterial();
        fetchLocation();
    }, []);

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="dashboard_tab_main">
                        <h4 className="title">Inventory - All Material</h4>
                        <div className="row all_material_search_box_main">
                            <div className="col-lg-3 all_material_search_box">
                                <input type="text" onChange={(event) => { searchFilter(event.target.value); setSearchFilterValue({ ...searchFilterValue, searchValue: event.target.value }) }} className='form=control' placeholder='search by material, brand' title="search by material, brand" />
                            </div>
                            <div className="col-lg-9 all_material_button">
                                <select className="form-select" onChange={(event) => { setSearchFilterValue({ ...searchFilterValue, searchByLocation: JSON.parse(event.target.value)?._id }); getAvailableMaterialByLocation(JSON.parse(event.target.value)?._id); }} >
                                    <option value={JSON.stringify({ _id: "" })}>Select Location</option>
                                    {locations?.map((location) => {
                                        return (
                                            <option value={JSON.stringify(location)} selected={location?._id === searchFilterValue?.searchByLocation}>{location.locationName}</option>
                                        )
                                    })}
                                </select>
                                {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.material_issuing_edit === true) ?
                                    <button onClick={() => { setShowMaterialIssuingForm(true) }}>Issue</button>
                                    :
                                    <></>
                                }

                                {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.material_transfer_edit === true) ?
                                    <button className="ms-3 me-3" onClick={() => setShowMaterialTransferForm(true)}>Transfer</button>
                                    :
                                    <></>
                                }
                                {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.create_purchase_indent === true) ?
                                    <button onClick={() => setShowNewPurchaseIndentForm(true)}>New Purchase Indent</button>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-lg-6 col-md-12 ps-lg-0">
                                <div className="table_section all_material_section">
                                    <div className="all_material_table">
                                        <div className="table_header">
                                            <div className="row m-0">
                                                <div className="col-lg-1 col-md-1">
                                                    <h5>Sr.No</h5>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <h5>Material Name</h5>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <h5>Make/Brand</h5>
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <h5>Available Quantity</h5>
                                                </div>
                                            </div>
                                        </div>
                                        {availableMaterial?.length > 0 ?
                                            <>
                                                {availableMaterial?.map((material, index) => {
                                                    return (
                                                        <div className={`table_data ${activeSelectedItem !== index ? "" : "active_selected_row"}`} key={index}>
                                                            <div className="row m-0">
                                                                <div className="col-lg-1 col-md-1">
                                                                    <h5>{pageLimit * currentPage + (index + 1)}</h5>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 data click_event">
                                                                    <h5 onClick={() => { setSelectedBrand(material); setActiveSelectedItem(index) }} ><a>{material?.materialName}</a></h5>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 data">
                                                                    <h5>{material?.brand}</h5>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 data">
                                                                    <h5>{material?.materialQuantity}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                            :
                                            <div className="data_not_available_message">
                                                <h5>Material Not Available in Inventory</h5>
                                            </div>

                                        }


                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 ps-lg-0 pe-lg-0">
                                <AllMaterialDetail selectedBrand={selectedBrand} />
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                        {showMaterialIssuingForm &&
                            < div className="modal_box">
                                <MaterialIssuingForm setShowMaterialIssuingForm={setShowMaterialIssuingForm} />
                            </div>
                        }
                        {showMaterialTransferForm &&
                            < div className="modal_box">
                                <MaterialTransferForm setShowMaterialTransferForm={setShowMaterialTransferForm} />
                            </div>
                        }
                        {showNewPurchaseIndentForm &&
                            < div className="modal_box">
                                <NewPurchaseIndentForm setShowNewPurchaseIndentForm={setShowNewPurchaseIndentForm} />
                            </div>
                        }
                    </div >
            }
        </>
    );
}
export default AllMaterial;