import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { communication } from '../communication/communication';
import Loader from '../Modals/Loader';
import { hideLoader, showLoader } from '../redux-store/loaderReducer';
import { useMemo } from "react";

const DashboardTab = () => {

    const [searchFilterValue, setSearchFilterValue] = useState({
        searchByLocation: "",
    });
    const [date, setDate] = useState({
        startDate: "",
        endDate: "",
    });
    const { searchByLocation } = searchFilterValue;

    const [machineDetail, setMachineDetail] = useState([]);
    const [inventoryDetails, setInventoryDetails] = useState([]);
    const [materialDetails, setMaterialDetails] = useState([])
    const [locationArray, setLocationArray] = useState([]);
    const [filteredMachineDetail, setFilteredMachineDetail] = useState([])
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    //Fetch dashboard data on initial load
    const fetchDashboardData = async () => {
        try {
            dispatch(showLoader());
            const responseFromServer = await communication.machineData();
            if (responseFromServer?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                setMachineDetail(responseFromServer?.data?.machineDetails);
                setInventoryDetails(responseFromServer?.data?.inventoryDetails);
                setMaterialDetails(responseFromServer?.data?.materialDetails)
                setFilteredMachineDetail(responseFromServer?.data?.machineDetails);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: responseFromServer.data?.message, icon: "warning" });
            } else {
                dispatch(hideLoader());
                setMachineDetail([]);
                setInventoryDetails([]);
                setMaterialDetails([])
            }
        } catch (error) {
            dispatch(hideLoader());
            swal({ text: error.message, icon: "error" })
        }
    };

    //Fetch dashboard data by search
    const fetchDashBoardDataBySearch = async (locationId, startDate, endDate) => {
        try {
            if (locationId || (startDate && endDate)) {
                const responseFromServer = await communication.machineData(locationId, startDate, endDate);
                if (responseFromServer?.data?.status === "SUCCESS") {
                    setInventoryDetails(responseFromServer?.data?.inventoryDetails);
                    setMachineDetail(responseFromServer?.data?.machineDetails);
                    setFilteredMachineDetail(responseFromServer?.data?.machineDetails);
                    setMaterialDetails(responseFromServer?.data?.materialDetails);
                } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                    dispatch(hideLoader())
                    swal({ text: responseFromServer.data?.message, icon: "warning" });
                    navigate("/login");
                } else {
                    dispatch(hideLoader());
                    setMachineDetail([]);
                    setInventoryDetails([]);
                    setMaterialDetails([])
                }
            } else {
                setSearchFilterValue({ searchByLocation: "", });
                setDate({ startDate: "", endDate: "" });
                fetchDashboardData();
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    //Fetch All Locations
    const fetchLocations = async () => {
        try {
            const responseFromServer = await communication.getAllLocations();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setLocationArray(responseFromServer?.data?.locations)
            } else {
                setLocationArray([]);
            }
        } catch (error) {
            swal({ text: error.message, icon: "error" })
        }
    }

    // Get data datewise on startDate change
    // const onStartDateChange = async (event) => {
    //     try {
    //         let startDate = event.target.value;
    //         const fetchMachineDetail = await communication.machineData(startDate, data.endDate);
    //         if (fetchMachineDetail?.data?.status === "SUCCESS") {
    //             setFilteredMachineDetail(fetchMachineDetail?.data?.machineDetails);
    //             setInventoryDetails(fetchMachineDetail?.data?.inventoryDetails);
    //             setMaterialDetails(fetchMachineDetail?.data?.machineDetails);
    //             setMachineDetail(fetchMachineDetail?.data?.machineDetails);
    //         } else {
    //             setFilteredMachineDetail([])
    //             setInventoryDetails([]);
    //             setMaterialDetails([])
    //             setMachineDetail([]);

    //         }
    //     } catch (error) {
    //         swal({ text: error.message, icon: "error" })
    //     }
    // }

    // Get data datewise on endDate change
    // const onEndDateChange = async (event) => {
    //     try {
    //         let endDate = event.target.value;
    //         const fetchMachineDetail = await communication.machineData(data.startDate, endDate);
    //         if (fetchMachineDetail?.data?.status === "SUCCESS") {
    //             setFilteredMachineDetail(fetchMachineDetail?.data?.machineDetails);
    //         } else {
    //             setMachineDetail([]);
    //         }
    //     } catch (error) {
    //         swal({ text: error.message, icon: "error" })
    //     }
    // }

    //show location wise data
    const showLocationWiseData = (event) => {

        const filteredDetail = machineDetail?.filter((ele) => {
            if (ele?.locationName?.toLowerCase() === event?.target?.value?.toLowerCase()) {
                return ele
            }
        })
        setFilteredMachineDetail(filteredDetail);
    }

    useEffect(() => {
        fetchDashboardData();
        fetchLocations();
    }, []);

    useMemo(() => {
        fetchDashBoardDataBySearch(searchByLocation, date?.startDate, date?.endDate);
    }, [searchByLocation]);

    useMemo(() => {
        if (date?.startDate && date?.endDate) {
            fetchDashBoardDataBySearch(searchByLocation, date?.startDate, date?.endDate)
        }
    }, [date]);

    return (
        <>
            {loader?.showHideLoader === true ?
                <Loader />
                :
                <div className="dashboard_tab_main">
                    <h4 className="title">Dashboard</h4>
                    <div className="row search_box">
                        <div className="col-lg-3 col-md-4 mt-lg-0 mt-md-1 ps-lg-2 ps-md-2">
                            {/* <label>From</label> */}
                            <input type="date" value={date?.startDate} onChange={(event) => setDate({ ...date, startDate: event.target.value })} className='form-control' placeholder="From" />
                        </div>
                        <div className="col-lg-3 col-md-4 mt-lg-0 mt-md-1">
                            {/* <label>To</label> */}
                            <input type="date" value={date?.endDate} onChange={(event) => setDate({ ...date, endDate: event.target.value })} className='form-control' placeholder="To" />
                        </div>
                        <div className="col-lg-3 col-md-4 mt-lg-0 mt-md-1">
                            <select className="form-select" onChange={(event) => setSearchFilterValue({ ...searchFilterValue, searchByLocation: JSON.parse(event.target.value)?._id })}>
                                <option value={JSON.stringify({ _id: "" })} selected>Select Location</option>
                                {locationArray?.map((location) => (
                                    <option value={JSON.stringify(location)} selected={location?._id === searchByLocation}>{location?.locationName}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="row m-0">
                        <div className="col-lg-6 ps-0 pe-lg-3 pe-md-0">
                            <div className="Dashboard_table_main">
                                <h5>Stock Statement Inventory</h5>
                                <div className="dashboard_table stock_statement_inventory">
                                    <div className="table_heading">
                                        <div className="sr_no">
                                            <h5>Sr. No.</h5>
                                        </div>
                                        <div className="material_name">
                                            <h5>Material Name</h5>
                                        </div>
                                        <div className="location">
                                            <h5>Location</h5>
                                        </div>
                                        <div className="site_name">
                                            <h5>Site</h5>
                                        </div>
                                        <div className="opening_date">
                                            <h5>Opening Selected from Date</h5>
                                        </div>
                                        <div className="consumption_range">
                                            <h5>Consumption range</h5>
                                        </div>

                                    </div>
                                    {inventoryDetails?.length > 0 ?
                                        <>
                                            {inventoryDetails?.map((inventoryData, index) => {
                                                return (
                                                    <div className="table_content" key={index}>
                                                        <div className="sr_no">
                                                            <h5>{index + 1}</h5>
                                                        </div>
                                                        <div className="material_name">
                                                            <h5>{inventoryData?.materialName}</h5>
                                                        </div>
                                                        <div className="location">
                                                            <h5>{inventoryData?.locationName}</h5>
                                                        </div>
                                                        <div className="site_name">
                                                            <h5>{inventoryData?.siteName}</h5>
                                                        </div>
                                                        <div className="opening_date">
                                                            <h5>{new Date(inventoryData?.grnDate).toLocaleDateString().split("/").join("-")}</h5>
                                                        </div>
                                                        <div className="consumption_range">
                                                            <h5>{inventoryData?.availableMaterialQuantity}</h5>
                                                        </div>

                                                    </div>
                                                );
                                            })}
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Inventory Data Not Available</h5>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-0 pe-lg-3 pe-md-0">
                            <div className="Dashboard_table_main">
                                <h5>Machinery List</h5>
                                <div className="dashboard_table dashboard_machinery_list">
                                    <div className="table_heading">
                                        <div className="sr_no">
                                            <h5>Sr. No.</h5>
                                        </div>
                                        <div className="material_name">
                                            <h5>Machine Name</h5>
                                        </div>
                                        <div className="location">
                                            <h5>Location</h5>
                                        </div>
                                        <div className="site_name">
                                            <h5>Site</h5>
                                        </div>
                                        <div className="opening_date">
                                            <h5>Fuel Taken from Date(ltr)</h5>
                                        </div>
                                        <div className="consumption_range">
                                            <h5>Reading on from Date</h5>
                                        </div>

                                    </div>
                                    {filteredMachineDetail?.length > 0 ?
                                        <>
                                            {filteredMachineDetail?.map((machineData, index) => {
                                                return (
                                                    <div className="table_content" key={index}>
                                                        <div className="sr_no">
                                                            <h5>{index + 1}</h5>
                                                        </div>
                                                        <div className="material_name">
                                                            <h5>{machineData?.machineName}</h5>
                                                        </div>
                                                        <div className="location">
                                                            <h5>{machineData?.locationName}</h5>
                                                        </div>
                                                        <div className="site_name">
                                                            <h5>{machineData?.siteName}</h5>
                                                        </div>
                                                        <div className="opening_date">
                                                            <h5>{machineData?.totalFuel}</h5>
                                                        </div>
                                                        <div className="consumption_range">
                                                            <h5>{machineData?.lastReading ?? "-"}</h5>
                                                        </div>

                                                    </div>
                                                );
                                            })}
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Machine Data Not Available</h5>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0">
                        <div className="col-lg-6 ps-0 pe-lg-3 pe-md-0">
                            <div className="Dashboard_table_main">
                                <h5>Site Material Statement</h5>
                                <div className="dashboard_table site_material_statement">
                                    <div className="table_heading">
                                        <div className="sr_no">
                                            <h5>Sr. No.</h5>
                                        </div>
                                        <div className="material_name">
                                            <h5>Material Name</h5>
                                        </div>
                                        <div className="location">
                                            <h5>Location</h5>
                                        </div>
                                        <div className="site_name">
                                            <h5>Site</h5>
                                        </div>
                                        <div className="opening_date">
                                            <h5>Opening Selected from Date</h5>
                                        </div>
                                        <div className="consumption_range">
                                            <h5>Consumption range</h5>
                                        </div>

                                    </div>
                                    <div className="table_content">
                                        <div className="sr_no">
                                            <h5>1</h5>
                                        </div>
                                        <div className="material_name">
                                            <h5>Material Name</h5>
                                        </div>
                                        <div className="location">
                                            <h5>Location</h5>
                                        </div>
                                        <div className="site_name">
                                            <h5>Abhyankar Nagar</h5>
                                        </div>
                                        <div className="opening_date">
                                            <h5>10-05-2022</h5>
                                        </div>
                                        <div className="consumption_range">
                                            <h5>xyz</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-0 pe-0">
                            <div className="Dashboard_table_main">
                                <h5>Total Material Received Statement</h5>
                                <div className="dashboard_table material_received_statement">
                                    <div className="table_heading">
                                        <div className="sr_no">
                                            <h5>Sr. No.</h5>
                                        </div>
                                        <div className="material_name">
                                            <h5>Machine Name</h5>
                                        </div>
                                        <div className="location">
                                            <h5>Location</h5>
                                        </div>
                                        <div className="site_name">
                                            <h5>Site</h5>
                                        </div>
                                        <div className="opening_date">
                                            <h5>Total Received </h5>
                                        </div>
                                        <div className="unit">
                                            <h5>Unit</h5>
                                        </div>

                                    </div>
                                    {materialDetails?.length > 0 ?
                                        <>
                                            {materialDetails?.map((materialData, index) => {
                                                return (
                                                    <div className="table_content" key={index}>
                                                        <div className="sr_no">
                                                            <h5>{index + 1}</h5>
                                                        </div>
                                                        <div className="material_name">
                                                            <h5>{materialData?.material}</h5>
                                                        </div>
                                                        <div className="location">
                                                            <h5>{materialData?.location}</h5>
                                                        </div>
                                                        <div className="site_name">
                                                            <h5>{materialData?.site}</h5>
                                                        </div>
                                                        <div className="opening_date">
                                                            <h5>{materialData?.totalMaterialRecieved}</h5>
                                                        </div>
                                                        <div className="unit">
                                                            <h5>{materialData?.unit}</h5>
                                                        </div>

                                                    </div>
                                                );
                                            })}
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Material Data Not Available</h5>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </>
    );
}
export default DashboardTab;