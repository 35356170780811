import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../../Modals/Loader";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";


const NewPurchaseIndentForm = ({ setShowNewPurchaseIndentForm }) => {
    const date = new Date();
    const navigate = useNavigate();
    const loginUserDetails = useSelector((state) => state.userDetails);
    const [validator, showValidationMessage] = useValidator();
    const [unlimitedQuantity, setUnlimitedQuantity] = useState(false)
    const [quantityAvailableInStore, setQuantityAvailableInStore] = useState();
    const [category, setCategory] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [locations, setLocations] = useState([]);
    const [sites, setSites] = useState([]);
    const [newPurchaseIndent, setNewPurchaseIndent] = useState(
        {
            categoryId: "",
            materialId: "",
            locationId: "",
            siteId: "",
            deliveryTo: "",
            materialDescription: "",
            // quantityAvailableInStore: "",
            quantityRequired: "",
            materialTechSpecification: "",
            requiredFor: "",
            remark: "",
        }
    )
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();


    const { categoryId, materialId, locationId, siteId, deliveryTo, materialDescription, quantityRequired, materialTechSpecification, requiredFor, remark } = newPurchaseIndent;

    let createdBy = loginUserDetails;

    //Fetch all categories on initial load
    const fetchCategory = async () => {
        try {
            const responseFromServer = await communication.getAllCategory();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setCategory(responseFromServer?.data?.category);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setCategory([]);
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }

    }

    //Get CategoryWise Material
    const categoryWiseMaterial = async (categoryId) => {
        try {
            const responseFromServer = await communication.getMaterialByCategory(categoryId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setMaterials(responseFromServer?.data?.materials);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Get All Locations
    const fetchLocations = async () => {
        try {
            const responseFromServer = await communication.getAllLocations();
            if (responseFromServer?.data?.status === "SUCCESS") {
                setLocations(responseFromServer?.data?.locations);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setLocations([]);
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Get locationWise Sites
    const locationWiseSites = async (locationId) => {
        try {
            const responseFromServer = await communication.getLocationWiseSites(locationId);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setSites(responseFromServer?.data?.sites);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    //Get Material wise Quantity
    const materialWiseQuantity = async (materialId) => {
        try {
            const serverResponse = await communication.getStockByMaterial(materialId);
            if (serverResponse?.data?.status === "SUCCESS") {
                setQuantityAvailableInStore(serverResponse?.data?.availableQuantity);
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setQuantityAvailableInStore(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        fetchCategory();
        fetchLocations();
    }, []);

    const addNewPurchaseIndent = async () => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader())
            const dataToSend = {
                categoryId, materialId, unlimitedQuantity, locationId, siteId, deliveryTo, createdBy,
                materialDescription, quantityAvailableInStore, quantityRequired, materialTechSpecification, requiredFor, remark
            }

            const serverResponse = await communication.addPurchaseIndent(dataToSend);
            if (serverResponse?.data?.status === "SUCCESS") {
                dispatch(hideLoader());
                setShowNewPurchaseIndentForm(false);
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
            dispatch(hideLoader());
        }
    }

    return (
        <>
        {
            loader.showHideLoader === true ?
            <Loader />
            :
            <div className="issue_transfer">
                <div className="form">
                    <div className="form_heading">
                        <h5>New Purchase Indent</h5>
                    </div>
                    <div className="row mt-4" style={{ textAlign: "left" }}>
                        <div className="col-lg-4 col-md-3">
                            <h5><b>Date : {`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`}</b></h5>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <h5><b>From : {createdBy?.userName}</b></h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <select name="categoryName" className="form-select" onChange={(event) => { setNewPurchaseIndent({ ...newPurchaseIndent, categoryId: JSON.parse(event.target.value)?._id }); categoryWiseMaterial(JSON.parse(event.target.value)?._id) }}>
                                <option value={JSON.stringify({ _id: "" })}>Select Category</option>
                                {category?.map((categories, index) => (
                                    <option value={JSON.stringify(categories)} key={index}>{categories?.categoryName}</option>
                                ))}
                            </select>
                            {validator.message("categoryName", categoryId, "required", {
                                messages: {
                                    required: "Category is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <select name="material" className="form-select" onChange={(event) => { setNewPurchaseIndent({ ...newPurchaseIndent, materialId: JSON.parse(event.target.value)?._id }); materialWiseQuantity(JSON.parse(event.target.value)?._id) }}>
                                <option value={JSON.stringify({ _id: "" })}>Select Material</option>
                                {materials?.map((material, index) => (
                                    <option value={JSON.stringify(material)} key={index}>{material?.materialName}</option>
                                ))}
                            </select>
                            {validator.message("material", materialId, "required", {
                                messages: {
                                    required: "Material is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <select name="locationName" className="form-select" onChange={(event) => { setNewPurchaseIndent({ ...newPurchaseIndent, locationId: JSON.parse(event.target.value)?._id }); locationWiseSites(JSON.parse(event.target.value)?._id) }}>
                                <option value={JSON.stringify({ _id: "" })} selected>Select Location</option>
                                {locations?.map((location, index) => (
                                    <option value={JSON.stringify(location)} key={index}>{location?.locationName}</option>
                                ))}
                            </select>
                            {validator.message("locationName", locationId, "required", {
                                messages: {
                                    required: "Location is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <select name="siteName" className="form-select" onChange={(event) => setNewPurchaseIndent({ ...newPurchaseIndent, siteId: JSON.parse(event.target.value)?._id })}>
                                <option value={JSON.stringify({ _id: "" })} selected>Select Site</option>
                                {sites?.map((site, index) => (
                                    <option value={JSON.stringify(site)} key={index}>{site?.siteName}</option>
                                ))}
                            </select>
                            {validator.message("siteName", siteId, "required", {
                                messages: {
                                    required: "Site is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <select name="deliveryTo" className="form-select" onChange={(event) => setNewPurchaseIndent({ ...newPurchaseIndent, deliveryTo: event.target.value })}>
                                <option value="" selected>Deliver To</option>
                                <option value="Site">Site</option>
                                <option value="Inventory">Inventory</option>
                            </select>
                            {validator.message("deliveryTo", deliveryTo, "required", {
                                messages: {
                                    required: "Requirement Location is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input_group">
                                <input type="text" name="materialDescription" onChange={(event) => setNewPurchaseIndent({ ...newPurchaseIndent, materialDescription: event.target.value })} className="form-control" autoComplete="off" required />
                                <label className="input_label">Material Description</label>
                            </div>
                            {validator.message("materialDescription", materialDescription, "required", {
                                messages: {
                                    required: "Material description is required"
                                }
                            })}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="input_group">
                                <input type="text" name="quantityAvailableInStore" value={quantityAvailableInStore} className="form-control" autoComplete="off" required />
                                <label className="input_label">Quantity Available in Store</label>
                            </div>
                        </div>
                        {unlimitedQuantity === false &&
                            <div className="col-lg-4 col-md-6">
                                <div className="input_group">
                                    <input type="text" name="quantityRequired" onChange={(event) => setNewPurchaseIndent({ ...newPurchaseIndent, quantityRequired: event.target.value })} className="form-control" autoComplete="off" required />
                                    <label className="input_label">Required Quantity</label>
                                </div>
                                {validator.message("quantityRequired", quantityRequired, "required|numeric", {
                                    messages: {
                                        required: "Required Quantity is required"
                                    }
                                })}
                            </div>
                        }
                        {[undefined, null, ""].includes(quantityRequired) &&
                            <div className="col-lg-4 col-md-6 unlimited_quantity_checkbox">
                                <h5>Check this for Unlimited QTY</h5>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" onChange={() => setUnlimitedQuantity(!unlimitedQuantity)} />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="input_group">
                                <input type="text" name="materialTechSpecification" onChange={(event) => setNewPurchaseIndent({ ...newPurchaseIndent, materialTechSpecification: event.target.value })} className="form-control" autoComplete="off" required />
                                <label className="input_label">Specification</label>
                            </div>
                            {validator.message("materialTechSpecification", materialTechSpecification, "required", {
                                messages: {
                                    required: "Specification is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input_group">
                                <input type="text" name="requiredFor" onChange={(event) => setNewPurchaseIndent({ ...newPurchaseIndent, requiredFor: event.target.value })} className="form-control" autoComplete="off" required />
                                <label className="input_label">Required For</label>
                            </div>
                            {validator.message("requiredFor", requiredFor, "required|alpha_space", {
                                messages: {
                                    required: "Field is required"
                                }
                            })}
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="input_group">
                                <input type="text" name="remark" onChange={(event) => setNewPurchaseIndent({ ...newPurchaseIndent, remark: event.target.value })} className="form-control" autoComplete="off" required />
                                <label className="input_label">Remark</label>
                            </div>
                        </div>
                    </div>
                    <div className="form_button">
                        <button onClick={() => addNewPurchaseIndent()}>Add</button>
                    </div>
                    <div className="close_form">
                        <i className="fa-solid fa-xmark" onClick={() => setShowNewPurchaseIndentForm(false)}></i>
                    </div>
                </div>
            </div>
        }
            
        </>
    );
}
export default NewPurchaseIndentForm;