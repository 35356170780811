import React, { useRef, useState } from "react";
import { communication } from "../../communication/communication";
import { useNavigate } from "react-router-dom";
import useValidator from "../../formValidation/useValidator";
import swal from "sweetalert";

const AddNewSite = ({ setShowAddSiteForm, selectedLocationSite, showLocationDetails }) => {
    const [validator, showValidationMessage] = useValidator();
    const [siteName, setSiteName] = useState();
    const navigate = useNavigate();

   
    //add new site
    const addSite = async () => {
        try {
            //check SiteName is added or not
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }

            //send request to backend
            const serverResponse = await communication.addSite(selectedLocationSite.locationId, siteName);
            if (serverResponse.data.status === "SUCCESS") {
                setShowAddSiteForm(false);
                showLocationDetails();
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse.data.status === "JWT_INVALID") {
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate('/login');
                return;
            } else {
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }

    return (
        <div className="add_new_site">
            <div className="form">
                <div className="form_heading">
                    <h5>Add New Site</h5>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="input_group">
                            <input type="text" name="siteName" onChange={(event) => setSiteName(event.target.value)} className="form-control" autoComplete="off" required />
                            <label className="input_label">Enter Site Name</label>
                        </div>
                        {validator.message("siteName", siteName, "required|alpha_space", {
                            messages: {
                                required: "Site Name is required"
                            }
                        })}
                    </div>
                </div>
                <div className="form_button">
                    <button onClick={() => { addSite() }}>Add</button>
                </div>
                <div className="close_form">
                    <i className="fa-solid fa-xmark" onClick={() => setShowAddSiteForm(false)}></i>
                </div>
            </div>
        </div>
    );
}
export default AddNewSite;