import React from "react";
import { NavLink, Outlet } from "react-router-dom";

const Reports = () => {
    return (
        <div className="dashboard_tab_main">
            <h5 className="title">Inventory - Reports</h5>
            <div className="reports_tabs">
                <div className="row">
                    <div className="col-6">
                        <NavLink to="/main-dashboard/inventory/reports/material-transfer" className={window.location.pathname === "/main-dashboard/inventory/reports" ? "active" : ""} >
                            <li className="tabs">
                                <h5>Material Transfer</h5>
                            </li>
                        </NavLink>
                    </div>
                    <div className="col-6">
                        <NavLink to="/main-dashboard/inventory/reports/material-issue">
                            <li className="tabs">
                                <h5>Material Issuing</h5>
                            </li>
                        </NavLink>
                    </div>
                </div>
            </div>
            <Outlet></Outlet>
        </div>
    );
}
export default Reports;