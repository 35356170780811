import React, { useState } from "react";
import { communication } from "../../communication/communication";
import useValidator from "../../formValidation/useValidator";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../../Modals/Loader";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from "../../redux-store/loaderReducer";

const UpdateUnit = ({ setUpdateUnit, updateUnit }) => {
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [validator, showValidationMessage] = useValidator();
    const [unitName, setUnitName] = useState(updateUnit.unitName);

    //Update unit
    const UpdateUnit = async (unitId) => {
        try {
            //check Validation 
            if (!validator.allValid()) {
                showValidationMessage(true);
                return false;
            }
            dispatch(showLoader());
            const serverResponse = await communication.updateUnit(unitId, unitName);
            if (serverResponse.data.status === "SUCCESS") {
                dispatch(hideLoader());
                setUpdateUnit({ isShowModal: false });
                swal({ text: serverResponse.data?.message, icon: "success" });
            } else if (serverResponse?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                dispatch(hideLoader());
                swal({ text: serverResponse.data?.message, icon: "warning" });
            }

        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }


    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <div className="add_new_unit">
                        <div className="form">
                            <div className="form_heading">
                                <h5>Update Unit</h5>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="input_group">
                                        <input type="text" name="unitName" value={unitName} onChange={(event) => setUnitName(event.target.value)} className="form-control" autoComplete="off" required />
                                        <label className="input_label">Unit Name</label>
                                    </div>
                                    {validator.message("unitName", unitName, "required|alpha_space", {
                                        messages: {
                                            required: "Unit is required"
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="form_button">
                                <button onClick={() => UpdateUnit(updateUnit.unitId)}>Update</button>
                            </div>
                            <div className="close_form">
                                <i className="fa-solid fa-xmark" onClick={() => setUpdateUnit({ isShowModal: false })}></i>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}
export default UpdateUnit;