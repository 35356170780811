import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import AddUnitForm from "../forms/AddUnitForm";
import { showLoader } from "../../redux-store/loaderReducer";
import { hideLoader } from "../../redux-store/loaderReducer";
import Loader from "../../Modals/Loader";
import { communication } from "../../communication/communication";
import UpdateUnit from "../forms/UpdateUnit";
import { confirmAlert } from 'react-confirm-alert';
import ReactPaginate from "react-paginate";
import swal from "sweetalert";

const UnitManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);
    const loginUserDetails = useSelector((state) => state.userDetails);

    const [showUnitForm, setShowUnitForm] = useState(false);
    const [updateUnit, setUpdateUnit] = useState({ isModalShow: false, unitId: "", unitName: "" })
    const [allUnit, setAllUnit] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");

    //set current page on click
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage);
        if (searchValue) {
            fetchUnitsBySearch(searchValue, selectedPage + 1);
        } else {
            getAllUnits(selectedPage + 1);
        }
    }

    const searchFilter = async (searchString) => {
        if (searchString) {
            fetchUnitsBySearch(searchString, currentPage + 1);
        } else {
            const responseFromServer = await communication.getAllUnitsList();
            if (responseFromServer.data.status === "SUCCESS") {
                setAllUnit(responseFromServer?.data?.units);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setAllUnit([]);
                setPageCount(0);
            }
        }
    }

    //fetch units by search
    const fetchUnitsBySearch = async (searchString, page) => {
        try {
            const responseFromServer = await communication.searchUnits(searchString, page);
            if (responseFromServer?.data?.status === "SUCCESS") {
                setAllUnit(responseFromServer?.data?.units);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setAllUnit([]);
                setPageCount(0);
            }
        } catch (error) {
            swal({ text: error?.message, icon: "error" });
        }
    }
    //Fetch All Units on Initial Load
    const getAllUnits = async (page) => {
        try {
            dispatch(showLoader())
            const responseFromServer = await communication.getAllUnitsList(page);
            if (responseFromServer.data.status === "SUCCESS") {
                dispatch(hideLoader())
                setAllUnit(responseFromServer?.data?.units);
                setPageCount(responseFromServer?.data?.totalPages);
            } else if (responseFromServer?.data?.status === "JWT_INVALID") {
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "warning" });
                navigate("/login");
            } else {
                setAllUnit([]);
                setPageCount(0);
                dispatch(hideLoader())
                swal({ text: responseFromServer.data?.message, icon: "error" });
            }
        } catch (error) {
            dispatch(hideLoader())
            swal({ text: error?.message, icon: "error" });
        }
    }

    useEffect(() => {
        getAllUnits();
    }, [updateUnit]);

    //Delete unit 
    const deleteUnit = async (unitId) => {
        confirmAlert({
            title: 'Are you sure you want to Delete Unit?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const serverResponse = await communication.deleteUnit(unitId);
                        if (serverResponse.data.status === "SUCCESS") {
                            getAllUnits();
                            return swal({ text: serverResponse.data?.message, icon: "success" });
                        } else if (serverResponse?.data?.status === "JWT_INVALID") {
                            swal({ text: serverResponse.data?.message, icon: "warning" });
                            navigate("/login");
                        } else {
                            return swal({ text: serverResponse.data?.message, icon: "warning" });
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return false }
                }
            ],
            overlayClassName: "react-confirm-alert"
        });
    }

    return (
        <>
            {
                loader.showHideLoader === true ?
                    <Loader />
                    :
                    <>
                        <div className='search_div' >
                            <input type="text" className='form=control' onChange={(event) => { searchFilter(event.target.value); setSearchValue(event.target.value) }} placeholder='search by unit' title="search by unit" />

                            {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.add_material === true) ?
                                <button onClick={() => setShowUnitForm(true)}>Add New Unit</button>
                                :
                                <></>
                            }
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="table_section material_management_section">
                                    <div className="table_header">
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Sr. No</h5>
                                            </div>
                                            <div className="col-lg-8 col-md-8">
                                                <h5>Unit</h5>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <h5>Action</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {allUnit?.length > 0 ?
                                        <>
                                            {allUnit.map((unit, index) => (
                                                <div className="table_data">
                                                    <div className="row">
                                                        <div className="col-lg-2 col-md-2">
                                                            <h5>{index + 1}</h5>
                                                        </div>
                                                        <div className="col-lg-8 col-md-8">
                                                            <h5>{unit.unitName}</h5>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div className="action">
                                                                <i className="fa-solid fa-pen-to-square" onClick={() => setUpdateUnit({ isModalShow: true, unitId: unit._id, unitName: unit.unitName })}></i>
                                                                <i className="fa-solid fa-trash" onClick={() => deleteUnit(unit._id)}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                        :
                                        <div className="data_not_available_message">
                                            <h5>Unit not Available</h5>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        {pageCount > 1 &&
                            <div className="pagination_wrapper">
                                <ReactPaginate
                                    previousLabel={pageCount === 1 ? "First" : "Prev"}
                                    nextLabel={pageCount <= 1 ? "Last" : "Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    forcePage={currentPage}
                                    activeClassName={"active"} />
                            </div>
                        }
                        {showUnitForm &&
                            < div className="modal_box">
                                <AddUnitForm setShowUnitForm={setShowUnitForm} getAllUnits={getAllUnits} />
                            </div>
                        }
                        {updateUnit.isModalShow &&
                            < div className="modal_box">
                                <UpdateUnit setUpdateUnit={setUpdateUnit} updateUnit={updateUnit} />
                            </div>
                        }

                    </>
            }
        </>
    );
}
export default UnitManagement