import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import InventoryDropdown from "./dropdowns/InventoryDropdown";
import ProcurementDropdown from "./dropdowns/ProcurementDropdown";

const SideNav = () => {
    const [showDropdown, setShowDropdown] = useState({ procurementDrop: false, InventoryDrop: false });
    const loginUserDetails = useSelector((state) => state.userDetails);

    return (
        <div className="dashboard_sidenav">
            <div className="hps_logo">
                <img src="./Images/logo.png" alt="logo.png" />
            </div>
            <ul className="tab_list">
                {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.dashboard === true) ?
                    <NavLink to="/main-dashboard/dashboard" className={window.location.pathname === "/main-dashboard" ? "active" : ""} >
                        <li>
                            <div className="icon"><i class="fa-solid fa-chart-column"></i></div>
                            <div className="tab_title">Dashboard</div>
                        </li>
                    </NavLink>
                    :
                    <></>
                }
                {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.designation_management === true) ?
                    <NavLink to="/main-dashboard/designation-management">
                        <li>
                            <div className="icon"><i class="fa-solid fa-users-gear"></i></div>
                            <div className="tab_title">Designation Management</div>
                        </li>
                    </NavLink>
                    :
                    <></>
                }
                {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.site_management === true) ?
                    <NavLink to="/main-dashboard/site-management">
                        <li>
                            <div className="icon"><i class="fa-solid fa-location-dot"></i></div>
                            <div className="tab_title">Site Management</div>
                        </li>
                    </NavLink>
                    :
                    <></>
                }
                {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.user_management === true) ?
                    <NavLink to="/main-dashboard/user-management">
                        <li>
                            <div className="icon"><i class="fa-solid fa-user-gear"></i></div>
                            <div className="tab_title">User Management</div>
                        </li>
                    </NavLink>
                    :
                    <></>
                }
                {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.material_management === true) ?
                    <NavLink to="/main-dashboard/material-management">
                        <li> <div className="icon"><i class="fa-solid fa-box-open"></i></div>
                            <div className="tab_title">Material Management</div>
                        </li>
                    </NavLink>
                    :
                    <></>
                }
                {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.machine_management === true) ?
                    <NavLink to="/main-dashboard/machine-management">
                        <li>
                            <div className="icon"><i class="fa-solid fa-gears"></i></div>
                            <div className="tab_title">Machine Management</div>
                        </li>
                    </NavLink>
                    :
                    <></>
                }
                {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.sub_contractor === true) ?
                    <NavLink to="/main-dashboard/sub-contractor-management">
                        <li>
                            <div className="icon"><i class="fa-solid fa-person-digging"></i></div>
                            <div className="tab_title">Sub-Contractor Management</div>
                        </li>
                    </NavLink>
                    :
                    <></>
                }

                {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.procurement === true) ?
                    <li onClick={() => setShowDropdown({ ...showDropdown, procurementDrop: !showDropdown.procurementDrop, InventoryDrop: false })}>
                        <div className="icon"><i class="fa-solid fa-truck-droplet"></i></div>
                        <div className="tab_title">Procurement{showDropdown.procurementDrop ? <i className="fa-solid fa-caret-up"></i> : <i className="fa-solid fa-caret-down"></i>}</div>
                    </li>
                    :
                    <></>
                }

                {showDropdown.procurementDrop &&
                    <ProcurementDropdown />
                }


                {loginUserDetails?.designation?.toLowerCase() === "director" || loginUserDetails?.accessControl?.some(access => access?.inventory === true) ?
                    <li onClick={() => setShowDropdown({ ...showDropdown, InventoryDrop: !showDropdown.InventoryDrop, procurementDrop: false })}>
                        <div className="icon"><i class="fa-solid fa-warehouse"></i></div>
                        <div className="tab_title">Inventory{showDropdown.InventoryDrop ? <i className="fa-solid fa-caret-up"></i> : <i className="fa-solid fa-caret-down"></i>}</div>
                    </li>
                    :
                    <></>
                }


                {showDropdown.InventoryDrop &&
                    <InventoryDropdown />
                }







            </ul>
        </div >
    );
}
export default SideNav;