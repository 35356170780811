import { createSlice } from '@reduxjs/toolkit';

const alertModal = createSlice({
    name: "alertSlice",
    initialState: {
        alertModal: false,
        alertMessage: ""
    },

    reducers: {
        showAlertModal: (state, action) => {
            state.alertModal = true;
            state.alertMessage = action?.payload?.alertMessage;
            console.log(state.alertMessage);
            return state;
        },
        hideAlertModal: (state, action) => {
            state.alertModal = false;
            return state;
        },
    }
})
export const { showAlertModal, hideAlertModal } = alertModal.actions;
export default alertModal.reducer;